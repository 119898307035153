// / 本模块实现与IOS(swift)/Android(Java/Kotlin)/PC(Electron)的IPC 通信
// / 用于web应用与native原生(ipad端, android端, 以及云课堂端)进行通信.
// /

function setupWKWebViewJavascriptBridge(callback) {
  if (window.WKWebViewJavascriptBridge) {
    return callback(window.WKWebViewJavascriptBridge);
  }
  if (window.WKWVJBCallbacks) {
    return window.WKWVJBCallbacks.push(callback);
  }
  window.WKWVJBCallbacks = [callback];
  if (
    window.webkit
    && window.webkit.messageHandlers
    && window.webkit.messageHandlers.iOS_Native_InjectJavascript
  ) {
    window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
  }
}

setupWKWebViewJavascriptBridge(bridge => {
  let wkbridge = {};
  wkbridge = {};
  wkbridge.backToLogin = () => bridge.callHandler('backToLogin');
  wkbridge.snedToHost = (...args) => window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(
      JSON.stringify({
        name: 'bellcodeMessageHandle',
        args,
      }),
    );
  const wkbridgeCallbacks = [];

  wkbridge.dispatch = (event, data) => {
    const callbacks = wkbridgeCallbacks[event];
    if (callbacks) {
      callbacks.forEach(callback => {
        callback(event, data);
      });
    }
  };

  wkbridge.on = (event, callback) => {
    if (!wkbridgeCallbacks[event]) {
      wkbridgeCallbacks[event] = [];
    }
    wkbridgeCallbacks[event].push(callback);
  };

  wkbridge.removeAllListeners = event => {
    wkbridgeCallbacks[event] = [];
  };

  window.wkbridge = wkbridge;
});
