const SHOW_DETAIL_IMAGE = 'SHOW_DETAIL_IMAGE';
const HIDE_DETAIL_IMAGE = 'HIDE_DETAIL_IMAGE';
const TOGGLE_TUTORIAL_CONTENT = 'TOGGLE_TUTORIAL_CONTENT';
const TURN_TO_NEXT_STEP = 'TURN_TO_NEXT_STEP';
const TURN_TO_PREVIOUS_STEP = 'TURN_TO_PREVIOUS_STEP';
const initialState = {
    imgSrc: '',
    isShowContent: false,
    enableToggleContentAnimation: false,
    enableDrag: true,
    showDetailImage: false,
    tutorialInfo: null,
    curStep: 1,
    totalStep: 1,
    curContent: null,
    curTips: null
};

const reducer = (state, action) => {
    if (typeof state === 'undefined') return initialState;
    switch (action.type) {
        case SHOW_DETAIL_IMAGE:
            return Object.assign({}, state, {
                showDetailImage: true,
                imgSrc: action.imgSrc
            });
        case HIDE_DETAIL_IMAGE:
            return Object.assign({}, state, {
                showDetailImage: false,
                imgSrc: ''
            });
        case TOGGLE_TUTORIAL_CONTENT:
            return Object.assign({}, state, {
                isShowContent: !state.isShowContent,
                enableToggleContentAnimation: true,
                enableDrag: !state.enableDrag
            });
        case TURN_TO_NEXT_STEP:
            return Object.assign({}, state, {
                curStep: ++state.curStep
            });
        case TURN_TO_PREVIOUS_STEP:
            return Object.assign({}, state, {
                curStep: --state.curStep
            });
        default:
            return state;
    }
};

const showDetailImage = imgSrc => ({
    type: SHOW_DETAIL_IMAGE,
    imgSrc
});

const hideDetailImage = () => ({
    type: HIDE_DETAIL_IMAGE
});

const toggleTutorialContent = () => ({
    type: TOGGLE_TUTORIAL_CONTENT
});

const turnToNextStep = () => ({
    type: TURN_TO_NEXT_STEP
});

const turnToPreviousStep = () => ({
    type: TURN_TO_PREVIOUS_STEP
});

export {
    reducer as default,
    initialState as tutorialInitialState,
    toggleTutorialContent,
    showDetailImage,
    turnToNextStep,
    turnToPreviousStep,
    hideDetailImage
};
