exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".account-info_account-info_c6ea1 {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    height: 100%;\n    padding: 0 0.75rem;\n    position: relative;\n}\n\n.account-info_profile-icon_2NqaG {\n    margin: 0 0.5rem 0 0.25rem;\n    width: 2rem;\n    border-radius: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"account-info": "account-info_account-info_c6ea1",
	"accountInfo": "account-info_account-info_c6ea1",
	"profile-icon": "account-info_profile-icon_2NqaG",
	"profileIcon": "account-info_profile-icon_2NqaG"
};