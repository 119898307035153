import { introJs } from 'intro.js';
import 'intro.js/introjs.css';
import './index.css';
import { renderBellRobotAnimation } from './animations';

export function initIntro(opts) {
  const intro = introJs();
  intro.setOptions({
    steps: [],
    exitOnOverlayClick: false,
    keyboardNavigation: false,
    showStepNumbers: false,
    showBullets: false,
    hidePrev: true,
    hideNext: true,
    nextLabel: '我知道了',
    doneLabel: '我知道了',
    tooltipClass: 'intro-tips',
    highlightClass: 'intro-highlight',
    disableInteraction: false,
    ...opts,
  });
  return intro;
}

export function startIntro(intros, oncomplete) {
  const steps = [];
  const styleFns = [];
  let opt = {};
  intros.forEach(item => {
    const { step, styleFn, ...otherOpt } = item;
    opt = { ...opt, ...otherOpt };
    steps.push(step);
    styleFns.push(styleFn);
  });
  const intro = initIntro({ steps, ...opt });
  intro.start();
  styleFns[0] && styleFns[0]();
  intro.onafterchange(() => {
    styleFns[intro._currentStep] && styleFns[intro._currentStep]();
  });
  oncomplete && intro.oncomplete(oncomplete);
  oncomplete && intro.onbeforeexit(oncomplete);
  setTimeout(renderBellRobotAnimation, 500);
  return intro;
}
