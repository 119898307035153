import React from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import {
  AudioManager,
  AudioManagerType,
} from 'PublicRepo/components/AudioManager/audioManager';

import EmptyStars from '../img/empty-star.png';

import './index.less';
import LoadStar from '../img/load-star.json';

class Stars extends React.Component {
  static propTypes = {
    number: PropTypes.number.isRequired,
    isLast: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.refStars = [];
  }

  componentDidMount() {
    const animations = [];

    for (let i = 0; i < this.props.number; i++) {
      animations[i] = lottie.loadAnimation({
        container: this.refStars[i], // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: LoadStar, // the path to the animation json
      });
    }

    for (let i = 0; i < this.props.number; i++) {
      if (this.props.isLast) {
        // 重现不显示动效
        animations[i].goToAndStop(100, true);
      } else {
        setTimeout(() => {
          AudioManager.play(AudioManagerType.STAR);
          animations[i].play();
        }, 500 + (500 * i));
      }
    }
  }

  render() {
    return (
      <div className="stars">
        <img src={EmptyStars} className="empty-stars" />
        <div
          className="star left"
          ref={e => {
            this.refStars[0] = e;
          }}
        />
        <div
          className="star mid"
          ref={e => {
            this.refStars[1] = e;
          }}
        />
        <div
          className="star right"
          ref={e => {
            this.refStars[2] = e;
          }}
        />
      </div>
    );
  }
}

export default Stars;
