import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../../components/box/box';
import classNames from 'classnames';

import styles from './python-target-area.css';
import Puzzle2LeftPanel from '../../../containers/puzzle2-left-panel';
import TargetIcon from '../../python-puzzle/assets/target-icon.svg';

const PythonTargetArea = props => {
    const {className} = props;
    return (
        <Box
            className={classNames(styles.target, className)}
            id="target-content"
        >
            <Box className={styles.richText}>
                <Box className={styles.header}>
                    <img
                        className={styles.targetIcon}
                        src={TargetIcon}
                    />
                    <Box>目标</Box>
                </Box>
                <Puzzle2LeftPanel />
            </Box>
        </Box>
    );
};
PythonTargetArea.propTypes = {
    className: PropTypes.string
};

export default PythonTargetArea;
