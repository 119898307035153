import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';

const SkipPuzzle = props => (
  <div className="answer answer-try">
    <span className="answer-bg" />
    <p className="answer-txt">
      {i18n.get('puzzle-answer_If you skip this puzzle, you can learn the next lesson directly, but unfortunately you can not get any coins now. you can redo this puzzle at any time later.')}
    </p>
    <div>
      <span
        role="button"
        tabIndex="0"
        className="answer-btn answer-btn--better"
        onClick={props.onClose}
      >
        {i18n.get('puzzle-answer_Forget it, I\'ll try again')}
      </span>
      <span
        role="button"
        tabIndex="0"
        className="answer-btn"
        onClick={props.skipPuzzle}
      >
        {i18n.get('puzzle-answer_Skip it')}
      </span>
    </div>
  </div>
);

SkipPuzzle.propTypes = {
  onClose: PropTypes.func.isRequired,
  skipPuzzle: PropTypes.func.isRequired,
};

export default SkipPuzzle;

// {i18n.get('')}
