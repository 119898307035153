import React from 'react';
import PropTypes from 'prop-types';
import styles from './activity-save-tips.css';

const ActivitySaveTips = props => {
    const {saveTipsText} = props;
    return (
        <div className={styles.activitySaveTips}>
            <p>{saveTipsText}</p>
        </div>
    );
};

ActivitySaveTips.propTypes = {
    saveTipsText: PropTypes.string
};

export default ActivitySaveTips;
