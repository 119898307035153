const initialState = {l0Shouldopen: false};
const OPENLOGUIDE = 'OPENLOGUIDE';
const CLOSELOGUIDE = 'CLOSELOGUIDE';
const openL0Guide = () => ({
    type: OPENLOGUIDE,
    payload: {l0Shouldopen: true}
});
const closeL0Guide = () => ({
    type: CLOSELOGUIDE,
    payload: {l0Shouldopen: false}
});
const DiyReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPENLOGUIDE:
        case CLOSELOGUIDE:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export {
    DiyReducer as default,
    initialState as diyInitialState,
    openL0Guide,
    closeL0Guide
};
