import lottie from '../node_modules/lottie-web';

const loadAnimation = lottie.loadAnimation;

function newLoadAnimation(opt) {
  const { assetsPath } = opt;
  let newOpt = opt;
  if (assetsPath) {
    newOpt = { ...opt, assetsPath: `${__CDN_URL__}${assetsPath}` };
  }
  return loadAnimation.call(this, newOpt);
}

lottie.loadAnimation = newLoadAnimation;
export default lottie;
