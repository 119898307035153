/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import '../global.css';
import './button.css';

export const VariantType = {
  default: 'raised',
  outlined: 'outlined',
  text: 'flat',
};

export const ColorType = {
  blue: 'blue',
  oringe: 'oringe',
  purple: 'purple',
  danger: 'danger',
};

export const SizeType = {
  normal: 'normal', // 36px height
  smaller: 'smaller', // 32px height
  mobile: 'mobile', //44px height
};

export default class CustomButton extends PureComponent {
  get className() {
    const { colorType, sizeType, variantType, className } = this.props;
    return `material-button ${variantType} ${colorType} ${sizeType} ${className}`;
  }
  render() {
    const { iconSrc, style, onClick, disabled } = this.props;
    return (
      <Button
        disabled={disabled}
        variant={this.props.variantType}
        className={this.className}
        style={style}
        onClick={onClick}
      >
        {iconSrc && <img className="icon" src={iconSrc} />}
        {this.props.children}
      </Button>
    );
  }
}

CustomButton.defaultProps = {
  sizeType: SizeType.normal,
  colorType: ColorType.blue,
  variantType: VariantType.default,
  disabled: false,
  className: '',
};

CustomButton.propTypes = {
  sizeType: PropTypes.string,
  colorType: PropTypes.string,
  variantType: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconSrc: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  style: PropTypes.object,
};
