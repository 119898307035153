/*
 * Copied & modified from src/lib/backpack/block-to-image
 * */

import computedStyleToInlineStyle from 'computed-style-to-inline-style';
import ScratchBlocks from 'scratch-blocks';
import {DETECT} from '../../config';
import opCodeToImage from './opcode-to-image';
import get from 'lodash/get';
import set from 'lodash/set';

const BlocksWithSubStackTypeSet = new Set([
    'control_repeat',
    'control_forever',
    'control_if',
    'control_if_else',
    'control_repeat_until'
]);

export const BlockOptions = {
    KEEP_ALL: 'keepAll',
    KEEP_PARAMS: 'keepParams',
    CLEAR_ALL: 'clearAll'
};

/**
 * Given a blockId, return a data-uri image that can be used to create a thumbnail.
 * @param {string} blockId the ID of the block to imagify
 * @param {BlockOptions} blockOption Enumerable block options.
 * @return {Promise} resolves to a data-url of a picture of the blocks
 */
export default function blockToImage(
    blockId,
    blockOption = BlockOptions.KEEP_ALL
) {
    // Not sure any better way to access the scratch-blocks workspace than this...
    const block = ScratchBlocks.getMainWorkspace().getBlockById(blockId);
    if (!block) {
        return Promise.reject(new Error(`Block {${blockId}} Unfounded.`));
    }

    if (block.type === 'event_whenflagclicked') {
        // todo replace it to real gui image link
        return Promise.resolve(
            'https://img.wkcoding.com/blocks/a32e3e20b296b2c6.svg'
        );
    }

    const blockSvg = block.getSvgRoot().cloneNode(true /* deep */);

    // If in flyout, ignore blockOption
    if (!block.isInFlyout && blockOption !== BlockOptions.KEEP_ALL) {
        // In the current usage scenario, control block with subStack might be too large
        // when keeping params, so temporarily ignore the config for these blocks.
        if (BlocksWithSubStackTypeSet.has(block.type)) {
            return opCodeToImage(block.type);
        }

        if (
            block.type === 'procedures_definition' ||
            blockOption === BlockOptions.KEEP_PARAMS
        ) {
            const nextBlockSvgHTML =
                block.nextConnection &&
                block.nextConnection.targetConnection &&
                block.nextConnection.targetConnection
                    .getSourceBlock()
                    .getSvgRoot().outerHTML;

            if (nextBlockSvgHTML) {
                for (let i = blockSvg.childNodes.length - 1; i >= 0; i--) {
                    if (blockSvg.childNodes[i].outerHTML === nextBlockSvgHTML) {
                        blockSvg.removeChild(blockSvg.childNodes[i]);
                    }
                }
            }
        } else if (blockOption === BlockOptions.CLEAR_ALL) {
            return opCodeToImage(block.type);
        }
    }

    // Once we have the cloned SVG, do the rest in a setTimeout to prevent
    // blocking the drag end from finishing promptly.
    return new Promise(resolve => {
        setTimeout(() => {
            // Strip &nbsp; entities that cannot be inlined
            blockSvg.innerHTML = blockSvg.innerHTML.replace(/&nbsp;/g, ' ');

            // Create an <svg> element to put the cloned blockSvg inside
            const NS = 'http://www.w3.org/2000/svg';
            const svg = document.createElementNS(NS, 'svg');
            svg.appendChild(blockSvg);

            // Needs to be on the DOM to get CSS properties and correct sizing
            document.body.appendChild(svg);

            const bounds = blockSvg.getBoundingClientRect();
            const width = bounds.width;
            const height = bounds.height;
            const PADDING = 4;
            let extraWidth = PADDING / 2;
            let extraHeight = PADDING / 2;
            if (blockSvg.getAttribute('data-shapes') === 'c-block c-1 hat') {
                extraHeight += 20;
            } else if (blockSvg.getAttribute('data-shapes') === 'hat') {
                extraWidth += DETECT.isJunior ? 20 : 25;
                extraHeight += DETECT.isJunior ? 24 : 12;
            }
            blockSvg.setAttribute(
                'transform',
                `translate(${extraWidth}, ${extraHeight})`
            );
            svg.setAttribute('width', width + PADDING);
            svg.setAttribute('height', height + PADDING);

            // We need to inline the styles set by CSS rules because
            // not all the styles are set directly on the SVG. This makes the
            // image styled the same way the block actually appears.
            // TODO this doesn't handle images that are xlink:href in the SVG
            computedStyleToInlineStyle(svg, {
                recursive: true,
                // Enumerate the specific properties we need to inline.
                // Specifically properties that are set from CSS in scratch-blocks
                properties: ['fill', 'font-family', 'font-size', 'font-weight']
            });

            // if svg has img
            const imgs = svg.querySelectorAll('image');
            // frist get block background color
            const blockBgColor = get(block, 'colour_');
            const promiseArr = [];
            imgs.forEach(img => {
                const href = get(img, 'href.baseVal');
                const p = new Promise((re, rj) => {
                    if (href) {
                        // translate to base64
                        const imgEl = new Image();
                        imgEl.crossOrigin = 'anonymous';
                        imgEl.onload = function() {
                            // eslint-disable-next-line no-shadow
                            const width = get(img, 'width.baseVal.value', 0);
                            // eslint-disable-next-line no-shadow
                            const height = get(img, 'height.baseVal.value', 0);
                            const canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            const ctx = canvas.getContext('2d');
                            // set canvas background color
                            if (blockBgColor) {
                                ctx.fillStyle = blockBgColor;
                                ctx.fillRect(0, 0, canvas.width, canvas.height);
                            }
                            ctx.drawImage(imgEl, 0, 0, width, height);
                            const base64 = canvas.toDataURL('image/jpeg');
                            set(img, 'href.baseVal', base64);
                            re();
                        };
                        imgEl.onerror = rj;
                        imgEl.src = href;
                    } else {
                        re();
                    }
                });
                promiseArr.push(p);
            });

            Promise.all(promiseArr)
                .then(() => {
                    const svgString = new XMLSerializer().serializeToString(
                        svg
                    );

                    // Once we have the svg as a string, remove it from the DOM
                    svg.parentNode.removeChild(svg);

                    resolve(
                        `data:image/svg+xml;utf-8,${encodeURIComponent(
                            svgString
                        )}`
                    );
                })
                .catch(() => {
                    resolve('');
                });
        }, 10);
    });
}
