import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.css';

const POSITION = {
  CENTER: 'center',
  BOTTOM: 'bottom',
};

export { POSITION };

const EMPTY_FUNCTION = new Function(); //eslint-disable-line
const ESC_KEY = 27;
const NAME_SEPARATOR = ',';

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMaskClick = this.handleMaskClick.bind(this);
  }

  componentDidMount() {
    if (this.props.focusEle) {
      this.props.focusEle.focus();
    } else if (!this.props.DISABLE_AUTO_FOCUS) {
      this.contentEle.focus();
    }
  }

  handleKeyDown(event) {
    if (this.props.ESC_CLOSABLE && event.keyCode === ESC_KEY) {
      event.preventDefault();
      this.props.onClose();
    }
  }

  handleMaskClick() {
    if (this.props.MASK_CLOSABLE) {
      this.props.onClose();
    }
  }

  buildClass(prefix) {
    if (!(prefix instanceof Array)) {
      prefix = prefix.split(' ');
    }

    return className => {
      if (!(className instanceof Array)) {
        className = className.split(' ');
      }

      const result = [];

      className.forEach(item => {
        prefix.forEach(item2 => {
          result.push(item ? `${item2}__${item}` : item2);
        });
      });

      return result.join(' ');
    };
  }

  render() {
    const {
      ariaLabel,
      name,
      shouldStopPropagation,
      position,
      className,
      children,
      ALL_AS_CONTENT,
      ...otherProps
    } = this.props;

    delete otherProps.MASK_CLOSABLE;
    delete otherProps.ESC_CLOSABLE;
    delete otherProps.DISABLE_AUTO_FOCUS;
    delete otherProps.onClose;
    delete otherProps.focusEle;

    if (name) {
      console.warn(
        'Props name is deprecated. Use style or define className by yourself.',
      );
    }

    const classPrefixArr = ['modal'];
    name.split(NAME_SEPARATOR).forEach(name => {
      (name = name.trim()) && classPrefixArr.push(`modal-${name}`);
    });

    const buildClassWithPrefix = this.buildClass(classPrefixArr);

    const modalContentClassArr = [className];
    if (position) {
      modalContentClassArr.push(`modal__content--${position}`);
    }

    const contentProps = {
      className: `${buildClassWithPrefix(
        'content',
      )} ${modalContentClassArr.join(' ')}`,
      tabIndex: '0',
      role: 'main',
      'aria-label': ariaLabel,
      ref: element => {
        this.contentEle = element;
      },
      ...otherProps,
    };

    let childrenWithNewProps = children;
    if (children) {
      if (ALL_AS_CONTENT) {
        childrenWithNewProps = <div {...contentProps}>{children}</div>;
      } else {
        childrenWithNewProps = [].concat(children);
        childrenWithNewProps[0] = (
          <div key={childrenWithNewProps[0].key} {...contentProps}>
            {childrenWithNewProps[0]}
          </div>
        );
      }
    }

    // let isContentChildFound = false;
    // const childrenWithNewProps = Children.toArray(
    //   [].concat(children).reduceRight((pre, next, index) => {
    //     const isContentChild = (index === 0 && !isContentChildFound);
    //
    //     if (isContentChild) {
    //       isContentChildFound = true;
    //       pre.unshift();
    //     } else {
    //       pre.unshift(next);
    //     }
    //
    //     return pre;
    //   }, [])
    // );

    return (
      <div
        role="presentation"
        className={buildClassWithPrefix('')}
        onClick={e => {
          if (shouldStopPropagation) {
            e.stopPropagation();
          }
        }}
        onKeyDown={this.handleKeyDown}
      >
        <div
          role="presentation"
          className={buildClassWithPrefix('mask')}
          onClick={this.handleMaskClick}
        />
        {childrenWithNewProps}
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  MASK_CLOSABLE: PropTypes.bool,
  ESC_CLOSABLE: PropTypes.bool,
  DISABLE_AUTO_FOCUS: PropTypes.bool,
  ALL_AS_CONTENT: PropTypes.bool,
  position: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClose: PropTypes.func,
  focusEle: PropTypes.element,
  shouldStopPropagation: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  MASK_CLOSABLE: false,
  ESC_CLOSABLE: false,
  DISABLE_AUTO_FOCUS: false,
  ALL_AS_CONTENT: false,
  position: '',
  name: '',
  className: '',
  ariaLabel: 'modal content',
  focusEle: null,
  onClose: EMPTY_FUNCTION,
};

export default Modal;
