import GeneralConfig from '../utility/generalConfig';
import Fetch from './fetch';

const webStorage = {
  get: key => Fetch({
      url: GeneralConfig.serverIo.webStorageGet,
      method: 'get',
      params: {
        key,
      },
    }),
  set: (key, value) => Fetch({
      url: GeneralConfig.serverIo.webStorageSet,
      method: 'post',
      params: {
        key,
        value,
      },
    }),
  has: key => Fetch({
      url: GeneralConfig.serverIo.webStorageHas,
      method: 'get',
      params: {
        key,
      },
    }),
  delete: key => Fetch({
      url: GeneralConfig.serverIo.webStorageUnset,
      method: 'post',
      params: {
        key,
      },
    }),
  pop: key => Fetch({
      url: GeneralConfig.serverIo.webStoragePop,
      method: 'get',
      params: {
        key,
      },
    }),
  all: () => Fetch({
      url: GeneralConfig.serverIo.webStorageAll,
    }),
};

export default webStorage;
