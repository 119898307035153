let _errorEventTargetCheckResult = null;

export const handleEventTargets = (
    results,
    puzzleInfo,
    errorEventTargetHandler
) => {
    const eventTargetCheckResult = results
        .slice(0, puzzleInfo.eventTargetGroup.length)
        .map(result => result.status);
    const errorEventTargetCheckResult = results
        .slice(puzzleInfo.eventTargetGroup.length)
        .map(result => result.status);

    if (!_errorEventTargetCheckResult) {
        _errorEventTargetCheckResult = errorEventTargetCheckResult;
    }
    errorEventTargetCheckResult.forEach((newResult, index) => {
        const oldResult = _errorEventTargetCheckResult[index];
        _errorEventTargetCheckResult[index] = newResult;

        if (newResult !== oldResult && !newResult) {
            _errorEventTargetCheckResult[index] = newResult;

            // Show tips
            errorEventTargetHandler(
                puzzleInfo.errorEventTargetGroup[index].tips_content,
                puzzleInfo.errorEventTargetGroup[index].talking_role
            );
        }
    });
    return {
        eventTargetCheckResult,
        errorEventTargetCheckResult: _errorEventTargetCheckResult
    };
};
