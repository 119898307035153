import React, {Component} from 'react';
import PropTypes from 'prop-types';

import QRious from 'qrious';

class Social extends Component {
    static propTypes = {
        data: PropTypes.shape({
            uri: PropTypes.string,
            title: PropTypes.string,
            desc: PropTypes.string,
            coverUri: PropTypes.string
        }).isRequired
    };

    constructor(props) {
        super(props);

        this.renderCommonSocial = this.renderCommonSocial.bind(this);
    }

    componentDidMount() {
        this.qr = new QRious({
            element: this.qrCanvas,
            foreground: 'rgba(0,0,0,0.54)',
            level: 'H',
            size: 500,
            value: this.props.data.shareUri
        });
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data.shareUri !== newProps.data.shareUri) {
            this.qr.set({
                value: newProps.data.shareUri
            });
        }
    }

    renderCommonSocial() {
        return (
            <ul className="social__list">
                <canvas
                    className="item__img-qr_hover_canvas"
                    ref={ele => (this.qrCanvas = ele)}
                />
            </ul>
        );
    }

    render() {
        return <div className="share-social">{this.renderCommonSocial()}</div>;
    }
}

export default Social;
