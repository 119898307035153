import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'public-repo/i18n';
import Dialog from 'public-repo/components/ActivityDialog/DIYDialog.js';
import '!style-loader!css-loader!public-repo/components/Modal/index.css';
import '!style-loader!css-loader!public-repo/components/Modal/withAnimation.css';
import '!style-loader!css-loader!public-repo/components/lesson/activity/resultModal.css';

import Box from '../../../components/box/box.jsx';
import LessonHead from '../LessonHead/LessonHead.jsx';
import ActivitySaveTips from '../activity-save-tips/activity-save-tips.jsx';
import ActivityResetTips from '../activity-reset-tips/activity-reset-tips.jsx';
import AccountCoin from '../../containers/account-coin.jsx';
import AccountInfo from '../../containers/account-info.jsx';
import DeletionRestorer from '../../../containers/deletion-restorer.jsx';

import GuideBubble, {
    DirectionType,
    ArrorPositions,
    bubbleTargetIndicateClassName
} from '../guide-bubble/guide-bubble.jsx';
import ClipWrap from 'Components/clip-wrap/clip-wrap';
import styles from './activity-menu-bar.css';
import '!style-loader!css-loader!./activity-menu-bar.css';
import {getParams} from '../../../lib/env-utils';
// import guiLogo from 'public-repo/images/logo--border-white.svg';
// import teacherIcon from 'public-repo/images/hi_teacher.svg';
import videoIcon from './tv.svg';
import customRestoreIcon from './custom-restore.png';
import VideoModal from '../modals/VideoModal';
import ConfirmPopup from '../confirm-popup/confirm-popup';
import CustomGuideBtn from '../custom-guide-btn/custom-guide-btn.jsx';

const ActivityMenuBar = props => {
    const {
        activity,
        actorHighlight,
        actorName,
        hasDiyGuide,
        homework,
        backToPrevious,
        handleOpenVideoModal,
        // handleShowPanel,
        lessonInfo,
        handleCloseVideoModal,
        handleRetryActivity,
        handleDoneActivity,
        toggleResetDiyTips,
        resetDiy,
        saveProjectBeforeSubmit,
        isActivitySuccessModalVisible,
        tips,
        popData,
        isUpdating,
        isVideoModalVisible,
        manualSave,
        shouldShowLeaveWarning,
        leaveWarningConfig,
        customGuideVisible,
        onOpenCustomGuide,
        diyPre,
        saveAndSettle
    } = props;

    return (
        <Box className={styles.menuBar}>
            <div className={styles.menuBarLeft}>
                {/* <div className={classNames(styles.menuBarItem)}>
                    <img
                        alt="Bellcode"
                        className={styles.scratchLogo}
                        draggable={false}
                        src={guiLogo}
                    />
                </div> */}
                <div
                    className={classNames(
                        styles.menuBarItem,
                        styles.hoverable,
                        styles.accountNavMenu
                    )}
                >
                    <div
                        className={classNames(styles.btn, styles.back)}
                        role="button"
                        onClick={backToPrevious}
                    >
                        <span className={styles.txt}>{i18n.get('Back')}</span>
                    </div>
                </div>
                {
                    <div
                        className={classNames(
                            styles.menuBarItem,
                            styles.hoverable,
                            styles.accountNavMenu
                        )}
                    >
                        <div
                            className={classNames(styles.btn, styles.refresh)}
                            role="button"
                            onClick={toggleResetDiyTips}
                        >
                            <span className={styles.txt}>
                                {i18n.get('Refresh')}
                            </span>
                        </div>
                    </div>
                }
                {activity &&
                    activity.videoUrl &&
                    (activity.videoUrl.cn || activity.videoUrl.en) && (
                        <div
                            className={styles.videoHeader}
                            onClick={handleOpenVideoModal}
                        >
                            <img
                                alt="teacher"
                                src={videoIcon}
                                className={styles.tv}
                            />
                            <div>{i18n.get('TV')}</div>
                        </div>
                    )}
                {/* {activity && (
                    <div
                        onClick={handleShowPanel}
                        className={styles.videoHeader}
                    >
                        <img
                            alt="teacher"
                            src={teacherIcon}
                            className={styles.tv}
                        />
                        <div>{i18n.get('班主任')}</div>
                    </div>
                )} */}
                {hasDiyGuide && (
                    <CustomGuideBtn
                        onOpenCustomGuide={onOpenCustomGuide}
                        customGuideVisible={customGuideVisible}
                    />
                )}
                <DeletionRestorer>
                    {(handleRestore, {restorable, deletedItem}) => {
                        const isRestorable =
                            restorable && deletedItem === 'Sprite';
                        return (
                            <div
                                className={classNames(
                                    styles.restoreCustom,
                                    {[styles.disabledRestore]: !isRestorable},
                                    {[styles.enabledRestore]: isRestorable}
                                )}
                                onClick={() => isRestorable && handleRestore()}
                            >
                                <img
                                    alt="restore"
                                    src={customRestoreIcon}
                                    className={styles.restoreImg}
                                />
                                <span>{i18n.get('RestoreCostume')}</span>
                            </div>
                        );
                    }}
                </DeletionRestorer>
            </div>
            <div className={styles.menuBarCenter}>
                {getParams().actId ? (
                    <LessonHead
                        activity={activity}
                        lessonInfo={lessonInfo}
                    />
                ) : (
                    <div className="header-title">{homework.name}</div>
                )}
            </div>
            <div className={styles.menuBarRight}>
                <AccountInfo />
                <AccountCoin />
            </div>
            {tips.isShowSaveTip && (
                <ActivitySaveTips saveTipsText={tips.saveTipsText} />
            )}
            {tips.isShowResetActivityTips && (
                <ActivityResetTips
                    toggleResetActivityTips={toggleResetDiyTips}
                    resetActivity={resetDiy}
                />
            )}
            {activity && isActivitySuccessModalVisible && (
                <Dialog
                    key="dialog"
                    stars={popData.score}
                    popUpData={popData.pop_up}
                    showConfetti
                    isLast={false}
                    onRetry={handleRetryActivity}
                    onDone={handleDoneActivity}
                />
            )}
            {activity && activity.videoUrl && (
                <VideoModal
                    key="video_modal"
                    in={isVideoModalVisible}
                    onClose={handleCloseVideoModal}
                    src={activity.videoUrl}
                    videoType={parseInt(activity.videoType, 10)}
                    videoPackageId={getParams().packageId}
                    videoLessonId={getParams().lessonId}
                    videoActivityId={activity.videoActId}
                    videoClassId={getParams().classId}
                />
            )}
            <div
                className={classNames(
                    styles.menuBarItem,
                    styles.hoverable,
                    styles.accountNavMenu,
                    styles.submitButton
                )}
            >
                <div
                    className={styles.btnSubmit}
                    onClick={diyPre ? saveAndSettle : saveProjectBeforeSubmit}
                    id={'btnSubmit'}
                >
                    <span className={styles.submitTxt}>
                        {diyPre ? '我已完成' : i18n.get('Submit')}
                    </span>
                    <i className={styles.icon} />
                    {/* {getParams().homeworkId == MothersDayHomeworkId && (*/}
                    {/* <div className="mothersday-activity-tips">*/}
                    {/* 点击这里录制作品，和妈妈说一句母亲节快乐吧~*/}
                    {/* </div>*/}
                    {/* )}*/}
                </div>
            </div>
            <div
                className={classNames(
                    styles.menuBarItem,
                    styles.hoverable,
                    styles.accountNavMenu,
                    styles.saveButton
                )}
            >
                <button onClick={manualSave}>
                    {isUpdating ? i18n.get('Saving...') : i18n.get('Save')}
                </button>
            </div>
            {shouldShowLeaveWarning && <ConfirmPopup {...leaveWarningConfig} />}
            {actorHighlight !== -1 && (
                <GuideBubble
                    visible
                    arrowPos={ArrorPositions.CENTER}
                    direction={DirectionType.BOTTOM}
                    targetIndex={actorHighlight}
                    indicatorClass={bubbleTargetIndicateClassName}
                    text={`点这里可以切换到${actorName}`}
                />
            )}
            {actorHighlight !== -1 && (
                <ClipWrap
                    hiddenBlockTipShow={false}
                    offset={2}
                    className={styles.actorClipWrap}
                    targetDom={bubbleTargetIndicateClassName}
                    targetIndex={actorHighlight}
                />
            )}
        </Box>
    );
};

ActivityMenuBar.propTypes = {
    activity: PropTypes.object,
    actorHighlight: PropTypes.number,
    actorName: PropTypes.string,
    backToPrevious: PropTypes.func,
    customGuideVisible: PropTypes.bool,
    diyPre: PropTypes.bool,
    handleCloseVideoModal: PropTypes.func,
    handleDoneActivity: PropTypes.func,
    handleOpenVideoModal: PropTypes.func,
    handleRetryActivity: PropTypes.func,
    // handleShowPanel: PropTypes.func,
    homework: PropTypes.object,
    isActivitySuccessModalVisible: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isVideoModalVisible: PropTypes.bool,
    leaveWarningConfig: PropTypes.object || null,
    lessonInfo: PropTypes.object,
    manualSave: PropTypes.func,
    onOpenCustomGuide: PropTypes.func,
    popData: PropTypes.object,
    resetDiy: PropTypes.func,
    saveAndSettle: PropTypes.func,
    saveProjectBeforeSubmit: PropTypes.func,
    shouldShowLeaveWarning: PropTypes.bool,
    tips: PropTypes.object,
    toggleResetDiyTips: PropTypes.func
};

export default ActivityMenuBar;
