export default {
  'pulbish-submit': 'Submit',
  'Login-Scan to login': 'Scan to login',
  'Login-Normal login': 'Normal login',
  'Login-Login or register using WeChat scan':
    'Login or register using WeChat scan',
  "Login-If you don't have WeChat, ask your mom or dad for help":
    "If you don't have WeChat, ask your mom or dad for help",
  'Login-Welcome to using bellcode': 'Welcome to using bellcode',
  'Login-Use WeChat scan to bind bellcode': 'Use WeChat scan to bind bellcode',
  'Login-Skip': 'Skip',
  'Login-Binding Wechat': 'Binding Wechat',
  'Login-Bound': 'Bound',
  'Login-Unbind': 'Unbind',
  'Login-Bind': 'Bind',
  'Login-This WeChat ID has been bound': 'This WeChat ID has been bound',
  'Login-This WeChat ID has been used': 'This WeChat ID has been used',
  'Login-WeChat ID has been successfully bound':
    'WeChat ID has been successfully bound',
  'Login-Forgot password?': 'Forgot password?',
  'Login-Back Log': 'Back Log',
  'Login-If you forget your password, please scan the QR code to contact the Head Teacher.':
    'If you forget your password, please scan the QR code to contact the Head Teacher.',
  "Login-She'll get back the password.": "She'll get back the password.",
  'Login-* If you do not have an account, Please sign in with WeChat scan~':
    'If you do not have an account, Please sign in with WeChat scan~',
  'Login-refresh': 'Refresh',
  'Login-QR code has expired': 'QR code has expired',
  'Login-Please click': 'Please click',
  'Login-Please remember your login account : ':
    'Please remember your login account : ',
  'Login-And set your own password~': 'And set your own password~',
  'Login-When mom and dad are not around, you can log in directly using account numbers and passwords':
    'When mom and dad are not around, you can log in directly using account numbers and passwords',
  'Login-Input password': 'Input password',
  'Login-confirm password': 'Confirm password',
  'Login-confirm': 'Confirm',
  'Login-Login-Binding WeChat, get the most timely learning progress and report~':
    'Binding WeChat, get the most timely learning progress and report~',
  'Login-There are also the latest course coupons':
    'There are also the latest course coupons',

  'Password-The length of the password is 6 to 12 characters':
    'The length of the password is 6 to 12 characters',
  'Password-The password can only be made up of letters and numbers':
    'The password can only be made up of letters and numbers',
  'Password-Confirm password and password are inconsistent':
    'Confirm password and password are inconsistent',
  'Password-Input password': 'Input password',
  'Password-Confirm password': 'Confirm password',
  'Password-Password should not be empty': 'Password should not be empty',
  'Password-Confirm password should not be empty':
    'Confirm password should not be empty',
  'Password-Unsuccessful password settings': 'Unsuccessful password settings',
  'Password-Successful password settings': 'Successful password settings',

  // header bar
  '__Header-Bar Home': 'Home',
  // Footer
  '__Footer Location: ': 'Location: ',
  '__Footer Hanking Center 12F, Shenzhen nanshan district.':
    '2F, Unit 01, Building C, Tongfang Information Port, Nanshan District, Shenzhen',
  // '__Footer About Us': 'About Bellcode',
  BBS: 'The Bellcode Bbs',
  Blog: 'The Bellcode Blog',

  // 快速发布与录屏发布选择弹框
  '__Publish-Popup Publish': 'Publish',
  '__Publish-Popup Publish with Video': 'Publish with Video',
  '__Publish-Popup rewards 100 ': 'rewards 100 ',
  '__Publish-Popup Up to 300 gold coins a day.': 'Up to 300 gold coins a day.',
  '__Publish-Popup download': 'download',
  "__Publish-Popup your browser doesn't support":
    "your browser doesn't support",
  '__Publish-Popup Use Chrome': 'Use Chrome',
  '__Publish-Popup Your device does not support.':
    'Your device does not support.',
  '__Publish-Popup Please use PC instead.': 'Please use PC instead',

  // 快速发布页面
  '__Submit Add some Video?': 'Add some Video?',
  // HeaderBar
  'HeaderBar2 Home': 'Home',
  'HeaderBar2 Take Class': 'Classroom',
  'HeaderBar2 Choose Class': 'Courses',
  'HeaderBar2 Game': 'Game',
  'HeaderBar2 More': 'More',
  'HeaderBar2 School Cooperation': 'School Cooperation',
  'HeaderBar2 Address': 'Address',
  'HeaderBar2 Courses Structure': 'Courses Structure',
  'HeaderBar2 Coding Education': 'Code Edu',
  // 'HeaderBar2 Dual Teachers': 'Dual Teachers',
  'HeaderBar2 Competition': 'Competition',
  'HeaderBar2 Create': 'Create',
  'HeaderBar2 My Courses': 'My Courses',
  'HeaderBar2 My Works': 'My Works',
  'HeaderBar2 My Homepage': 'My Homepage',
  'HeaderBar2 Setting': 'Setting',
  'HeaderBar2 Language': 'Toggle Chinese',
  'HeaderBar2 Logout': 'Logout',
  'HeaderBar2 Login or Register': 'Login/Register',
  'HeaderBar2 Feedback': 'Feedback',

  // organization
  'org-name': 'Name',

  // CLassroomInstructorManagement
  Instructor_avatar: 'Avatar',
  Instructor_add: 'Add new instructor',

  // video player
  video_play: 'Play',
  video_pause: 'Pause',

  // live&record
  Class_type: 'type',
  Class_stu_num: 'student',
  Class_teacher_name: 'creator',
  Course_Online: 'Online',
  Course_Offline: 'Offline',
  Class_Live: 'Live',
  Class_Record: 'Record',

  RestoreCostume: 'restore costume',

  // New Dialog
  'New Dialog Perfect': ['Perfect! Better than ', ' of people.'],
  'New Dialog Good': ['Good! Better than ', ' of people.'],
  'New Dialog Normal': ['Done! Better than ', ' of people.'],
  'New Dialog Bad': 'Fail to pass.',
  'New Dialog Fail Because Of Carelessness': 'Fail to pass.',
  'New Dialog Next Level': 'Next Level',
  'New Dialog Done': ' Done',
  'New Dialog Do Better': 'Do Better',
  'New Dialog Check The Answer': 'Check The Answer',
  "New Dialog What's The Wrong": "What's The Wrong",
  'New Dialog Play Again': 'Play Again',
  'New Dialog Play Again And Win The Prize': 'Play Again',
  'New Dialog Give Up The Prize': 'Give Up The Prize',
  'New Dialog Use Blocks': ['Use ', ' blocks.'],
  'New Dialog Cost Time': ['Cost ', ' seconds.', ' minutes.'],
  'New Dialog Gold': ' gold coins',
  'New Dialog Code': ' code coins',
  'New Dialog Rest': 'Rest ',
  'New Dialog Get': 'Get ',
  'New Dialog Accuracy': 'Accuracy ',
  'New Dialog Behave Well': 'Behave Well!',
  'New Dialog First Answer Correct': ['First Answer Correct ', ''],
  'New Dialog Watch Video Again': 'Watch Video Again',
  'New Dialog Use Knowledge': ['Use ', ' Knowledge'],
  'New Dialog Study Cost Time': ['Study Cost Time ', ' seconds', ' minutes'],

  // submit
  '_VideoSubmit Publish to Plaza': 'Publish to Plaza',

  // 官网信息相关
  'Brand Name': 'Bellcode',
};
