import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import backSrc from './back.svg';
import styles from './puzzle2-left-panel.css';
import {replaceStringToBlockBoxComponent} from '../../components/puzzle2-block-box/puzzle2-block-box.jsx';
import videoIcon from 'Asset/img/puzzle2-video-icon.svg';
import codeTargetIcon from './code-target-icon.svg';
import eventTargetIcon from './event-target-icon.svg';
import stopVoiceIcon from './stop-voice.png';
import playVoiceIcon from './play-voice.png';
import {getParams} from '../../../lib/env-utils';
import TargetBlockComponent from '../target-block/target-block.jsx';
import {targetStatus} from '../../reducers/puzzle/code-target';
import VideoModal from '../../components/modals/VideoModal';
import classNames from 'classnames';
import UserData from 'PublicRepo/utility/userDataManager.js';
import {AudioManager} from 'PublicRepo/components/AudioManager/audioManager';
import config from '../../config';

const scrollDistanceToTop = 20; // 左边栏滚动 20 个像素会开始挡住返回按钮与标题
const videoLocation = 'gui puzzle2';

const bodyTag = document.getElementsByTagName('body')[0];
const TargetBlockOnPython = props => (
    <TargetBlockComponent
        {...props}
        className={styles.borderColor}
    />
);
let TargetBlock = TargetBlockComponent;
class Puzzle2LeftPanel extends Component {
    constructor(props) {
        super(props);
        const isPlayingVoice = !!UserData.isStudent;
        this.state = {
            // 进度条渲染需要等到 block box 渲染完才能开始渲染
            alreadyRenderProgressBar: false,
            isVideoShow: false,
            isUserScrollDown: false,
            isPlayingVoice: isPlayingVoice
        };
        if (props.pythonMode) {
            TargetBlock = TargetBlockOnPython;
        }
    }

    componentDidMount() {
        this.rootRef.addEventListener('scroll', this.checkIfUserScrollDown);

        this.checkPlayVoice();
    }

    componentDidUpdate() {
        const {alreadyRenderProgressBar} = this.state;
        if (
            !alreadyRenderProgressBar &&
            this.lastTargetBlockNode &&
            this.firstTargetBlockNode
        ) {
            // 相当于 forceUpdate，强行再渲染一次让进度条出现
            // eslint-disable-next-line
            this.setState({alreadyRenderProgressBar: true});
        }
    }

    componentWillUnmount() {
        this.rootRef.removeEventListener('scroll', this.checkIfUserScrollDown);
    }

    checkIfUserScrollDown = this.checkIfUserScrollDown.bind(this);
    checkIfUserScrollDown({target}) {
        this.setState({
            isUserScrollDown: target.scrollTop > scrollDistanceToTop
        });
    }

    backToMap = this.backToMap.bind(this);
    backToMap() {
        const params = getParams();
        if (params.from && params.from === config.bellplanet) {
            const msgInfo = {key: config.postMsgId.closePuzzle, value: true};
            if (window.parent) {
                window.parent.postMessage(msgInfo, '*');
            } else {
                window.postMessage(msgInfo, '*');
            }
            return;
        }
        window.location.href = getParams().cbUrl;
    }

    renderTitle() {
        const {info} = this.props;
        const {isUserScrollDown} = this.state;
        if (!info) {
            return;
        }

        return (
            <div
                className={`${styles.title} ${
                    isUserScrollDown ? styles.withBackground : ''
                }`}
            >
                <img
                    className={styles.back}
                    src={backSrc}
                    onClick={this.backToMap}
                />
                {info.name}
            </div>
        );
    }

    renderDescription() {
        const {info} = this.props;
        if (!info) {
            return;
        }

        return (
            <div className={styles.backgroundDescription}>
                {replaceStringToBlockBoxComponent(
                    info.backgroundDescription,
                    info.projectDescription,
                    styles.leftPanel
                )}
            </div>
        );
    }

    handleVideoShow = this.handleVideoShow.bind(this);
    handleVideoShow() {
        // if ( this.props.isPlaying ) {
        //     this.props.toggleIsPlaying(false);
        //     PuzzleUtility.stopPuzzle();
        // }
        // scratch.blocks.blur();
        // scratch.stopProject();
        this.setState({isVideoShow: true});
    }

    hanldeVideoClose = this.hanldeVideoClose.bind(this);
    hanldeVideoClose() {
        // scratch.startProject();
        this.setState({isVideoShow: false});
    }

    onPlayVoiceClick = this.onPlayVoiceClick.bind(this);
    onPlayVoiceClick() {
        this.setState({isPlayingVoice: !this.state.isPlayingVoice}, () => {
            // 不管是停止播放，还是播放新的音效，都要把上一个音效停止
            this.stopPlayingVoice();
            this.checkPlayVoice();
        });
    }

    /** 检查并播放音效 */
    checkPlayVoice = this.checkPlayVoice.bind(this);
    checkPlayVoice() {
        const currentVoice = this.props.info.voice;
        if (!currentVoice) return;
        if (!this.state.isPlayingVoice) return;

        this.setState({
            playingVoiceName: currentVoice
        });
        const soundObj = AudioManager.play(currentVoice);
        soundObj.once('end', this.onPlayVoiceEnd);
    }

    /** 当音效播放完毕 */
    onPlayVoiceEnd = this.onPlayVoiceEnd.bind(this);
    onPlayVoiceEnd() {
        this.setState({isPlayingVoice: false});
        this.setState({playingVoiceName: ''});
    }

    /** 停止正在播放的音效 */
    stopPlayingVoice = this.stopPlayingVoice.bind(this);
    stopPlayingVoice() {
        if (this.state.playingVoiceName) {
            AudioManager.stop(this.state.playingVoiceName);
            this.setState({
                playingVoiceName: ''
            });
        }
    }

    renderVideoComponentIfNeeded() {
        const {info, pythonMode} = this.props;
        if (pythonMode && UserData.is2bStudent) {
            return null;
        }

        // 说明上一个关卡为视频，显示视频按钮
        if (info.videoDescription) {
            return (
                <React.Fragment>
                    <div
                        className={styles.videoComponent}
                        onClick={this.handleVideoShow}
                    >
                        <img
                            src={videoIcon}
                            className={styles.videoIcon}
                        />
                        看视频
                    </div>
                    {createPortal(
                        <VideoModal
                            key="video_modal"
                            in={this.state.isVideoShow}
                            onClose={this.hanldeVideoClose}
                            src={info.videoDescription}
                            videoType={parseInt(info.videoDescription.type, 10)}
                            videoPackageId={getParams().packageId}
                            videoLessonId={getParams().lessonid}
                            videoActivityId={info.videoDescription.act_id}
                            videoClassId={getParams().classid}
                            videoLocation={videoLocation}
                            videoName={info.name}
                        />,
                        bodyTag
                    )}
                </React.Fragment>
            );
        }
    }

    renderVoiceComponentIfNeeded() {
        if (!this.props.info.voice) return null;

        const voiceIcon = this.state.isPlayingVoice
            ? playVoiceIcon
            : stopVoiceIcon;
        return (
            <React.Fragment>
                <img
                    src={voiceIcon}
                    className={styles.voiceIcon}
                    onClick={() => this.onPlayVoiceClick()}
                />
            </React.Fragment>
        );
    }

    renderHeader() {
        const {pythonMode} = this.props;
        return (
            <React.Fragment>
                {!pythonMode && this.renderTitle()}
                <div
                    className={classNames(styles.header, {
                        [styles.hiddenBorder]: pythonMode
                    })}
                >
                    <div className={styles.backgroundDescriptionContainer}>
                        {this.renderDescription()}
                    </div>
                    <div className={styles.videoVoiceContainer}>
                        {this.renderVideoComponentIfNeeded()}
                        {this.renderVoiceComponentIfNeeded()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderCodeTarget() {
        const {info, targets, pythonMode} = this.props;
        if (!targets) {
            return;
        }
        let text = '组合代码块';
        if (pythonMode) {
            text = '创作小目标';
        }

        return (
            <div
                className={styles.eventTarget}
                id="left-panel-event-target"
            >
                <div className={styles.head}>
                    <img
                        className={styles.icon}
                        src={codeTargetIcon}
                    />
                    {text}
                </div>
                {targets.code.map((target, index) => {
                    if (index === 0) {
                        return (
                            <TargetBlock
                                key={index}
                                targetState={target.status}
                                content={target.content}
                                emoji={info.projectDescription}
                                domRef={firstTargetBlockNode =>
                                    (this.firstTargetBlockNode = firstTargetBlockNode)
                                }
                            />
                        );
                    } else if (index === targets.code.length - 1) {
                        return (
                            <TargetBlock
                                key={index}
                                targetState={target.status}
                                content={target.content}
                                emoji={info.projectDescription}
                                domRef={lastTargetBlockNode =>
                                    (this.lastTargetBlockNode = lastTargetBlockNode)
                                }
                            />
                        );
                    }
                    return (
                        <TargetBlock
                            key={index}
                            targetState={target.status}
                            content={target.content}
                            emoji={info.projectDescription}
                        />
                    );
                })}
                {this.renderProgressBar()}
            </div>
        );
    }

    generateProgressBarRate() {
        const {targets} = this.props;
        if (!targets) {
            return;
        }

        if (!targets.code.length) {
            return; // 空数组立即返回
        }
        const totalLength = targets.code.length - 1;
        const currentLength =
            targets.code.filter(target => target.status == targetStatus.SUCCESS)
                .length - 1;

        if (currentLength < 0) {
            // 没有一个成功
            return 0;
        }

        return currentLength / totalLength;
    }

    renderProgressBar() {
        if (!this.lastTargetBlockNode || !this.firstTargetBlockNode) {
            return;
        }

        const codeBlocklistHeight =
            this.lastTargetBlockNode.offsetTop -
            this.firstTargetBlockNode.offsetTop +
            this.lastTargetBlockNode.offsetHeight;

        const progressBarContainerHeight =
            codeBlocklistHeight -
            (this.firstTargetBlockNode.offsetHeight / 2) -
            (this.lastTargetBlockNode.offsetHeight / 2);

        const progressBarTop =
            this.firstTargetBlockNode.offsetTop +
            (this.firstTargetBlockNode.offsetHeight / 2);
        const progressBarHeight =
            this.generateProgressBarRate() * progressBarContainerHeight;

        return (
            <div
                className={styles.progressBarContainer}
                style={{
                    height: progressBarContainerHeight,
                    top: progressBarTop
                }}
            >
                <div
                    className={styles.progressBar}
                    style={{height: progressBarHeight}}
                />
            </div>
        );
    }

    renderEventTarget() {
        const {info, targets, isPuzzlePlaying} = this.props;
        if (!targets) {
            return;
        }

        return (
            <div className={styles.eventTarget}>
                <div className={styles.head}>
                    <img
                        className={styles.icon}
                        src={eventTargetIcon}
                    />
                    完成任务
                </div>
                {targets.event
                    .map(target => (
                        <TargetBlock
                            key={target.content}
                            targetState={target.status}
                            content={target.content}
                            emoji={info.projectDescription}
                        />
                    ))
                    .concat(
                        targets.errorEvent.map(target => (
                            <TargetBlock
                                key={target.tips_content}
                                targetState={target.status}
                                content={target.content}
                                emoji={info.projectDescription}
                                isPlaying={isPuzzlePlaying}
                            />
                        ))
                    )}
            </div>
        );
    }

    render() {
        return (
            <div
                className={classNames(styles.leftPanel, {
                    [styles.python]: this.props.pythonMode
                })}
                ref={ref => {
                    this.rootRef = ref;
                }}
            >
                {this.renderHeader()}
                {this.renderCodeTarget()}
                {this.renderEventTarget()}
            </div>
        );
    }
}

export default Puzzle2LeftPanel;
