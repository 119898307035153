import Fetch from 'PublicRepo/lib/fetch';
import Config from 'Config';
import Detect from 'Common/detect';
import {uploadVideo} from '../lib/upload-utils';

export default {
    saveProjectMedia(videoUrl, audioUrl, isMicWork){
        return Fetch({
            method: 'post',
            url: Config.serverIo.saveProjectMedia,
            params :{
                'project_id' : Detect.proId || '',
                'video_url' : videoUrl,
                'audio_url' : audioUrl,
                'is_recorded_audio': isMicWork ? 1 : 0
            }
        });
    },

    getUploadMediaUri(prefix,mediaData){
        return uploadVideo(mediaData, prefix);
    },

    getWeChatJSConfig (url) {
        return Fetch({
            url: Config.serverIo.getWeChatShareConfig,
            params: {
                share_url: url
            }
        }).then(data => {
            const config = JSON.parse(data.js_config);
            return {
                appId: config.appId,
                debug: config.debug,
                jsApiList: config.jsApiList,
                nonceStr: config.nonceStr,
                signature: config.signature,
                timestamp: config.timestamp
            };
        });
    },
};
