function extractHostname(url) {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('://') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}

function getUrlAttr(url) {
  if (!url) {
    return;
  }
  const TEMP_LINK = document.createElement('a');
  TEMP_LINK.href = url;
  return {
    protocol: TEMP_LINK.protocol,
    hostname: TEMP_LINK.hostname,
    pathname: TEMP_LINK.pathname,
    search: TEMP_LINK.search,
  };
}

function extractRootDomain(url) {
  let domain = extractHostname(url);

  const splitArr = domain.split('.');

  const arrLen = splitArr.length;

  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = `${splitArr[arrLen - 2]}.${splitArr[arrLen - 1]}`;
    // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (
      splitArr[arrLen - 2].length === 2
      && splitArr[arrLen - 1].length === 2
    ) {
      // this is using a ccTLD
      domain = `${splitArr[arrLen - 3]}.${domain}`;
    }
  }
  return domain;
}

export default {
  extractRootDomain,
  getUrlAttr,
};
