import Fetch from 'public-repo/lib/fetch';
import GeneralConfig from 'public-repo/utility/generalConfig';

const getProjectById = proId => {
    if (!proId) {
        return null;
    }
    return Fetch({
        url: GeneralConfig.serverIo.getProjectById(proId)
    }).then(data => {
        try {
            // lastSavedProject = {
            //     proid: proId,
            //     proName: data.proName,
            //     param: data.params,
            //     proCover: null
            // };
        } catch (e) {
            console.error(e); // eslint-disable-line
        }

        return data;
    });
};
const sumbitDiyProject = (classId, projectId) =>
    Fetch({
        url: GeneralConfig.serverIo.sumbitDiyProject,
        method: 'post',
        params: {
            class_id: classId,
            project_id: projectId
        }
    });

export {getProjectById, sumbitDiyProject};
