import iconVolume from './img/volume.svg';
import iconVolumeMute from './img/mute.svg';
import fullscreenEnter from './img/fullscreen.svg';
import fullscreenExit from './img/fullscreen-exit.svg';
import replay from './img/replay.svg';
import playPause from './img/play-pause.svg';
import next from './img/next.svg';
import iconPlay from './img/play.svg';
import iconPause from './img/pause.svg';
import iconCnButton from './img/cn-button.svg';
import button480p from './img/480p-button.svg';

import clarityOption360p from './img/360p-option.png';
import clarityOption480p from './img/480p-option.png';
import clarityOption720p from './img/720p-option.png';

// 默认中英文按钮图标
const languageButton = iconCnButton;
const clarityButton = button480p;

const Controls = `
<div class="video-question-wrapper">
        <div class="video-question-content">
          <div class="video-question-title">
          </div>
          <div class="video-options-wrapper">
          </div>
        </div>
        <div class="video-correct-popup">
            <img src="https://img.wkcoding.com/quiz-feedback-right.png" alt="">
        </div>
        <div class="video-tips-bar">
        </div>
        <div class="bell-cartoon"></div>
        <div class="close-button"></div>
        <div class="screen-button">
            <div class="content"></div>
        </div>
        
        <div class="zoom-in">
          <div class="content"></div>
        </div>
</div>

<div class='operation-button-wrapper'>
    <div class='operation-replay'><img src=${replay} alt="" /></div>
    <!--<div class='operation-next'><img src=${next} alt="" /></div>-->
</div>

<div class='play-button-wrapper'>
    <div class='operation-play'><img src=${playPause} alt=""></div>
</div>

<div class="plyr__controls">
  <button id="control-play" type="button" class="plyr__control" aria-pressed="false" aria-label="Play, {title}" data-plyr="play">
    <svg class="icon--pressed" role="presentation">
        <image width="60" height="60" xlink:href=${iconPause} />
    </svg>
    <svg class="icon--not-pressed" role="presentation">
        <image width="60" height="60" xlink:href=${iconPlay} />
    </svg>
  </button>

  <div class="plyr__progress-wrapper">
        <div class="plyr__progress">
            <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
            <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
            <span role="tooltip" class="plyr__tooltip">00:00</span>
        </div>
        <div class="plyr__time-wrapper">
            <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
            <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
        </div>
  </div>
  
   <button id="control-clarity" type="button" class="plyr__control" aria-pressed="false" aria-label="clarity" data-plyr="clarity">

    <svg class="icon--not-pressed" role="presentation">
        <image id="clarity-button" width="60" height="60" xlink:href=${clarityButton}></image>
    </svg>
    
    <div class="control-clarity-option-ct">
      <div class="control-clarity-option-wrapper">
        <div class="control-clarity-option" data-clarity="720p"><img data-clarity="720p" src=${clarityOption720p} alt=""></div>    
        <div class="control-clarity-option" data-clarity="480p"><img data-clarity="480p" src=${clarityOption480p} alt=""></div>    
        <div class="control-clarity-option" data-clarity="360p"><img data-clarity="360p" src=${clarityOption360p} alt=""></div>    
      </div>
    </div>

  </button>

  <button id="control-lang" type="button" class="plyr__control" aria-pressed="false" aria-label="language" data-plyr="language">

    <svg class="icon--not-pressed" role="presentation">
        <image id="lang-button" width="60" height="60" xlink:href=${languageButton}></image>
    </svg>
    <div class="control-lang">
        <div class="control-lang-wrapper">
            <div class="control-lang-cn">
                <div class="control-lang-cn-img"></div>
            </div>
            <div class="control-lang-en">
                <div class="control-lang-en-img"></div>
            </div>
        </div>
    </div>

  </button>

  <div id="control-volume">
    <button id="control-volume-button" type="button" class="plyr__control" aria-pressed="false" aria-label="Mute" data-plyr="mute">
        <svg class="icon--pressed" role="presentation">
            <image width="60" height="60" xlink:href=${iconVolumeMute}></image>
        </svg>
        <svg class="icon--not-pressed" role="presentation">
            <image width="60" height="60" xlink:href=${iconVolume}></image>
        </svg>
    </button>
    <div class="plyr__volume-wrapper">
        <div class="plyr__volume">
            <input class="plyr__volume-input" data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
        </div>
    </div>
  </div>

  <button id="control-fullscreen" type="button" class="plyr__control" aria-pressed="false" aria-label="Enter fullscreen" data-plyr="fullscreen">
    <svg class="icon--pressed" role="presentation">
        <image width="60" height="60" xlink:href=${fullscreenExit}></image>
    </svg>
    <svg class="icon--not-pressed" role="presentation">
        <image width="60" height="60" xlink:href=${fullscreenEnter}></image>
    </svg>
  </button>
</div>`;

export default Controls;
