import ScratchBlocks from 'scratch-blocks';

const BLOCK_PREFIX_COLOUR_MAP = {
    motion: 'motion',
    looks: 'looks',
    sound: 'sounds',
    control: 'control',
    event: 'event',
    sensing: 'sensing',
    pen: 'pen',
    operator: 'operators',
    data: 'data'
};

const CACHE = Object.create(null);
const PROCEDURES_PREFIX = 'procedures_';

/**
 * Get block colours.
 * @param {string} opCode Block opCode.
 * @param {string} [proCode] Procedure code.
 * @return {object} Block colour definition.
 */
export default function getBlockColour(opCode, proCode = '') {
    let key = opCode;
    if (opCode.indexOf(PROCEDURES_PREFIX) === 0) {
        if (proCode) {
            key = `${opCode} ${proCode}`;
        }
        CACHE[key] = ScratchBlocks.Colours.more;
    }

    if (CACHE[key]) return CACHE[key];

    const opCodePrefix = opCode.split('_')[0];
    if (BLOCK_PREFIX_COLOUR_MAP[opCodePrefix]) {
        return (CACHE[key] =
            ScratchBlocks.Colours[BLOCK_PREFIX_COLOUR_MAP[opCodePrefix]]);
    }

    const extensionBlockSvg = ScratchBlocks.getMainWorkspace()
        .getFlyout()
        .getWorkspace()
        .getTopBlocks()
        .find(blockSvg => blockSvg.type === opCode);

    if (extensionBlockSvg) {
        return (CACHE[key] = {
            primary: extensionBlockSvg.getColour(),
            secondary: extensionBlockSvg.getColourSecondary(),
            tertiary: extensionBlockSvg.getColourTertiary()
        });
    }

    // treat as procedure call blocks.
    return getBlockColour('procedures_call', opCode);
}
