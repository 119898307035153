import locationBuilder from './locationBuilder';

const TOKEN_STORAGE_KEY = 'webviewToken';
const TOKEN_QUERY_KEY = 'token';
// NOTE: 简单的防守式兼容
const LOCAL_STORAGE = window.localStorage || {};

/**
 * 初始化会话
 * @param {String} token
 * @return Promise
 */
function setup(token) {
  if (!token) {
    console.warn('setup token is null');
    return Promise.resolve();
  }

  LOCAL_STORAGE[TOKEN_STORAGE_KEY] = token;

  return Promise.resolve();
}

/**
 * 基于当前location初始化会话
 * @param {Location} location
 * @return Promise
 */
function setupByLocation() {
  const token = locationBuilder.searchQuery(TOKEN_QUERY_KEY);
  if (token) {
    return setup(token);
  }
  return Promise.resolve();
}

/**
 * @return Promise<String>
 */
function getSessionToken() {
  const token = LOCAL_STORAGE[TOKEN_STORAGE_KEY];
  return Promise.resolve(token);
}

/**
 * @return String
 */
function getSessionTokenSync() {
  return LOCAL_STORAGE[TOKEN_STORAGE_KEY];
}

/**
 * 删除会话
 */
function clearToken() {
  LOCAL_STORAGE.removeItem(TOKEN_STORAGE_KEY);
}

export default {
  setup,
  setupByLocation,
  getSessionToken,
  getSessionTokenSync,
  clearToken,
  TOKEN_QUERY_KEY,
};
