import React from 'react';
import PropTypes from 'prop-types';
import classNams from 'classnames';
import InlineSVG from 'react-inlinesvg';

import styles from './python-scratch-tips.css';

import CloseIcon from './../../python-puzzle/assets/fork.svg';
import RobotIcon from './../../python-puzzle/assets/astronaut.svg';

const PythonScratchTips = ({
    text,
    buttonText = '再试一次',
    className,
    isOpen,
    onClickBTN
}) => (
    <div
        className={classNams(className, styles.tipsBox, {
            [styles.hidden]: !isOpen
        })}
    >
        <div
            className={styles.closeIcon}
            onClick={onClickBTN}
        >
            <InlineSVG src={CloseIcon} />
        </div>
        <InlineSVG
            className={styles.robot}
            src={RobotIcon}
        />

        <div
            className={styles.richText}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: text}}
        />
        <button
            className={styles.btn}
            onClick={onClickBTN}
        >
            {buttonText}
        </button>
    </div>
);

PythonScratchTips.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    onClickBTN: PropTypes.func,
    buttonText: PropTypes.string
};

export default PythonScratchTips;
