exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".activity-save-tips_activity-save-tips_3ZJ8q {\n    position: absolute;\n    top: calc(5px + 3rem);\n    left: calc(50% - 120px);\n    z-index: 100;\n    color: rgb(254, 254, 254);\n    font-size: 14px;\n    background-color: rgb(130, 88, 242);\n    width: 240px;\n    height: 48px;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n}\n", ""]);

// exports
exports.locals = {
	"activity-save-tips": "activity-save-tips_activity-save-tips_3ZJ8q",
	"activitySaveTips": "activity-save-tips_activity-save-tips_3ZJ8q"
};