import log from 'Lib/log';

import Walker from './walker';
import NODE_TYPES from '../parser/node-type';
import ERROR_TYPES from './error-types';

import matcherGenerator from '../helpers/matcher-generator';

/* eslint-disable no-use-before-define */

const pickOutBestMatchResultIndex = results => {
    let targetIndex = 0;

    results.reduce((pre, next, nextIndex) => {
        let result = pre;

        if (result.status === next.status) {
            if (next.depth > result.depth) {
                targetIndex = nextIndex;
                result = next;
            }
        } else if (!result.status) {
            targetIndex = nextIndex;
            result = next;
        }

        return result;
    });

    return targetIndex;
};

const diffBlock = (source, target, walker) => {
    const isProcedures = source.opCode.indexOf('procedures_') === 0;
    const sourceBlockData = isProcedures
        ? {
              opCode: source.opCode,
              procCode: source.params.CODE?.value.text || null
          }
        : {
              opCode: source.opCode
          };

    if (!target) {
        return {
            status: false,
            context: walker.createContext(),
            error: {
                type: ERROR_TYPES.BLOCK_MISSING,
                expect: sourceBlockData
            }
        };
    }

    let result;

    const targetBlockData =
        target.opCode.indexOf('procedures_') === 0
            ? {
                  opCode: target.opCode,
                  procCode: target.params.CODE?.value.text || null
              }
            : {
                  opCode: target.opCode
              };

    walker.walk({
        type: NODE_TYPES.BLOCK,
        id: target.id,
        ...targetBlockData
    });

    if (
        source.opCode === target.opCode &&
        (!isProcedures ||
            (!sourceBlockData.procCode ||
                sourceBlockData.procCode === targetBlockData.procCode))
    ) {
        Object.keys(source.params)
            .filter(key => !(isProcedures && key === 'CODE'))
            .some(key => {
                const paramWalker = walker.walkParam({
                    type: NODE_TYPES.PARAM,
                    key
                });

                result = target.params[key]
                    ? diffParam(
                          source.params[key],
                          target.params[key],
                          paramWalker
                      )
                    : {
                          status: false,
                          context: walker.createContext(),
                          error: {
                              type: ERROR_TYPES.BLOCK_PARAM_MISSING,
                              expect: {
                                  key
                              }
                          }
                      };

                // If error find, return `true` to exit.
                return !result.status;
            });
    } else {
        result = {
            status: false,
            context: walker.createContext(),
            error: {
                type: ERROR_TYPES.BLOCK_ERROR,
                expect: sourceBlockData,
                source: targetBlockData
            }
        };
    }

    return (
        result || {
            status: true,
            context: null
        }
    );
};

// @range(1,2)
const MATCHER_MATCH_REG_EXP = /@([^(]+?)\(([^)]*)\)$/;

const diffText = (source, target, walker) => {
    walker.walk({
        type: NODE_TYPES.PARAM_VALUE_NUM_STRING,
        text: target.text
    });

    let result;

    const matcherMatchResult = source.text.match(MATCHER_MATCH_REG_EXP);
    let matcher = null;

    if (matcherMatchResult) {
        const matcherName = matcherMatchResult[1];
        const matcherArgs = matcherMatchResult[2]
            .split(/\s+/)
            .map(v => v.trim())
            .filter(v => v !== '');

        matcher = matcherGenerator.generate(matcherName, matcherArgs);
    }

    if (matcher) {
        if (!matcher.test(target.text)) {
            result = {
                status: false,
                context: walker.createContext(),
                error: {
                    type: ERROR_TYPES.BLOCK_PARAM_VALUE_ERROR,
                    expect: {
                        text: matcher
                    },
                    source: {
                        text: target.text
                    }
                }
            };
        }
    } else if (source.text !== target.text) {
        result = {
            status: false,
            context: walker.createContext(),
            error: {
                type: ERROR_TYPES.BLOCK_PARAM_VALUE_ERROR,
                expect: {
                    text: source.text
                },
                source: {
                    text: target.text
                }
            }
        };
    }

    return (
        result || {
            status: true,
            context: null
        }
    );
};

const diffParam = (source, target, walker) => {
    let result;

    switch (source.value.type) {
        case NODE_TYPES.PARAM_VALUE_NUM_STRING:
            result = diffText(source.value, target.value, walker);
            break;
        default:
            result = diff(source.value, target.value, walker);
    }

    return result;
};

const diffPattern = (source, target, walker) => {
    const sourceChain = source.chain;
    const targetChain = target.chain;

    let result;

    for (let i = 0; i < sourceChain.length; i++) {
        const chainItem = sourceChain[i];

        switch (chainItem.type) {
            case NODE_TYPES.BLOCKS_OPTIONAL:
                result = diffBlocksOptional(
                    {
                        type: NODE_TYPES.PATTERN,
                        chain: sourceChain.slice(i)
                    },
                    target,
                    walker
                );

                // BLOCKS_OPTIONAL would hijack diffPattern method.
                // So just cancel current diff loop.
                i = sourceChain.length - 1;
                break;
            case NODE_TYPES.BLOCK:
                result = diffBlock(
                    chainItem,
                    targetChain && targetChain[walker.steps],
                    walker
                );
                break;
            default:
                result = diff(chainItem, target, walker);
        }
        // TODO:remove 注入错误的代码块和前一个代码块信息
        if (result && result.error) {
            Object.assign(result.error, {
                __ERRORSOURCE__: {
                    currentBlock: chainItem,
                    preBlock: sourceChain[i - 1] || null
                }
            });
        }
        if (!result.status) break;
    }

    return (
        result || {
            status: true,
            context: null
        }
    );
};

const diffPatterns = (source, target, walker) => {
    const resultNotations = [];
    const results = source.patterns.map(tree => {
        const notation = walker.notate();
        const result = diff(tree, target, walker);
        resultNotations.push(walker.notate());

        walker.backtrack(notation);
        return result;
    });

    const targetIndex = pickOutBestMatchResultIndex(results);
    walker.backtrack(resultNotations[targetIndex]);

    return results[targetIndex];
};

const diff = (source, target, walker) => {
    let result;

    switch (source.type) {
        case NODE_TYPES.PATTERNS:
            result = diffPatterns(source, target, walker);
            break;
        case NODE_TYPES.PATTERN:
            result = diffPattern(source, target, walker);
            break;
        default:
            result = {
                status: false,
                context: walker.createContext(),
                error: {
                    type: ERROR_TYPES.UNKNOWN_NODE_TYPE,
                    nodeType: source.type
                }
            };
    }

    result.depth = walker.depth;
    return result;
};

const diffBlocksOptional = (source, target, walker) => {
    const sourceChain = source.chain;
    const targetChain = target.chain;

    const sourceMatchOptionalMin = sourceChain[0].min;
    let restTargetLength = targetChain.slice(walker.steps).length;
    let result;

    for (
        let i = 0;
        i < Math.min(sourceMatchOptionalMin, restTargetLength);
        i++
    ) {
        // walker.walk would auto increase walker.steps
        const targetChainItemMatch = targetChain[walker.steps];
        walker.walk({
            type: NODE_TYPES.BLOCKS_OPTIONAL,
            id: targetChainItemMatch.id,
            opCode: targetChainItemMatch.opCode
        });
    }

    if (restTargetLength < sourceMatchOptionalMin) {
        walker.walk({
            type: NODE_TYPES.BLOCKS_OPTIONAL,
            opCode: null
        });
        result = {
            status: false,
            context: walker.createContext(),
            error: {
                type: ERROR_TYPES.BLOCK_OPTIONAL_MISSING,
                expect: null
            }
        };
    }

    if (!result) {
        restTargetLength = targetChain.slice(walker.steps).length;

        const diffResults = [];
        const diffResultNotations = [];
        let isMatch = false;

        const notation = walker.notate();
        for (let i = 0; i <= restTargetLength; i++) {
            // walk all blocks that regard as optional blocks.
            for (let j = 1; j <= i; j++) {
                // walker.walk would auto increase walker.steps
                const targetChainItemMatch = targetChain[walker.steps];
                walker.walk({
                    type: NODE_TYPES.BLOCKS_OPTIONAL,
                    id: targetChainItemMatch.id,
                    opCode: targetChainItemMatch.opCode
                });
            }

            result = diff(
                {
                    type: NODE_TYPES.PATTERN,
                    // The first one source is BLOCKS_OPTIONAL
                    chain: sourceChain.slice(1)
                },
                target,
                walker
            );

            diffResults.push(result);
            diffResultNotations.push(walker.notate());

            // context restore here, so do not need to do `peekStack`
            walker.backtrack(notation);

            if (result.status) {
                isMatch = true;
                break;
            }
        }

        const selectedDiffResultIndex = isMatch
            ? diffResults.length - 1 // Find out the result which has the deepest match
            : pickOutBestMatchResultIndex(diffResults); // among error results.

        walker.backtrack(diffResultNotations[selectedDiffResultIndex]);
        result = diffResults[selectedDiffResultIndex];
    }

    return result;
};

const treeDiff = (expectSource, target) => {
    const sourceTargetsResults = expectSource.map(sourceItem =>
        target
            .map((targetItem, index) => {
                const walker = new Walker();

                let result;
                try {
                    result = diff(sourceItem, targetItem, walker);
                } catch (err) {
                    result = {
                        status: false,
                        context: walker.createContext(),
                        error: {
                            type: ERROR_TYPES.TARGET_PATH_ERROR,
                            data: err
                        }
                    };
                    log.error(err);
                }

                return {
                    index,
                    result
                };
            })
            .sort((a, b) => {
                if (a.result.status === b.result.status) {
                    return b.result.depth - a.result.depth;
                }

                if (b.result.status) {
                    return 1;
                }

                return -1;
            })
    );

    const sourceResultsIndexMap = Object.create(null);
    const targetSourceIndexMap = Object.create(null);

    const tryPickOutBestSourceTargetMap = sourceIndex => {
        const results = sourceTargetsResults[sourceIndex];
        const newResult = results[sourceResultsIndexMap[sourceIndex]];
        const newTargetIndex = newResult.index;

        if (typeof targetSourceIndexMap[newTargetIndex] !== 'undefined') {
            const oldSourceIndex = targetSourceIndexMap[newTargetIndex];
            const oldTargetResultsIndex = sourceResultsIndexMap[oldSourceIndex];
            const oldResult =
                sourceTargetsResults[oldSourceIndex][oldTargetResultsIndex];

            // Old result has a higher priority.
            if (
                pickOutBestMatchResultIndex([
                    newResult.result,
                    oldResult.result
                ]) === 1
            ) {
                sourceResultsIndexMap[sourceIndex]++;
                if (sourceResultsIndexMap[sourceIndex] >= target.length) {
                    sourceResultsIndexMap[sourceIndex] = -1;
                    return;
                }
                tryPickOutBestSourceTargetMap(sourceIndex);
            } else {
                targetSourceIndexMap[newTargetIndex] = sourceIndex;

                sourceResultsIndexMap[oldSourceIndex]++;
                if (sourceResultsIndexMap[oldSourceIndex] >= target.length) {
                    sourceResultsIndexMap[oldSourceIndex] = -1;
                    return;
                }
                tryPickOutBestSourceTargetMap(oldSourceIndex);
            }

            return;
        }

        targetSourceIndexMap[newTargetIndex] = sourceIndex;
    };

    sourceTargetsResults.forEach((results, index) => {
        sourceResultsIndexMap[index] = sourceResultsIndexMap[index] || 0;
        tryPickOutBestSourceTargetMap(index);
    });

    return sourceTargetsResults.map((results, index) => {
        if (sourceResultsIndexMap[index] === -1) {
            return {
                status: false,
                context: null,
                error: {
                    type: ERROR_TYPES.SCRIPT_MISSING
                }
            };
        }

        return results[sourceResultsIndexMap[index]].result;
    });
};

export {treeDiff as default, pickOutBestMatchResultIndex};
