const GENERATE_MAP = {
    number(type) {
        const TYPE_MAP = {
            'N': {
                description: `自然数`,
                test(value) {
                    return TYPE_MAP['Z+0'].test(value);
                }
            },
            'R+': {
                description: `正数`,
                test(value) {
                    return value > 0;
                }
            },
            'R-': {
                description: `负数`,
                test(value) {
                    return value < 0;
                }
            },
            'R+0': {
                description: `0 或 正数`,
                test(value) {
                    return value >= 0;
                }
            },
            'R-0': {
                description: `0 或 负数`,
                test(value) {
                    return value <= 0;
                }
            },
            'Z': {
                description: `整数`,
                test(value) {
                    return value == parseInt(value, 10);
                }
            },
            'Z+': {
                description: `正整数`,
                test(value) {
                    return TYPE_MAP['R+'].test(value) && TYPE_MAP.Z.test(value);
                }
            },
            'Z-': {
                description: `负整数`,
                test(value) {
                    return TYPE_MAP['R-'].test(value) && TYPE_MAP.Z.test(value);
                }
            },
            'Z+0': {
                description: `0 或 正整数`,
                test(value) {
                    return (
                        TYPE_MAP['R+0'].test(value) && TYPE_MAP.Z.test(value)
                    );
                }
            },
            'Z-0': {
                description: `0 或 负整数`,
                test(value) {
                    return (
                        TYPE_MAP['R-0'].test(value) && TYPE_MAP.Z.test(value)
                    );
                }
            }
        };

        return TYPE_MAP[type] || null;
    },

    range(min, max) {
        min = min === '?' ? Number.NEGATIVE_INFINITY : parseFloat(min);
        max = max === '?' ? Number.POSITIVE_INFINITY : parseFloat(max);

        if (min > max) {
            min = min ^ max;
            max = min ^ max;
            min = min ^ max;
        }

        let desc = `${min} 到 ${max} 范围内的值`;

        if (min === Number.NEGATIVE_INFINITY) {
            desc = `不能大于 ${max} 的值`;
        } else if (max === Number.NEGATIVE_INFINITY) {
            desc = `不能小于 ${min} 的值`;
        }

        return {
            description: desc,
            test(value) {
                value = parseFloat(value);

                if (!value) return false;

                if (value < min) {
                    return false;
                } else if (value > max) {
                    return false;
                }
                return true;
            }
        };
    },

    select(...values) {
        const SEPARATOR_SYMBOL = '、';
        const desc = values
            .join(SEPARATOR_SYMBOL)
            .replace(
                new RegExp(`${SEPARATOR_SYMBOL}${values[values.length - 1]}$`),
                `或者${values[values.length - 1]}`
            );

        values = values.map(value => value.toString());

        return {
            description: desc,
            test(value) {
                return values.indexOf(value.toString()) !== -1;
            }
        };
    }
};

export default {
    generate(funcName, funcArgs = []) {
        return GENERATE_MAP[funcName]
            ? GENERATE_MAP[funcName](...funcArgs)
            : null;
    }
};
