const SHOW_FOLLOW_TIP = 'puzzle/tip/SHOW_FOLLOW_TIP';
const HIDE_FOLLOW_TIP = 'puzzle/tip/HIDE_FOLLOW_TIP';
const SET_FOLLOW_TIP_PRIORITY = 'puzzle/tip/SET_FOLLOW_TIP_PRIORITY';

const priorityMap = {
    HIGH: 9,
    MIDDLE: 6,
    LOW: 3,
    UNSET: 0
};

const initialState = {
    shouldShowTip: false,
    currentTipPriority: priorityMap.UNSET,
    tipData: {}
};

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SHOW_FOLLOW_TIP:
            return Object.assign({}, state, {
                shouldShowTip: true,
                tipData: action.tip
            });
        case HIDE_FOLLOW_TIP:
            return Object.assign({}, state, {
                shouldShowTip: false
            });
        case SET_FOLLOW_TIP_PRIORITY:
            return Object.assign({}, state, {
                currentTipPriority: action.priority
            });
        default:
            return state;
    }
};

const showFollowTip = tip => ({
    type: SHOW_FOLLOW_TIP,
    tip
});

const hideFollowTip = () => ({
    type: HIDE_FOLLOW_TIP
});

const setFollowTipPriority = priority => ({
    type: SET_FOLLOW_TIP_PRIORITY,
    priority
});

export {
    reducer as default,
    initialState as tipInitialState,
    showFollowTip,
    hideFollowTip,
    priorityMap,
    setFollowTipPriority
};
