import PropTypes from 'prop-types';
import React, {Component} from 'react';
import MonacoEditor from 'react-monaco-editor';
import {snippets} from './snippets';
import styles from './python-editor.css';
import debounce from 'lodash.debounce';
import {customeTheme} from './monaco-theme-custome';

const options = {
    minimap: {
        enabled: false
    },
    fontSize: '26px',
    lineNumbersMinChars: 2,
    extraEditorClassName: styles.editor
};

const CHINESE_CHARACTERS = '（）‘’”“：;，【】《》';

export default class PythonEditor extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChange = debounce(this.onChange, 300);
        this.editorWillMount = this.editorWillMount.bind(this);
        this.tryError = this.tryError.bind(this);
        this.editorDidMount = this.editorDidMount.bind(this);
        this.setMarkers = this.setMarkers.bind(this);
        this.changeErrorColumnStyle = this.changeErrorColumnStyle.bind(this);
        this.triggerErrorHover = this.triggerErrorHover.bind(this);
        this.getNotEmptyStringLine = this.getNotEmptyStringLine.bind(this);
        this.setBlock = this.setBlock.bind(this);
        this.judgePartError = this.judgePartError.bind(this);
        this.fnList = [];
        const jsonCode = [''].join('\n');
        this.state = {
            code:
                this.props.puzzleEngine.config.lastSavePythonCode ||
                this.props.puzzleEngine.config.preCode ||
                this.props.pre ||
                jsonCode
        };
        this.decorations = [];
    }

    componentDidUpdate(prevProps) {
        if (!this.isInitTransform && this.editor) {
            this.isInitTransform = true;
            this.onChange(this.editor.getValue());
        }
        const {puzzleEngine, currentTargetIndex} = this.props;
        if (currentTargetIndex !== prevProps.currentTargetIndex) {
            const {userPythonCode} = puzzleEngine.config;
            this.editor.setValue(userPythonCode[currentTargetIndex] || '');
        }
    }

    editorWillMount(monaco) {
        monaco.editor.defineTheme('monokai', customeTheme);
        monaco.languages.registerCompletionItemProvider('python', {
            provideCompletionItems: function() {
                return {
                    suggestions: Object.keys(snippets).map(item => ({
                        label: item,
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: Array.isArray(snippets[item].body)
                            ? snippets[item].body.join('\n')
                            : snippets[item].body,
                        insertTextRules:
                            monaco.languages.CompletionItemInsertTextRule
                                .InsertAsSnippet,
                        documentation: item
                    }))
                };
            }
        });
    }

    storeCursorPosition() {
        this.lastFocusPosition = this.editor.getPosition();
    }
    resumeCursorPosition() {
        if (this.lastFocusPosition) {
            this.editor.setPosition(this.lastFocusPosition);
            this.editor.focus();
        }
    }

    get markers() {
        if (!this._markers) {
            this.storeCursorPosition();
            this.triggerErrorHover();
            this.resumeCursorPosition();
            this._markers = this.modesContentHoverWidget._messages;
        }
        return this._markers;
    }

    clearLastMarkers() {
        this._markers = null;
    }

    get modesContentHoverWidget() {
        return this.editor._contentWidgets[
            'editor.contrib.modesContentHoverWidget'
        ].widget;
    }

    renderErrorTips() {
        if (!this.hijackModesContentHoverWidget) {
            this.hijackModesContentHoverWidget = this.modesContentHoverWidget.hide;
        }
        this.modesContentHoverWidget.hide = () => {};
        const errorMarkers = this.markers[0];
        this.modesContentHoverWidget._renderMessages(
            errorMarkers.range,
            this.markers
        );
    }

    hideErrorTips() {
        if (this.hijackModesContentHoverWidget) {
            this.modesContentHoverWidget.hide = this.hijackModesContentHoverWidget;
        }
    }

    triggerErrorHover() {
        this.storeCursorPosition();
        this.editor.setPosition({lineNumber: this.errorLine[0], column: 1});
        this.editor.focus();
        this.editor.trigger('', 'editor.action.showHover');
    }

    onChange(newValue) {
        this.hasError = false;
        this.tryError(newValue);
    }
    // 触发monaco error hint，永远只触发一种
    setMarkers(config = {}) {
        if (config.startLineNumber) {
            this.errorLine = [config.startLineNumber, config.endLineNumber];
        } else {
            this.errorLine = [];
        }
        this.monaco.editor.setModelMarkers(this.editor.getModel(), '', [
            config
        ]);
    }

    changeErrorColumnStyle(firstLine, lastLine) {
        this.clearLastMarkers();
        if (firstLine) {
            this.decorations = this.editor.deltaDecorations(this.decorations, [
                {
                    range: new this.monaco.Range(firstLine, 1, lastLine, 1),
                    options: {
                        isWholeLine: true,
                        marginClassName: styles.errorColumn,
                        className: styles.errorColumnWithIcon
                    }
                }
            ]);
        } else {
            this.decorations = this.editor.deltaDecorations(this.decorations, [
                {
                    range: new this.monaco.Range(1, 1, 1, 1),
                    options: {
                        isWholeLine: true,
                        marginClassName: '',
                        className: ''
                    }
                }
            ]);
        }
    }

    setBlock(value) {
        const {onStopPuzzle, isPuzzlePlaying} = this.props;
        if (isPuzzlePlaying) {
            onStopPuzzle();
        }
        this.props.puzzleEngine.workspace.options.readOnly = false;
        this.props.puzzleEngine.pythonString2Block(value, true, true);
        if (!this.hasError) {
            this.setMarkers();
            this.changeErrorColumnStyle();
        }
        this.props.puzzleEngine.workspace.options.readOnly = true;
    }

    tryError(value) {
        const currentValueArr = this.editor.getValue().split(/\n/);
        try {
            this.setBlock(value);
        } catch (error) {
            this.props.puzzleEngine.workspace.options.readOnly = true;
            const textArr = value.split(/\n/g);
            // TODO:之后尝试写一个基础的parse替换掉filber  重新整理py2scratch 错误抛出
            let errorMes =
                error.mes ||
                error.message ||
                '请仔细检查代码' ||
                'SyntaxError: Unexpected token';
            switch (true) {
                case errorMes === 'Identifier directly after number':
                    errorMes += `;\n变量不能以数字开头`;
                    break;
                case /Unexpected character/.test(errorMes): {
                    const firstCharacter = errorMes.split(
                        'Unexpected character'
                    )[1];
                    errorMes += `;\n无效${firstCharacter}`;
                    if (
                        new RegExp(firstCharacter.trim()[1]).test(
                            CHINESE_CHARACTERS
                        )
                    ) {
                        errorMes += `;\n符号 ${firstCharacter}是个中文字符，把它修正为英文字符吧`;
                    } else {
                        errorMes += `;\n代码写错了，快修正代码吧`;
                    }
                    break;
                }
                default:
                    errorMes += `;\n代码写错了，快修正代码吧`;
                    break;
            }

            if (!this.hasError) {
                let line =
                    (error.position && error.position.line) ||
                    (error.loc && error.loc.line) ||
                    textArr.length;
                const column = (error.loc && error.loc.column) || false;
                this.shouldFixLastErrorLine = false;
                line = this.getNotEmptyStringLine(currentValueArr, line);
                let firstLine = line;
                if (
                    !(
                        column &&
                        column > 1 &&
                        textArr[line - 1][column - 1] !== ' '
                    )
                ) {
                    firstLine = this.judgePartError(
                        currentValueArr,
                        line,
                        line
                    );
                }
                if (this.shouldFixLastErrorLine) {
                    line = firstLine;
                }

                this.setMarkers({
                    startLineNumber: firstLine,
                    endLineNumber: line,
                    startColumn: 1,
                    endColumn: Number.MAX_SAFE_INTEGER,
                    message: errorMes,
                    severity: 3
                });
                this.changeErrorColumnStyle(firstLine, line);
            }
            this.hasError = true;
            textArr.pop();
            const newValue = textArr.join('\n');
            if (textArr.length < 1) {
                this.props.puzzleEngine.setWorkspaceBlocks(
                    '<xml xmlns="http://www.w3.org/1999/xhtml">',
                    true
                );
            } else {
                this.tryError(newValue);
            }
        }
    }

    judgePartError(arr, line, lastLine) {
        if (line === 1) return line;
        try {
            const str = [
                ...arr.slice(0, line - 1),
                `${arr[line - 1]})`,
                ...arr.slice(line, arr.length)
            ].join('\n');
            try {
                if (arr[line - 1].trim() !== '') {
                    this.setBlock(str);
                    this.shouldFixLastErrorLine = true;
                    return line;
                }
            } catch (error) {
                const errorLine =
                    (error.position && error.position.line) ||
                    (error.loc && error.loc.line) ||
                    str.length;
                if (errorLine > lastLine) {
                    this.shouldFixLastErrorLine = true;
                    return line;
                }
            }
            this.setBlock(arr.slice(0, line - 1).join('\n'));

            return line;
        } catch (error) {
            return this.judgePartError(arr, line - 1, lastLine);
        }
    }

    getNotEmptyStringLine(arr, lineNum) {
        if (lineNum === 1) return lineNum;
        const index = lineNum - 1;
        if (
            arr[index].trim() === '' ||
            arr[index].trim().startsWith('#') ||
            arr[index].trim().startsWith(`'''`) ||
            arr[index].trim().startsWith(`"""`)
        ) {
            return this.getNotEmptyStringLine(arr, index);
        }
        return lineNum;
    }

    editorDidMount(editor, monaco) {
        const {editorDidMount} = this.props;
        this.editor = editor;
        this.monaco = monaco;
        this.editor.getModel().updateOptions({tabSize: 4});
        editorDidMount(editor, monaco);
        editor.onMouseMove(e => {
            if (
                e.target.position &&
                this.errorLine &&
                this.errorLine.length > 0 &&
                e.target.position.lineNumber >= this.errorLine[0] &&
                e.target.position.lineNumber <= this.errorLine[1] &&
                e.target.detail &&
                !e.target.detail.isAfterLines
            ) {
                this.renderErrorTips();
            } else {
                this.hideErrorTips();
            }
        });
    }

    render() {
        const {code} = this.state;
        const {options: opt} = this.props;
        const opts = Object.assign({}, options, opt);
        return (
            <MonacoEditor
                width="100%"
                height="100%"
                language="python"
                defaultValue={code[0] || ''}
                options={opts}
                theme={'monokai'}
                editorWillMount={this.editorWillMount}
                onChange={this.onChange}
                editorDidMount={this.editorDidMount}
            />
        );
    }
}
PythonEditor.propTypes = {
    currentTargetIndex: PropTypes.number,
    editorDidMount: PropTypes.func, // monaco editor 挂载后触发
    isPuzzlePlaying: PropTypes.bool,
    onStopPuzzle: PropTypes.func, // editor 配置项
    options: PropTypes.object, // 预置代码
    pre: PropTypes.array,
    puzzleEngine: PropTypes.object
};
