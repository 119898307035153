import React from 'react';
import PropTypes from 'prop-types';
import GeneralConfig from 'PublicRepo/utility/generalConfig';
import {LESSON} from 'public-repo/lib/config';
import '!style-loader!css-loader!./LessonHead.css'; //eslint-disable-line

const LessonHead = ({lessonInfo, ...props}) => {
    /* eslint-disable babel/camelcase */
    let activeLevelIndex = null;
    const prizeLevelIndex = [];
    if (lessonInfo) {
        return (
            <div className="l-level-con">
                <h1 className="l-level-name">
                    {lessonInfo.lesson_order &&
                        parseInt(lessonInfo.lesson_order, 10)}
                </h1>
                <ul>
                    {lessonInfo.activity &&
                        lessonInfo.activity.map((item, index) => {
                            if (
                                String(item.id) === props.activity.params.actId
                            ) {
                                activeLevelIndex = index + 1;
                                return (
                                    <li
                                        className="l-level l-active-level"
                                        key={item.title}
                                    >
                                        {activeLevelIndex}
                                    </li>
                                );
                            }
                            if (item.locked) {
                                if (item.type === LESSON.TYPE.PRIZE) {
                                    prizeLevelIndex.push(index + 1);
                                    return (
                                        <li
                                            className="l-level l-prize-level l-prize-locked"
                                            key={item.title}
                                        >
                                            <div className="treasure-star close-left" />
                                            <div className="treasure-star close-right" />
                                            <div className="treasure-star close-top" />
                                            <div className="treasure-star close-bottom" />
                                            <div className="treasure-star close-center" />
                                        </li>
                                    );
                                }
                                return (
                                    <li
                                        className="l-level l-normal-locked"
                                        key={item.title}
                                    />
                                );
                            }
                            if (item.type === LESSON.TYPE.PRIZE) {
                                prizeLevelIndex.push(index + 1);
                                return (
                                    <li
                                        className="l-level l-prize-level l-prize-unlocked"
                                        key={item.title}
                                    />
                                );
                            }
                            return (
                                <li
                                    className="l-level l-normal-unlocked"
                                    key={item.title}
                                >
                                    {index + 1}
                                </li>
                            );
                        })}
                </ul>
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{
                            width:
                                lessonInfo.activity.length &&
                                (GeneralConfig.activityHeaderbarWidth *
                                    activeLevelIndex) /
                                    lessonInfo.activity.length
                        }}
                    />
                    <div className="level-info">
                        {lessonInfo.lesson_order &&
                            `Lesson ${parseInt(
                                lessonInfo.lesson_order,
                                10
                            )} - ${activeLevelIndex}`}
                    </div>
                    {prizeLevelIndex.map(item => (
                        <div
                            className="level-prize"
                            key={item}
                            style={{
                                left: (360 * item) / lessonInfo.activity.length
                            }}
                        >
                            <div className="treasure-star close-left" />
                            <div className="treasure-star close-right" />
                            <div className="treasure-star close-top" />
                            <div className="treasure-star close-bottom" />
                            <div className="treasure-star close-center" />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return null;
};

LessonHead.propTypes = {
    activity: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            actId: PropTypes.string.isRequired,
            classId: PropTypes.string.isRequired,
            lessonId: PropTypes.string.isRequired,
            taskId: PropTypes.string
        }).isRequired,
        videoUrl: PropTypes.string
    }),
    lessonInfo: PropTypes.shape({
        name: PropTypes.string.isRequired,
        lesson_order: PropTypes.string.isRequired,
        activity: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired
            })
        ).isRequired
    })
};

export default LessonHead;
