exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".clip-wrap_fixed_15tdd {\n    position: fixed;\n    top: 0;\n    left: 0;\n    background: black;\n    opacity: 0.9;\n    z-index: 9998;\n    -webkit-perspective: 1000;\n            perspective: 1000;\n    /* -webkit-font-smoothing: subpixel-antialiased; */\n    /* backface-visibility: hidden; */\n    /* transform: translateZ(0); */\n}\n.clip-wrap_touch_w6SOg {\n    z-index: 9999;\n    background: rgba(256, 256, 256, 0);\n    border: 3px dashed #ffb030;\n    border-radius: 8px;\n    pointer-events: none;\n}\n.clip-wrap_hidden_1jfzi {\n    visibility: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"fixed": "clip-wrap_fixed_15tdd",
	"touch": "clip-wrap_touch_w6SOg",
	"hidden": "clip-wrap_hidden_1jfzi"
};