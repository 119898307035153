// 通用 定制 配置

/**
 * 各系统之间使用Cookie作为用户信息传递，所以需要在Cookie有个用户标示 是哪个品牌，如bell
 */

/**
 * 1. 用户在登录时，获取到用户所使用的机构品牌名，如 bellcode
 * 2. 用bellcode标识，同步更新配置，保存在localstorage
 * 3. 各个组件需要用到配置再获取
 *
 * 在用户退出登录 时可以
 */

import localstory from 'localstory';
import oGet from 'lodash/get';

import Config from 'PublicRepo/utility/generalConfig';
import SettingsModel from 'PublicRepo/models/setting';

const mockConfig = {
  // "id": "5",
  // "name": "jimmy",
  // "code": "202003271407",
  // "Site": {
  //   "UserDefaultAvatar": "https://img.wkcoding.com/default_user_avatar.png",
  //   "Logo": "https://img.wkcoding.com/admin/FonYkYhg9Wr0iWPRXFMVYRYXssvi",
  //   "LogoPrimary": "https://img.wkcoding.com/admin/Fq19Mw1Bccs48g8Br3kYR9ds6CTc",
  //   "LogoHref": "",
  //   "Email": "zhangxun@bell.ai",
  //   "Concat": "400-8078-928",
  //   "Address": "深圳市南山区朗山路11号同方信息港C栋01单元2楼整层",
  //   "Subdomain": "jimmy"
  // },
  // "Map": {
  //   "background": "https://img.wkcoding.com/jimmyBackground.png",
  //   "backIcon": "https://img.wkcoding.com/jimmy-back-icon.png",
  //   "backBg": "https://img.wkcoding.com/jimmy-back-bg.png"
  // },
  // "Carousel": {
  //   "Jump": false
  // },
  // "Footer": {
  //   "Type": "component",
  //   "Value": "common",
  //   "JsElementId": ""
  // },
  // "Student": {
  //   "HideLessonCourse": true
  // },
  // "Theme": {
  //   "MainColor": "#FCCF0C",
  //   "MainColor_8": "rgba(252, 207, 12, 0.8)",
  //   "HeightColor": "#FDA416",
  //   "TagColors": [
  //     "#FCCF0C",
  //     "#FFAC2A",
  //     "#00aef6",
  //     "rgba(237, 120, 63, 1)"
  //   ],
  //   "FooterTitleColor": "#FCCF0C",
  //   "MapBgColor": "rgba(255,216,36, 1)",
  //   "MapLevelBgColor": "#fdbc1d",
  //   "MapLevelLockBgColor": "#dfbf3e",
  //   "MapGoldBgColor": "rgba(200,158,29,1)",
  //   "MapGoldBorder": "2px solid rgba(240,202,34,1)",
  //   "MapGoldColor": "rgba(255, 241, 91, 1)",
  //   "MapCodeBgColor": "rgba(200,158,29,1)",
  //   "MapCodeBorder": "2px solid rgba(240,202,34,1)",
  //   "MapCodeColor": "rgba(56, 253, 255, 1)",
  //   "MapRankListBgColor": "#e4b830",
  //   "MapRankListBgBorder": "2px solid rgba(240,202,34,1)",
  //   "MapStarFontColor": "rgba(159, 104, 44, 1)",
  //   "MapStarTextShadow": ""
  // }
};
// BellCode 默认配置
const defaultWebConfig = {
  id: 'bellcode',
  code: '20200203',

  Site: {
    UserDefaultAvatar: Config.defaultAvatar,
    Logo: '',
    LogoHref: Config.offcialOrigin,
  },

  Carousel: {
    Jump: true, // 轮播图 点击跳转
  },

  Footer: {
    Type: 'component',
    Value: 'bellcode',
    JsElementId: '',
  },
  // ...

  Theme: {
    MainColor: '#5d37b7',
  },
};

export const getBrandsSettingAjax = id => {
  return SettingsModel.getBrandsSetting(id).then(data => {
    const settingJsonStr = oGet(data, 'setting');
    if (!settingJsonStr) {
      throw Error('origin config is null');
    }
    const config = JSON.parse(settingJsonStr);
    return config;
  });
};

// 初始化本地缓存数据
const configStore = localstory(
  window.localStorage,
  'CustomizeWebConfigNamespace',
  {
    // ttl: '1d',
    // vacuum: 15000,
  }
);

let CACHE_CONFIG;

// 更新配置
const updateWebConfig = config => {
  Object.assign(config, mockConfig);
  configStore.set('id', config.id);
  configStore.set('code', config.code);
  configStore.set('config', config);
  CACHE_CONFIG = config;
};

// 获取配置失败
const error = e => {
  console.warn(`[Customize Config Error]: ${e.message}`);
  // 采用默认配置
  updateWebConfig(defaultWebConfig);
  return defaultWebConfig;
};

// 清除所有配置
export const clear = () => {
  configStore.clear();
  CACHE_CONFIG = void 0;
};

// 同步机构配置
export const sync = id => {
  console.log(`[Customize Config]: 正在同步配置 id:${id}`);
  return getBrandsSettingAjax(id)
    .then(origin => {
      // 配置不同
      if (
        origin.id !== configStore.get('id') ||
        origin.code !== configStore.get('code')
      ) {
        console.log(`[Customize Config]: 配置不同，需要更新`, origin);
        // 更新配置
        updateWebConfig(origin);
      } else {
        console.log(`[Customize Config]: 配置相同，无须更新`);
      }
      return origin;
    })
    .catch(err => error(err));
};

// 加载本地配置
export const load = () => {
  if (CACHE_CONFIG && CACHE_CONFIG.id) {
    console.log(`[Customize Config]: 内存已有配置`, CACHE_CONFIG);
    return Promise.resolve(CACHE_CONFIG);
  }
  return new Promise(re => {
    const localConfig = configStore.get('config');
    if (localConfig && localConfig.id) {
      console.log(`[Customize Config]: 本地缓存已有配置`, localConfig);
      re(localConfig);
    } else {
      throw Error('本地无默认配置');
    }
  })
    .then(config => {
      CACHE_CONFIG = config;
      return config;
    })
    .catch(err => error(err));
};

// 获取配置
// export const get = (key) => {
//   // 未开始加载
//   if(CACHE_CONFIG === void 0) {
//     load()
//   }
//   // 未有正确配置
//   if(! ('id' in CACHE_CONFIG)) {
//     // 没有获取到配置，需要同步

//   }
//   return oGet(CACHE_CONFIG, key)
// }'

export const getConfig = () => {
  if (CACHE_CONFIG) {
    return CACHE_CONFIG;
  }

  return defaultWebConfig;
};

// 获取配置 (同步)
export const getSync = (key, defaultValue) => {
  const value = oGet(CACHE_CONFIG, key);
  if (value === void 0) {
    if (defaultValue === void 0) {
      return oGet(defaultWebConfig, key);
    } else {
      return defaultValue;
    }
  }
  return value;
};

export const get = getSync;

// 获取配置（异步 推荐）
// ...
