import getEventEmitterSingleton from 'PublicRepo/utility/getEventEmitterSingleton';
import Config from 'PublicRepo/utility/generalConfig';
import oGet from 'lodash/get';
import * as webConfig from 'PublicRepo/config/customize';
import Fetch from '../lib/fetch';
import apiOrigin from './getAPIHost';
import tokenStorage from './tokenStorage';
import domainParser from './domainParser';

let userInfo = null;
let sysInfo = null;
let isUserLoggedOut = false;
const brandImageBell = 1;
const brandImageJimmy = 2;

const UserData = {
  /**
   * @param {onSuccess} invoked when got user info successfully
   */
  initUserInfo(onSuccess) {
    return Fetch({ url: `${apiOrigin}v3/common/member/init` })
      .then(data => {
        userInfo = data.user_info;
        formatSchoolInfo(userInfo);
        sysInfo = data.sys_info;
        _defaultInitUserInfoCallback();
        onSuccess && onSuccess();
        return userInfo;
      })
      .catch(err => {
        _defaultInitUserInfoCallback();
        if (err.code === 400 || err.code === 401) {
          onSuccess && onSuccess();
        }
      });
  },

  resetUserInfo() {
    userInfo = null;
    sysInfo = null;
  },

  updateUserInfo(data) {
    return Fetch({
      url: `${apiOrigin}common/member/update-personal-info`,
      method: 'post',
      params: data,
    }).then(() => {
      userInfo = Object.assign(userInfo, data);
      this.emitter.emit('update_profile');
      return registerUserInfo(userInfo);
    });
  },

  // 用户通过扫描二维码进行登录
  getBindUserInfo(data) {
    return Fetch({
      url: `${apiOrigin}v3/common/member/init`,
      method: 'get',
      params: data,
    }).then(data => {
      userInfo = data.user_info;
      formatSchoolInfo(userInfo);
      this.emitter.emit('get_bind_qr_code_profile');
      return userInfo;
    });
  },

  login(uname, password) {
    return Fetch({
      url: `${apiOrigin}common/member/login`,
      method: 'post',
      params: {
        passwd: password,
        username: uname,
      },
    })
      .then(loginResult => tokenStorage
          .setup(loginResult.token)
          .then(() => this.initUserInfo().then(data => {
              data.token = loginResult.token;
              return data;
            }))
          .then(data => data))
      .catch(err => {
        switch (err.code) {
          // 认证老师必须使用微信扫码登录
          case 10200: {
            return {
              loginType: Config.loginState.ACTIVATED_TEACHER,
              info: err.data,
            };
          }

          // 账号密码错误（暂时后端定义为 参数不合法 ）
          case 10101: {
            return {
              loginType: Config.loginState.ACCOUNT_ERROR,
              info: err.data,
            };
          }

          // 此用户不是该域名下的用户，跳转至正常域名
          case 1302: {
            return {
              loginType: Config.loginState.NOTBELONG,
              info: err.message,
            };
          }

          // 通用错误码
          default: {
            return {
              loginType: Config.loginState.GENERAL_ERROR,
              info: err.data,
            };
          }
        }
      });
  },

  logout() {
    return Fetch({
      url: `${apiOrigin}common/member/logout`,
    }).then(() => {
      tokenStorage.clearToken();
      userInfo = null;
      sysInfo = null;
      this.emitter.emit('update_profile');
      this.emitter.emit('user_logout');
      registerUserInfo(userInfo);
    });
  },

  register(data, callbackUrl) {
    return Fetch({
      url: `${apiOrigin}common/member/register`,
      method: 'post',
      params: data,
    }).then(data => {
      userInfo = data;
      this.emitter.emit('update_profile');
      registerUserInfo(data);
      _redirectUrl(callbackUrl);
      return userInfo;
    });
  },

  getWechatQrcode() {
    return Fetch({
      url: `${apiOrigin}thirdparty/wechat/get-login-qr-code`,
      method: 'get',
    })
      .then(data => Promise.resolve(data))
      .catch(err => console.log(err));
  },

  getWechatBindQrcode() {
    return Fetch({
      url: `${apiOrigin}thirdparty/wechat/get-qr-code`,
      method: 'get',
    })
      .then(data => Promise.resolve(data))
      .catch(err => console.log(err));
  },

  completeRegistration(data) {
    return Fetch({
      url: `${apiOrigin}common/member/complete-registration`,
      method: 'post',
      params: data,
    }).then(() => {
      this.emitter.emit('update_profile');
    });
  },

  checkQrLogin(ticket) {
    return Fetch({
      url: `${apiOrigin}thirdparty/wechat/check-qr-login`,
      method: 'get',
      params: {
        ticket,
      },
    })
      .then(data => tokenStorage.setup(data.token).then(() => ({
          loginType: Config.wechatQrState.SUCCESS, // 扫码登录/绑定成功
          info: data,
        })))
      .catch(err => {
        switch (err.code) {
          case 20401: {
            return {
              loginType: Config.wechatQrState.UNBIND, // 微信已绑定
              info: err.data,
            };
          }
          case 20402: {
            return {
              loginType: Config.wechatQrState.USED, // 微信已使用
              info: err.data,
            };
          }
          case 30001: {
            return {
              loginType: Config.wechatQrState.ORG_TEACHER, // 机构老师辅助学生登录
              info: err.data,
            };
          }
          case 1302: {
            return {
              loginType: Config.wechatQrState.NOTBELONG, // 此用户不是该域名下的用户
              info: err.message,
            };
          }
        }
      });
  },

  getBrands() {
    return Fetch({
      url: Config.serverIo.getBrands,
      method: 'get',
    });
  },

  setBrands(id) {
    return Fetch({
      url: Config.serverIo.setBrandId,
      method: 'post',
      params: {
        brand_id: id,
      },
    });
  },

  loginByToken(token) {
    return Fetch({
      url: Config.serverIo.loginByToken,
      method: 'post',
      params: {
        temporary_token: token,
      },
    })
      .then(data => tokenStorage.setup(data.token).then(() => data))
      .catch(err => {
        switch (err.code) {
          case 20000: {
            return {
              errType: Config.tokenType.OVER_DUE, // 临时登录码已经失效的情况
              info: err.data,
            };
          }
        }
      });
  },

  // 更新关联服务需要的用户信息
  // 主要是修复Intercom没有加载成功的情况下，用户信息无法注册，导致其后续使用 to-chat 服务的时候出现无法找到用户的情况
  refreshDependenceService() {
    userInfo && registerUserInfo(userInfo);
  },

  updateLocalUserInfo(updatedUserInfo) {
    userInfo = updatedUserInfo;
    this.emitter.emit('update_profile');
  },

  get userInfo() {
    return userInfo;
  },

  get sysInfo() {
    return sysInfo;
  },

  set userInfo(u) {
    userInfo = u;
  },

  get isUserLoggedOut() {
    return isUserLoggedOut;
  },

  get isTeacher() {
    return (
      userInfo && userInfo.usertype.toString() === Config.userTypeMap.teacher
    );
  },

  get isInstructor() {
    return (
      userInfo && userInfo.usertype.toString() === Config.userTypeMap.instructor
    );
  },

  get isStudent() {
    return (
      userInfo && userInfo.usertype.toString() === Config.userTypeMap.student
    );
  },

  get is2bUser() {
    return userInfo && userInfo.is_offline_business_user;
  },

  get is2bStudent() {
    return this.is2bUser && this.isStudent;
  },

  get isOrgTeacher() {
    return (
      userInfo
      && (userInfo.usertype.toString() === Config.userTypeMap.instructor
        && userInfo.school
        && userInfo.school.school_type === Config.schoolType.OFF_LINE)
    );
  },

  set isUserLoggedOut(isLoggedOut) {
    isUserLoggedOut = isLoggedOut;
  },

  get emitter() {
    return getEventEmitterSingleton();
  },

  /** 获取品牌id 优先用云课堂定制品牌id 没有则从用户信息中获取 默认用bellcode */
  get brandId() {
    const cloudBrandId = localStorage.getItem('cloudBrandId');
    if (cloudBrandId !== null) {
      return cloudBrandId;
    }

    const defaultId = oGet(userInfo, 'default_brand_id');

    return defaultId === null
      ? oGet(userInfo, 'school.brand_id', 'bellcode')
      : defaultId;
  },

  // 更新是否鲸幂
  get isJimmy() {
    console.log(webConfig.get('brandImageType'));
    return webConfig.get('brandImageType', brandImageBell) === brandImageJimmy;
  },

  // 是否鲸幂学生端
  get isJimmyStudent() {
    return this.isJimmy && this.isStudent;
  },

  /** 是否贝尔星球Native */
  get isBellPlanetNative() {
    return /BellplanetNative/i.test(window.navigator.userAgent);
  },

  /**
   * 是否为直营用户
   */
  get isDirectUser() {
    return (
      userInfo
      && userInfo.host_id === Config.hostIdMap.DIRECT
      && userInfo.school
      && userInfo.school.school_type === Config.schoolType.OFF_LINE
    );
  },

  /**
   * 是否为玛酷用户
   */
  get isMakcooUser() {
    return (
      userInfo
      && userInfo.host_id === Config.hostIdMap.MAKCOO
      && userInfo.school
      && userInfo.school.school_type === Config.schoolType.OFF_LINE
    );
  },

  /**
   * 是否为第三方加盟用户
   */
  get isIcodeeUser() {
    return (
      userInfo
      && userInfo.host_id === Config.hostIdMap.ICODEE
      && userInfo.school
      && userInfo.school.school_type === Config.schoolType.OFF_LINE
    );
  },

  /**
   * 是否为编程世界用户
   */
  get isCodeworldUser() {
    return (
      userInfo
      && userInfo.host_id === Config.hostIdMap.CODEWORLD
      && userInfo.school
      && userInfo.school.school_type === Config.schoolType.OFF_LINE
    );
  },

  /**
   * 机构是否开启Python Diy课程使用vscode插件的功能
   */
  get useVscodePlugin() {
    return (
      userInfo
      && userInfo.school
      && Array.isArray(userInfo.school.features)
      && userInfo.school.features.includes('use_vscode_plugin')
    );
  },

  /**
   * 当前学生是否被班级锁定
   */
  get isLockedStatus() {
    return this.isStudent && userInfo && userInfo.class_locked;
  },
};

function _defaultInitUserInfoCallback() {
  UserData.emitter.emit('update_profile');
  registerUserInfo(userInfo);
}

function _redirectUrl(url) {
  if (!url) {
    return false;
  }

  const urlDomain = domainParser.extractRootDomain(url);
  const validHost = ['bellcode.com', 'wkcoding.com'];

  for (let i = 0; i < validHost.length; i++) {
    const validHostItem = validHost[i];
    if (validHostItem === urlDomain) {
      window.location.href = url;
      return true;
    }
  }
  return false;
}

// 注册upscope.io服务，录制用户行为
function registerUpscope(userInfo) {
  if (window.Upscope && userInfo) {
    window.Upscope('init', {
      uniqueId: userInfo.hash_id,
      identities: [userInfo.username],
    });
  }
}

function registerUserInfo(userInfo) {
  UserData.emitter.emit('register_tracker', userInfo);
  UserData.emitter.emit('register_intercom', userInfo);
  registerUpscope(userInfo);
  return userInfo;
}

function formatSchoolInfo(userInfo) {
  if (userInfo && userInfo.school) {
    const { school_type, name: school_name, id: school_id } = userInfo.school;
    userInfo.school_type = school_type;
    userInfo.school_name = school_name;
    userInfo.school_id = school_id;
  }
}

UserData.emitter.on('reset_userInfo', UserData.resetUserInfo);
UserData.emitter.on('logged_out', () => (UserData.isUserLoggedOut = true));
UserData.emitter.on(
  'refresh_userInfo_related_service',
  UserData.refreshDependenceService,
);

export default UserData;
