import ScratchBlocks from 'scratch-blocks';
import log from '../../../../lib/log';

/**
 * Set flyout blocks that user can use.
 * @param {Blockly.Workspace} workspace Blockly mainWorkspace.
 * @param {string[]} sourceBlocks Blocks that user can use.
 */
export default function setToolbox(workspace, sourceBlocks) {
    // Get all rendered blocks in flyout and find out needed blocks.
    const topBlocks = workspace
        .getFlyout()
        .getWorkspace()
        .getTopBlocks();

    const blocksXmlMapTemp = {};
    topBlocks.forEach(block => {
        let type = block.type;

        // For button blocks
        // create variable & create list
        if (
            !type &&
            block.tagName &&
            block.tagName.toLowerCase() === 'button'
        ) {
            type = block.getAttribute('callbackkey');
        }

        if (sourceBlocks.indexOf(type) !== -1) {
            blocksXmlMapTemp[type] = blocksXmlMapTemp[type] || [];
            blocksXmlMapTemp[type].push(ScratchBlocks.Xml.blockToDom(block));
        }
    });

    const targetBlocksXmlList = sourceBlocks.reduce((list, blockType) => {
        if (blocksXmlMapTemp[blockType]) {
            list.push.apply(list, blocksXmlMapTemp[blockType]);
        } else {
            log.warn(`Require block ${blockType} does not exist.`);
        }

        return list;
    }, []);

    workspace.getFlyout().show(targetBlocksXmlList);
}
