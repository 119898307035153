import log from '../../../../lib/log';

export default puzzle => {
    const {workspaceVMBlocks, runtime} = puzzle;

    return {
        getPuzzleTargetAttr(name, targetIndex = 0) {
            if (!runtime.vmBridge.editableTargets[targetIndex]) {
                log.warn(
                    `Required puzzle target with index ${targetIndex} do not exist.`
                );
                return null;
            }

            return runtime.vmBridge.editableTargets[targetIndex][name];
        },
        puzzleBlocks: {
            simpleCheckOrder(blocks) {
                const xml = workspaceVMBlocks.toXML();

                return blocks
                    .map(block => xml.indexOf(block))
                    .every(
                        (item, i, array) =>
                            item !== -1 &&
                            (typeof array[i + 1] === 'undefined'
                                ? true
                                : item < array[i + 1])
                    );
            },
            exist(blocks) {
                const xml = workspaceVMBlocks.toXML();

                return [].concat(blocks).every(block => xml.indexOf(block) > 0);
            },
            getNum(block) {
                const xml = workspaceVMBlocks.toXML();

                return xml.match(new RegExp(block, 'ig')).length;
            }
        }
    };
};
