/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import UserData from 'PublicRepo/utility/userDataManager';
import Config from 'PublicRepo/utility/generalConfig';
import HappyBellMan from '../img/happy-bellman.json';
import SadBellMan from '../img/sad-bellman.json';
import NormalBellMan from '../img/normal-bellman.json';
import HappyJimmy from '../img/happy-jimmy.png';
import SadJimmy from '../img/sad-jimmy.png';
import HappyCodeWorld from '../img/happy-codeworld.png';
import NormalCodeWorld from '../img/normal-codeworld.png';
import SadCodeWorld from '../img/sad-codeworld.png';

import './index.less';

// 星星对应的codeworld icon形象
const codeWorldMan = {
  0: SadCodeWorld,
  1: NormalCodeWorld,
  2: NormalCodeWorld,
  3: HappyCodeWorld,
};
class BellMan extends React.Component {
  static propTypes = {
    stars: PropTypes.number.isRequired,
    bellManType: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.refStars = [];
    this.isCodeWorld = UserData
      && UserData.userInfo
      && UserData.userInfo.school
      && UserData.userInfo.school.brand_id === Config.brandIdMap.icodeWorld;
  }

  componentDidMount() {
    const { stars, bellManType } = this.props;

    // 人物形象 1为小贝形象 2为鲸幂形象
    if (bellManType === 1 && !this.isCodeWorld) {
      // 不同星级load不同人物
      let animationData = '';
      if (stars === 0) {
        animationData = SadBellMan;
      } else if (stars === 2 || stars === 1) {
        animationData = NormalBellMan;
      } else if (stars === 3) {
        animationData = HappyBellMan;
      }

      // bellman人物动效加载
      lottie.loadAnimation({
        container: this.refBellMan,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  }

  render() {
    const { stars, bellManType } = this.props;

    return (
      <div
        className="bellman"
        ref={e => {
          this.refBellMan = e;
        }}
      >
        {bellManType === 1 && this.isCodeWorld && (
          <img className="jimmy" src={codeWorldMan[stars]} />
        )}
        {bellManType === 2 && (
          <img className="jimmy" src={stars === 0 ? SadJimmy : HappyJimmy} />
        )}
      </div>
    );
  }
}

export default BellMan;
