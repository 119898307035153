import Track from 'public-repo/components/Track/Track';

class Sensor {
    /* eslint-disable */
    constructor() {
        this.commonParams = {};
        this.clock = null;
    }

    track(name, param) {
        return new Promise(resolve => {
            Track.track(
                name,
                Object.assign({}, this.commonParams, param),
                () => {
                    resolve();
                }
            );
        });
    }

    setCommonParams(param) {
        this.commonParams = Object.assign({}, this.commonParams, param);
    }

    setProfile(param) {
        sa.setProfile(param);
    }

    getProfile(key) {
        return sa;
    }

    startClock(key, value) {
        let count = 0;
        this.clock = setInterval(function() {
            sa.setProfile({
                [key]: value + count
            });
            count += 10;
        }, 10000);
    }

    stopClock(key) {
        sa.deleteProfile(key);
        clearInterval(this.clock);
    }
}

export default Sensor;
