import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import classNames from 'classnames';
import PuzzleSpriteListContainer from '../../../containers/puzzle-sprite-list';

import styles from './python-left-control-group.css';
import Box from '../../../../components/box/box';
import refreshSrc from './../../puzzle2-control-panel/image/refresh.svg';

import otherStyles from './../../puzzle2-control-panel/puzzle2-control-panel.css';

const noop = () => {};
const PreviousBtn = ({previousSlide}) => (
    <img
        className={classNames(styles.guiBtn, styles.left)}
        src={require('./../../python-puzzle/assets/arrow-left.svg')}
        onClick={previousSlide}
    />
);
PreviousBtn.propTypes = {
    previousSlide: PropTypes.func
};
const NextBtn = ({nextSlide}) => (
    <img
        className={classNames(styles.guiBtn, styles.right)}
        src={require('./../../python-puzzle/assets/arrow-left.svg')}
        onClick={nextSlide}
    />
);
NextBtn.propTypes = {
    nextSlide: PropTypes.func
};
const PythonLeftControlGroup = props => {
    const {
        className,
        handleUndo,
        handleRedo,
        // handleStartBtn,
        undoIcon = require('./../../python-puzzle/assets/back.svg'),
        redoIcon = require('./../../python-puzzle/assets/cancel.svg'),
        // btnIcon,
        undoIconClassName,
        redoIconClassName,
        // btnIconClassName,
        isResetting,
        onResetPuzzle,
        isPuzzlePlaying,
        onStopPuzzle
    } = props;
    return (
        <>
            <Box className={classNames(styles.controlGroup, className)}>
                <Box className={styles.spriteContainer}>
                    <Box className={styles.spriteText}>角色区</Box>
                    <Box className={styles.spriteList}>
                        <PuzzleSpriteListContainer
                            height={'63px'}
                            slidesToShow={4}
                            cellSpacing={10}
                            width={'300px'}
                            renderBottomCenterControls={noop}
                            renderCenterLeftControls={PreviousBtn}
                            renderCenterRightControls={NextBtn}
                            isPuzzlePlaying={isPuzzlePlaying}
                            onStopPuzzle={onStopPuzzle}
                        />
                    </Box>
                </Box>
                <ReactSVG
                    className={classNames(
                        undoIconClassName,
                        styles.rightPositon
                    )}
                    src={undoIcon}
                    onClick={handleUndo}
                />
                <ReactSVG
                    className={classNames(redoIconClassName)}
                    src={redoIcon}
                    onClick={handleRedo}
                />
                <div
                    className={classNames(
                        otherStyles.refreshButton,
                        styles.refreshButton,
                        {[otherStyles.rotate]: isResetting}
                    )}
                    onClick={onResetPuzzle}
                >
                    <img
                        src={refreshSrc}
                        className={otherStyles.refreshButtonInnerIcon}
                    />
                </div>
            </Box>
        </>
    );
};

PythonLeftControlGroup.propTypes = {
    className: PropTypes.string,
    handleRedo: PropTypes.func,
    handleUndo: PropTypes.func,
    isPuzzlePlaying: PropTypes.bool,
    isResetting: PropTypes.bool,
    onResetPuzzle: PropTypes.func,
    onStopPuzzle: PropTypes.func,
    redoIcon: PropTypes.string,
    redoIconClassName: PropTypes.string,
    undoIcon: PropTypes.string,
    undoIconClassName: PropTypes.string
};

export default PythonLeftControlGroup;
