import React from 'react';
import PropTypes from 'prop-types';
import GuideBubble, {
    DirectionType,
    ArrorPositions
} from '../guide-bubble/guide-bubble.jsx';
import classNames from 'classnames';
import styles from '../activity-menu-bar/activity-menu-bar.css';
import '!style-loader!css-loader!../activity-menu-bar/activity-menu-bar.css';

const customGuideKey = 'bellcode_custom-guide-time';
const guideBtnIndicator = 'bellcode-guide-btn-indicator';

class CustomGuideBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bubbleVisible: false
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.customGuideVisible !== this.props.customGuideVisible) {
            this.setState({
                bubbleVisible: this.checkIfNeedBubble(
                    newProps.customGuideVisible
                )
            });
        }
    }

    checkIfNeedBubble = guideVisible => {
        if (guideVisible) return false;
        let times = localStorage.getItem(customGuideKey);
        if (!times) {
            localStorage.setItem(customGuideKey, 1);
            return true;
        }
        times = parseInt(times, 10);
        localStorage.setItem(customGuideKey, ++times);
        return times < 3;
    };

    render() {
        const {customGuideVisible, onOpenCustomGuide} = this.props;
        return (
            <div
                className={classNames(
                    styles.menuBarItem,
                    styles.hoverable,
                    styles.accountNavMenu
                )}
            >
                <div
                    className={classNames(
                        guideBtnIndicator,
                        styles.btn,
                        styles.diyGuide,
                        customGuideVisible ? styles.hidden : ''
                    )}
                    role="button"
                    onClick={onOpenCustomGuide}
                >
                    <span className={styles.txt}>教程</span>
                </div>
                <GuideBubble
                    visible={this.state.bubbleVisible}
                    direction={DirectionType.BOTTOM}
                    arrowPos={ArrorPositions.LEFT}
                    text="点我可以获取帮助哦～"
                    indicatorClass={guideBtnIndicator}
                    targetIndex={0}
                />
            </div>
        );
    }
}

CustomGuideBtn.propTypes = {
    customGuideVisible: PropTypes.bool,
    onOpenCustomGuide: PropTypes.func
};

export default CustomGuideBtn;
