import React from 'react';
import {connect} from 'react-redux';

import AccountCoinComponent from '../components/account-coin/account-coin.jsx';

const AccountCoin = props => <AccountCoinComponent {...props} />;

AccountCoin.propTypes = AccountCoinComponent.propTypes;

const mapStateToProps = state => {
    const {codeNum, goldNum} = state.scratchGui.account.info;
    return {
        codeNum,
        goldNum
    };
};

export default connect(mapStateToProps)(AccountCoin);
