import React from 'react';
import {connect} from 'react-redux';
import AccountInfoComponent from '../components/account-info/account-info.jsx';

const AccountInfo = props => <AccountInfoComponent {...props} />;

AccountInfo.propTypes = AccountInfoComponent.propTypes;

const mapStateToProps = state => {
    const {avatar, nickname} = state.scratchGui.account.info;
    return {
        avatar,
        nickname
    };
};

export default connect(mapStateToProps)(AccountInfo);
