import Fetch from '../lib/fetch';
import Config from '../utility/generalConfig';

export default {
  getSettingInfo() {
    return Fetch({
      url: Config.serverIo.settings,
    });
  },

  getBrandsSetting(brandId) {
    return Fetch({
      url: Config.serverIo.get_brands,
      params: {
        brand_id: brandId,
      },
    });
  },
};
