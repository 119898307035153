import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import reactStringReplace from 'react-string-replace';
import styles from './puzzle2-block-box.css';
import {getBlockHTMLByOpCode} from '../../lib/block-helper/block-to-html';
import getBlockColour from '../../lib/block-helper/get-block-colour';
import generateBlockLightenColor from './generate-block-bg-color';

/**
 * 判断元素是否在 viewport 的上半部分
 * @param {HTMLElement} dom
 */
const checkIfInTopHalfScreen = dom =>
    dom.getBoundingClientRect().top < window.innerHeight / 2;

/**
 * 这个帮助函数用于把一个(maybe)包含代码块特殊标识的字符串替换成 react 组件
 *
 * @param {string} str  包含代码块悬浮介绍窗口标识的字符串
 *  eg. "要先用开始代码块 :{event_whenflagclicked}。"
 * @param {object} descriptionObject 代码块介绍相关字段集合
 * eg. {
 *   event_whenflagclicked: {
 *      "label": "当点击开始",
 *      "desc": "当点击绿旗运行后，该块所连接的代码块将会被执行。"
 *   }
 * }
 * @param {string} offsetParentClassName 能够获取左边边界dom的类名,用于定位悬浮窗口位置
 */
const replaceStringToBlockBoxComponent = (
    str,
    descriptionObject,
    offsetParentClassName
) =>
    reactStringReplace(str, /:\{(.+?)\}/g, (type, i) => (
        <ConnectedBlockBox
            key={i}
            type={type}
            blockTitle={
                descriptionObject[type]
                    ? descriptionObject[type].label
                    : `${type} - title`
            }
            blockDescription={
                descriptionObject[type]
                    ? descriptionObject[type].desc
                    : `${type} - desc`
            }
            offsetParentClassName={offsetParentClassName}
        />
    )).map((e, index) => {
        if (typeof e !== 'object') {
            return (
                <span
                    /* eslint-disable react/no-danger */
                    dangerouslySetInnerHTML={{__html: e}}
                    key={index}
                />
            );
        }
        return e;
    });

/**
 * 代码块悬浮介绍窗口
 * 用于关卡的介绍文案及目标文案
 */
class BlockBox extends Component {
    static propTypes = {
        blockDescription: PropTypes.string, // 代码块描述
        blockTitle: PropTypes.string, // 代码块名称
        isPuzzleReady: PropTypes.bool, // puzzle 载入成功
        type: PropTypes.string // 代码块类型
    };

    state = {
        isTopHalfScreen: true,
        floatWindowStyle: {},
        badgeBackgroundColor: '#c3c3c3', // 代码块标题背景颜色
        blockImageNode: null
    };

    componentDidMount() {
        if (this.props.isPuzzleReady) {
            this.asyncGetBlockData();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.isPuzzleReady && !prevProps.isPuzzleReady) ||
            (this.props.isPuzzleReady && this.props.type !== prevProps.type)
        ) {
            this.asyncGetBlockData();
        }
    }

    asyncGetBlockData() {
        getBlockHTMLByOpCode(this.props.type).then(blockImageNode =>
            this.setState({blockImageNode})
        );
        this.setState({
            badgeBackgroundColor: getBlockColour(this.props.type).primary
        });
    }

    get chunkClassName() {
        const {isTopHalfScreen} = this.state;
        return `${styles.blockChunk} ${
            isTopHalfScreen ? styles.bottom : styles.top
        }`;
    }

    updateFloatWindowStyle = this.updateFloatWindowStyle.bind(this);
    updateFloatWindowStyle() {
        const isTopHalfScreen = checkIfInTopHalfScreen(this.rootRef);
        const {offsetParentClassName} = this.props;
        const {left: outerLeft} = document
            .querySelector(`.${offsetParentClassName}`)
            .getBoundingClientRect();
        const {left: innerLeft} = this.arrowRef.getBoundingClientRect();
        const offsetLeft = parseInt(innerLeft - outerLeft, 10);
        const floatWindowStyle = isTopHalfScreen
            ? {
                  top: `8px`,
                  left: `-${offsetLeft}px`
              }
            : {
                  top: `-8px`,
                  transform: 'translateY(-100%)',
                  left: `-${offsetLeft}px`
              };

        this.setState({
            isTopHalfScreen,
            floatWindowStyle
        });
    }

    get chunkStyle() {
        const {badgeBackgroundColor} = this.state;
        const {isTopHalfScreen} = this.state;
        return isTopHalfScreen
            ? {
                  borderBottomColor: badgeBackgroundColor,
                  color: generateBlockLightenColor(badgeBackgroundColor)
              }
            : {
                  borderTopColor: badgeBackgroundColor,
                  color: generateBlockLightenColor(badgeBackgroundColor)
              };
    }

    get floatWindowStyle() {
        const {badgeBackgroundColor} = this.state;
        return Object.assign({}, this.state.floatWindowStyle, {
            borderColor: badgeBackgroundColor,
            backgroundColor: generateBlockLightenColor(badgeBackgroundColor)
        });
    }

    render() {
        const {blockTitle, blockDescription} = this.props;

        const {badgeBackgroundColor, blockImageNode} = this.state;

        return (
            <div
                style={{backgroundColor: badgeBackgroundColor}}
                className={styles.blockBox}
                ref={ref => (this.rootRef = ref)}
                onMouseEnter={this.updateFloatWindowStyle}
            >
                {blockTitle}
                <div
                    className={this.chunkClassName}
                    style={this.chunkStyle}
                    ref={ref => (this.arrowRef = ref)}
                >
                    <div
                        className={styles.descriptionFloatWindow}
                        style={this.floatWindowStyle}
                    >
                        {this.state.blockImageNode && (
                            <div
                                className={styles.blockImgContainer}
                                /* eslint-disable react/no-danger */
                                dangerouslySetInnerHTML={{
                                    __html: blockImageNode
                                }}
                            />
                        )}
                        <div style={{minWidth: 230}}>
                            <span className={styles.blockTitle}>
                                {blockTitle}
                            </span>
                            <span className={styles.floatDescription}>
                                {blockDescription}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isPuzzleReady: state.puzzle.status.isPuzzleReady
});

const ConnectedBlockBox = connect(mapStateToProps)(BlockBox);

export {ConnectedBlockBox as default, replaceStringToBlockBoxComponent};
