const SHOW_LEAVEWARNING = 'gui/activity/SHOW_LEAVEWARNING';
const HIDE_LEAVEWARNING = 'gui/activity/HIDE_LEAVEWARNING';

const initialState = {
    shouldShowLeaveWarning: false,
    leaveWarningConfig: null
};

const LeaveWarningReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LEAVEWARNING:
            return {
                ...state,
                shouldShowLeaveWarning: true,
                leaveWarningConfig: action.config
            };
        case HIDE_LEAVEWARNING:
            return {
                ...state,
                shouldShowLeaveWarning: false
            };
        default:
            return state;
    }
};

const showLeaveWarning = config => ({
    type: SHOW_LEAVEWARNING,
    config
});

const hideLeaveWarning = () => ({
    type: HIDE_LEAVEWARNING
});

export {
    LeaveWarningReducer as default,
    initialState as leaveWarningInitialState,
    showLeaveWarning,
    hideLeaveWarning
};
