var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* monaco 编辑器 行号位置 */\n.python-editor_editor_26WDu .margin-view-overlays .line-numbers {\n    text-align: center;\n    width: 100% !important;\n}\n/* monaco 编辑器fold 大小 */\n.python-editor_editor_26WDu .margin-view-overlays .cldr.folding {\n    left: 82px !important;\n    -webkit-transform: scale(0.6);\n        -ms-transform: scale(0.6);\n            transform: scale(0.6);\n}\n/*\n*   缩进错误\n*/\n.python-editor_squiggly-error_1n_fG .squiggly-error {\n    -webkit-transform: translateX(16px);\n        -ms-transform: translateX(16px);\n            transform: translateX(16px);\n}\n/*\n** 用样式hack 不显示\n** 隐藏monaco editor hover-content status-bar\n*/\n.hover-row.status-bar {\n    display: none !important;\n}\n/* 错误行样式*/\n.python-editor_errorColumn_3-UV7,\n.python-editor_errorColumnWithIcon_1A6yr {\n    background-color: hsl(11, 44%, 31%);\n}\n.python-editor_errorColumnWithIcon_1A6yr {\n    background-image: url(" + escape(require("../python-puzzle/assets/error-tip.png")) + ");\n    background-position: right center;\n    background-repeat: no-repeat;\n    border-right: 30px solid hsl(11, 44%, 31%);\n    background-size: 24px 24px;\n}\n.python-editor_editor_26WDu .squiggly-error {\n    background: transparent !important;\n}\n.python-editor_editor_26WDu .monaco-editor-hover {\n    background: #0b0810;\n    border: 1px solid rgba(247, 93, 59, 0.55) !important;\n    color: #f75d3b;\n}\n.python-editor_editor_26WDu .monaco-editor-hover .hover-contents {\n    padding: 18px 30px;\n}\n.python-editor_editor_26WDu .marker.hover-contents {\n    font-size: 18px !important;\n    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;\n}\n", ""]);

// exports
exports.locals = {
	"editor": "python-editor_editor_26WDu",
	"squiggly-error": "python-editor_squiggly-error_1n_fG",
	"squigglyError": "python-editor_squiggly-error_1n_fG",
	"errorColumn": "python-editor_errorColumn_3-UV7",
	"errorColumnWithIcon": "python-editor_errorColumnWithIcon_1A6yr"
};