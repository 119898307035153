import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';

import Box from '../../../../components/box/box';
import classNames from 'classnames';
import SubmitIcon from './img/submit-icon.svg';
import BackIcon from './img/icon_back.svg';
import UnZoomIcon from '../../python-puzzle/assets/unzoom.svg';

import styles from './python-left-target-bar.css';

const PythonLeftTargetBar = props => {
    const {
        className,
        submitIcon = SubmitIcon,
        backIcon = BackIcon,
        goBack,
        saveProject,
        submitProject,
        saveText = '保存',
        submitText = '提交',
        startText = '开始运行',
        stopText = '停止运行',
        isPuzzlePlaying,
        handleStartBtn,
        pythonDiy,
        isFullScreen,
        exitFullScreen
    } = props;
    const btnClassName = classNames(styles.button, {
        [styles.hidden]: !pythonDiy
    });
    if (isFullScreen) {
        return (
            <Box
                className={classNames(
                    styles.targetBar,
                    className,
                    styles.isFullScreen
                )}
            >
                <div
                    className={classNames(styles.startOrStop, {
                        [styles.stop]: isPuzzlePlaying
                    })}
                    onClick={handleStartBtn}
                    id="start-button"
                >
                    <div className={styles.statusIcon} />
                    {isPuzzlePlaying ? stopText : startText}
                </div>
                <div
                    className={classNames(styles.exitFullScreen)}
                    onClick={exitFullScreen}
                >
                    <InlineSVG src={UnZoomIcon} />
                </div>
            </Box>
        );
    }
    return (
        <Box className={classNames(styles.targetBar, className)}>
            <img
                src={backIcon}
                className={styles.backIcon}
                onClick={goBack}
            />
            <div
                className={classNames(styles.startOrStop, {
                    [styles.stop]: isPuzzlePlaying
                })}
                onClick={handleStartBtn}
                id="start-button"
            >
                <div className={styles.statusIcon} />
                {isPuzzlePlaying ? stopText : startText}
            </div>

            <button
                className={btnClassName}
                onClick={saveProject}
            >
                {saveText}
            </button>
            <button
                className={btnClassName}
                onClick={submitProject}
            >
                {submitText}
                <img
                    src={submitIcon}
                    className={styles.submitIcon}
                />
            </button>
        </Box>
    );
};

PythonLeftTargetBar.propTypes = {
    backIcon: PropTypes.string,
    className: PropTypes.string,
    goBack: PropTypes.func,
    pythonDiy: PropTypes.bool.isRequired,
    saveProject: PropTypes.func,
    saveText: PropTypes.string,
    submitIcon: PropTypes.string,
    submitProject: PropTypes.func,
    submitText: PropTypes.string,
    handleStartBtn: PropTypes.func,
    exitFullScreen: PropTypes.func,
    isFullScreen: PropTypes.bool,
    isPuzzlePlaying: PropTypes.bool,
    startText: PropTypes.string,
    stopText: PropTypes.string
};

export default PythonLeftTargetBar;
