const CACHE = Object.create(null);

/**
 * Generate block lighten color depend on it's primary color.
 * @param {string} hexColor Block hex color
 * @return {string} Lighten color.
 */
export default function generateBlockLightenColor(hexColor) {
    if (!CACHE[hexColor]) {
        hexColor = hexColor.replace(/^#/, '');

        if (hexColor.length === 3) {
            hexColor = hexColor
                .split('')
                .map(s => `${s}${s}`)
                .join('');
        }

        CACHE[hexColor] = `#${hexColor
            .match(/\w{2}/g)
            .map(str => parseInt(str, 16))
            .map(int => parseInt(((int ^ 255) * 0.9) + int, 10))
            .map(int => int.toString(16).padStart(2, '0'))
            .join('')}`;
    }

    return CACHE[hexColor];
}
