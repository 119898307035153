import Fetch from 'public-repo/lib/fetch';
import Config from '../config';

export default {
    testUnameExist(name) {
        return Fetch({
            url : Config.serverIo.uname_check,
            method : 'post',
            params: {
                username: name,
            }
        });
    },

    testMobileExist(mobile) {
        return Fetch({
            url : Config.serverIo.phone_check,
            method : 'post',
            params: {
                mobile: mobile,
            }
        });
    },

    register(data) {
        return Fetch({
            url : Config.serverIo.register,
            method : 'post',
            params : data,
        }).then((data) => data);
    },

    sendTelCode(type, mobile) {
        return Fetch({
            url : Config.serverIo.send_code,
            method : 'post',
            params : {
                type, mobile
            }
        });
    },

    login({username, passwd}) {
        return Fetch({
            url : Config.serverIo.login,
            method : 'post',
            params : {
                username,
                passwd
            }
        });
    },

    logout() {
        return Fetch({
            url : Config.serverIo.logout
        });
    },

    getUserInfo() {
        return Fetch({
            url : Config.serverIo.get_profile
        });
    },

    getDiyTime({actId, packageId, lessonId, classId}){
        return Fetch({
            url: Config .serverIo.getDiyTime,
            method: 'get',
            params: {
                activity_id: actId,
                package_id: packageId,
                lesson_id: lessonId,
                class_id: classId
            }
        });
    }
};
