import React from 'react';
import PropTypes from 'prop-types';
import LikeAnimationBtn from './likeAnimationBtn';
import {formatNumber} from 'PublicRepo/utility/formatNumber';
import GeneralConfig from 'PublicRepo/utility/generalConfig';
import UserData from 'PublicRepo/utility/userDataManager';

class ControlBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            likeNum: this.props.likeNum
        };
        this.updateLikeNum = this.updateLikeNum.bind(this);
    }

    componentDidMount() {
        UserData.emitter.on('update_like_num', this.updateLikeNum);
    }

    componentWillUnmount() {
        UserData.emitter.off('update_like_num', this.updateLikeNum);
    }

    updateLikeNum(value) {
        this.setState({
            likeNum: this.state.likeNum + value
        });
    }

    render() {
        const {
            isMobile,
            pageType,
            stopPlay,
            loading,
            isLiked,
            onLike,
            proId,
            fingerprint,
            mobileOrientation,
            onFullScreenClick,
            isFullScreen,
            trackEvent
        } = this.props;
        return (
            <div
                className={`handle${
                    mobileOrientation === 'Landscape' ? '-right' : ''
                } ${isMobile ? 'm' : 'pc'}-handle`}
            >
                <ul>
                    {!isMobile && (
                        <li className="fullscreen">
                            <a
                                className={`${
                                    isFullScreen ? 'btn-fullscreen' : ''
                                } btn-extend`}
                                href="#"
                                onClick={onFullScreenClick}
                            />
                        </li>
                    )}
                    {!isMobile && (
                        <li className="like-num">
                            {formatNumber(this.props.likeNum)}
                        </li>
                    )}
                    {(!isMobile ||
                        pageType ===
                            GeneralConfig.mobileSharePageTypes.experience) && (
                        <li className="like">
                            <LikeAnimationBtn
                                fingerprint={fingerprint}
                                isLiked={isLiked}
                                isMobile={isMobile}
                                loading={loading}
                                onLike={onLike}
                                proId={proId}
                                trackEvent={trackEvent}
                                updateLikeNum={this.updateLikeNum}
                            />
                        </li>
                    )}
                    <li>
                        <a
                            className="restart"
                            href="#"
                            onClick={stopPlay}
                        />
                    </li>
                </ul>
            </div>
        );
    }
}

ControlBar.propTypes = {
    fingerprint: PropTypes.string,
    isFullScreen: PropTypes.bool,
    isLiked: PropTypes.bool,
    isMobile: PropTypes.bool,
    likeNum: PropTypes.number,
    loading: PropTypes.bool,
    mobileOrientation: PropTypes.string,
    onFullScreenClick: PropTypes.func,
    onLike: PropTypes.func,
    pageType: PropTypes.string,
    proId: PropTypes.string,
    stopPlay: PropTypes.func,
    trackEvent: PropTypes.func
};

export default ControlBar;
