/* eslint-disable import/no-cycle */
import qa from './qaInject';
import fetch from '../lib/fetch';
import Config from './generalConfig';

const LOCALSTORAGE_KYES = {
  BELLPLANET_URL: 'BELLPLANET_URL',
};

function randomString(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  let result = '';
  for (let i = length; i > 0; --i) {
    const randomIndex = Math.round(Math.random() * (chars.length - 1));
    result += chars.charAt(randomIndex);
  }
  return result;
}

/**
 * 跳过关卡
 * @param {Int} activityId
 * @param {Int} classId
 * @param {Int} packageId
 */
const skipActivity = (activityId, classId, packageId) => fetch({
    url: Config.serverIo.skip_activity,
    newPost: true,
    method: 'post',
    params: {
      activity_id: activityId,
      class_id: classId,
      package_id: packageId,
    },
  });

const login = (userName, passWord) => fetch({
    url: Config.serverIo.login,
    newPost: true,
    method: 'post',
    params: {
      username: userName,
      passwd: passWord,
    },
  });

const joinClass = classId => fetch({
    url: Config.serverIo.testSupport.joinClass,
    newPost: true,
    method: 'post',
    params: {
      class_id: classId,
    },
  });

const register = (userName, passWord) => fetch({
    url: Config.serverIo.register,
    newPost: true,
    method: 'post',
    params: {
      username: userName,
      passwd: passWord,
      usertype: 1,
    },
  });

const setPassVideoTime = time => {
  if (!Number.isInteger(+time)) {
    alert('你确定输入的是整数吗？');
    return;
  }

  localStorage.setItem('PassVideoTime', time);
};

const registerAndJoinClass = classId => {
  const userName = `bell_test_${randomString(6)}`;
  const passWord = '123456';
  return register(userName, passWord).then(() => joinClass(classId));
};

const publishClassNextLesson = classId => fetch({
    url: Config.serverIo.testSupport.publishNextLesson,
    newPost: true,
    method: 'post',
    params: {
      class_id: classId,
    },
  });

const setBellplanetURL = url => {
  localStorage.setItem(
    LOCALSTORAGE_KYES.BELLPLANET_URL,
    encodeURIComponent(url),
  );
};

const getBellPlanetURL = () => localStorage.getItem(LOCALSTORAGE_KYES.BELLPLANET_URL);

export default function () {
  // 跳过关卡
  qa.loadCommand(
    'skipActivity',
    skipActivity,
    '跳过指定关卡',
    '关卡id,班级id,课程包id',
  );

  qa.loadCommand(
    'setBellplanetURL',
    setBellplanetURL,
    '设置贝尔星球url',
    'url',
  );

  qa.loadCommand('login', login, '登录账号', 'tiyan01, 123456');
  qa.loadCommand('register', register, '注册学生账号', 'tiyan01, 123456');
  qa.loadCommand('joinClass', joinClass, '加入班级', 'class_id');
  qa.loadCommand(
    'registerAndJoinClass',
    registerAndJoinClass,
    '注册并且加入班级',
    'class_id',
  );

  qa.loadCommand(
    'setPassVideoTime',
    setPassVideoTime,
    '设定增加video的观看累计时间',
    'time',
  );
  qa.loadCommand(
    'publishClassNextLesson',
    publishClassNextLesson,
    '自动开启该班级下一节课',
    '班级id',
  );
}

export { LOCALSTORAGE_KYES, getBellPlanetURL };
