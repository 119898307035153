const INIT_CODE_TARGETS = 'puzzle/code-target/INIT_CODE_TARGETS';
const SET_CURRENT_CODE_TARGET_SUCCESS =
    'puzzle/code-target/SET_CURRENT_CODE_TARGET_SUCCESS';
const SET_CURRENT_CODE_TARGET_FAIL =
    'puzzle/code-target/SET_CURRENT_CODE_TARGET_FAIL';

const targetStatus = {
    LOCKED: 0,
    UNLOCK: 1,
    SUCCESS: 2,
    FAIL: 3
};

const initialState = [];

const reducer = (state = initialState, {type, payload}) => {
    const targets = state.slice();
    switch (type) {
        case INIT_CODE_TARGETS:
            return payload.map((target, index) => ({
                ...target,
                // status: 0/1/2/3  0:locked, 1:unlock, 2: success, 3: fail
                status: index === 0 ? targetStatus.UNLOCK : targetStatus.LOCKED
            }));
        case SET_CURRENT_CODE_TARGET_FAIL:
            targets[payload].status = targetStatus.FAIL;
            return targets;
        case SET_CURRENT_CODE_TARGET_SUCCESS:
            targets[payload].status = targetStatus.SUCCESS;
            if (targets[payload + 1]) {
                targets[payload + 1].status = targetStatus.UNLOCK;
            }
            return targets;
        default:
            return state;
    }
};

const initCodeTargets = codeTargetArray => ({
    type: INIT_CODE_TARGETS,
    payload: codeTargetArray
});

const setCurrentCodeTargetSuccess = index => ({
    type: SET_CURRENT_CODE_TARGET_SUCCESS,
    payload: index
});

const setCurrentCodeTargetFail = index => ({
    type: SET_CURRENT_CODE_TARGET_FAIL,
    payload: index
});

export {
    reducer as default,
    initialState as codeTargetInitialState,
    initCodeTargets,
    setCurrentCodeTargetSuccess,
    setCurrentCodeTargetFail,
    targetStatus
};
