import UserData from 'PublicRepo/utility/userDataManager';
import Cookie from 'js-cookie';
import Config from 'PublicRepo/utility/generalConfig';

// default language is English,other option is "cn"
// todo cn should be zh-CN
let currentLanguage = 'cn';

if (UserData.sysInfo && UserData.sysInfo.lan) {
  currentLanguage = UserData.sysInfo.lan;
} else if (Cookie.get('lang')) {
  currentLanguage = Cookie.get('lang');
}

export default {
  langMaps: {},
  getCurrentLanguage() {
    return currentLanguage;
  },
  setLang(lang) {
    Cookie.set('lang', lang, { domain: Config.cookieDomain, expires: 7 });
    return (currentLanguage = lang);
  },
  setLangMaps(maps) {
    Object.keys(maps).forEach(lang => {
      if (this.langMaps[lang]) {
        return Object.assign(this.langMaps[lang], maps[lang]);
      }

      return (this.langMaps[lang] = maps[lang]);
    });
  },
  get(phrase, language = currentLanguage) {
    const lanDict = this.langMaps[language] || {};

    return lanDict[phrase] || phrase;
  },
};
