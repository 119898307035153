import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {initIntro} from 'PublicRepo/components/intro/index';
import fetchMethod from './../../models/lesson';
import '!style-loader!css-loader!intro.js/introjs.css';
import style from './diy-guide-tips.css';
import blockMethod from 'PublicRepo/utility/scratch-blocks';
import scratch from 'scratch-blocks';
import EleTip from 'PublicRepo/components/intro/eleTip';
import {renderBellRobotAnimation} from 'PublicRepo/components/intro/animations';
import '!style-loader!css-loader!PublicRepo/components/intro/eleTip.css';
import '!style-loader!css-loader!PublicRepo/components/intro/index.css';
import ClipWrap from 'Components/clip-wrap/clip-wrap';
import VideoModal from 'Components/modals/VideoModal';
import Draggable from 'react-draggable';
import tipsImage from 'Asset/img/L0-diy-tips.gif';
import lottie from 'lottie-web';
import robotSpeaking from 'PublicRepo/animations/robot-speaking/robotSpeaking.json';
import arrowSvg from 'Asset/img/arrow.svg';
import InlineSVG from 'react-inlinesvg';
import ReactTooltip from 'react-tooltip';
import step1Gif from 'Asset/img/L0-diy-step1.gif';
import step2Gif from 'Asset/img/L0-diy-step2.gif';
import step3Gif from 'Asset/img/L0-diy-step3.gif';
import VM from 'scratch-vm';

const ArrowTip = () => (
    <div className={style.arrowBox}>
        <InlineSVG
            className={style.arrowSvg}
            src={arrowSvg}
        />
    </div>
);

const bellRobotAnimationConfig = {
    renderer: 'svg',
    container: null,
    animationData: robotSpeaking,
    loop: true,
    autoplay: true,
    assetsPath: '/animation/robot-speaking/'
};
const playgroundAreaIntro = ({el}) => ({
    element: el,
    intro: `<div class="intro-box">舞台在这里</div>`,
    position: 'left'
});
const postAreaIntro = ({
    el,
    text = '点击这里提交你的作品，邀请爸爸妈妈一起在手机上玩吧～～',
    position = 'bottom'
}) => ({
    element: el,
    intro: `<div class="intro-box">${text}</div>`,
    position: position
});
const texts = [
    {
        toolItem: '点击这里我们可以找到“事件”类代码块',
        block: '把“当角色被点击”代码块拖到右边的代码编辑区',
        gif: <img
            src={step1Gif}
            className={style.stepGif}
        />
    },
    {
        toolItem: '点击这里我们可以找到“事件”类代码块',
        block:
            '“发送广播”代码块可以给舞台中的角色发送消息，把它拼接在“当角色被点击”代码块下面，点击“Play”按钮就可以发送广播消息。',
        gif: <img
            src={step2Gif}
            className={style.stepGif}
        />
    },
    {
        toolItem: '点击这里我们可以找到“外观”类代码块',
        block:
            '“隐藏”代码块，将它拼接在“发送广播代码块下面可以让按钮发送广播后在舞台消失。',
        gif: <img
            src={step3Gif}
            className={style.stepGif}
        />
    }
];

// {
//     "运动":"motion",
//     "外观":"looks",
//     "声音":"sound",
//     "事件":"events",
//     "控制":"control",
//     "侦测":"sensing",
//     "运算":"operators",
//     "变量":"variables",
//     "自制积木":"myBlocks"
// }
const toolList = ['events', 'events', 'looks'];

// L0 diy 代码块拼接
// 事件 - 开始点击 events - event_whenflagclicked
// 控制 - 重复执行 control - control_if
// 外观 - 下一个造型   looks - looks_nextcostume
// 运动 - 移动10步   motion - motion_movesteps
// 运动 - 碰到边缘反弹   motion - motion_ifonedgebounce
const blockList = [
    'event_whenthisspriteclicked',
    'event_broadcast',
    'looks_hide'
];

const $ = e => document.querySelector(e);

const CompeleteTips = ({handleClick, position}) => (
    <Draggable
        handle={`.${style.compeleteTips}`}
        bounds="parent"
    >
        <div
            className={style.compeleteTips}
            style={position}
        >
            <img
                className={style.tipsImage}
                src={tipsImage}
                alt=""
            />
            <p className={style.description}>
                {`跟着小贝一起检查三个代码块有没有拼接在一起吧，需要像动图这样拼接程序才能正常运行哦`}
            </p>
            <button
                className={style.tipsButton}
                onClick={handleClick}
            >
                我知道了
            </button>
            <div
                className={style.bellRobot}
                id={style.bellRobot}
            />
        </div>
    </Draggable>
);

class Diytips extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: null,
            blockTipShow: false,
            shouldShowCompeleteTips: false,
            shouldShowArrow: true
        };
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.blockTipsShow = this.blockTipsShow.bind(this);
        this.getTips = this.getTips.bind(this);
        this.getToolbox = this.getToolbox.bind(this);
        this.controlsSteps = this.controlsSteps.bind(this);
        this.nextToolTip = this.nextToolTip.bind(this);
        this.dragThenDelay = this.dragThenDelay.bind(this);
        this.clearDelay2sTimer = this.clearDelay2sTimer.bind(this);
        this.closeGifGuide = this.closeGifGuide.bind(this);
        this.startStageTips = this.startStageTips.bind(this);
    }
    componentDidMount() {
        const isOver = localStorage.getItem('pop-data');
        if (isOver) {
            return;
        }
        fetchMethod
            .getL0video()
            .then(data => {
                this.setState({
                    videoData: JSON.parse(data)
                });
            })
            .catch(e => console.error(e)); //eslint-disable-line
        fetchMethod.checkIfL0().then(data => {
            if (data.is_open_guide) {
                this.props.openL0Guide();
                this.setState({
                    shouldGuide: true
                });
            }
        });
    }
    componentWillReceiveProps(nextProps) {
        const {modalIsOpen} = this.state;
        if (modalIsOpen === null && nextProps.loadingFinished) {
            setTimeout(() => {
                this.setState({
                    modalIsOpen: true
                });
            }, 300);
        }
    }

    handleCloseModal() {
        this.setState(
            {
                modalIsOpen: false
            },
            this.startStageTips
        );
    }

    // 创建intro目标， 开始舞台区域引导
    startStageTips() {
        this.leftWorkspace = scratch.getMainWorkspace();
        this.intro = initIntro({
            steps: [
                // 引导第一个目标scratch舞台区域
                playgroundAreaIntro({el: $('#scratch-stage')}),
                // 引导最后一个目标提交按钮
                postAreaIntro({el: $('#btnSubmit')})
            ],
            hidePrev: true,
            hideNext: true,
            overlayOpacity: '0.9'
        });
        this.intro.start();

        // 显示小贝动画
        this.lottieInstance = renderBellRobotAnimation();
        $('.introjs-tooltip').className += ' diy-first-step';
        this.getTips($('.introjs-button:last-child'), () => {
            const index = this.intro._currentStep;
            this.intro.exit();
            this.intro._currentStep = index;
            this.lottieInstance && this.lottieInstance.destroy();
            this.nextToolTip();
        });
    }

    clearDelay2sTimer() {
        if (this.delay2sTimer) {
            clearTimeout(this.delay2sTimer);
        }
        if (this.afterPlayTimer) {
            clearTimeout(this.afterPlayTimer);
        }
    }

    getTips(target, cb) {
        const fn = () => {
            if (cb) {
                cb();
            } else {
                this.controlsSteps();
            }
            target.removeEventListener('click', fn);
            target.removeEventListener('touchend', fn);
        };

        target.addEventListener('click', fn);
        target.addEventListener('touchend', fn);
    }

    // 代码块提示
    blockTipsShow(type) {
        const scrollTarget = this.leftWorkspace.getFlyout().scrollTarget;
        if (scrollTarget === null) {
            this.setState({
                blockTarget: blockMethod.getFlyoutWorkspaceBlockByType(
                    this.leftWorkspace,
                    type
                ).svgGroup_,
                blockTipShow: true,
                elementData: blockMethod
                    .getFlyoutWorkspaceBlockByType(this.leftWorkspace, type)
                    .svgGroup_.getBoundingClientRect(),
                text: texts[this.currentBlock - 1].block,
                dragGif: texts[this.currentBlock - 1].gif || null,
                offset: 10,
                hiddenBlockTipShow: false
            });
            let blockId = null;
            const originCancel = scratch.Gesture.prototype.cancel;
            this.dragIsOk = true;
            const self = this;
            scratch.Gesture.prototype.cancel = function() {
                self.dragIsOk = false;
                originCancel.call(this);
            };
            this.borrowOnScrollFn = this.leftWorkspace.getFlyout().scrollbar_.onScroll_;
            this.leftWorkspace.getFlyout().scrollbar_.onScroll_ = () => {};
            const fn = e => {
                if (
                    e.type === 'create' &&
                    e.xml.getAttribute('type') === type
                ) {
                    blockId = e.blockId;
                    this.setState({
                        hiddenBlockTipShow: true
                    });
                }
                if (e.type === 'endDrag' && blockId === e.blockId) {
                    this.setState({
                        hiddenBlockTipShow: false
                    });
                    if (this.dragIsOk) {
                        blockId = null;
                        this.leftWorkspace.removeChangeListener(fn);
                        this.leftWorkspace.getFlyout().scrollbar_.onScroll_ = this.borrowOnScrollFn;
                        this.setState(
                            {
                                blockTipShow: false,
                                hiddenBlockTipShow: false
                            },
                            this.dragThenDelay
                        );
                    }

                    this.dragIsOk = true;
                }
            };
            this.leftWorkspace.addChangeListener(fn);
        } else {
            requestAnimationFrame(() => this.blockTipsShow(type));
        }
    }
    dragThenDelay() {
        if (this.currentBlock === blockList.length) {
            this.setState(
                {
                    shouldShowCompeleteTips: true,
                    position: {
                        left: `calc ( ${
                            $('#scratch-stage').getBoundingClientRect().width
                        } + 0.5rem`
                    }
                },
                () => {
                    const config = {
                        ...bellRobotAnimationConfig,
                        container: $(`#${style.bellRobot}`)
                    };
                    this.lottieInstance = lottie.loadAnimation(config);
                }
            );
            const greenFlag = $('#gui-controls__green-flag');
            greenFlag.setAttribute('data-tip', '');
            this.arrowTimer = setTimeout(() => {
                ReactTooltip.show(greenFlag);
            }, 3000);
            this.getTips(greenFlag, () => {
                this.arrowTimer && clearTimeout(this.arrowTimer);
                ReactTooltip.hide();
                this.closeGifGuide();
                this.setState({
                    shouldShowArrow: false
                });

                this.afterPlayTimer = setTimeout(() => {
                    this.props.vm.stopAll();
                    this.intro.goToStep(1).start();
                    renderBellRobotAnimation();
                    $('.introjs-tooltipbuttons').className += ` ${
                        style.hidden
                    }`;
                    $('.introjs-tooltipReferenceLayer').className = $(
                        '.introjs-tooltipReferenceLayer'
                    ).className.replace(style.hidden, '');
                }, 10000);
            });
        } else {
            this.nextToolTip();
        }
    }
    nextToolTip() {
        if (!this.currentTool) {
            this.currentTool = 0;
        }
        const targetTool = toolList[this.currentTool];
        if (!targetTool) {
            return;
        }
        if (targetTool === toolList[this.currentTool - 1]) {
            this.currentTool++;
            return this.controlsSteps();
        }
        const toolDom = this.getToolbox(targetTool).parentHtml_;
        this.setState(
            {
                blockTarget: toolDom,
                blockTipShow: true,
                elementData: toolDom.getBoundingClientRect(),
                text: texts[this.currentTool].toolItem,
                dragGif: null,
                offset: 2,
                hiddenBlockTipShow: false
            },
            () => {
                this.currentTool++;
                this.getTips(toolDom);
            }
        );
    }

    controlsSteps() {
        if (!this.currentBlock) {
            this.currentBlock = 0;
        }
        const current = blockList[this.currentBlock];
        if (current) {
            this.setState(
                {
                    blockTipShow: false,
                    hiddenBlockTipShow: false
                },
                () => {
                    this.currentBlock++;
                    blockMethod.scrollBlockInView(
                        this.leftWorkspace,
                        blockMethod.getFlyoutWorkspaceBlockByType(
                            this.leftWorkspace,
                            current
                        ),
                        true
                    );
                    this.blockTipsShow(current);
                }
            );
        }
    }

    getToolbox(id) {
        return this.leftWorkspace.toolbox_.categoryMenu_.categories_.find(
            el => el.id_ === id
        );
    }
    closeGifGuide() {
        this.setState(
            {shouldShowCompeleteTips: false},
            () => this.lottieInstance && this.lottieInstance.destroy()
        );
    }

    render() {
        const {
            modalIsOpen,
            text,
            blockTarget,
            blockTipShow,
            hiddenBlockTipShow,
            shouldGuide,
            videoData,
            offset,
            shouldShowCompeleteTips,
            shouldShowArrow,
            dragGif,
            position
        } = this.state;
        return (
            <React.Fragment>
                {videoData && shouldGuide && (
                    <VideoModal
                        in={!!modalIsOpen}
                        onClose={this.handleCloseModal}
                        src={videoData}
                        onEnded={this.handleCloseModal}
                    />
                )}
                {blockTipShow && (
                    <ClipWrap
                        hiddenBlockTipShow={hiddenBlockTipShow}
                        offset={offset}
                        targetDom={blockTarget}
                    >
                        {orient => (
                            <EleTip
                                elementData={{
                                    elementPos: blockTarget.getBoundingClientRect(),
                                    orient: orient,
                                    text: text,
                                    children: dragGif
                                }}
                            />
                        )}
                    </ClipWrap>
                )}
                {shouldShowCompeleteTips && (
                    <CompeleteTips
                        handleClick={this.closeGifGuide}
                        position={position}
                    />
                )}
                {shouldShowArrow && (
                    <ReactTooltip
                        className={style.arrowWrap}
                        place="left"
                    >
                        <ArrowTip />
                    </ReactTooltip>
                )}
            </React.Fragment>
        );
    }
}

Diytips.propTypes = {
    loadingFinished: PropTypes.bool,
    openL0Guide: PropTypes.func,
    vm: PropTypes.instanceOf(VM)
};
export default Diytips;
