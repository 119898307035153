import NODE_TYPES from './node-type';

const parseBlocksToTree = sourceBlocks => {
    const getBlockParams = blockId => {
        const block = sourceBlocks.getBlock(blockId);
        const params = [];

        Object.values(block.fields).forEach(field => {
            if (field.value) {
                params.push({
                    key: field.name,
                    value: {
                        type: NODE_TYPES.PARAM_VALUE_NUM_STRING,
                        text: field.value
                    }
                });
            }
        });

        Object.values(block.inputs).forEach(input => {
            if (!input.block && !input.shadow) return;

            // use shadow
            if (input.shadow === input.block) {
                const shadowParam = getBlockParams(input.shadow)[0];
                if (shadowParam) {
                    params.push({
                        key: input.name,
                        value: shadowParam.value
                    });
                }

                return;
            }

            // use block
            params.push({
                key: input.name,
                // eslint-disable-next-line no-use-before-define
                value: parseBlockToTree(input.block)
            });
        });

        return params;
    };

    const getBlockProcedureCallParams = blockId => {
        const originParams = getBlockParams(blockId);
        const block = sourceBlocks.getBlock(blockId);
        const argumentIds = JSON.parse(block.mutation.argumentids);

        const params = [
            {
                key: 'CODE',
                value: {
                    type: NODE_TYPES.PARAM_VALUE_NUM_STRING,
                    text: block.mutation.proccode
                }
            }
        ];

        argumentIds.forEach((argId, index) => {
            const {value} =
                originParams.find(param => param.key === argId) || {};

            if (value) {
                params.push({
                    key: `ARG${index}`,
                    value
                });
            }
        });

        return params;
    };

    const getBlockProcedureDefParams = blockId => {
        const protoBlock = sourceBlocks.getBlock(
            sourceBlocks.getBlock(blockId).inputs.custom_block.block
        );

        return [
            {
                key: 'CODE',
                value: {
                    type: NODE_TYPES.PARAM_VALUE_NUM_STRING,
                    text: protoBlock.mutation.proccode
                }
            }
        ];
    };

    const parseBlockToTree = (blockId, tree) => {
        tree = tree || {
            type: NODE_TYPES.PATTERN,
            chain: []
        };

        const block = sourceBlocks.getBlock(blockId);
        const treeBlock = {
            type: NODE_TYPES.BLOCK,
            opCode: block.opcode,
            id: block.id,
            params: {}
        };

        tree.chain.push(treeBlock);

        const params =
            block.opcode === 'procedures_definition'
                ? getBlockProcedureDefParams(blockId)
                : block.opcode === 'procedures_call'
                ? getBlockProcedureCallParams(blockId)
                : getBlockParams(blockId);

        params.forEach(({key, value}) => {
            treeBlock.params[key] = {
                type: NODE_TYPES.PARAM,
                key,
                value
            };
        });

        if (block.next) {
            parseBlockToTree(block.next, tree);
        }

        return tree;
    };

    return (
        sourceBlocks
            .getScripts()
            // Shadow block should not show in script, this might be a official bug.
            .filter(topBlockId => {
                const block = sourceBlocks.getBlock(topBlockId);

                return !block.shadow;
            })
            .map(topBlockId => parseBlockToTree(topBlockId))
    );
};

export default parseBlocksToTree;
