import ScratchBlocks from 'scratch-blocks';
import CodePath from '../code-path/code-path';
import parseBlocksToPath from '../code-path/parser/blocks-to-path';
import log from '../../../lib/log';

const codePath = new CodePath();
export const detectEmptyBlock = () => {
    const workspace = ScratchBlocks.getMainWorkspace();
    return (
        Object.keys(workspace.blockDB_).filter(
            key => !workspace.blockDB_[key].isShadow()
        ).length === 0
    );
};

export const resetToHistoryAnswer = (puzzleEngine, answer) => {
    puzzleEngine.stop();
    puzzleEngine.setWorkspaceBlocks(JSON.parse(answer), true);

    // Puzzle2Store.dispatch(toggleIsPlaying(false));
    // Puzzle2Store.dispatch(toggleWrapClass('active'));
    // Puzzle2Store.dispatch(keepFirstTip());
    // Puzzle2Store.dispatch(toggleShouldStopRunningTip(false));
};

export const checkCodeTargets = (codeTargetGroup, puzzleEngine) => {
    const userAnswerPath = [];
    const refAnswerPath = [];
    let checkResult = null;
    let errorIndex = -1;
    // const errorTip = '';
    codeTargetGroup.forEach(codeTarget => {
        refAnswerPath.push(codeTarget.code_path);
    });

    userAnswerPath.push(parseBlocksToPath(puzzleEngine.workspaceVMBlocks));

    for (let i = 0; i < refAnswerPath.length; i++) {
        checkResult = userAnswerPath.map(() =>
            codePath.diff(puzzleEngine.workspaceVMBlocks, refAnswerPath[i])
        );
        if (!checkResult.every(result => result.status === true)) {
            errorIndex = i;
            break;
        }
    }
    if (!refAnswerPath.length) log.warn('no reference answer path');

    return {
        errorIndex,
        checkResult: checkResult ? checkResult[0] : null,
        userAnswerPath
    };
};
