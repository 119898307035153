import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Renderer from 'scratch-render';
import debounce from 'lodash.debounce';
import ResizeObserver from 'resize-observer-polyfill';

import PuzzlePlaygroundComponent from '../components/puzzle-playground/puzzle-playground.jsx';
import {
    getStageDimensions,
    getStageDimensionsOnPython
} from '../lib/puzzle-utils';
import {toggleWorkspaceExpend} from '../reducers/puzzle/control';
import {setCustomStageDimensions} from '../../reducers/stage-size';

class PuzzlePlayground extends React.Component {
    constructor(props) {
        super(props);
        this.updateStageSize = this.updateStageSize.bind(this);
        this.debounceppdateStageSize = debounce(
            this.updateStageSize.bind(this),
            500
        );
        this.handleToggleWorkspaceExpend = this.handleToggleWorkspaceExpend.bind(
            this
        );
        this.state = {
            isRendererSupported: Renderer.isSupported()
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.debounceppdateStageSize);
        this.stageListen();
        // For blocks.
        // force workspace to redraw for the new stage size
        window.dispatchEvent(new Event('resize'));
    }

    componentWillUnmount() {
        this.stageRemoveListen();
        window.removeEventListener('resize', this.debounceppdateStageSize);
    }

    stageListen() {
        if (!this.stageObserve) {
            this.stageObserve = new ResizeObserver(() => {
                this.updateStageSize();
            });
            this.stageObserve.observe(
                document.querySelector('#stage-playground div:nth-child(2)')
            );
        }
    }

    stageRemoveListen() {
        if (this.stageObserve) {
            this.stageObserve.disconnect();
        }
    }

    updateStageSize() {
        const stageDimensions = (this.props.pythonMode
            ? getStageDimensionsOnPython
            : getStageDimensions)(this.props.isFullScreen);
        this.props.onSetCustomStageDimensions(stageDimensions);
    }

    handleToggleWorkspaceExpend() {
        if (this.props.isWorkspaceExpended) {
            this.props.onToggleWorkspaceExpend();
        } else {
            this.props.isPuzzlePlaying
                ? this.props.onStopPuzzle()
                : this.props.onToggleWorkspaceExpend();
        }
    }

    render() {
        return (
            <PuzzlePlaygroundComponent
                isRendererSupported={this.state.isRendererSupported}
                handleToggleWorkspaceExpend={this.handleToggleWorkspaceExpend}
                {...this.props}
            />
        );
    }
}

const {
    /* eslint-disable no-unused-vars */
    isRendererSupported,
    handleToggleWorkspaceExpend,
    /* eslint-disable no-unused-vars */
    ...playgroundPropTypes
} = PuzzlePlaygroundComponent.propTypes;

PuzzlePlayground.propTypes = playgroundPropTypes;

const mapStateToProps = state => ({
    alertsVisible: state.scratchGui.alerts.visible,
    isWorkspaceExpended: state.puzzle.control.isWorkspaceExpended,
    onToggleWorkspaceExpend: PropTypes.func.isRequired,
    vm: state.scratchGui.vm,
    isPuzzlePlaying: state.puzzle.control.isPuzzlePlaying,
    isFullScreen: state.scratchGui.mode.isFullScreen,
    isRtl: state.locales.isRtl
});

const mapDispatchToProps = dispatch => ({
    onToggleWorkspaceExpend: () => dispatch(toggleWorkspaceExpend()),
    onSetCustomStageDimensions: dimensions =>
        dispatch(setCustomStageDimensions(dimensions))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PuzzlePlayground);
