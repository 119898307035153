/* eslint-disable import/no-cycle */

/**
 * get data function
 * @param  {object} opts {method, url, headers, params}
 * @return {Promise}
 * makeRequest({
      method: 'GET',
      url: 'http://example.com'
    })
 .then(function (datums) {
      return makeRequest({
        method: 'POST',
        url: datums.url,
        params: {
          score: 9001
        },
        headers: {
          'X-Subliminal-Message': 'Upvote-this-answer'
        }
      });
    })
 .catch(function (err) {
      console.error('Augh, there was an error!', err.statusText);
    });
 */

import getEventEmitterSingleton from 'PublicRepo/utility/getEventEmitterSingleton';
import getType from 'PublicRepo/utility/getType';
import buildQueryString from 'PublicRepo/utility/buildQueryString';
import generalConfig from 'PublicRepo/utility/generalConfig';
import locationBuilder, {
  getHttpURL,
} from 'PublicRepo/utility/locationBuilder';
import tokenStorage from 'PublicRepo/utility/tokenStorage';

const fetch = window.fetch;

// 将 token 持久化为 session storage
(() => {
  const webviewToken = locationBuilder.searchQuery('webviewToken');
  if (window.webviewToken || webviewToken) {
    localStorage.setItem('webviewToken', window.webviewToken || webviewToken);
  }
})();

const _checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const globalEventEmitterSingleton = getEventEmitterSingleton();

// 监听注销信息,移除webviewToken值
globalEventEmitterSingleton.on('user_logout', () => {
  localStorage.removeItem('webviewToken');
});

function makeRequest(opts) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    opts.method = opts.method || 'get';

    if (opts.mockData && __LOCAL__) {
      const delay = opts.mockDelay || 300;
      setTimeout(() => {
        resolve(opts.mockData);
      }, delay);
      return;
    }

    // 如果要增加params参宿. 则在77行之前修改.
    let params = opts.params || {};

    const url = getHttpURL(opts.url, window.location.href);
    const requestURL = url.origin + url.pathname;
    // TODO 处理generalConfig api带有参数的情况
    url.searchParams.forEach((v, k) => {
      params[k] = v;
    });

    // TODO:: 处理优先级
    // 设置请求头token
    const localToken = tokenStorage.getSessionTokenSync();
    if (localToken) {
      params.token = localToken;
    }

    const webviewToken = localStorage.getItem('webviewToken');

    if (webviewToken) {
      params.token = webviewToken;
    }

    // We'll need to stringify if we've been given an object
    // If we have a string, this is skipped.
    if (!opts.newPost && params && typeof params === 'object') {
      const object_data = {};

      params = Object.keys(params)
        .map(key => {
          if (params[key] === undefined || params[key] === null) {
            return '';
          }
          // 兼容
          if (opts.true_array && typeof params[key] === 'object') {
            object_data[key] = params[key];
            return '';
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key],
          )}`;
        })
        .filter(e => e !== '')
        .join('&');
      // eslint-disable-next-line no-restricted-syntax
      for (const i in object_data) {
        if (object_data.hasOwnProperty(i)) {
          // eslint-disable-next-line no-restricted-syntax
          for (const j in object_data[i]) {
            if (object_data[i].hasOwnProperty(j)) {
              params += `&${i}[${j}]=${object_data[i][j]}`;
            }
          }
        }
      }
    }

    xhr.open(
      opts.method,
      opts.method === 'get' && params ? `${requestURL}?${params}` : url.href,
    );
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        //  resolve the ret
        let _data = xhr.response;

        _data = typeof _data === 'string' ? JSON.parse(_data) : _data;

        if (_data.code !== 200) {
          if (_data.code === 400 || _data.code === 401) {
            globalEventEmitterSingleton.emit('reset_userInfo');
            if (_data.code === 401) {
              globalEventEmitterSingleton.emit('logged_out');
              globalEventEmitterSingleton.emit('user_logout');
              window.location.href = `${generalConfig.HomeDomain}${
                generalConfig.loginPath
              }`;
            }
          }
          reject(_data);
        } else {
          resolve(_data.data);
        }
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };

    // set default headers
    // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.withCredentials = true;
    if (opts.withCredentials) {
      xhr.withCredentials = opts.withCredentials === '1';
    }
    if (opts.newPost) {
      xhr.setRequestHeader('Content-Type', 'application/json');
      params = JSON.stringify(params);
    } else if (opts.method === 'post' || opts.method === 'put') {
      xhr.setRequestHeader(
        'Content-Type',
        'application/x-www-form-urlencoded; charset=UTF-8',
      );
    }

    if (opts.headers) {
      Object.keys(opts.headers).forEach(key => {
        xhr.setRequestHeader(key, opts.headers[key]);
      });
    }

    // xhr.withCredentials = true;
    xhr.send(params);
  });
}

/**
 * @param { String } url
 * @param { String } [method]
 * @param { Object } [data]  Use for [ JSON ] format communication.
 * @param { Object | String } [params]   Use for [ FORM ] format communication.
 * @param { Object } [headers]
 * @param { Object } [config]
 * */
const baseFetch = ({
  url,
  method = 'GET',
  data,
  params,
  headers = {},
  disableHeader = false,
  ...config
}) => {
  method = method.toUpperCase();

  let body;

  switch (method) {
    case 'GET':
      // eslint-disable-next-line no-cond-assign
      if ((data = data || params)) {
        url
          += getType(data) === 'object'
            ? `?${buildQueryString(data)}`
            : `?${String(data)}`;
      }
      break;
    case 'POST':
    case 'PUT':
      if (data) {
        if (!disableHeader) {
          headers['Content-Type'] = headers['Content-Type'] || 'application/json; charset=UTF-8';
        }
        body = getType(data) === 'object' ? JSON.stringify(data) : data;
      } else if (params) {
        if (!disableHeader) {
          headers['Content-Type'] = headers['Content-Type']
            || 'application/x-www-form-urlencoded; charset=UTF-8';
        }
        body = getType(params) === 'object' ? buildQueryString(params) : params;
      }
      break;
    default:
      body = data || params || undefined;
  }

  config = Object.assign({}, config, { method, body, headers });

  return fetch(url, config).then(_checkStatus);
};

// 贝播专用
const beboBaseFetch = ({
  url,
  method = 'GET',
  data,
  params,
  headers = {},
  disableHeader = false,
  ...config
}) => baseFetch({
    url,
    method,
    data,
    params,
    headers,
    disableHeader,
    ...config,
  })
    .then(data => data.json())
    .then(res => {
      if (res.Result === false) {
        throw new Error(res.code);
      }
      return res;
    });

export { baseFetch, beboBaseFetch };

export default makeRequest;
