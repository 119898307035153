import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {LoadingState, LoadingStates} from '../../reducers/project-state';
import {
    showResearch,
    showSuccessDialog,
    hideSuccessDialog,
    setUserCodeBlocks
} from '../reducers/activity/info';
import VM from 'scratch-vm';
import ScratchBlocks from 'scratch-blocks';

import {getParams} from 'Lib/env-utils';
import Detect from 'Common/detect';
import Config from '../config';
import {
    getWorkspaceNotShadowBlocksNum,
    teacherComplete,
    reportPuzzle2StudentAnswerPath,
    getPythonCodeLineCount
} from '../lib/puzzle-utils';
import GeneralConfig from 'PublicRepo/utility/generalConfig';
import Puzzle from './puzzle/puzzle';
import {
    puzzleSuccess,
    puzzleFail,
    setPuzzleTimeout
} from '../reducers/puzzle/status';
import {
    togglePuzzlePlaying,
    toggleWorkspaceExpend
} from '../reducers/puzzle/control';
import {
    showFollowTip,
    hideFollowTip,
    setFollowTipPriority,
    priorityMap
} from '../reducers/puzzle/tip';
import {tipsCall} from '../components/puzzle-tips/puzzle2-tips';
import puzzleTips from './puzzle-detector/puzzle-tips';
import {
    initCodeTargets,
    setCurrentCodeTargetSuccess,
    setCurrentCodeTargetFail
} from '../reducers/puzzle/code-target';
import {
    initEventTargets,
    updateEventTargetsStatus
} from '../reducers/puzzle/event-target';
import {
    initErrorEventTargets,
    updateErrorEventTargetsStatus
} from '../reducers/puzzle/error-event-target';
import {
    detectEmptyBlock,
    checkCodeTargets,
    resetToHistoryAnswer
} from './puzzle-detector/code-path';
import {handleEventTargets} from './puzzle-detector/events';
import i18n from 'public-repo/i18n';
import {
    resetUserInteraction,
    registerScriptGlowOn,
    checkIfNeedInteraction,
    detectUserAction
} from './puzzle-detector/user-interaction';
import {getPuzzle2ActivityResult} from '../lib/activity';
import UserData from 'PublicRepo/utility/userDataManager';
import DialogTracker from 'public-repo/components/ActivityDialog/DialogTracker.js';
import IntercomHandler from 'PublicRepo/components/Intercom/intercomHandler';
import WebStorage from 'PublicRepo/lib/webStorage';
import config from '../config';

const PuzzleRuntimeHOC = function(WrappedComponent) {
    class PuzzleRuntime extends React.Component {
        constructor(props) {
            super(props);
            this.errorCounter = {
                codeTarget: 0,
                noInteraction: 0,
                errorEventTarget: 0,
                timeout: 0,
                noBlock: 0
            };
            this.puzzleStartTime = null;
            this.trackData = {};
        }
        componentDidMount() {
            this.props.vm.start();
            // connect to UI
            const {actId, lessonId, classId, packageId} = getParams();
            const puzzleKey = [
                this.props.account.info.id,
                this.props.puzzleInfo.id,
                actId || 0,
                lessonId || 0,
                classId || 0,
                packageId || 0
            ].join('_');
            const handleConfig = {
                resolve: this.handlePuzzleSuccess,
                reject: () => {
                    this.props.onPuzzleFail();
                },
                monitor: this.handleMonitorDetectResults,
                state: {
                    namespace: this.props.pythonMode
                        ? 'python-puzzle'
                        : 'GUI-Puzzle2',
                    key: puzzleKey
                }
            };
            // python diy 不需要监测机制
            if (this.props.pythonDiy) {
                handleConfig.resolve = () => {};
                handleConfig.monitor = () => {};
                handleConfig.monitor = () => {};
            }

            this.props.puzzleEngine.setConfig(handleConfig);
            this.resetPuzzle();
            WebStorage.pop(puzzleKey).then(data => {
                if (data) {
                    const storageData = JSON.parse(data);
                    this.trackData = {...this.trackData, ...storageData};
                }
            });

            this._puzzleCheckerTimer = setTimeout(
                this.checkIfPuzzleStartLoad,
                Config.TIMEOUT
            );
        }

        componentDidUpdate(preProps) {
            // puzzle load until project load successful
            if (
                preProps.loadingState !== this.props.loadingState &&
                this.props.loadingState === LoadingState.SHOWING_WITH_ID &&
                this.props.projectId === this.props.puzzleInfo.projectId
            ) {
                this.startLoadPuzzle();
                registerScriptGlowOn(this.props.vm, this.props.puzzleEngine);
                if (this.props.userAnswer && !this.props.pythonMode) {
                    this.showHistoryAnswer();
                }
            }
            if (
                this.props.editor &&
                this.props.pythonMode &&
                !this.isEditorInit
            ) {
                this.isEditorInit = true;
                if (this.props.userAnswer) {
                    this.showHistoryAnswer();
                }
            }
        }

        startLoadPuzzle = () => {
            this._puzzleCheckerTimer && clearTimeout(this._puzzleCheckerTimer);
            this._loadPuzzleAlreadyStarted = true;
            this.props.onload();
        };

        checkIfPuzzleStartLoad = () => {
            !this._loadPuzzleAlreadyStarted && this.startLoadPuzzle();
        };

        resetPuzzleContext(keepTargetStatus) {
            resetUserInteraction();
            this.props.setPuzzleTimeout(false);
            clearTimeout(this.codeTargetTimer);

            // pythonDiy 模式不注册这些
            if (!keepTargetStatus && !this.props.pythonDiy) {
                this.props.initCodeTargets(
                    this.props.puzzleInfo.codeTargetGroup
                );
                this.props.initEventTargets(
                    this.props.puzzleInfo.eventTargetGroup
                );
                this.props.initErrorEventTargets(
                    this.props.puzzleInfo.errorEventTargetGroup
                );
            }
        }

        handleStartPuzzle = () => {
            if (detectEmptyBlock(this.props.vm)) {
                if (this.props.pythonMode) {
                    return tipsCall({
                        type: 'empty',
                        text: '请先检查python代码'
                    });
                }
                tipsCall({
                    type: 'empty',
                    text: i18n.get(
                        "There's no code block yet, try to drag some code blocks."
                    )
                });
                return;
            }
            if (this.props.isWorkspaceExpended) {
                this.props.onToggleWorkspaceExpend();
            }
            this.props.onTogglePuzzlePlaying(true);

            tipsCall.dismiss();
            const result = checkCodeTargets(
                this.props.puzzleInfo.codeTargetGroup,
                this.props.puzzleEngine
            );
            this.handleCodeResult(result);

            // If check fail, run in previewMode.
            // It would not run detection.
            this.props.puzzleEngine.setPreviewMode(result.errorIndex !== -1);
            this.props.puzzleEngine.start();
        };

        handleStopPuzzle = keepTargetStatus => {
            if (!this.props.isWorkspaceExpended) {
                this.props.onToggleWorkspaceExpend();
            }
            this.props.onTogglePuzzlePlaying(false);

            this.props.hideFollowTip();
            this.resetPuzzleContext(keepTargetStatus);
            this.props.puzzleEngine.stop();
        };

        resetPuzzle = () => {
            this.handleStopPuzzle();
            this.puzzleStartTime = Date.now();
            this.props.puzzleEngine.reset();
        };

        handleResetPuzzle = cb => {
            this.resetPuzzle();
            if (this.props.userAnswer) {
                this.showHistoryAnswer();
            }
            if (cb && typeof cb === 'function') cb();
        };

        showHistoryAnswer = () => {
            const {
                puzzleEngine,
                userAnswer,
                pythonMode,
                editor,
                currentTargetIndex
            } = this.props;
            const puzzle2HistoryAnswer = () =>
                resetToHistoryAnswer(puzzleEngine, userAnswer.code_info);
            let anwser = userAnswer.code_info;
            if (
                (pythonMode &&
                    (userAnswer.code_info &&
                        userAnswer.code_info.includes('{"id":'))) ||
                this.anwser
            ) {
                puzzleEngine.setPythonCode(
                    editor.getValue(),
                    currentTargetIndex
                );

                anwser =
                    this.anwser ||
                    JSON.stringify(puzzleEngine.config.userPythonCode);
            }
            const pythonHistoryAnswer = () => {
                try {
                    anwser = JSON.parse(anwser);
                } catch (error) {
                    anwser = [anwser];
                }
                const cb = () => editor.setValue(anwser[currentTargetIndex]);
                puzzleEngine.setLastSubmitPythonBlocks(anwser, cb);
            };
            tipsCall({
                type: 'answer',
                text: '',
                btnCb: pythonMode ? pythonHistoryAnswer : puzzle2HistoryAnswer
            });
        };

        handleCodeResult = result => {
            const {errorIndex, checkResult, userAnswerPath} = result;
            if (errorIndex === -1) {
                this.isNeedToDetectUserInteraction = checkIfNeedInteraction(
                    userAnswerPath
                );
                this.generateCodeTargetEvent(errorIndex, 0);
            } else {
                puzzleTips(checkResult, this.props.pythonMode).then(tip => {
                    this.generateCodeTargetEvent(errorIndex, 0, tip);
                });
            }
        };

        errorEventTargetHandler = (tip, role) => {
            this.updateErrorCounter('errorEventTarget');
            this.showFollowTipWithPriority(priorityMap.MIDDLE, {
                text: tip,
                targetIndex: role,
                buttonText: i18n.get('gui-puzzle2_Try again'),
                onClickBTN: this.handleErrorRetry
            });
        };

        handleErrorRetry = () => {
            tipsCall.dismiss();
            this.props.hideFollowTip();
            this.resetPuzzleContext();
            this.props.puzzleEngine.stop();

            const result = checkCodeTargets(
                this.props.puzzleInfo.codeTargetGroup,
                this.props.puzzleEngine
            );
            this.handleCodeResult(result);

            // If check fail, run in previewMode.
            // It would not run detection.
            this.props.puzzleEngine.setPreviewMode(result.errorIndex !== -1);
            this.props.puzzleEngine.start();
        };

        generateCodeTargetEvent = (errorIndex, currentIndex, errorTip) => {
            let delay =
                currentIndex > 0
                    ? GeneralConfig.Puzzle2Config.TARGET_ANIMATION_DURATION
                    : 0;
            // python 模式会使用puzzle配置的失败延迟
            const {pythonMode, puzzleEngine} = this.props;
            if (pythonMode) {
                delay = puzzleEngine.config.rejectDelay * 1000;
            }
            this.codeTargetTimer = setTimeout(() => {
                if (
                    (errorIndex === -1 &&
                        currentIndex <
                            this.props.puzzleInfo.codeTargetGroup.length) ||
                    currentIndex < errorIndex
                ) {
                    this.props.setCurrentCodeTargetSuccess(currentIndex);
                } else if (currentIndex === errorIndex) {
                    this.props.setCurrentCodeTargetFail(currentIndex);
                    this.updateErrorCounter('codeTarget');
                    this.showFollowTipWithPriority(priorityMap.HIGH, {
                        text: errorTip,
                        targetIndex: this.props.puzzleInfo.codeTargetGroup[
                            currentIndex
                        ].talking_role,
                        buttonText: i18n.get('gui-puzzle2_ok'),
                        onClickBTN: this.handleStopPuzzle.bind(this, true)
                    });
                } else {
                    return;
                }
                currentIndex++;
                this.generateCodeTargetEvent(
                    errorIndex,
                    currentIndex,
                    errorTip
                );
            }, delay);
        };

        handleMonitorDetectResults = (results, detectContext) => {
            if (
                !this.props.isPuzzleRunTimeout &&
                detectContext.time > this.props.puzzleInfo.timeoutTipTime
            ) {
                this.props.setPuzzleTimeout(true);
                this.updateErrorCounter('timeout');
                const isShow = this.showFollowTipWithPriority(priorityMap.LOW, {
                    text: i18n.get(
                        'It spent a long time! Is there something wrong? Try to solve the puzzle again.'
                    ),
                    buttonText: i18n.get('gui-puzzle2_ok'),
                    targetIndex: this.props.puzzleInfo.timeOutTalkingRole,
                    onClickBTN: this.hideFollowTipWithTimer
                });
                if (isShow) {
                    this.timeoutTimer = setTimeout(
                        this.props.hideFollowTip,
                        20000
                    );
                }
            }
            const checkResult = handleEventTargets(
                results,
                this.props.puzzleInfo,
                this.errorEventTargetHandler
            );
            this.props.updateEventTargetsStatus(
                checkResult.eventTargetCheckResult
            );
            this.props.updateErrorEventTargetsStatus(
                checkResult.errorEventTargetCheckResult
            );

            if (
                this.isNeedToDetectUserInteraction &&
                results.some(step => !step.status)
            ) {
                detectUserAction(
                    this.props.vm,
                    this.handleUserHasAction,
                    this.handleUserNoAction
                );
            }
        };

        handleUserHasAction = () => {
            clearTimeout(this.noInteractionTipTimer);
            this.props.shouldShowFollowTip && this.props.hideFollowTip();
        };

        handleUserNoAction = tip => {
            this.updateErrorCounter('noInteraction');
            /**
             * python 按键提示 不显示
             */
            if (
                !this.props.pythonMode &&
                tip &&
                this.showFollowTipWithPriority(priorityMap.LOW, {
                    text: tip,
                    buttonText: i18n.get('gui-puzzle2_ok'),
                    targetIndex: this.props.puzzleInfo.notOperationTalkingRole,
                    onClickBTN: this.hideFollowTipWithTimer
                })
            ) {
                clearTimeout(this.noInteractionTipTimer);
                this.noInteractionTipTimer = setTimeout(
                    this.props.hideFollowTip,
                    20000
                );
            }
        };

        showFollowTipWithPriority = (priority, tipObj) => {
            if (
                this.props.shouldShowFollowTip &&
                this.props.currentTipPriority > priority
            ) {
                return false;
            }

            if (this.props.puzzleInfo.isRacing) {
                return false;
            }

            this.props.setFollowTipPriority(priority);
            /**
             * python scratch 提示全部替换
             */
            if (this.props.pythonMode) {
                tipObj.text = '创作目标没有完成,快去检查下代码吧';
            }

            this.props.showFollowTip(tipObj);
            return true;
        };

        hideFollowTipWithTimer = () => {
            this.clearTipTimer();
            this.props.hideFollowTip();
        };

        clearTipTimer = () => {
            clearTimeout(this.noInteractionTipTimer);
            clearTimeout(this.timeoutTimer);
        };

        updateErrorCounter = errorType => {
            if (!this.errorCounter[errorType]) this.errorCounter[errorType] = 0;
            this.errorCounter[errorType]++;
        };

        calculateResult = detectState => {
            const result = {
                errTimes: parseInt(detectState.errTimes, 10),
                costTime: Math.ceil((Date.now() - this.puzzleStartTime) / 1000),
                usedBlocksNum: getWorkspaceNotShadowBlocksNum(
                    ScratchBlocks.getMainWorkspace()
                )
            };

            return getPuzzle2ActivityResult({
                ...result,
                ...this.props.puzzleInfo,
                ...this.props.activity
            });
        };

        handlePuzzleSuccess = (detectState, results, detectContext) => {
            this.props.puzzleEngine.freeze();
            this.props.onPuzzleSuccess();

            const params = {
                pid: Detect.proId,
                duration:
                    (new Date().getTime() - this.props.timeSinceOpen) / 1000
            };
            Object.keys(this.errorCounter).forEach(event => {
                params[`${event}Count`] = this.errorCounter[event];
            });

            const promises = [];
            const activityResult = this.calculateResult(detectState);
            const blocksJSON = JSON.stringify(
                this.props.puzzleEngine.workspaceVMBlocks._blocks
            );
            this.props.setUserCodeBlocks(blocksJSON);
            /* python puzzle */
            let codeInfo = null;
            if (this.props.pythonMode) {
                this.props.puzzleEngine.setPythonCode(
                    this.props.editor.getModel().getValue(),
                    this.props.currentTargetIndex
                );
                codeInfo = JSON.stringify(
                    this.props.puzzleEngine.config.userPythonCode
                );
                this.anwser = codeInfo;
                activityResult.usedBlocksNum = getPythonCodeLineCount(
                    this.props.puzzleEngine.config.userPythonCode[0]
                );
            }
            /* python puzzle end */
            const currentUserType = parseInt(UserData.userInfo.usertype, 10);
            if (
                currentUserType ===
                    parseInt(GeneralConfig.userTypeMap.teacher, 10) ||
                currentUserType ===
                    parseInt(GeneralConfig.userTypeMap.organization, 10) ||
                currentUserType ===
                    parseInt(GeneralConfig.userTypeMap.instructor, 10)
            ) {
                promises.push(teacherComplete());
            } else {
                promises.push(
                    // python puzzle 传入用户代码
                    reportPuzzle2StudentAnswerPath(activityResult, {
                        blocksJSON: this.props.pythonMode
                            ? codeInfo
                            : blocksJSON
                    })
                );
            }

            Promise.all(promises).then(data => {
                const delayTime =
                    Math.max(
                        this.props.puzzleInfo.codeTargetGroup.length -
                            detectContext.time,
                        1
                    ) * 1000;

                if (Detect.from && Detect.from === Config.bellplanet) {
                    const puzzleData = {
                        score: data[0].score,
                        timeConsume: data[0].pop_up && data[0].pop_up.time
                    };
                    const info = {
                        key: config.postMsgId.puzzeSettle,
                        value: puzzleData
                    };
                    if (window.parent) {
                        window.parent.postMessage(info, '*');
                    } else {
                        window.postMessage(info, '*');
                    }
                }
                this.successDialogTimer = setTimeout(() => {
                    DialogTracker.trackFirstCheck(data[0], this.trackData);
                    this.props.showSuccessDialog(data[0]);
                    // const _r = Math.random();

                    // if (_r < 0.2) {
                    //     this.props.showResearchShow();
                    //     this.setState({
                    //         researchShow: true
                    //     });
                    // }
                }, delayTime);

                IntercomHandler.trackEventWithNotification(
                    'Puzzle2Success',
                    ['Puzzle2成功过关', 'pid', Detect.proId].join(' '),
                    {
                        puzzleId: Detect.proId
                    }
                );
                // });
            });
        };

        finishAndContinue = () => {
            const cb = () => {
                if (Detect.from && Detect.from === Config.bellplanet) {
                    const msgInfo = {
                        key: config.postMsgId.closePuzzle,
                        value: true
                    };
                    if (window.parent) {
                        window.parent.postMessage(msgInfo, '*');
                    } else {
                        window.postMessage(msgInfo, '*');
                    }
                    return;
                }
                location.href = Detect.cbUrl || Config.home;
            };
            if (this.props.pythonDiy) {
                cb();
            } else {
                DialogTracker.trackFirstNext(
                    this.props.dialogData,
                    this.trackData,
                    cb
                );
            }
        };

        handleRetry = cb => {
            if (!this.props.pythonDiy) {
                DialogTracker.trackFirstRestart(
                    this.props.dialogData,
                    this.trackData
                );
            }

            this.handleResetPuzzle(cb);
            this.props.hideSuccessDialog();
        };

        render() {
            return (
                <WrappedComponent
                    onStartPuzzle={this.handleStartPuzzle}
                    onStopPuzzle={this.handleStopPuzzle}
                    onResetPuzzle={this.handleResetPuzzle}
                    handleRetry={this.handleRetry}
                    finishAndContinue={this.finishAndContinue}
                    {...this.props}
                />
            );
        }
    }

    PuzzleRuntime.propTypes = {
        account: PropTypes.shape({
            info: PropTypes.shape({
                id: PropTypes.string
            })
        }),
        loadingState: PropTypes.oneOf(LoadingStates),
        onPuzzleFail: PropTypes.func,
        onPuzzleSuccess: PropTypes.func,
        puzzleEngine: PropTypes.instanceOf(Puzzle).isRequired,
        pythonMode: PropTypes.bool,
        vm: PropTypes.instanceOf(VM).isRequired
    };

    const mapStateToProps = state => ({
        account: state.scratchGui.account,
        puzzleEngine: state.puzzle.engine,
        vm: state.scratchGui.vm,
        puzzleInfo: state.puzzle.info,
        loadingState: state.scratchGui.projectState.loadingState,
        projectId: state.scratchGui.projectState.projectId,
        isPuzzleRunTimeout: state.puzzle.status.isPuzzleRunTimeout,
        timeSinceOpen: state.puzzle.status.timeSinceOpen,
        activity: state.puzzle.activity,
        shouldShowSuccessDialog: state.activity.info.shouldShowSuccessDialog,
        dialogData: state.activity.info.dialogData,
        userAnswer: state.activity.info.userAnswer,
        isWorkspaceExpended: state.puzzle.control.isWorkspaceExpended,
        shouldShowFollowTip: state.puzzle.tip.shouldShowTip,
        currentTipPriority: state.puzzle.tip.currentTipPriority,
        pythonMode: !!state.puzzle.python.pythonMode,
        editor: state.puzzle.python.editor,
        variables: state.puzzle.python.scratchVariables,
        currentTargetIndex: state.puzzle.control.currentTargetIndex
    });

    const mapDispatchToProps = dispatch => ({
        onPuzzleSuccess: () => dispatch(puzzleSuccess()),
        onPuzzleFail: () => dispatch(puzzleFail()),
        setPuzzleTimeout: isPuzzleTimeout =>
            dispatch(setPuzzleTimeout(isPuzzleTimeout)),
        showFollowTip: tip => dispatch(showFollowTip(tip)),
        setFollowTipPriority: priority =>
            dispatch(setFollowTipPriority(priority)),
        hideFollowTip: () => dispatch(hideFollowTip()),
        updateEventTargetsStatus: newResult =>
            dispatch(updateEventTargetsStatus(newResult)),
        updateErrorEventTargetsStatus: result =>
            dispatch(updateErrorEventTargetsStatus(result)),
        setCurrentCodeTargetSuccess: index =>
            dispatch(setCurrentCodeTargetSuccess(index)),
        setCurrentCodeTargetFail: index =>
            dispatch(setCurrentCodeTargetFail(index)),
        initCodeTargets: targets => dispatch(initCodeTargets(targets)),
        initEventTargets: targets => dispatch(initEventTargets(targets)),
        initErrorEventTargets: targets =>
            dispatch(initErrorEventTargets(targets)),
        onTogglePuzzlePlaying: isPlaying =>
            dispatch(togglePuzzlePlaying(isPlaying)),
        showResearch: () => dispatch(showResearch()),
        showSuccessDialog: dialogData =>
            dispatch(showSuccessDialog(dialogData)),
        hideSuccessDialog: () => dispatch(hideSuccessDialog()),
        onToggleWorkspaceExpend: () => dispatch(toggleWorkspaceExpend()),
        setUserCodeBlocks: blocksJSON => dispatch(setUserCodeBlocks(blocksJSON))
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(PuzzleRuntime);
};

export {PuzzleRuntimeHOC as default};
