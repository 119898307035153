import PATH_SYMBOLS from './path-symbol';

const parseBlocksToPath = (sourceBlocks, hasProcDef = false) => {
    const buildFieldValue = value => {
        const escapedValue = String(value).replace(
            new RegExp(
                `(${PATH_SYMBOLS.BLOCK_PARAM_CLOSING}|${
                    PATH_SYMBOLS.DOUBLE_QUOTES
                })`,
                'g'
            ),
            '\\$1'
        );

        return `"${escapedValue}"`;
    };

    const getBlockParams = blockId => {
        const block = sourceBlocks.getBlock(blockId);
        const params = [];

        Object.values(block.fields).forEach(field => {
            if (field.value) {
                params.push({
                    key: field.name,
                    value: buildFieldValue(field.value)
                });
            }
        });

        Object.values(block.inputs).forEach(input => {
            if (!input.block && !input.shadow) return;

            // use shadow
            if (input.shadow === input.block) {
                const shadowParam = getBlockParams(input.shadow)[0];
                if (shadowParam) {
                    params.push({
                        key: input.name,
                        value: shadowParam.value
                    });
                }

                return;
            }

            // use block
            params.push({
                key: input.name,
                // eslint-disable-next-line no-use-before-define
                value: parseBlockToPath(input.block)
            });
        });

        return params;
    };

    const getBlockProcedureCallParams = blockId => {
        const originParams = getBlockParams(blockId);
        const block = sourceBlocks.getBlock(blockId);
        const argumentIds = JSON.parse(block.mutation.argumentids);

        const params = [
            {
                key: 'CODE',
                value: buildFieldValue(block.mutation.proccode)
            }
        ];

        argumentIds.forEach((argId, index) => {
            const {value} =
                originParams.find(param => param.key === argId) || {};

            if (value) {
                params.push({
                    key: `ARG${index}`,
                    value
                });
            }
        });

        return params;
    };

    const getBlockProcedureDefParams = blockId => {
        const protoBlock = sourceBlocks.getBlock(
            sourceBlocks.getBlock(blockId).inputs.custom_block.block
        );

        return [
            {
                key: 'CODE',
                value: buildFieldValue(protoBlock.mutation.proccode)
            }
        ];
    };

    const parseBlockToPath = blockId => {
        const block = sourceBlocks.getBlock(blockId);
        const params =
            block.opcode === 'procedures_definition'
                ? getBlockProcedureDefParams(blockId)
                : block.opcode === 'procedures_call'
                ? getBlockProcedureCallParams(blockId)
                : getBlockParams(blockId);

        let path = block.opcode;
        params.forEach(({key, value}) => {
            const paramPath = `${key}${PATH_SYMBOLS.PARAM_ASSIGNMENT}${value}`;
            path += `${PATH_SYMBOLS.BLOCK_PARAM_OPENING}${paramPath}${
                PATH_SYMBOLS.BLOCK_PARAM_CLOSING
            }`;
        });

        if (block.next) {
            path += `${PATH_SYMBOLS.BLOCK_NEXT}${parseBlockToPath(block.next)}`;
        }

        return path;
    };

    return (
        sourceBlocks
            .getScripts()
            // Shadow block should not show in script, this might be a official bug.
            .filter(topBlockId => {
                const block = sourceBlocks.getBlock(topBlockId);

                return (
                    !block.shadow &&
                    (hasProcDef || block.opcode !== 'procedures_definition')
                );
            })
            .map(topBlockId => parseBlockToPath(topBlockId))
            .join(PATH_SYMBOLS.PATH_SEPARATOR)
    );
};

export default parseBlocksToPath;
