const INIT_ERROR_EVENT_TARGETS =
    'puzzle/error-event-target/INIT_ERROR_EVENT_TARGETS';
const UPDATE_ERROR_EVENT_TARGETS_STATUS =
    'puzzle/error-event-target/UPDATE_ERROR_EVENT_TARGETS_STATUS';

const initialState = [];

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case INIT_ERROR_EVENT_TARGETS:
            return payload.map(target => ({
                ...target,
                status: 2 // 2: success, 3: fail
            }));
        case UPDATE_ERROR_EVENT_TARGETS_STATUS:
            return state.slice().map((target, index) => ({
                ...target,
                status: payload[index] ? 2 : 3
            }));
        default:
            return state;
    }
};

const initErrorEventTargets = errorEventTargetArray => ({
    type: INIT_ERROR_EVENT_TARGETS,
    payload: errorEventTargetArray
});

const updateErrorEventTargetsStatus = errorEventTargetsCheckResult => ({
    type: UPDATE_ERROR_EVENT_TARGETS_STATUS,
    payload: errorEventTargetsCheckResult
});

export {
    reducer as default,
    initialState as errorEventTargetInitialState,
    initErrorEventTargets,
    updateErrorEventTargetsStatus
};
