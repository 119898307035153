import ScratchBlocks from 'scratch-blocks';
import initContext from './init-context';
import setBaseBlocks from './set-base-blocks';
import setSourceBlocks from './set-source-blocks';

import createVMBlocksOrphan from '../helpers/create-vm-blocks-orphan';

export default class PuzzleWorkspace {
    constructor(vm, workspace, config) {
        this.vm = vm;
        this.workspace = workspace;
        this.isContextInited = false;

        this.readOnly = config.readOnly || false;
        this.sourceBlocks = (config.blocks && config.blocks.source) || [];
        this.baseBlocks = (config.blocks && config.blocks.base) || [];
        this.proceduresDefinitionNames =
            (config.blocks && config.blocks.proceduresDefinitionNames) || [];
        this.initContext();
    }

    get isProceduresDefinitionUse() {
        return this.baseBlocks.some(
            element => element === 'procedures_definition'
        );
    }

    initContext() {
        if (!this.readOnly && !this.isContextInited) {
            initContext(
                this.vm,
                this.workspace,
                this.isContextInited,
                this.isProceduresDefinitionUse,
                this.proceduresDefinitionNames
            );
            if (this.baseBlocks.length) {
                setBaseBlocks(this.workspace, this.baseBlocks);
            }
            if (this.sourceBlocks.length) {
                // `setSourceBlocks` would modify flyout blocks,
                // so it should execute at the last.
                setSourceBlocks(this.workspace, this.sourceBlocks);
            }
            this.initCache = this.getBlocks();
        }
        if (!this.readOnly && this.isContextInited) {
            this.setBlocksWithDom(this.initCache);
        }
        // ReadOnly mode need init once.
        if (this.readOnly && !this.isContextInited) {
            initContext(
                this.vm,
                this.workspace,
                this.isProceduresDefinitionUse
            );
            this.workspace.getFlyout().hide();
            this.workspace
                .getAllBlocks()
                .forEach(blockSvg => blockSvg.setMovable(false));
            this.workspace.getTopBlocks().forEach(blockSvg => {
                blockSvg.setMovable(true);
                blockSvg.setDeletable(false);
            });
        }

        this.isContextInited = true;
    }

    reset() {
        this.workspace.setResizesEnabled(false);
        this.workspace.setToolboxRefreshEnabled(false);
        this.workspace.clear();
        this.initContext();
        this.workspace.setResizesEnabled(true);
        this.workspace.setToolboxRefreshEnabled(true);
    }

    setBlocks(blocks, clearWorkspace = false) {
        if (clearWorkspace) {
            this.workspace.clear();
        }

        const VMBlocksTemp = createVMBlocksOrphan(this.vm);
        const parsedBlocks = JSON.parse(JSON.stringify(blocks));
        Object.keys(blocks).forEach(blockId => {
            VMBlocksTemp.createBlock(parsedBlocks[blockId]);
        });

        // Render target blocks to workspace.
        // Do not use `vm.emitWorkspaceUpdate` here.
        // Because it would remove no-ref variables by target blocks unexpectedly.
        const xmlString = `<xml>${VMBlocksTemp.toXML()}</xml>`;
        ScratchBlocks.Xml.domToWorkspace(
            ScratchBlocks.Xml.textToDom(xmlString),
            this.workspace
        );

        this.initContext();
    }

    setBlocksWithDom(dom, clearWorkspace = false) {
        if (clearWorkspace) {
            this.workspace.clear();
        }

        ScratchBlocks.Xml.domToWorkspace(dom, this.workspace);
    }

    setBlocksWithXmlStr(xmlString, clearWorkspace = false) {
        if (clearWorkspace) {
            this.workspace.clear();
        }

        ScratchBlocks.Xml.domToWorkspace(
            ScratchBlocks.Xml.textToDom(xmlString),
            this.workspace
        );

        this.initContext();
    }

    getBlocks() {
        return ScratchBlocks.Xml.workspaceToDom(this.workspace);
    }
}
