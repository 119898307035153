const GRID_MIN_WIDTH = 240;
const GRID_MIN_HEIGHT = 280;

// TODO: 临时解决方案
export const layoutWorkspaceBlocksTem = workspace => {
    const metrics = workspace.getMetrics();
    let x = (metrics.contentWidth - (0.4 * metrics.viewWidth)) / 2;
    if (workspace.flyout_) {
        x -= workspace.flyout_.width_ / 2;
    }
    const y = (metrics.contentHeight - metrics.viewHeight) / 2;
    workspace.scrollbar.set(x, y);
};

const layoutWorkspaceBlocks = workspace => {
    const {contentWidth, contentHeight} = workspace.getMetrics();
    const horizontalFirst = contentWidth < contentHeight;
    let blockCount = 0;

    workspace.getTopBlocks().forEach(blockSvg => {
        blockCount++;

        const blockCountSqrt = Math.sqrt(blockCount);
        const roundCount = Math.ceil(blockCountSqrt);

        let x = GRID_MIN_WIDTH * (roundCount - 1);
        let y = GRID_MIN_HEIGHT * (roundCount - 1);

        if (blockCountSqrt !== roundCount) {
            const lastRoundCount = roundCount - 1;
            const lastRoundMaxCount = Math.pow(lastRoundCount, 2);
            const singleSideBlocksNum = roundCount;

            if (blockCount - lastRoundMaxCount < singleSideBlocksNum) {
                if (horizontalFirst) {
                    // layout on the bottom side
                    x = GRID_MIN_WIDTH * (blockCount - lastRoundMaxCount - 1);
                } else {
                    // layout on the right side
                    y = GRID_MIN_HEIGHT * (blockCount - lastRoundMaxCount - 1);
                }
            } else if (horizontalFirst) {
                // layout on the right side
                y =
                    GRID_MIN_HEIGHT *
                    (blockCount - (lastRoundMaxCount + singleSideBlocksNum));
            } else {
                // layout on the bottom side
                x =
                    GRID_MIN_WIDTH *
                    (blockCount - (lastRoundMaxCount + singleSideBlocksNum));
            }
        }

        const coordinate = blockSvg.getRelativeToSurfaceXY();
        blockSvg.moveBy(x - coordinate.x, y - coordinate.y);
    });
};

export default layoutWorkspaceBlocks;
