import React from 'react';
import PropTypes from 'prop-types';

import Modal, {POSITION} from 'PublicRepo/components/Modal/index';
// import withButton from 'PublicRepo/components/Modal/withButton';
import withAnimation from 'PublicRepo/components/Modal/withAnimation';
import '!style-loader!css-loader!PublicRepo/components/Modal/index.css';
import '!style-loader!css-loader!PublicRepo/components/Modal/withAnimation.css';
import Answer from 'PublicRepo/components/lesson/activity/Answer/index';
import '!style-loader!css-loader!PublicRepo/components/lesson/activity/Answer/index.css';

const ModalWithAnimationAndButton = withAnimation(Modal);

const AnswerModal = ({
    in: inProp,
    onClose,
    onPay,
    isAnswerVisible,
    answer,
    payCoinNum,
    isRacing,
    ...props
}) => (
    <ModalWithAnimationAndButton
        in={inProp}
        onClose={onClose}
        position={POSITION.CENTER}
        className="answer-modal"
    >
        <Answer
            {...props}
            isAnswerVisible={isAnswerVisible}
            isRacing={isRacing}
            payCoinNum={payCoinNum}
            onPay={onPay}
            onClose={onClose}
            answer={answer}
        />
    </ModalWithAnimationAndButton>
);

AnswerModal.propTypes = {
    answer: PropTypes.string.isRequired,
    in: PropTypes.bool.isRequired,
    isAnswerVisible: PropTypes.bool.isRequired,
    isRacing: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onPay: PropTypes.func.isRequired,
    payCoinNum: PropTypes.number.isRequired
};

export default AnswerModal;
