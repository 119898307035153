exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".python-target-area_target_2dvrh {\n}\n\n.python-target-area_richText_2rjFT {\n    -webkit-box-flex: 1;\n    -webkit-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n    overflow: hidden;\n}\n\n.python-target-area_hidden_3zffG {\n    visibility: hidden;\n}\n\n.python-target-area_header_3Ne-8 {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    height: 26px;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    font-size: 17px;\n    font-weight: normal;\n    font-stretch: normal;\n    letter-spacing: 0px;\n    color: #00aef6;\n    align-items: center;\n    margin-top: 20px;\n    margin-left: 18px;\n}\n\n.python-target-area_targetIcon_1auNq {\n    width: 14px;\n    display: block;\n    margin-right: 16px;\n    position: relative;\n    top: 2px;\n}\n\n.python-target-area_closeIcon_3epj2 {\n    width: 15px;\n    display: block;\n    margin-left: auto;\n    margin-right: 18px;\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"target": "python-target-area_target_2dvrh",
	"richText": "python-target-area_richText_2rjFT",
	"hidden": "python-target-area_hidden_3zffG",
	"header": "python-target-area_header_3Ne-8",
	"targetIcon": "python-target-area_targetIcon_1auNq",
	"closeIcon": "python-target-area_closeIcon_3epj2"
};