import ScratchBlocks from 'scratch-blocks';
import ERROR_TYPES from '../code-path/tree-diff/error-types';
import {
    getBlockHTMLById,
    getBlockHTMLByOpCode
} from '../block-helper/block-to-html';
import {BlockOptions} from '../block-helper/block-to-image';
import blocks, {parseProcedureBlock} from './blocks';
import {pythonOpcodeDic} from '../../components/python-editor/snippets';

/**
 * 区分gui puzzle和python puzzle 提示
 */
let isPythonPuzzle = false;
const judgeTip = (isCondition, opcode) =>
    (isCondition ? `或${opcode}出现问题,请检查` : '');
const dic = Object.keys(pythonOpcodeDic).reduce((pre, now) => {
    pre[pythonOpcodeDic[now].origin] = now;
    return pre;
}, {});
let getDataSetvariableto = false;
const getPythonStr = (opcode, procCode, error) => {
    const mapping = dic[opcode];
    let result = '';
    if (opcode === 'event_whenflagclicked') {
        result = '第一行';
    } else if (opcode && opcode.startsWith('procedures')) {
        result = `<span class='global-python-tips-span'>${procCode &&
            procCode.split(' ')[0]}()</span>`;
    } else if (opcode === 'data_setvariableto') {
        const {expect, __ERRORSOURCE__} = error.error;
        const {preBlock} = __ERRORSOURCE__;
        const {currentBlock, paramKey} = error.context;

        let text = paramKey
            ? currentBlock?.params[paramKey][0]?.text
            : currentBlock?.params?.VARIABLE[0]?.text;
        if (paramKey !== 'VALUE' && preBlock.opCode === 'data_setvariableto') {
            if (getDataSetvariableto) {
                getDataSetvariableto = false;
                text =
                    paramKey === 'VARIABLE'
                        ? expect.text
                        : __ERRORSOURCE__.currentBlock?.params?.VARIABLE?.value
                              ?.text;
            } else {
                text = preBlock?.params?.VARIABLE?.value?.text;
                getDataSetvariableto = true;
            }
        } else if (paramKey === 'VARIABLE') {
            text = expect.text;
        }
        result = `变量<span class='global-python-tips-span'>${text}</span>`;
    } else if (error.error.expect.opCode === 'data_variable') {
        result = `<span class='global-python-tips-span'>函数${
            dic[error.context.currentBlock.opCode]
        }</span>`;
        if (opcode && opcode === 'data_variable') {
            result = `
            <span class='global-python-tips-span'>${error.context.paramKey}</span>`;
        }
    } else {
        result = `<span class='global-python-tips-span'>${mapping}()</span>`;
    }

    return Promise.resolve(result);
};

const randomStr = tipsArr =>
    tipsArr[Math.floor(Math.random() * tipsArr.length)];

const SubStackParamRexExp = /^SUBSTACK\d?/;

const transformParamsText = text => {
    switch (text) {
        case 'right arrow':
            return '→';
        case 'left arrow':
            return '←';
        case 'down arrow':
            return '↓';
        case 'up arrow':
            return '↑';
        case 'space':
            return ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_SPACE;
        case 'any':
            return ScratchBlocks.Msg.EVENT_WHENKEYPRESSED_ANY;
        case '_mouse_':
            return ScratchBlocks.Msg.MOTION_POINTTOWARDS_POINTER;
        case '_random_':
            return ScratchBlocks.Msg.MOTION_GOTO_RANDOM;
        case '_edge_':
            return ScratchBlocks.Msg.SENSING_TOUCHINGOBJECT_EDGE;
        case '_myself_':
            return ScratchBlocks.Msg.CONTROL_CREATECLONEOF_MYSELF;
        case 'backdrop #':
            return ScratchBlocks.Msg.SENSING_OF_BACKDROPNUMBER;
        case 'backdrop name':
            return ScratchBlocks.Msg.SENSING_OF_BACKDROPNAME;
        case 'volume':
            return ScratchBlocks.Msg.SENSING_OF_VOLUME;
        case 'x position':
            return ScratchBlocks.Msg.SENSING_OF_XPOSITION;
        case 'y position':
            return ScratchBlocks.Msg.SENSING_OF_YPOSITION;
        case 'direction':
            return ScratchBlocks.Msg.SENSING_OF_DIRECTION;
        case 'costume #':
            return ScratchBlocks.Msg.SENSING_OF_COSTUMENUMBER;
        case 'costume name':
            return ScratchBlocks.Msg.SENSING_OF_COSTUMENAME;
        case 'size':
            return ScratchBlocks.Msg.SENSING_OF_SIZE;
        default:
            return text;
    }
};

const paramErrorTip = (html, {opCode, procCode}, param, text, result, img) => {
    const blockInfo = procCode ? parseProcedureBlock(procCode) : blocks[opCode];
    const isSubStackParam = SubStackParamRexExp.test(param);
    let paramStr = '';
    if (!isPythonPuzzle && blockInfo && !isSubStackParam) {
        const paramData = blockInfo[param];
        const subTipStrArr = ['', '你可以尝试', '试试'];
        if ((typeof text).toLowerCase() === 'string') {
            paramStr = `${subTipStrArr[Math.floor(Math.random() * 3)]}在${
                paramData.only ? '' : `第${paramData.index}个`
            }${paramData.type}中填入"${transformParamsText(text)}"`;
        } else {
            paramStr = `在${paramData.only ? '' : `第${paramData.index}个`}${
                paramData.type
            }中填入"${text.description}"`;
        }
    }
    if (
        isPythonPuzzle &&
        opCode === 'data_setvariableto' &&
        result.context.paramKey === 'VARIABLE'
    ) {
        if (
            result.error.expect.text !==
            result.context.currentBlock.params.VARIABLE[0].text
        ) {
            return `${img[0]} 下请使用 ${img[1]} 并设置正确的值`;
        }
    }
    const tipStrArr = [
        `仔细看看，${html}的内容填对了吗？${paramStr}`,
        `认真看一下，${html}的内容是不是填对了？${paramStr}`,
        `检查一下，${html}的内容正确吗？${paramStr}`
    ];
    return randomStr(tipStrArr);
};

const paramMissingTip = (html, {opCode, procCode}, param) => {
    const blockInfo = procCode ? parseProcedureBlock(procCode) : blocks[opCode];
    const isSubStackParam = SubStackParamRexExp.test(param);
    let paramStr = '';
    if (blockInfo && !isSubStackParam) {
        const paramData = blockInfo[param];
        paramStr = `的${paramData.only ? '' : `第${paramData.index}个`}${
            paramData.type
        }中`;
    }
    const tipStrArr = [
        `${html}${
            isPythonPuzzle ? '' : '代码块里面'
        }${paramStr}是不是少填了内容？`,
        `思考一下，${html}${
            isPythonPuzzle ? '' : '代码块里面'
        }${paramStr}是不是缺了东西？`,
        `想一想，${html}${
            isPythonPuzzle ? '' : '代码块里面'
        }${paramStr}缺了什么？`,
        `快想想，${html}${
            isPythonPuzzle ? '' : '代码块里面'
        }${paramStr}还需要什么内容`
    ];
    return randomStr(tipStrArr);
};

const blockErrorTip = (block1, block2) => {
    if (block1 === '第一行') {
        return `试一试，使用${block2}替换${block1}代码 `;
    }
    const tipStrArr = isPythonPuzzle
        ? [
              `${block1} 下面需要 ${block2} 代码`,
              `试试把 ${block2} 代码写在 ${block1} 代码下面`,
              `试一试，把 ${block2} 代码写在 ${block1} 代码下面`
          ]
        : [
              `${block1} 下面需要拼接 ${block2} 代码块`,
              `试试把 ${block2} 代码块拼在 ${block1} 代码块下面`,
              `试一试，把 ${block2} 代码块拼在 ${block1} 代码块下面`
          ];
    return randomStr(tipStrArr);
};

/**
 * 根据puzzle code path的检测结果生成对应的提示文案（error tip）
 * @param { string } [result] code path result
 * @param { boolean } [isPython] python scratch
 * @returns { string } error tip
 */
export default function(result, isPython = false) {
    let html;
    let getBlockHTMLByIdTem = (x, y) => getBlockHTMLById(x.id, y);
    let getBlockHTMLByOpCodeTem = getBlockHTMLByOpCode;
    if (isPython) {
        isPythonPuzzle = true;
        getBlockHTMLByIdTem = x => getPythonStr(x.opCode, x.procCode, result);
        getBlockHTMLByOpCodeTem = (x, y) => getPythonStr(x, y, result);
    }
    return new Promise(resolve => {
        switch (result.error.type) {
            case ERROR_TYPES.SCRIPT_MISSING:
                html = '好像需要更多代码块才能完成目标啊～';
                break;
            case ERROR_TYPES.UNKNOWN_NODE_TYPE:
                html = '嗯。。。好像哪里不对劲 > . <';
                break;
            case ERROR_TYPES.TARGET_PATH_ERROR:
                html = '报告老师！代码有bug！';
                break;
            case ERROR_TYPES.BLOCK_OPTIONAL_MISSING: {
                const {previousBlock, parent} = result.context;

                if (previousBlock) {
                    return resolve(
                        getBlockHTMLByIdTem(
                            previousBlock,
                            BlockOptions.KEEP_PARAMS
                        ).then(
                            img =>
                                `${img} 下面是不是少了什么代码${
                                    isPythonPuzzle ? '' : '块'
                                }${judgeTip()}？`
                        )
                    );
                } else if (parent) {
                    return resolve(
                        getBlockHTMLByIdTem(
                            parent.currentBlock,
                            BlockOptions.KEEP_PARAMS
                        ).then(
                            img =>
                                `${img} 里面是不是少了什么代码${
                                    isPythonPuzzle ? '' : '块'
                                }？`
                        )
                    );
                }
                return resolve(`是不是没有代码${isPythonPuzzle ? '' : '块'}？`);
            }
            case ERROR_TYPES.BLOCK_MISSING: {
                const {currentBlock, parent} = result.context;

                if (
                    currentBlock &&
                    currentBlock.id &&
                    currentBlock.type !== 'PARAM'
                ) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                currentBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByOpCodeTem(
                                result.error.expect.opCode,
                                result.error.expect.procCode
                            )
                        ]).then(
                            img =>
                                `${img[0]} 下面是不是少了 ${img[1]} 代码${
                                    isPythonPuzzle ? '' : '块'
                                }？`
                        )
                    );
                } else if (parent) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                parent.currentBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByOpCodeTem(
                                result.error.expect.opCode,
                                result.error.expect.procCode
                            )
                        ]).then(
                            img =>
                                `${img[0]} 里面是不是少了 ${img[1]} 代码${
                                    isPythonPuzzle ? '' : '块'
                                }？`
                        )
                    );
                }
                return resolve(
                    getBlockHTMLByOpCodeTem(
                        result.error.expect.opCode,
                        result.error.expect.procCode
                    ).then(
                        img =>
                            `是不是少了 ${img} 代码${
                                isPythonPuzzle ? '' : '块'
                            }？`
                    )
                );
            }
            case ERROR_TYPES.BLOCK_ERROR: {
                const {previousBlock, parent} = result.context;
                if (previousBlock) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                previousBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByOpCodeTem(
                                result.error.expect.opCode,
                                result.error.expect.procCode
                            )
                        ]).then(
                            img => blockErrorTip(img[0], img[1])
                            // `${img[0]} 下面需要 ${img[1]} 代码块`
                        )
                    );
                } else if (parent) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                parent.currentBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByOpCodeTem(
                                result.error.expect.opCode,
                                result.error.expect.procCode
                            )
                        ]).then(
                            img => blockErrorTip(img[0], img[1])
                            // `${img[0]} 里面需要 ${img[1]} 代码块`
                        )
                    );
                }
                return resolve(
                    getBlockHTMLByOpCodeTem(
                        result.error.expect.opCode,
                        result.error.expect.procCode
                    ).then(
                        img => `需要 ${img} 代码${isPythonPuzzle ? '' : '块'}`
                    )
                );
            }
            case ERROR_TYPES.BLOCK_PARAM_MISSING: {
                const {currentBlock, previousBlock, parent} = result.context;

                if (previousBlock) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                previousBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByIdTem(
                                currentBlock,
                                BlockOptions.KEEP_PARAMS
                            )
                        ]).then(
                            img =>
                                paramMissingTip(
                                    `${img[0]} 下面的 ${img[1]}`,
                                    currentBlock,
                                    result.error.expect.key
                                )
                            // `${img[0]} 下面的 ${
                            //     img[1]
                            // } 代码块是不是少填了内容？`
                        )
                    );
                } else if (parent) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                parent.currentBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByIdTem(
                                currentBlock,
                                BlockOptions.KEEP_PARAMS
                            )
                        ]).then(
                            img =>
                                paramMissingTip(
                                    `${img[0]} 里面 ${img[1]}`,
                                    currentBlock,
                                    result.error.expect.key
                                )
                            // `${img[0]} 里面 ${
                            //     img[1]
                            // } 代码块是不是少填了内容？`
                        )
                    );
                }
                return resolve(
                    getBlockHTMLByIdTem(
                        currentBlock,
                        BlockOptions.KEEP_PARAMS
                    ).then(
                        img =>
                            paramMissingTip(
                                `${img}`,
                                currentBlock,
                                result.error.expect.key
                            )
                        // `${img} 代码块是不是少填了内容？`
                    )
                );
            }
            case ERROR_TYPES.BLOCK_PARAM_VALUE_ERROR: {
                const {
                    currentBlock,
                    previousBlock,
                    paramKey,
                    parent
                } = result.context;

                if (previousBlock) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                previousBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByIdTem(
                                currentBlock,
                                BlockOptions.KEEP_PARAMS
                            )
                        ]).then(
                            img =>
                                paramErrorTip(
                                    `${img[0]} 下面的 ${img[1]} 代码${
                                        isPythonPuzzle ? '' : '块'
                                    }`,
                                    currentBlock,
                                    paramKey,
                                    result.error.expect.text,
                                    result,
                                    img
                                )
                            // `${img[0]} 下面的 ${
                            //     img[1]
                            // } 代码块内容填对了吗？试下 ${
                            //     result.error.source.value
                            // }？`
                        )
                    );
                } else if (parent) {
                    return resolve(
                        Promise.all([
                            getBlockHTMLByIdTem(
                                parent.currentBlock,
                                BlockOptions.KEEP_PARAMS
                            ),
                            getBlockHTMLByIdTem(
                                currentBlock,
                                BlockOptions.KEEP_PARAMS
                            )
                        ]).then(
                            img =>
                                paramErrorTip(
                                    `${img[0]} 里面的 ${img[1]} 代码${
                                        isPythonPuzzle ? '' : '块'
                                    }`,
                                    currentBlock,
                                    paramKey,
                                    result.error.expect.text,
                                    result,
                                    img
                                )
                            // `${img[0]} 里面的 ${
                            //     img[1]
                            // } 代码块内容填对了吗？试下 ${
                            //     result.error.source.value
                            // }？`;
                        )
                    );
                }
                return resolve(
                    getBlockHTMLByIdTem(
                        currentBlock,
                        BlockOptions.KEEP_PARAMS
                    ).then(
                        img =>
                            paramErrorTip(
                                `${img} 代码${isPythonPuzzle ? '' : '块'}`,
                                currentBlock,
                                paramKey,
                                result.error.expect.text,
                                result,
                                img
                            )
                        // `${img} 代码块内容填对了吗？试下 ${
                        //     result.error.source.value
                        // }？`
                    )
                );
            }
            default:
        }

        resolve(html);
    });
}
