export default {
  // 作品管理：我的主页相关
  'my_profile All Cards': 'All Cards',
  'my_profile Card List': 'Card List',
  'my_profile Project List': 'Project List',

  'my_profile My Published': 'My Published',
  "my_profile He's Published": "He's Published",
  'my_profile Her Published': 'Her Published',

  'my_profile My Collections': 'My Collections',
  "my_profile He's Collections": "He's Collections",
  'my_profile Her Collections': 'Her Collections',

  'my_profile My Stars': 'My Stars',
  "my_profile He's Stars": "He's Stars",
  'my_profile Her Stars': 'Her Stars',

  'my_profile Publish': 'Publish',
  'my_profile View': 'View',
  'my_profile Recreate': 'Recreate',
  'my_profile Likes': 'Likes',
  'my_profile Stars': 'Stars',

  'teacher-stu-project-Name': 'Name',
  'teacher-stu-project-Video': 'Video',
  'teacher-stu-project-Total': 'Total',
  'teacher-stu-project-records': 'records',

  // 作品管理
  '__Project-Management Free Creation': 'Free Creation',
  '__Project-Management Lesson Creation': 'Lesson Creation',
  '__Project-Management Lesson': 'Lesson',
  '__Project-Management go to select curriculums': 'go to select curriculums',
  '__Project-Management Status': 'Status',
  '__Project-Management Type': 'Type',
  '__Project-Management Publish': 'Publish',
  '__Project-Management Unpublish': 'Unpublish',
  '__Project-Management Edit': 'Edit',
  '__Project-Management Publish project': 'Publish project',
  '__Project-Management Publish to Plaza': 'Publish to Plaza',
  '__Project-Management All': 'All',
  '__Project-Management Published': 'Published',
  '__Project-Management Unpublished': 'Unpublished',
  '__Project-Management Create Project': 'Create Project',
  '__Project-Management My Projects': 'My Projects',
  '__Project-Management Creat Project': 'Creat Project',
  '__Project-Management Classes': 'Classes',
  '__Project-Management Post the interesting video introduction':
    'Post the interesting video introduction',
  '__Project-Management suspend for commenting': 'Pending review',
  '__Project-Management passed': 'Passed',
  '__Project-Management failed': 'Failed',
  '__Project-Management Homework': 'Homework',

  '__Project-Management Scratch': 'Scratch New',
  '__Project-Management Block coding': 'Scratch',
  '__Project-Management Junior': 'Scratch Junior',
  '__Project-Management Text coding': 'Text coding',
  'create-project_Scratch Editor': 'Scratch Editor',
  'create-project_JrScratch Editor': 'JrScratch Editor',
  'create-project_Text Coding': 'Text Coding',
  'create-project_PyDiy Coding': 'Python DIY Coding',

  'new editor': 'New Version Editor',
  'old editor': 'Older Editor',

  'new junior editor': 'New Version Junior Editor',
  'old junior editor': 'Older Junior Editor',

  'teacher-progress_Progress Management': 'Progress Management',
  'teacher-progress_Student ID / nickname / name':
    'Student ID / nickname / name',
  'teacher-progress_Unrelease': 'Unrelease',
  'teacher-progress_Unlearn': 'Unlearn',
  'teacher-progress_In Progress': 'In Progress',
  'teacher-progress_Finished': 'Finished',
  'teacher-progress_Open': 'Open',
  'teacher-progress_Take class': 'Take class',
  'teacher-progress_Planed': 'Planed',
  'teacher-progress_Generate report': 'Generate report',
  'teacher-progress_Generate ending report': 'Generate ending report',
  'teacher-progress_Generated': 'Generated',
  'teacher-progress_Informed': 'Informed',
  'teacher-progress_Review report': 'Review report',
  'teacher-progress_Review ending report': 'Review ending report',
  'student-online-classroom_Ready to go to class': 'Ready to class',
  'student-online-classroom_The instructor is waiting for class, click to enter class':
    'The instructor is waiting for class',
  'teacher-progress_Please enter the correct time':
    'Please enter the correct time',
  'teacher-progress_Unassigned room number': 'Unassigned room number',
  'teacher-progress_Are you sure you want to enter the classroom to start class?':
    'Are you sure to start class?',
  'teacher-progress_After opening, the following message will be sent to the student WeChat':
    'Following message will be sent to  student WeChat',
  "teacher-progress_Dear parents, name's course is about to begin, please be prepared.":
    "Dear parents, name's course is about to begin, please be prepared.",
  'teacher-progress_Course': 'Course',
  'teacher-progress_Time': 'Time',
  'teacher-progress_OK': 'OK',
  'teacher-progress_Cancel': 'Cancel',
  'teacher-progress_Cancel take class': 'Cancel',
  'teacher-progress_OK take class': 'OK',
  'teacher-progress_Take the kids and go to www.bellcode to learn':
    'Take the kids and go to www.bellcode to learn.',
  'teacher-progress_Please fill in the  time and click OK.':
    'Please fill in the  time and click OK',
  'teacher-classroom-tab_Lesson Plans': 'Lesson Plans',
  'teacher-lesson-plan_Prepare Lesson': 'Prepare Lesson',

  'msg_all message': 'All message',
  'msg_system message': 'System message',
  'msg_compose message': 'Compose message',
  'msg_Mark all as read': 'mark all as read',
  'msg_Clear all': 'clear all',
  'msg_Mark as read': 'mark as read',
  msg_read: 'read',
  msg_coin_receive: 'Receive',
  "msg_There's no message": "There's no message",

  'prev-page': 'Prev',
  'next-page': 'Next',

  'detect_In order to ensure your learning experience, please check your equipment first':
    'In order to ensure your learning experience, please check your equipment first',
  'detect_Please prepare your ': 'Please prepare your ',
  "detect_I'm ready, let's begin": "I'm ready, let's begin",
  detect_camera: 'camera',
  detect_microphone: 'microphone',
  detect_speaker: 'speaker',
  detect_skip: 'skip',

  'create class_first lesson open': 'Open',

  "__PlayGround You don't have this card,  can't set your avater.":
    "You don't have this card,  can't set your avater.",
  '__PlayGround Set AVATAR successfully': 'Set AVATAR successfully',

  // 新上课页
  '__CourseGallery about to begin': 'about to begin',
  '__CourseGallery begin learning': 'begin learning',
  '__CourseGallery continue learning': 'continue learning',
  '__CourseGallery do homework': 'do homework',
  '__CourseGallery review': 'review',
  '__CourseGallery level': 'level',
  '__CourseGallery community': 'community',
  '__CourseGallery create': 'create',
  '__CourseGallery project': 'project',
  '__CourseGallery you passed the equipment test!':
    'you passed the equipment test!',
  '__CourseGallery Click on the course card to go to class.':
    'Click on the course card to go to class.',
  '__CourseGallery Scan the qrcode for help': 'Scan the qrcode for help',
  '__CourseGallery return equipment detection': 'return equipment detection',
  "__CourseGallery There's something wrong.": "There's something wrong.",
  '__CourseGallery check again': 'check again',
  '__CourseGallery Ask parents to help consult teachers':
    'Ask parents to help consult teachers',
  "__CourseGallery You haven't finished testing the equipment":
    "You haven't finished testing the equipment",
  '__CourseGallery Immediately detect': 'Immediately detect',
};
