/* eslint-disable no-restricted-properties */
const {
 pow, floor, abs, log,
} = Math;
function round(n, precision) {
  const prec = pow(10, precision);
  return Math.round(n * prec) / prec;
}

export function formatNumber(n) {
  const base = floor(log(abs(n)) / log(1000));
  const suffix = 'kmb'[base - 1];
  return suffix ? round(n / pow(1000, base), 1) + suffix : `${n}`;
}
