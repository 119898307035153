import Config from 'PublicRepo/utility/generalConfig';
import Fetch from 'PublicRepo/lib/fetch';
import UserData from 'PublicRepo/utility/userDataManager';
import {
  hashIdSubject,
  classIdSubject,
  networkStatusObservable,
} from './index';
import LocationBuilder from '../locationBuilder';

let CLASS_ID = 0;

/**
 *
 * @param {string} classId 班级 id
 * @returns {string} conversationId 用于建立实时通信
 */
const studentClassInfo = async classId => Fetch({
    url: Config.serverIo.studentClassInfo,
    params: {
      id: classId,
    },
  }).then(({ conversation_id: conversationId }) => conversationId);

const autoSubscribe = async classId => {
  if (LocationBuilder.searchQuery('classroom_type') === 'afterclass') {
    return;
  }

  CLASS_ID = classId;
  const conversationId = await studentClassInfo(classId);
  if (conversationId) {
    // 有 conversationId 说明是双师课堂
    if (UserData.userInfo) {
      const hashId = UserData.userInfo.hash_id;
      hashIdSubject.next(hashId);
      classIdSubject.next(conversationId);
    } else {
      UserData.emitter.on('update_profile', () => {
        const hashId = UserData.userInfo.hash_id;
        hashIdSubject.next(hashId);
        classIdSubject.next(conversationId);
      });
    }
  }
};

// 当网络发生重连的时候，重新发送一遍状态消息, 确保业务正确性
networkStatusObservable.subscribe(connected => {
  if (connected) {
    autoSubscribe(CLASS_ID);
  }
});

export default autoSubscribe;
