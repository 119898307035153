var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".forbidden-shade_lock-modal_1LLL3 {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 9;\n  color: white;\n  background: rgba(0, 0, 0, 0.81);\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  font-size: 30px;\n  z-index: 9999;\n  pointer-events: auto;\n}\n\n.forbidden-shade_lock-modal_1LLL3 .forbidden-shade_paysage_26B7n {\n  background: url(" + escape(require("./student-lock.svg")) + ") center / contain no-repeat;\n  width: 800px;\n  height: 520px;\n}\n", ""]);

// exports
exports.locals = {
	"lock-modal": "forbidden-shade_lock-modal_1LLL3",
	"lockModal": "forbidden-shade_lock-modal_1LLL3",
	"paysage": "forbidden-shade_paysage_26B7n"
};