import React, {Component} from 'react';
import PythonComponent from '../components/python-puzzle/python-puzzle.jsx';
import PuzzleManagerHOC from '../lib/puzzle-manager-hoc.jsx';

import PuzzleRuntimeHOC from '../lib/puzzle-runtime-hoc.jsx';
import {connect} from 'react-redux';
import {
    enableRedo,
    enableUndo,
    disableRedo,
    disableUndo,
    setMonaco,
    setEditor
} from '../reducers/puzzle/python.js';
import {toggleAudioState, toggleHelpState} from '../reducers/puzzle/control.js';
import {skipPuzzle} from '../lib/puzzle-utils.jsx';
import {getParams} from '../../lib/env-utils.js';
import {payForAnswer} from '../reducers/activity/info.js';
import {
    manualUpdateProject,
    getIsShowingWithId,
    getIsUpdating
} from '../../reducers/project-state.js';
import {setFullScreen} from '../../reducers/mode.js';
class PythonPuzzle extends Component {
    handleToggleAudioState = () => {
        const {isAudioOpen, vm} = this.props;
        const currentVolume = vm.runtime.audioEngine.inputNode.gain.value;
        if (currentVolume !== 0) {
            this.volumeCache = currentVolume;
        }
        vm.runtime.audioEngine.inputNode.gain.value = isAudioOpen
            ? 0
            : this.volumeCache;
        this.props.onToggleAudioState();
    };
    render() {
        return (
            <PythonComponent
                {...this.props}
                handleToggleAudioState={this.handleToggleAudioState}
            />
        );
    }
}

const mapStateToProps = state => {
    let preCode = [];
    if (
        state.puzzlz &&
        state.puzzle.info.extra &&
        state.puzzle.info.extra.python_preset_code
    ) {
        const pythonPresetCode = state.puzzle.info.extra.python_preset_code;
        preCode = Array.isArray(pythonPresetCode)
            ? pythonPresetCode
            : [pythonPresetCode];
    }
    return {
        vm: state.scratchGui.vm,
        puzzleEngine: state.puzzle.engine,
        isPuzzleReady: state.puzzle.status.isPuzzleReady,
        redo: state.puzzle.python.redo,
        undo: state.puzzle.python.undo,
        info: state.puzzle.info,
        isPuzzlePlaying: state.puzzle.control.isPuzzlePlaying,
        pythonPool: state.puzzle.info.pythonPool,
        customBlocks:
            (state.puzzle.info.extra &&
                Array.isArray(state.puzzle.info.extra.custom_blocks) &&
                state.puzzle.info.extra.custom_blocks) ||
            [],
        preCode: preCode,
        hints: state.puzzle.info.hints || [],
        backgroundDescription: state.puzzle.info.backgroundDescription,
        answerImgUrl: state.activity.info.answerImgUrl,
        isAudioOpen: state.puzzle.control.isAudioOpen,
        isHelpOpen: state.puzzle.control.isHelpOpen,
        isAnswerPaid: state.activity.info.isAnswerPaid,
        hasCompletedBefore: state.activity.info.hasCompletedBefore,
        answerPrice: state.activity.info.answerPrice,
        isShowingWithId: getIsShowingWithId(
            state.scratchGui.projectState.loadingState
        ),
        isUpdating: getIsUpdating(state.scratchGui.projectState.loadingState),
        reduxProjectId: state.scratchGui.projectState.projectId,
        currentTargetIndex: state.puzzle.control.currentTargetIndex,
        isFullScreen: state.scratchGui.mode.isFullScreen
    };
};

const mapDispatchToProps = dispatch => ({
    exitFullScreen: () => dispatch(setFullScreen(false)),
    onSetStageFull: () => dispatch(setFullScreen(true)),
    enableRedo: () => dispatch(enableRedo()),
    enableUndo: () => dispatch(enableUndo()),
    disableRedo: () => dispatch(disableRedo()),
    disableUndo: () => dispatch(disableUndo()),
    setMonaco: e => dispatch(setMonaco(e)),
    setEditor: e => dispatch(setEditor(e)),
    onToggleAudioState: () => dispatch(toggleAudioState()),
    onToggleHelpState: () => dispatch(toggleHelpState()),
    payForAnswerAction: answer => dispatch(payForAnswer(answer)),
    skipPuzzle: () => {
        skipPuzzle().then(() => {
            location.href = getParams().cbUrl;
        });
    },
    manualUpdateProject: () => dispatch(manualUpdateProject())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PuzzleManagerHOC(PuzzleRuntimeHOC(PythonPuzzle)));
