import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import PuzzleSpriteList from '../components/puzzle-sprite-item/puzzle-sprite-item';
import {highlightTarget} from '../../reducers/targets';
import {removeActorHighlight} from '../reducers/activity/custom-guide';
import {switchEditingTargetIndex} from '../reducers/puzzle/control';
import {USERMODIFY} from '../../cm/lib/puzzle/runtime/modify-type';

const mapStateToProps = state => {
    const puzzleEngine = state.puzzle.engine;
    const sprites = puzzleEngine.vm
        ? puzzleEngine.vm.runtime.targets
              .filter(
                  target =>
                      !target.isStage &&
                      (!target.hasOwnProperty('isOriginal') ||
                          target.isOriginal)
              )
              .reduce(
                  (targets, target, listId) =>
                      Object.assign(targets, {
                          [target.id]: {order: listId, ...target.toJSON()}
                      }),
                  {}
              )
        : {};
    const hasConfig =
        puzzleEngine.config && puzzleEngine.config.editableTargets;
    return {
        actorHighlight: state.activity
            ? state.activity.customGuide.actorHighlight
            : -1,
        editingTarget: state.scratchGui.targets.editingTarget,
        hoveredTarget: state.scratchGui.hoveredTarget,
        items: hasConfig
            ? puzzleEngine.config.editableTargets.map(e => sprites[e.id])
            : [],
        raised: state.scratchGui.blockDrag,
        selectedId: state.scratchGui.targets.editingTarget,
        vm: state.scratchGui.vm,
        currentTargetIndex: state.puzzle.control.currentTargetIndex,
        puzzleEngine: state.puzzle.engine,
        editor: state.puzzle.python.editor
    };
};
const mapDispatchToProps = dispatch => ({
    onHighlightTarget: id => {
        dispatch(highlightTarget(id));
    },
    removeActorHighlight: () => dispatch(removeActorHighlight()),
    switchEditingTargetIndex: id => dispatch(switchEditingTargetIndex(id))
});

class PuzzleSpriteListContainer extends Component {
    static propTypes = {
        currentTargetIndex: PropTypes.number,
        isPuzzlePlaying: PropTypes.bool,
        onHighlightTarget: PropTypes.func,
        onStopPuzzle: PropTypes.func,
        puzzleEngine: PropTypes.object,
        removeActorHighlight: PropTypes.func,
        switchEditingTargetIndex: PropTypes.func
    };

    handleSelectSprite = (id, index) => {
        const {
            switchEditingTargetIndex: switchRole,
            onStopPuzzle,
            isPuzzlePlaying,
            editor,
            currentTargetIndex,
            puzzleEngine,
            onHighlightTarget
        } = this.props;
        if (index === currentTargetIndex) return;
        if (isPuzzlePlaying) {
            onStopPuzzle();
        }
        puzzleEngine.setBlockCache(USERMODIFY);
        setTimeout(() => puzzleEngine.switchTargetEditor(id), 0);

        switchRole(index);
        puzzleEngine.setPythonCode(
            editor.getModel().getValue(),
            currentTargetIndex
        );
        onHighlightTarget(id);
        this.props.removeActorHighlight();
    };
    render() {
        return (
            <PuzzleSpriteList
                {...this.props}
                handleSelectSprite={this.handleSelectSprite}
                activeId={this.props.currentTargetIndex}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PuzzleSpriteListContainer);
