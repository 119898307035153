export default {
    SCRIPT_MISSING: -2,
    UNKNOWN_NODE_TYPE: -1,
    TARGET_PATH_ERROR: 0,
    BLOCK_OPTIONAL_MISSING: 1,
    BLOCK_MISSING: 2,
    BLOCK_ERROR: 3,
    BLOCK_PARAM_MISSING: 4,
    BLOCK_PARAM_VALUE_ERROR: 5
};
