import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from 'public-repo/i18n';
import styles from './activity-reset-tips.css';

const ActivityResetTips = props => {
    const {toggleResetActivityTips, resetActivity} = props;
    return (
        <div className={styles.activityResetTips}>
            <h2 className={styles.title}>{i18n.get('attention')}</h2>
            <p className={styles.content}>
                {i18n.get('project couldn‘t use it again.')}
            </p>
            <div className={styles.btnGroup}>
                <button
                    className={classNames(styles.btn, styles.btnCancel)}
                    onClick={toggleResetActivityTips}
                >
                    {i18n.get('think about it')}
                </button>
                <button
                    className={classNames(styles.btn, styles.btnReset)}
                    onClick={resetActivity}
                >
                    {i18n.get('Reset')}
                </button>
            </div>
            <i
                className={styles.close}
                onClick={toggleResetActivityTips}
            />
        </div>
    );
};

ActivityResetTips.propTypes = {
    resetActivity: PropTypes.func,
    toggleResetActivityTips: PropTypes.func
};

export default ActivityResetTips;
