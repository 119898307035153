import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import HashParserHOC from '../../../lib/hash-parser-hoc.jsx';
import TitledHOC from '../../../lib/titled-hoc';
import GUI from '../../../containers/gui.jsx';

import '!style-loader!css-loader!./scratchPlayground.css';

const WrapperGui = HashParserHOC(TitledHOC(GUI));

class ScratchPlayground extends PureComponent {
    constructor(props) {
        super(props);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeydown);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeydown);
    }
    handleKeydown(e) {
        if (this.props.isStarted && [32, 37, 38, 39, 40].includes(e.keyCode)) {
            e.preventDefault();
        }
    }

    render() {
        return (
            <div className="scratch-playground">
                <WrapperGui />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isStarted: state.scratchGui.vmStatus.started
});

ScratchPlayground.propTypes = {
    isStarted: PropTypes.bool
};

export default connect(mapStateToProps)(ScratchPlayground);
