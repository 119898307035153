// `qaInject` 提供动态配置基础配置的功能。典型使用场景是依赖方在 qa 环境（测试验
// 收环境）下，动态构建依赖方的项目，并由测试者动态指定一些环境配置。
//
// 对于依赖方，典型使用场景如下：
//
//  // in src/config.js
//  if (__QA__) {  // 处于 QA 环境中（由 webpack 注入的变量）
//    // 启用 qa 注入功能
//    qaInject.setup();
//
//    // 获取配置
//    const qaConfig = qaInject.load(apiOrigin, baseDomain);
//
//    baseDomain = qaConfig.baseDomain;
//    apiOrigin = qaConfig.apiOrigin;
//  }
//
//  // 构建其他依赖组件
//  module.exports = {
//    serverIo: {
//      userInit: `${apiOrigin}/member/init`,
//      // ...
//    },
//  };
//
// 对于 qa 测试者，可以在构建好的项目中动态切换配置
//
//  > window.__bcInjectQAEnv('http://testapi.wkcoding.com/', 'wkcoding.com')
//  reload required
//  < undefined
//
// 刷新浏览器后对应配置就会生效。

// NOTE: 简单的防守式兼容
const LOCAL_STORAGE = window.localStorage || {};

const SETTINGS_KEY_API_ORIGIN = '__bc_api_origin';
const SETTINGS_KEY_BASE_DOMAIN = '__bc_base_domain';
const SETTINGS_KEY_VERSION = '__qa_version';
const SETTINGS_KEY_ORIGIN_PROFIX = '__origin__';

const COMMANDS = {};

let setuped = false;

/**
 * 运行qa用的命令
 */
const CMD = {
  run(command, ...args) {
    const defaultCommand = 'list';
    if (!COMMANDS[command]) {
      return COMMANDS[defaultCommand].handler(...args);
    }
    return COMMANDS[command].handler(...args);
  },
};

/**
 * 当前支持qa项目列表,参考bellbot env.js
 * @link https://gitlab.com/bellcode/bellbot/blob/master/src/env.js#L43
 * @type {
 *        {
 *          BC_IDE: string,
 *          WEB_SITE: string,
 *          LEARN_REPORT: string,
 *          CM_MANAGEMENT: string,
 *          TC_IDE: string,
 *          BELL_BOT: string
 *          }
 *       }
 */
const PROJECT = {
  BC_IDE: 'bc_ide',
  WEB_SITE: 'site',
  LEARN_REPORT: 'learning_report',
  CM_MANAGEMENT: 'cm_management',
  TC_IDE: 'tc_ide',
  BELL_BOT: 'bellbot',
  GUI: 'gui',
};

/**
 * 注入版本号
 * 当前url构建规则是{project_name}-{version}.fe.wkcoding.com
 * @param {string|null} version
 */
function injectVersion(version = '') {
  if (version) {
    LOCAL_STORAGE[SETTINGS_KEY_VERSION] = version;
    return LOCAL_STORAGE[SETTINGS_KEY_VERSION];
  }
  delete LOCAL_STORAGE[SETTINGS_KEY_VERSION];
  return null;
}

/**
 *
 * @param {string} apiOrigin 当前的 api origin 设定
 * @param {string} baseDomain 当前的 base domain 设定
 * @param {string} ideOrigin 当前的ideOrigin
 * @param {string} siteOrigin 当前的siteOrigin
 * @param {string} guiOrigin 当前的guiOrigin
 * @return {Object} resp
 * @return {string} resp.apiOrigin 加载到的 api origin 设定
 * @return {string} resp.baseDomain 加载到的 base domain 设定
 * @return {string} resp.ideOrigin
 * @return {string} resp.siteOrigin
 * @return {string} resp.guiOrigin
 * @returns {{apiOrigin: *, baseDomain: *, ideOrigin: *, siteOrigin: *, guiOrigin: *,}}
 */
function load(
  apiOrigin = '',
  baseDomain = '',
  ideOrigin = '',
  siteOrigin = '',
  guiOrigin = '',
) {
  const rv = {
    apiOrigin,
    baseDomain,
    ideOrigin,
    siteOrigin,
    guiOrigin,
  };

  if (LOCAL_STORAGE[SETTINGS_KEY_API_ORIGIN]) {
    rv.apiOrigin = LOCAL_STORAGE[SETTINGS_KEY_API_ORIGIN];
  }

  if (LOCAL_STORAGE[SETTINGS_KEY_BASE_DOMAIN]) {
    rv.baseDomain = LOCAL_STORAGE[SETTINGS_KEY_BASE_DOMAIN];
  }

  rv.ideOrigin = LOCAL_STORAGE[getOriginSettingKey(PROJECT.BC_IDE)] || ideOrigin;
  rv.siteOrigin = LOCAL_STORAGE[getOriginSettingKey(PROJECT.WEB_SITE)] || siteOrigin;
  rv.guiOrigin = LOCAL_STORAGE[getOriginSettingKey(PROJECT.GUI)] || guiOrigin;

  return rv;
}

/**
 * 注入环境配置设定
 * @param apiOrigin 请求api 域名 例如  //demoapi.wkcoding.com/
 * @param baseDomain 当前base 例如 wkcoding.com
 */
function inject(apiOrigin = '', baseDomain = '') {
  if (apiOrigin) {
    LOCAL_STORAGE[SETTINGS_KEY_API_ORIGIN] = apiOrigin;
  } else {
    delete LOCAL_STORAGE[SETTINGS_KEY_API_ORIGIN];
  }

  if (baseDomain) {
    LOCAL_STORAGE[SETTINGS_KEY_BASE_DOMAIN] = baseDomain;
  } else {
    delete LOCAL_STORAGE[SETTINGS_KEY_BASE_DOMAIN];
  }

  console.log('reload required');
}

function getOriginSettingKey(projectName) {
  return `${SETTINGS_KEY_ORIGIN_PROFIX}${projectName}`;
}

/**
 * 重置所有预存的变量值
 */
function reset() {
  inject();
  console.log('reload required');
}

/**
 * 加载qa命令
 * @param {String} name 指令名称
 * @param {Function} handler 指令行为
 * @param {String} description 指令描述
 * @param {String} example 调用示例
 * @return {Boolean}
 */
function loadCommand(name, handler, description, example) {
  if (!setuped) {
    return false;
  }

  if (COMMANDS[name]) {
    console.warn(`${name} 指令重复加载`);
    return false;
  }

  COMMANDS[name] = {
    handler,
    description,
    example,
  };
  CMD[name] = handler;

  return true;
}

/**
 * 载入默认指令
 */
function injectDefaultCommands() {
  const defaultCommands = {
    ping: {
      handler() {
        console.log('pong');
      },

      example: '',

      description: '检测faker API是否正常',
    },

    list: {
      /**
       * 获取指令列表
       */
      handler() {
        const genExample = function (name, example) {
          if (!example) {
            return `__testTool.${name}()`;
          }

          const params = example
            .split(',')
            .map(v => `"${v}" `)
            .join(',');

          return `__testTool.${name}(${params})`;
        };

        Object.keys(COMMANDS).forEach((command) => {
          console.log(
            command,
            COMMANDS[command].description,
            genExample(command, COMMANDS[command].example),
          );
        });
      },

      example: '',

      description: '获取所有可用指令',
    },

    inject: {
      /**
       * 注入环境配置设定
       * @param apiOrigin 请求api域名 例如  //demoapi.wkcoding.com/
       * @param baseDomain 当前base 例如 wkcoding.com
       */
      handler: inject,

      example: '//demoapi.wkcoding.com/,wkcoding.com',

      description: '注入环境配置设定',
    },

    /**
     * 注入项目链接
     * @param {string} projectName 项目名
     * @param {string} origin 域名链接,例如 http://site.wkcoding.com
     */
    injectOrigin: {
      handler(projectName = '', origin = '') {
        if (origin) {
          LOCAL_STORAGE[getOriginSettingKey(projectName)] = origin;
        } else {
          delete LOCAL_STORAGE[getOriginSettingKey(projectName)];
        }
      },

      example: 'site,http://site.wkcoding.com',

      description: '注入项目链接',
    },

    projects: {
      handler() {
        console.log(PROJECT);
      },

      description: '获取所有项目',
    },
  };

  Object.keys(defaultCommands).forEach((name) => {
    loadCommand(
      name,
      defaultCommands[name].handler,
      defaultCommands[name].description,
      defaultCommands[name].example,
    );
  });
}

/**
 * 注入到全局配置
 */
function setup() {
  if (setuped) {
    return;
  }
  setuped = true;
  window.__bcInjectQAEnv = inject;
  window.__bcResetQAEnv = reset;
  window.__bcInjectVersion = injectVersion;
  window.__qaProject = PROJECT;
  window.__testTool = CMD;

  injectDefaultCommands();

  console.log('qa mode enabled');
}

export default {
  inject,
  load,
  reset,
  setup,
  loadCommand,
  project: PROJECT,
};
