import React from 'react';
import lottie from 'lottie-web';
import './eleTip.css';
import robotSpeaking from '../../animations/robot-speaking/robotSpeaking.json';

function adjustPos(orient, position) {
  switch (orient) {
    case 'bottom-next-level':
      return {
        top: position.bottom + 10,
        left: position.left,
      };
    case 'bottom-deep':
      return {
        top: position.bottom + 40,
        left: position.left,
      };
    case 'left-center':
      return {
        top: position.bottom - (position.height / 2),
        left: position.left + position.width + 50,
        transform: 'translateY(-50%)',
      };
    case 'bottom-left':
    default:
      return {
        top: position.bottom + 10,
        left: position.left - 250,
      };
  }
}

class EleTip extends React.Component {
  constructor(props) {
    super(props);
    this.bellRobotAnimationConfig = {
      renderer: 'svg',
      container: null,
      animationData: robotSpeaking,
      loop: true,
      autoplay: true,
      assetsPath: '/animation/robot-speaking/',
    };
    this.tipAnimationConfig = {
      renderer: 'svg',
      container: null,
      animationData: null,
      loop: true,
      autoplay: true,
      assetsPath: null,
    };
  }

  componentDidMount() {
    if (!this.props.elementData.hideBellRobot) {
      this.bellRobotAnimationConfig.container = document.getElementById(
        'bell-robot',
      );
      lottie.loadAnimation(this.bellRobotAnimationConfig);
    }
    if (this.props.elementData.animationFile) {
      this.initAnimation(this.props.elementData);
    }
  }

  initAnimation(elementData) {
    this.tipAnimationConfig.container = document.getElementById(
      'tip-animation',
    );
    this.tipAnimationConfig.animationData = elementData.animationFile;
    this.tipAnimationConfig.assetsPath = elementData.assetsPath;
    lottie.loadAnimation(this.tipAnimationConfig);
  }

  render() {
    const {
      orient = 'bottom',
      elementPos = {},
      text = '',
      animationFile,
      animationClassName = '',
      children = null,
    } = this.props.elementData;
    return (
      <div
        className={`element-tip ${orient}`}
        style={adjustPos(orient, elementPos)}
      >
        <span className={`arrow arrow-${orient}`} />
        {animationFile && (
          <div id="tip-animation" className={animationClassName} />
        )}
        <div className="text">{text}</div>
        {children}
        <div id="bell-robot" className={`${orient}-robot`} />
      </div>
    );
  }
}

export default EleTip;
