import LevelFSM from './level-fsm';
import PathSymbol from '../parser/path-symbol';

const FSMState = {
    GROUP: 'GROUP',
    PATTERN: 'PATTERN',
    BLOCK: 'BLOCK',
    BLOCK_DISORDER: 'BLOCK_DISORDER',
    GROUP_DISORDER: 'GROUP_DISORDER',
    PARAM: 'PARAM',
    PARAM_KEY: 'PARAM_KEY',
    PARAM_VALUE: 'PARAM_VALUE',
    OLD_PARAM_VALUE_NUM_STRING: 'OLD_PARAM_VALUE_NUM_STRING',
    PARAM_VALUE_NUM_STRING: 'PARAM_VALUE_NUM_STRING'
};

const Config = {
    [FSMState.GROUP]: {
        [LevelFSM.INIT_STATE]: true,
        from: [FSMState.GROUP, FSMState.PATTERN, FSMState.PARAM_VALUE],
        start: PathSymbol.GROUP_OPENING,
        end: PathSymbol.GROUP_CLOSING
    },
    [FSMState.PATTERN]: {
        from: [FSMState.GROUP, FSMState.GROUP_DISORDER, FSMState.PARAM_VALUE],
        start: LevelFSM.START_MATCH_ALL,
        startDrop: false,
        endByParent: true,
        end: PathSymbol.ALTERNATION
    },
    [FSMState.BLOCK]: {
        from: [FSMState.PATTERN],
        start: [
            '>',
            {
                mark: LevelFSM.START_MATCH_ALL,
                drop: false
            }
        ],
        endByParent: true,
        end: [
            PathSymbol.BLOCK_NEXT,
            {
                mark: PathSymbol.BLOCK_DISORDER,
                drop: false
            }
        ]
    },
    [FSMState.BLOCK_DISORDER]: {
        from: [FSMState.PATTERN],
        start: PathSymbol.BLOCK_DISORDER,
        endByParent: true,
        end: [
            PathSymbol.BLOCK_NEXT,
            {
                mark: PathSymbol.BLOCK_DISORDER,
                drop: false
            }
        ]
    },
    [FSMState.GROUP_DISORDER]: {
        from: [FSMState.PATTERN],
        start: `${PathSymbol.BLOCK_DISORDER}${PathSymbol.GROUP_OPENING}`,
        end: PathSymbol.GROUP_CLOSING
    },
    [FSMState.PARAM]: {
        from: [FSMState.BLOCK, FSMState.BLOCK_DISORDER],
        start: PathSymbol.BLOCK_PARAM_OPENING,
        end: PathSymbol.BLOCK_PARAM_CLOSING
    },
    [FSMState.PARAM_KEY]: {
        from: [FSMState.PARAM],
        start: LevelFSM.START_MATCH_ALL,
        startDrop: false
    },
    [FSMState.PARAM_VALUE]: {
        from: [FSMState.PARAM_KEY],
        start: PathSymbol.PARAM_ASSIGNMENT
    },
    [FSMState.PARAM_VALUE_NUM_STRING]: {
        from: [FSMState.PARAM_VALUE],
        start: PathSymbol.DOUBLE_QUOTES,
        end: PathSymbol.DOUBLE_QUOTES
    },
    [FSMState.OLD_PARAM_VALUE_NUM_STRING]: {
        from: [FSMState.PARAM_KEY],
        start: PathSymbol.OLD_PARAM_VALUE_NUM_STRING_ASSIGNMENT
    }
};

export {Config as default, FSMState};
