exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".account-coin_account-coin_2_W7- {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    width: 11rem;\n    height: 2.2rem;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    background-color: #3383ff;\n    border: 1px solid #2c7cf8;\n    border-radius: 1rem;\n    /* In case doesn't support evenly */\n    -webkit-justify-content: space-around;\n        -ms-flex-pack: distribute;\n            justify-content: space-around;\n    -webkit-box-pack: space-evenly;\n    -webkit-justify-content: space-evenly;\n        -ms-flex-pack: space-evenly;\n            justify-content: space-evenly;\n}\n\n.account-coin_gold-block_1P6_l,\n.account-coin_code-block_XDdU9 {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-justify-content: space-around;\n        -ms-flex-pack: distribute;\n            justify-content: space-around;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n\n.account-coin_gold-block_1P6_l p,\n.account-coin_code-block_XDdU9 p {\n    margin-left: 4px;\n    font-size: 12px;\n}\n\n.account-coin_coin-img_1HOst {\n    width: 1.5rem;\n    height: 1.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"account-coin": "account-coin_account-coin_2_W7-",
	"accountCoin": "account-coin_account-coin_2_W7-",
	"gold-block": "account-coin_gold-block_1P6_l",
	"goldBlock": "account-coin_gold-block_1P6_l",
	"code-block": "account-coin_code-block_XDdU9",
	"codeBlock": "account-coin_code-block_XDdU9",
	"coin-img": "account-coin_coin-img_1HOst",
	"coinImg": "account-coin_coin-img_1HOst"
};