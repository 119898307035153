import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';

const CoinNotEnough = props => (
  <div className="answer answer-fail">
    <span className="answer-bg" />
    <p className="answer-txt">
      {i18n.get(
        'puzzle-answer_Your gold coins is not enough, maybe you need to solve it by yourself',
      )}
    </p>
    <div>
      <span
        role="button"
        tabIndex="0"
        className="answer-btn answer-btn--better"
        onClick={props.onClose}
      >
        {i18n.get("puzzle-answer_OK, I'll try it again")}
      </span>
    </div>
  </div>
);

CoinNotEnough.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CoinNotEnough;
