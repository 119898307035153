exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".python-scratch-tips_hidden_aIKxK {\n    visibility: hidden;\n}\n\n.python-scratch-tips_tipsBox_BMRtX {\n    background: #eff4fb;\n    border: 4px solid #00aef6;\n    border-radius: 16px;\n    border-radius: 16px;\n    position: relative;\n    min-width: 480px;\n    min-height: 260px;\n    padding: 0 68px;\n}\n\n.python-scratch-tips_richText_YQlFG {\n    margin-top: 60px;\n}\n\n.python-scratch-tips_robot_2-XGO {\n    width: 60px;\n    height: 70px;\n    top: -39px;\n    left: -11px;\n    position: absolute;\n}\n\n.python-scratch-tips_closeIcon_2XHhl,\n.python-scratch-tips_btn_MRlSy {\n    cursor: pointer;\n}\n\n.python-scratch-tips_closeIcon_2XHhl {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    width: 30px;\n    height: 30px;\n}\n\n.python-scratch-tips_btn_MRlSy {\n    background: #00aef6;\n    border-radius: 20px;\n    font-size: 16px;\n    color: #ffffff;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    width: 200px;\n    height: 40px;\n    margin: 0 auto;\n    margin-top: 60px;\n    margin-bottom: 35px;\n    outline: none;\n    border: none;\n}\n", ""]);

// exports
exports.locals = {
	"hidden": "python-scratch-tips_hidden_aIKxK",
	"tipsBox": "python-scratch-tips_tipsBox_BMRtX",
	"richText": "python-scratch-tips_richText_YQlFG",
	"robot": "python-scratch-tips_robot_2-XGO",
	"closeIcon": "python-scratch-tips_closeIcon_2XHhl",
	"btn": "python-scratch-tips_btn_MRlSy"
};