import React, { Component } from 'react';
// import UserData from "PublicRepo/utility/userDataManager";
import VideoWrapper from 'PublicRepo/components/Video/videoWrapper';
import Config from 'PublicRepo/utility/generalConfig';
import UserData from 'PublicRepo/utility/userDataManager';
import LocationBuilder from 'PublicRepo/utility/locationBuilder';
import { stateUpdateMessageObservable } from '../index';
import { StateMessageOperation } from '../init';

class VideoCloud extends Component {
  state = {
    openQuizInfo: {},
    isOpenQuiz: false,
  };

  componentDidMount() {
    const from = LocationBuilder.searchQuery('from');
    // 仅线上云课堂不监听
    if (
      from !== 'cloud'
      || (from === 'cloud' && !Config.isNestedInElectron && !window.wkbridge)
    ) {
      stateUpdateMessageObservable.subscribe(msg => {
        // leanCloud 通知，若有需要打开的quiz，直接打开
        const openQuizInfo = msg[StateMessageOperation.OPEN_QUIZ];
        if (
          openQuizInfo
          && openQuizInfo.openQuizById
          && openQuizInfo.usersKey.includes(UserData.userInfo.hash_id)
        ) {
          this.setState({
            openQuizInfo: msg[StateMessageOperation.OPEN_QUIZ],
            isOpenQuiz: true,
          });
        } else {
          this.setState({
            isOpenQuiz: false,
          });
        }
      });
    }
  }

  render() {
    const { isOpenQuiz } = this.state;
    return (
      <div>
        {isOpenQuiz && (
          <VideoWrapper
            {...this.state.openQuizInfo}
            autoplay={this.props.autoplay}
            closePopup={() => this.setState({ isOpenQuiz: false })}
            controlSide={Config.QuizControlSide.STUDENT}
          />
        )}
      </div>
    );
  }
}

export default VideoCloud;
