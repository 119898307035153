import Fetch from 'PublicRepo/lib/fetch';
import Config from 'Config';
import GeneralConfig from 'PublicRepo/utility/generalConfig';
import Detect from 'Common/detect';
import {parseQueryString, buildQueryString} from 'PublicRepo/lib/utilities';

export default {
    //此接口自由创作专用，应该过滤掉课程标签
    getProjectCategoryInPublishPageWithoutDispose() {
        const CourseTagId = 5;
        return Fetch({
            url: Config.serverIo.getProjectTags,
            method: 'get',
        }).then( tags=> tags.filter(item=>item.id != CourseTagId ));
    },

    getUserRewardAvailable(id){
        // 返回字段
        // {
        //     count : int 今日剩余奖励次数
        //     is_reward : int 当前作品是否已经奖励
        // }
        return Fetch({
            url: Config.serverIo.rewardGold,
            method: 'get',
            params: {
                project_id: id
            }
        });
    },

    atomPublishProject({id, tags, share}){
        return Fetch({
            url: Config.serverIo.publish_production,
            method: 'post',
            true_array: true,
            params: {
                id,
                tags,
                share,
            }
        });
    },

    atomSubmitProject({id, share}){
        return Fetch({
            url: Config.serverIo.publish_production,
            method: 'post',
            true_array: true,
            params: {
                id,
                share
            }
        });
    },

    saveProjectName(id, name) {
        return Fetch({
            url: GeneralConfig.serverIo.saveProjectName,
            method: 'post',
            params: {
                id,
                name,
            },
        });
    },

    getProjectById(id) {
        id = id || Detect.proId;
        if (!id) {
            return null;
        }

        return Fetch({
            url: Config.serverIo.getProjectById(id)
        }).then(data => {
            try {
                Detect.lastSavedProject = {
                    proid: id,
                    proName: data.proName,
                    param: data.params,
                    proCover: null
                };
            } catch (e) {
                console.log(e);
                throw(e);
            }

            return data;
        });
    },

    getShareData() {
        return Fetch({
            url: GeneralConfig.serverIo.getShareData,
            params: {
                id: Detect.proId
            }
        });
    },

    getActivityRank() {
        return Fetch({
            url: GeneralConfig.serverIo.getRankInfo
        });
    },

    getMobileShareData(proId, page_type, fingerprint) {
        return Fetch({
            url: GeneralConfig.serverIo[`getMobileShareData_${page_type}`],
            params: {
                id: proId,
                fingerprint,
            }
        });
    },

    saveTeacherComment(params) {
        return Fetch({
            url: GeneralConfig.serverIo.teacherComment,
            method: 'post',
            true_array: true,
            params,
        });
    },

    likeProject () {
        return Fetch({
            url: GeneralConfig.serverIo.likeShare,
            params: {
                id: Detect.proId
            }
        });
    },

    markProject (proId) {
        return Fetch({
            url: GeneralConfig.serverIo.mark_project,
            method: 'post',
            params: {
                id: proId
            }
        });
    },

    saveProjectDesc ({id, text}) {
        return Fetch({
            url: GeneralConfig.serverIo.saveShareDesc,
            method: 'post',
            params: {id, text}
        });
    },

    publishProject({id, name, description, tags, share}) {
        return Fetch({
            url: GeneralConfig.serverIo.publish_production,
            method: 'post',
            true_array: true,
            params: {
                id,
                name,
                description,
                tags,
                share
            }
        });
    },

    getProjectCategory() {
        return Fetch({
            url: GeneralConfig.serverIo.getProjectTags,
            method: 'get',
        }).then((data) => {
            return data.map(item => item.name);
        });
    },

    deletePublishedVideo(project_id){
        return Fetch({
            url: GeneralConfig.serverIo.clear_project_media,
            method: 'post',
            params: {
                id: project_id,
            }
        });
    },

    getRecommendedProjects() {
        return Fetch({
            url: GeneralConfig.serverIo.get_recommended_project,
            method: 'get'
        }).then(data=>{
            // 随机抽取三个作品
            if(data.length>3){
                const result = [];
                for(let i = 0; i<3;i++){
                    let j = Math.floor(Math.random()*data.length);
                    result.push(data[j]);
                    data.splice(j,1);
                }
                return result;
            }
            return data;
        });
    },

    getCoupons(){
        return Fetch({
            url: GeneralConfig.serverIo.getCoupons,
            method: 'get'
        }).then(data=>{
            if(data.PREFERENTIAL){
                return JSON.parse(data.PREFERENTIAL);
            }
            return [];
        });
    },

    getHomework(lesson_id, class_id, package_id, id) {
        return Fetch({
            url: Config.serverIo.getHomework,
            params: {
                lesson_id,
                class_id,
                package_id,
                id
            }
        });
    },

    getStuHomeworkInfo(lesson_id, class_id, package_id) {
        return Fetch({
            url: Config.serverIo.get_stu_homework,
            params: {
                lesson_id, class_id, package_id
            }
        });
    },

    completeHomework(lesson_id, package_id, class_id, homework_id, project_id, is_ready) {
        return Fetch({
            url: Config.serverIo.completeHomework,
            method: 'post',
            params: {
                lesson_id,
                package_id,
                class_id,
                homework_id,
                project_id,
                is_ready: is_ready || 0
            }
        });
    },

    getMedals(params) {
        return Fetch({
            url: Config.serverIo.getMedals,
            params
        });
    },

    applyMedals(medal_ids, project_id) {
        return Fetch({
            url: Config.serverIo.applyMedals,
            method: 'post',
            true_array: true,
            params: {
                medal_ids,
                project_id
            }
        });
    },

    getRewards(params) {
        return Fetch({
            url: Config.serverIo.getRewards,
            params
        });
    },

    receiveReward(params) {
        return Fetch({
            url: Config.serverIo.receiveReward,
            method: 'post',
            params
        });
    },

    homeworkReview(params) {
        return Fetch({
            url: Config.serverIo.homeworkReview,
            true_array: true,
            method: 'post',
            params
        });
    },

    updateCommentIsRead ({homework_id}) {
        return Fetch({
            url: Config.serverIo.homeworkCommentIsRead,
            method: 'post',
            params: {
                homework_id
            }
        })
    },

    getRecordedMediaGuide(proId, type) {
        return Fetch({
            url: Config.serverIo.getRecordedMediaGuide,
            method: 'get',
            params: {
                project_id: proId,   // 作品 hash id
                type: type  // Enum:  作业: 'homework'  DIY2: 'diy2'
            }
        });
    }
};
