import i18n from './index';
import './common';
import zhCN from './ide/zh-CN';
import enUS from './ide/en-US';

const maps = {
  'zh-CN': Object.assign({}, zhCN),
  cn: Object.assign({}, zhCN),
  'en-US': Object.assign({}, enUS),
  en: Object.assign({}, enUS),
};

i18n.setLangMaps(maps);

export default i18n;
