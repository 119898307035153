import { Howl } from 'howler';
import confirm from './assets/237422__plasterbrain__hover-1.ogg';
import cancel from './assets/264763__farpro__guiclick2.ogg';
import coin from './assets/320655__rhodesmas__level-up-01.wav';
import happy from './assets/happy_win.mp3';
import confetti from './assets/confetti.mp3';
import star from './assets/star.mp3';
import beep from './assets/beep-xylo.mp3';
import defeat from './assets/defeat.mp3';
import treasureAppear from './assets/treasure-appear.mp3';
import treasureHover from './assets/treasure-hover.mp3';
import treasureOpen1 from './assets/treasure-open1.mp3';
import treasureOpen2 from './assets/treasure-open2.mp3';
import rewardAppear from './assets/reward-appear.mp3';
import settlementButtonClick from './assets/settlement-button-click.mp3';
import settlementNumGrowth from './assets/settlement-num-growth.mp3';
import settlementTextAppear from './assets/settlement-text-appear.mp3';
import levelForward from './assets/level-forward.mp3';
import evaluateBgm from './assets/evaluate-bgm.mp3'; // 测评关卡背景音乐

const AudioManagerType = {
  ACTIVITY_WIN: happy,
  BUTTON_HOVER: confirm,
  BUTTON_CONFIRM: confirm,
  BUTTON_CANCEL: cancel,
  GET_COIN: coin,
  CONFETTI: confetti,
  STAR: star,
  BEEP: beep,
  DEFEAT: defeat,
  TREASURE_APPEAR: treasureAppear,
  TREASURE_HOVER: treasureHover,
  TREASURE_OPEN1: treasureOpen1,
  TREASURE_OPEN2: treasureOpen2,
  REWARD_APPEAR: rewardAppear,
  SETTLEMENT_BUTTON_CLICK: settlementButtonClick,
  SETTLEMENT_NUM_GROWTH: settlementNumGrowth,
  SETTLEMENT_TEXT_APPEAR: settlementTextAppear,
  LEVEL_FORWARD: levelForward,
  EVALUATE_BGM: evaluateBgm,
};

const currentPlayDict = {};

const AudioManager = {
  play: (audioType, volume = 0.3) => {
    const currentSoundObj = new Howl({
      src: [audioType],
      volume,
    });
    currentPlayDict[audioType] = currentSoundObj;
    currentSoundObj.play();
    return currentSoundObj;
  },
  stop: audioType => {
    const item = currentPlayDict[audioType];
    if (item) {
      item.stop();
    }
  },
};

export { AudioManager, AudioManagerType };
