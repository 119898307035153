import React from 'react';
import PropTypes from 'prop-types';
import MDButton, {VariantType} from 'PublicRepo/material_components/button';
import '!style-loader!css-loader!PublicRepo/material_components/global.css';
import '!style-loader!css-loader!PublicRepo/material_components/button/button.css';
import '!style-loader!css-loader!./confirm-popup.css';

const ConfirmPopup = props => (
    <div className="modal-box">
        <h2 className="title">{props.title}</h2>
        <div className="content">{props.content}</div>
        {!!props.tip && <div className="tip">{props.tip}</div>}
        <div className="btn-group">
            {!!props.handleCancel && (
                <MDButton
                    variantType={VariantType.outlined}
                    onClick={props.handleCancel}
                >
                    {props.cancelBtn}
                </MDButton>
            )}
            <MDButton
                style={props.handleCancel ? {} : {width: '100%'}}
                onClick={props.handleConfirm}
            >
                {props.confirmBtn}
            </MDButton>
        </div>
        {!!props.handleClose && (
            <i
                className="close"
                onClick={props.handleClose}
            />
        )}
    </div>
);

ConfirmPopup.propTypes = {
    cancelBtn: PropTypes.string,
    confirmBtn: PropTypes.string,
    content: PropTypes.string,
    handleCancel: PropTypes.func,
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    tip: PropTypes.string,
    title: PropTypes.string
};

export default ConfirmPopup;
