import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';
import PModel from 'Models/project';
import UserData from 'PublicRepo/utility/userDataManager';
import bodymovin from 'bodymovin';
import likeWaitForClickAnimationJSON from 'Asset/img/share/like-wait-for-click.json';
import likedAnimationJSON from 'Asset/img/share/like-liked.json';

let prevLikeStatus = null;
class LikeAnimationBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLiked:
                prevLikeStatus === null ? this.props.isLiked : prevLikeStatus,
            likeAnimation: null
        };
        this.handleLike = debounce(this.handleLike.bind(this), 500, {
            leading: true,
            trailing: false
        });
        this.setInitLikeAnimation = this.setInitLikeAnimation.bind(this);
    }

    componentDidMount() {
        this.setInitLikeAnimation();
    }

    componentWillUnmount() {
        prevLikeStatus = this.state.isLiked;
    }

    setInitLikeAnimation() {
        this.setState(
            {
                likeAnimation: bodymovin.loadAnimation(
                    Object.assign(
                        {},
                        this.configurateLikeAnimation(this.state.isLiked),
                        {autoplay: !this.state.isLiked}
                    )
                )
            },
            () => {
                if (this.state.isLiked) {
                    this.state.likeAnimation.goToAndStop(2000);
                }
            }
        );
    }

    configurateLikeAnimation(isLiked) {
        return {
            container: document.getElementById('like-animation'),
            renderer: 'svg',
            loop: !isLiked,
            autoplay: true,
            animationData: isLiked
                ? likedAnimationJSON
                : likeWaitForClickAnimationJSON
        };
    }

    pcHandleLike(flag) {
        this.setState({
            isLiked: flag
        });
        this.props.onLike(flag);
    }

    handleLike() {
        const flag = !this.state.isLiked;
        if (!this.props.isMobile) return this.pcHandleLike(flag);
        this.props.trackEvent('MbShare_Project_Like');
        this.state.likeAnimation.destroy();
        this.setState(
            {
                isLiked: flag,
                likeAnimation: bodymovin.loadAnimation(
                    this.configurateLikeAnimation(flag)
                )
            },
            () => {
                this.props.updateLikeNum(flag ? 1 : -1);
                UserData.emitter.emit('update_like_num', flag ? 1 : -1);
            }
        );

        return PModel.likeProject(
            this.props.proId,
            this.props.fingerprint
        ).catch(e => {
            this.state.likeAnimation.destroy();
            this.setState(
                {
                    isLiked: !flag,
                    likeAnimation: bodymovin.loadAnimation(
                        this.configurateLikeAnimation(!flag)
                    )
                },
                () => {
                    this.props.updateLikeNum(flag ? -1 : 1);
                    UserData.emitter.emit('update_like_num', flag ? -1 : 1);
                }
            );
            throw e;
        });
    }

    render() {
        return (
            <span
                className={
                    this.props.isMobile
                        ? this.state.isLiked
                            ? 'liked'
                            : ''
                        : this.props.isLiked
                        ? 'liked'
                        : ''
                }
                id="like-animation"
                onClick={this.handleLike}
            />
        );
    }
}

LikeAnimationBtn.propTypes = {
    fingerprint: PropTypes.string,
    isLiked: PropTypes.bool,
    isMobile: PropTypes.bool,
    onLike: PropTypes.func,
    proId: PropTypes.string,
    trackEvent: PropTypes.func,
    updateLikeNum: PropTypes.func
};

export default LikeAnimationBtn;
