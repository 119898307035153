import i18n from 'PublicRepo/i18n/common';

export const USER = {
  unauthenticated: {
    name: i18n.get('Not Login'),
    avatar: 'https://img.wkcoding.com/default_avatar_not_login.png',
  },
  TYPE: {
    STUDENT: '1',
    TEACHER: '2',
    INSTRUCTOR: '5',
  },
};

export const LESSON = {
  TYPE: {
    LEARNING: '1',
    EXPLORATION: '2',
    CREATION: '3',
    PRIZE: '4',
    QUIZ: '5',
    TC_CREATION: '6',
    TC_EXPLORATION: '7',
    PUZZLE2: '9',
    LEARNING2: '10', // 带有题目的video
  },
};

export const DETECT = {
  isPuzzle: /^\/\w*?puzzle[0-9]?\b/i.test(window.location.pathname),
  isJunior: /^\/(.*?)junior(\.html)?$/i.test(window.location.pathname),
  isShare: /^\/\w*?share\b/i.test(window.location.pathname),
  isPythonScratch: /^\/\w*?python-scratch\b/i.test(window.location.pathname),
};

const defaultHostList = [
  'codingmonkey.com.cn',
  'wkcoding.com',
  'bellcode.cn',
  'bellcode.com',
  'makcoocode.com',
  'icodee.com',
  'wukonglab.cn',
  'icodeworld.cn',
  'democm.icodeworld.cn',
];

export const CONSTANT = {
  PATHNAME: {
    IDE: '/index',
    PUZZLE: '/puzzle',
    JUNIOR: '/junior',
    JUNIOR_PUZZLE: '/juniorPuzzle',
  },
  ORIGIN_WHITE_LIST:
    (window.bellcodeGlobalSettings
      && window.bellcodeGlobalSettings.hostWhiteList)
    || defaultHostList,
};
