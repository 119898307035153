import log from 'Lib/log';
import md5 from 'blueimp-md5';

import Handler from './handler';
import HandlerUtils from './handler-utils';
import DetectState from './detect-state';

const EMPTY_FUNC = new Function();

export default class Detector {
    /**
     * Detector constructor.
     * @param {VirtualMachine} vm - Vm instance.
     * @param {Workspace} workspace - Workspace instance.
     * @param {object} options - Detector config.
     * @param {number|string} [options.timeLimit = 20] - Time to end detection.
     * @param {boolean} [options.detectThreadEmpty = false] - Auto end up detection early
     * when thread is empty.
     * @param {object[]} [options.detectThreadTargets = []] - Targets that
     * used to check if still running in thread.
     * @param {object} options.detectThreadTargets.target - Detect thread target.
     * @param {object} [options.detectThreadTargets.blocks] - Detect thread target blocks.
     * used to check if still running in thread. If thread empty it would end up detection early.
     * @param {number|string} [options.resolveDelay = 0] - Delay time when detect success.
     * @param {number|string} [options.rejectDelay = 0] - Delay time when detect fail.
     * @param {function} [options.resolve] - Detect success callback.
     * @param {function} [options.reject] - Detect fail callback.
     * @param {function} [options.monitor] - Detect monitor callback.
     * @param {array} [options.handlers] - Detect handlers.
     * @param {object} [options.handlerUtilsArg] - Custom detection handlers args 'utils'.
     * @param {boolean} [options.isHandlersAutoBreak] - Should detect handler auto break when fail.
     * @param {object} [options.state] - Detect state.
     * @param {string} [options.state.namespace] - Detect state namespace.
     * @param {string} [options.state.key] - Detect state key for specific project.
     */
    constructor(vm, workspace, options) {
        this.vm = vm;
        this.workspace = workspace;
        this.resolveCallbackTimer = null;
        this.rejectCallbackTimer = null;

        this.timeLimit = parseFloat(options.timeLimit) || 20;
        this.detectThreadEmpty = options.detectThreadEmpty || false;
        this.detectThreadTargets = this.detectThreadEmpty
            ? []
            : (options.detectThreadTargets || []).map(target => ({
                  target: target.target,
                  blocks: target.blocks || target.target.blocks
              }));

        this.resolveDelay = parseFloat(options.resolveDelay) || 0;
        this.rejectDelay = parseFloat(options.rejectDelay) || 0;
        this.resolveCallback = options.resolve || EMPTY_FUNC;
        this.rejectCallback = options.reject || EMPTY_FUNC;
        this.monitorCallback = options.monitor || EMPTY_FUNC;

        const utils = new HandlerUtils(vm, workspace, options.handlerUtilsArg);
        this.handler = new Handler(
            options.handlers,
            [
                {
                    key: 'utils',
                    value: utils
                }
            ],
            options.isHandlersAutoBreak
        );

        this.animationFrame = null;
        this.detectContext = {};

        this.detectState = new DetectState({
            namespace: options.state.namespace,
            key: md5(options.state.key)
        });

        this.handleVmRuntimeStep = this.handleVmRuntimeStep.bind(this);
    }

    static STATIC_SCRIPT_HAT_LIST = ['event_whenflagclicked'];

    static get DETECT() {
        return 0;
    }

    static get DETECT_TIMEOUT() {
        return 1;
    }

    static get DETECT_THREAD_EMPTY() {
        return 2;
    }

    checkIsBlocksScriptStatic(blocks) {
        let isStatic = true;

        blocks.getScripts().some(topBlockId => {
            const topBlockOpCode = blocks.getBlock(topBlockId).opcode;
            // Unable to detect threads while use hat like 'when press XXX'.
            // Cuz it would modify threads uncontrolled.
            // See more in project 'scratch-vm' threads.
            if (
                this.vm.runtime.getIsHat(topBlockOpCode) &&
                Detector.STATIC_SCRIPT_HAT_LIST.indexOf(topBlockOpCode) === -1
            ) {
                isStatic = false;
                return true;
            }

            return false;
        });

        return isStatic;
    }

    initDetectContext() {
        const detectContext = {
            type: Detector.DETECT,
            time: this.vm.runtime.ioDevices.clock.projectTimer()
        };

        if (this.detectThreadEmpty) {
            let isStatic = true;

            this.detectThreadTargets.some(target => {
                if (!this.checkIsBlocksScriptStatic(target.blocks)) {
                    isStatic = false;
                    return true;
                }
                return false;
            });

            detectContext.isThreadsStatic = isStatic;
        }

        this.detectContext = detectContext;
    }

    run() {
        this.initDetectContext();
        this.handleVmRuntimeStep();
    }

    cancel() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }

        if (this.resolveCallbackTimer) {
            clearTimeout(this.resolveCallbackTimer);
            this.resolveCallbackTimer = null;
        }

        if (this.rejectCallbackTimer) {
            clearTimeout(this.rejectCallbackTimer);
            this.rejectCallbackTimer = null;
        }
    }

    checkIsDetectThreadEmpty() {
        return this.detectContext.type === Detector.DETECT_THREAD_EMPTY;
    }

    checkIsDetectTimeout() {
        return this.detectContext.type === Detector.DETECT_TIMEOUT;
    }

    filterThreadsForDetectThreadTargets() {
        return this.vm.runtime.threads.filter(thread => {
            const currDetectThreadTarget = this.detectThreadTargets.find(
                target => target.target === thread.target
            );

            if (currDetectThreadTarget) {
                return (
                    currDetectThreadTarget.blocks
                        .getScripts()
                        .indexOf(thread.topBlock) !== -1
                );
            }

            return false;
        });
    }

    updateDetectContext() {
        const projectTimer = this.vm.runtime.ioDevices.clock.projectTimer();

        this.detectContext.type = Detector.DETECT;
        this.detectContext.time = projectTimer;

        if (projectTimer >= this.timeLimit) {
            log.log('TIME OUT');
            this.detectContext.type = Detector.DETECT_TIMEOUT;
            return;
        }

        if (this.detectThreadEmpty) {
            if (!this.detectContext.isThreadsStatic) {
                return;
            }

            if (this.filterThreadsForDetectThreadTargets().length === 0) {
                log.log('[AUTO] THREAD END');
                this.detectContext.type = Detector.DETECT_THREAD_EMPTY;
            }
        }
    }

    clearResolveCallbackTimer() {
        if (this.resolveCallbackTimer) {
            clearTimeout(this.resolveCallbackTimer);
            this.resolveCallbackTimer = null;
        }
    }

    clearRejectCallbackTimer() {
        if (this.rejectCallbackTimer) {
            clearTimeout(this.rejectCallbackTimer);
            this.rejectCallbackTimer = null;
        }
    }

    _handleResolveCallback(results) {
        log.log('RESOLVE');

        this.cancel();
        this.resolveCallback(
            this.detectState.getState(),
            results,
            this.detectContext
        );
        this.detectState.clearState();
    }

    _handleRejectCallback(results) {
        log.log('REJECT');

        this.cancel();
        this.detectState.handleReject();
        this.rejectCallback(
            this.detectState.getState(),
            results,
            this.detectContext.type
        );
    }

    handleResolve(results, isImmediately = false) {
        if (isImmediately) {
            this.clearResolveCallbackTimer();
            this._handleResolveCallback.bind(this, results);
            return;
        }

        if (this.resolveCallbackTimer) return;

        this.clearRejectCallbackTimer();
        this.resolveCallbackTimer = setTimeout(
            this._handleResolveCallback.bind(this, results),
            this.resolveDelay * 1000
        );
    }

    handleReject(results, isImmediately = false) {
        if (isImmediately) {
            this.clearRejectCallbackTimer();
            this._handleRejectCallback.bind(this, results);
            return;
        }

        if (this.rejectCallbackTimer) return;

        this.clearResolveCallbackTimer();
        this.rejectCallbackTimer = setTimeout(
            this._handleRejectCallback.bind(this, results),
            this.rejectDelay * 1000
        );
    }

    detect() {
        const results = this.handler.tryChainExecute();

        this.monitorCallback(results, this.detectContext);

        if (results.every(result => result.status)) {
            this.handleResolve(results);
        } else {
            this.clearResolveCallbackTimer();
        }

        // Run callback immediately if timeout or thread empty(if need)
        if (
            this.checkIsDetectTimeout() ||
            (this.detectThreadEmpty && this.checkIsDetectThreadEmpty())
        ) {
            if (this.resolveCallbackTimer) {
                this.handleResolve(results, true /* isImmediately */);
            } else {
                this.handleReject(results, true /* isImmediately */);
            }
        }
    }

    handleVmRuntimeStep() {
        this.updateDetectContext();
        this.detect();

        this.animationFrame = requestAnimationFrame(this.handleVmRuntimeStep);
    }
}
