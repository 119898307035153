import ScratchBlocks from 'scratch-blocks';
import layoutWorkspaceBlocks from '../helpers/layout-ws-blocks';

/**
 * Set blocks in Workspace that can not be deleted.
 * @param {Blockly.Workspace} workspace Blockly mainWorkspace.
 * @param {string[]} baseBlocks Top blocks that do not deletable.
 */
export default function setBaseBlocks(workspace, baseBlocks) {
    let blockCount = baseBlocks.length;
    const baseBlocksCountMap = Object.create(null);

    baseBlocks.forEach(type => {
        if (!baseBlocksCountMap[type]) {
            baseBlocksCountMap[type] = 0;
        }

        baseBlocksCountMap[type]++;
    });

    // There may already some blocks exists on the right side.
    // Use them as the base.
    workspace.getTopBlocks().some(blockSvg => {
        const type = blockSvg.type;
        if (baseBlocksCountMap[type]) {
            blockSvg.setDeletable(false);

            baseBlocksCountMap[type]--;
            blockCount--;

            if (baseBlocksCountMap[type] === 0) {
                delete baseBlocksCountMap[type];
            }
        }

        return blockCount === 0;
    });

    // Render new blocks to right side from left side.
    if (blockCount !== 0) {
        const xml = document.createElement('xml');

        workspace
            .getFlyout()
            .getWorkspace()
            .getTopBlocks()
            .some(blockSvg => {
                const type = blockSvg.type;
                if (baseBlocksCountMap[type]) {
                    new Array(baseBlocksCountMap[type])
                        .fill(null)
                        .forEach(() => {
                            const blockXML = ScratchBlocks.Xml.blockToDom(
                                blockSvg
                            );
                            xml.appendChild(blockXML);

                            baseBlocksCountMap[type]--;
                            blockCount--;
                        });

                    delete baseBlocksCountMap[type];
                }

                return blockCount === 0;
            });

        const newIds = ScratchBlocks.Xml.domToWorkspace(xml, workspace);

        workspace.getTopBlocks().forEach(blockSvg => {
            if (newIds.indexOf(blockSvg.id) !== -1) {
                blockSvg.setDeletable(false);
            }
        });
    }

    layoutWorkspaceBlocks(workspace);

    workspace.startDragMetrics = workspace.getMetrics();
    if (workspace.scrollbar) {
        workspace.scroll(
            ScratchBlocks.VerticalFlyout.prototype.DEFAULT_WIDTH + 40,
            80
        );
    }
}
