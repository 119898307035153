const PAY_FOR_ANSWER = 'activity/info/PAY_FOR_ANSWER';
const SET_ACTIVITY_INFO = 'puzzle/info/SET_ACTIVITY_INFO';
const SHOW_RESEARCH = 'puzzle/lesson/SHOW_RESEARCH';
const SHOW_SUCCESS_DIALOG = 'puzzle/lesson/SHOW_SUCCESS_DIALOG';
const HIDE_SUCCESS_DIALOG = 'puzzle/lesson/HIDE_SUCCESS_DIALOG';
const SET_USER_CODE_BLOCKS = 'puzzle/lesson/SET_USER_CODE_BLOCKS';

const initialState = {
    isAnswerPaid: false,
    answerImgUrl: '',
    hasCompletedBefore: false,
    answerPrice: 0,
    userCodeNumber: 0, // 用户编程力数
    userCoinNumber: 0, // 用户金币数
    isRacing: false // 是否是竞赛关卡
};

const payForAnswer = img => ({
    type: PAY_FOR_ANSWER,
    payload: img
});

const showResearch = () => ({
    type: SHOW_RESEARCH
});

const showSuccessDialog = data => ({
    type: SHOW_SUCCESS_DIALOG,
    payload: data
});

const hideSuccessDialog = () => ({
    type: HIDE_SUCCESS_DIALOG
});

const setUserCodeBlocks = blocksJSON => ({
    type: SET_USER_CODE_BLOCKS,
    payload: blocksJSON
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PAY_FOR_ANSWER:
            return {
                ...state,
                isAnswerPaid: true,
                answerImgUrl: action.payload,
                userCodeNumber: state.userCodeNumber - state.answerPrice
            };
        case SET_ACTIVITY_INFO:
            return Object.assign({}, state, {
                ...action.info
            });
        case SHOW_RESEARCH:
            return {...state, shouldShowResearch: true};
        case SHOW_SUCCESS_DIALOG:
            return {
                ...state,
                shouldShowSuccessDialog: true,
                dialogData: action.payload
            };
        case HIDE_SUCCESS_DIALOG:
            return {...state, shouldShowSuccessDialog: false};
        case SET_USER_CODE_BLOCKS:
            return {...state, userAnswer: {code_info: action.payload}};
        default:
            return state;
    }
};

export {
    reducer as default,
    initialState as infoInitialState,
    payForAnswer,
    showResearch,
    showSuccessDialog,
    hideSuccessDialog,
    setUserCodeBlocks
};
