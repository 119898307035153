import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';

const PayForAnswer = props => (
  <div className="answer answer-pay">
    <span className="answer-bg" />
    <p className="answer-txt">
      {i18n.get('puzzle-answer_Have you already tried your best? ')}
      <span className="answer-txt--strong">
        {i18n.get('puzzle-answer_Little Monkey')}
      </span>
      {i18n.get('puzzle-answer_ can show you how other students do it.')}
      <br />
      {i18n.get('puzzle-answer_But you have to pay a certain amount of gold~~')}
    </p>
    <div>
      <span
        role="button"
        tabIndex="0"
        className="answer-btn answer-btn--better"
        onClick={props.onClose}
      >
        {i18n.get("puzzle-answer_Forget it, I'll try again")}
      </span>
      <span
        role="button"
        tabIndex="0"
        className="answer-btn"
        onClick={props.onPay}
      >
        {i18n.get('puzzle-answer_Take a glance（')}
        {props.payCoinNum}
        {i18n.get('puzzle-answer_coins')}
        <span className="icon-coin" />
        {i18n.get('）')}
      </span>
    </div>
  </div>
);

PayForAnswer.propTypes = {
  payCoinNum: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onPay: PropTypes.func.isRequired,
};

export default PayForAnswer;
