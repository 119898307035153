import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TryLookTips from './steps/TryLookTips';
import PayForAnswer from './steps/PayForAnswer';
import ShowAnswer from './steps/ShowAnswer';
import CoinNotEnough from './steps/CoinNotEnough';
import SkipPuzzle from './steps/SkipPuzzle';

import './index.css';

const STEP_ENUM = {
  TRY_LOOK_TIPS: 0,
  PAY_FOR_ANSWER: 1,
  SHOW_ANSWER: 2,
  COIN_NOT_ENOUGH: 3,
  SKIP_PUZZLE: 4,
};

class Answer extends PureComponent {
  constructor(props) {
    super(props);
    const stepValue = this.getStepValue();
    this.state = {
      step: stepValue,
    };

    this.handleGoToPayForAnswer = this.handleGoToPayForAnswer.bind(this);
    this.handlePayForAnswer = this.handlePayForAnswer.bind(this);
    this.handleSkipPuzzle = this.handleSkipPuzzle.bind(this);
  }

  /** 获取当前步骤 */
  getStepValue() {
    if (this.props.isRacing) {
      return STEP_ENUM.SKIP_PUZZLE;
    }

    if (this.props.isAnswerVisible) {
      return STEP_ENUM.SHOW_ANSWER;
    }

    if (this.props.isPuzzle2) {
      return STEP_ENUM.PAY_FOR_ANSWER;
    }

    return STEP_ENUM.TRY_LOOK_TIPS;
  }

  componentDidMount() {
    this.state.step === STEP_ENUM.TRY_LOOK_TIPS
      && this.props.onShowPositionHints();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.isAnswerVisible
      && newProps.isAnswerVisible !== this.props.isAnswerVisible
    ) {
      this.setState({
        step: STEP_ENUM.SHOW_ANSWER,
      });
    }
  }

  handleGoToPayForAnswer() {
    this.setState({
      step: STEP_ENUM.PAY_FOR_ANSWER,
    });
    this.props.onClosePositionHints();
  }

  handlePayForAnswer() {
    this.props.onPay().catch(() => {
      this.setState({
        step: STEP_ENUM.COIN_NOT_ENOUGH,
      });
    });
  }

  handleSkipPuzzle() {
    this.setState({
      step: STEP_ENUM.SKIP_PUZZLE,
    });
  }

  render() {
    let child;

    switch (this.state.step) {
      case STEP_ENUM.TRY_LOOK_TIPS:
        child = (
          <TryLookTips
            key={this.state.step}
            onClose={() => {
              this.props.onClosePositionHints();
              this.props.onClose();
            }}
            onSaw={this.handleGoToPayForAnswer}
          />
        );
        break;
      case STEP_ENUM.PAY_FOR_ANSWER:
        child = (
          <PayForAnswer
            key={this.state.step}
            onClose={this.props.onClose}
            payCoinNum={this.props.payCoinNum}
            onPay={this.handlePayForAnswer}
          />
        );
        break;
      case STEP_ENUM.SHOW_ANSWER:
        child = (
          <ShowAnswer
            key={this.state.step}
            onClose={this.props.onClose}
            isPuzzleCompleted={this.props.isPuzzleCompleted}
            showSkipConfirm={this.handleSkipPuzzle}
            answer={this.props.answer}
          />
        );
        break;
      case STEP_ENUM.COIN_NOT_ENOUGH:
        child = (
          <CoinNotEnough key={this.state.step} onClose={this.props.onClose} />
        );
        break;
      case STEP_ENUM.SKIP_PUZZLE:
        child = (
          <SkipPuzzle
            key={this.state.step}
            onClose={this.props.onClose}
            skipPuzzle={this.props.skipPuzzle}
          />
        );
        break;
      default:
        child = (
          <TryLookTips
            key={this.state.step}
            onClose={() => {
              this.props.onClosePositionHints();
              this.props.onClose();
            }}
            onSaw={this.handleGoToPayForAnswer}
          />
        );
    }

    return child;
  }
}

Answer.propTypes = {
  isPuzzle2: PropTypes.bool,
  answer: PropTypes.string.isRequired,
  payCoinNum: PropTypes.number.isRequired,
  isAnswerVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPay: PropTypes.func.isRequired,
  onShowPositionHints: PropTypes.func.isRequired,
  onClosePositionHints: PropTypes.func.isRequired,
  skipPuzzle: PropTypes.func,
  isPuzzleCompleted: PropTypes.bool,
};

Answer.defaultProps = {
  positionHints: {},
  onShowPositionHints() {},
  onClosePositionHints() {},
};

export default Answer;
