import React from 'react';
import PropTypes from 'prop-types';

import Modal, {POSITION} from 'PublicRepo/components/Modal/index';
// import withButton from 'PublicRepo/components/Modal/withButton';
import withAnimation from 'PublicRepo/components/Modal/withAnimation';
import Video from 'PublicRepo/components/Video/index';
import VideoModel from 'Models/video';

import '!style-loader!css-loader!plyr/dist/plyr.css';
import '!style-loader!css-loader!PublicRepo/components/Video/index.css';
import '!style-loader!css-loader!PublicRepo/components/Modal/index.css';
import '!style-loader!css-loader!PublicRepo/components/Modal/withAnimation.css';

const ModalWithAnimation = withAnimation(Modal);

const VideoModal = ({in: inProp, ...props}) => (
    <ModalWithAnimation
        className="video-modal"
        in={inProp}
        position={POSITION.CENTER}
        style={
            props.isVertical
                ? {maxWidth: '50%'}
                : {width: '100vh', transform: 'translate(-50%, -55%)'}
        }
    >
        <Video
            {...props}
            videoModel={VideoModel}
        />
    </ModalWithAnimation>
);

VideoModal.propTypes = {
    in: PropTypes.bool.isRequired,
    isVertical: PropTypes.bool
};

export default VideoModal;
