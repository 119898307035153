/*
 *      !!! Version need to be updated !!!
 * While local puzzle storage structure modified
 *
 * {
 *   {namespace}: {
 *       version: '1.0.0',
 *       states: {
 *           md5Key: {
 *               errTimes: 3
 *           }
 *       }
 *   }
 * }
 * */

export default class DetectState {
    constructor({namespace, key}) {
        this.version = '1.1.0';

        this.namespace = namespace;
        this.key = key;

        this.storageStates = null;
        this.initState();
    }

    handleReject() {
        this.cacheState({
            errTimes: this.getState().errTimes + 1
        });
    }

    initState() {
        let json = localStorage.getItem(this.namespace);

        if (json) {
            try {
                json = JSON.parse(json);
                if (
                    json.version === this.version &&
                    typeof json.states === 'object'
                ) {
                    this.storageStates = json;
                } else {
                    // !!! Old version !!!
                    // Current now just clear state crudely.
                    this.clearAllStates();
                }
            } catch (e) {
                // Local json data err
                this.clearAllStates();
                throw e;
            }
        }

        this.storageStates = this.storageStates || {
            version: this.version,
            states: {}
        };
    }

    getState() {
        this.storageStates.states[this.key] = this.storageStates.states[
            this.key
        ] || {
            errTimes: 0
        };

        return this.storageStates.states[this.key];
    }

    cacheState(state) {
        if (state.errTimes) {
            this.storageStates.states[this.key] = {
                errTimes: state.errTimes
            };
        }
        localStorage.setItem(
            this.namespace,
            JSON.stringify(this.storageStates)
        );
    }

    clearState() {
        delete this.storageStates.states[this.key];
        localStorage.setItem(
            this.namespace,
            JSON.stringify(this.storageStates)
        );
    }

    clearAllStates() {
        localStorage.removeItem(this.namespace);
        this.storageStates = null;
    }
}
