import React, { Component } from 'react';
import UserData from 'PublicRepo/utility/userDataManager';
import LockModal from './index';

import { stateUpdateMessageObservable } from '../index';
import { globalType } from '../init';

import VideoCloud from '../video-cloud/index';

const subscribeForbiddenShade = OriginComponent => {
  class wrapperComponent extends Component {
    state = {
      shouldStudentControl: true,
    };

    componentDidMount() {
      stateUpdateMessageObservable.subscribe(msg => {
        const hashId = UserData.userInfo && UserData.userInfo.hash_id;
        const blackList = msg[globalType.STUDENT_CONTROL_BLACK_LIST] || [];
        if (blackList.includes(hashId)) {
          this.setState({ shouldStudentControl: false });
        } else {
          this.setState({ shouldStudentControl: true });
        }
      });
    }

    render() {
      const { shouldStudentControl } = this.state;
      return (
        <div>
          <OriginComponent {...this.props} />
          {!shouldStudentControl && <LockModal />}
          {<VideoCloud autoplay={false} />}
        </div>
      );
    }
  }

  return wrapperComponent;
};

export default subscribeForbiddenShade;

/*
    subscribeForbiddenShade(Component);
*/
