import Puzzle from '../../lib/puzzle/puzzle';

const SET_PUZZLE_ENGINE = 'puzzle/engine/SET_PUZZLE_ENGINE';
const initialState = new Puzzle();

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_PUZZLE_ENGINE:
            return action.engine;
        default:
            return state;
    }
};

const setPuzzleEngine = function(engine) {
    return {
        type: SET_PUZZLE_ENGINE,
        engine
    };
};

export {
    reducer as default,
    initialState as engineInitialState,
    setPuzzleEngine
};
