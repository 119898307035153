/* eslint-disable */
import getEventEmitterSingleton from 'PublicRepo/utility/getEventEmitterSingleton';
import UserData from 'PublicRepo/utility/userDataManager';
//import Fetch from "../../lib/fetch";
//import apiOrigin from "../../utility/getAPIHost";

var BellCodeIntercom = {
  ZHICHI_LOADED: false,
  lastUserId: null,
  initZhiChi: function(listeners) {
    // && !window.zhiManager
    if (this.ZHICHI_LOADED) {
      document
        .getElementById('zhichiScript')
        .setAttribute('data-args', updateScriptArgs());
      const zhiManager = getzhiSDKInstance();
      window.zhiManager = zhiManager;
      zhiManager.set('customBtn', 'true'); // not work
      zhiManager.set('customMargin', 40);
      zhiManager.set('satDegree_A', false);
      zhiManager.set('color', '5d37b7');
      zhiManager.set('isFeedBackFlag', false);
      zhiManager.set('moduleType', 4);
      this.setUserInfo();
      zhiManager.on('load', this.handleZhiChiLoaded.bind(this, listeners));
      return;
    } else {
      setTimeout(() => {
        this.initZhiChi(listeners);
      }, 100);
    }
  },

  setUserInfo: function() {
    const {
      userid,
      real_name,
      username,
      school_id,
      school_name,
      gold_num,
      code_num,
      sex,
      nickname,
      avator,
      phone,
      is_offline_business_user,
    } = UserData.userInfo;
    const params = {
      用户对接ID: userid,
      平台用户名: username,
      是否为B端用户: is_offline_business_user,
      学校ID: school_id,
      学校名称: school_name,
      金币数: gold_num,
      编程力: code_num,
    };
    zhiManager.set('partnerId', userid);
    zhiManager.set('userinfo', {
      tel: phone, //电话或手机
      uname: nickname,
      face: avator, //头像URL
      realname: real_name, //真实姓名
      sex,
      params: JSON.stringify(params), //自定义用户信息字段
    });
    if (!this.lastUserId) {
      this.lastUserId = userid;
      return;
    } else if (this.lastUserId !== userid) {
      this.lastUserId = userid;
      zhiManager.initBtnDOM();
    }
  },

  handleZhiChiLoaded: function(listeners) {
    zhiManager.initBtnDOM();
    this.setZhiEvent(listeners);
  },

  showPanel: function() {
    zhiManager.expand();
  },

  setZhiEvent: function({ onExpand, onCollapse }) {
    zhiManager.on('core.framestatuschange', status => {
      if (status === 'collapse' && onCollapse) {
        onCollapse();
      }
      if (status === 'expand' && onExpand) {
        onExpand();
      }
    });
  },

  handlePanelVisibility(fn) {
    _MEIQIA('getPanelVisibility', fn);
  },

  updateUserInfo: function(userInfo) {
    window._MEIQIA && _MEIQIA('metadata', userInfo);
  },

  trackEvent: function(eventName, metaData) {
    if (!eventName) return;
  },

  trackEventWithNotification: function(eventName, bodyMsg, metaData) {
    this.trackEvent(eventName, metaData);
  },

  setZhiScriptLoaded: function() {
    this.ZHICHI_LOADED = true;
  },
};

getEventEmitterSingleton().on(
  'register_intercom',
  BellCodeIntercom.updateUserInfo
);

export function updateScriptArgs() {
  return 'manual=true';
  // return `manual=true&partnerId=${UserData.userInfo.userid}`;
}

export default BellCodeIntercom;
