import {combineReducers} from 'redux';

import engineReducer, {engineInitialState} from './engine';
import infoReducer, {infoInitialState} from './info';
import statusReducer, {statusInitialState} from './status';
import tipReducer, {tipInitialState} from './tip';
import codeTargetReducer, {codeTargetInitialState} from './code-target';
import eventTargetReducer, {eventTargetInitialState} from './event-target';
import errorEventTargetReducer, {
    errorEventTargetInitialState
} from './error-event-target';
import controlReducer, {controlInitialState} from './control';
import pythonReducer, {pythonInitialState} from './python';

const puzzleInitialState = {
    engine: engineInitialState,
    info: infoInitialState,
    status: statusInitialState,
    tip: tipInitialState,
    targets: {
        code: codeTargetInitialState,
        event: eventTargetInitialState,
        errorEvent: errorEventTargetInitialState
    },
    control: controlInitialState,
    python: pythonInitialState
};

const puzzleReducers = combineReducers({
    engine: engineReducer,
    info: infoReducer,
    status: statusReducer,
    tip: tipReducer,
    targets: combineReducers({
        code: codeTargetReducer,
        event: eventTargetReducer,
        errorEvent: errorEventTargetReducer
    }),
    control: controlReducer,
    python: pythonReducer
});

export {puzzleReducers as default, puzzleInitialState};
