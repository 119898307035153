const ENABLE_REDO = 'puzzle/python/ENABLEREDO';
const ENABLE_UNDO = 'puzzle/python/ENABLEUNDO';
const DISABLE_REDO = 'puzzle/python/DISABLEREDO';
const DISABLE_UNDO = 'puzzle/python/DISABLEUNDO';
const SET_MONACO = 'puzzle/python/SET_MONACO';
const SET_EDITOR = 'puzzle/python/SET_EDITOR';

const initialState = {
    redo: false,
    undo: false,
    monaco: null,
    editor: null
};

const enableRedo = () => ({
    type: ENABLE_REDO
});
const enableUndo = () => ({
    type: ENABLE_UNDO
});
const disableRedo = () => ({
    type: DISABLE_REDO
});
const disableUndo = () => ({
    type: DISABLE_UNDO
});
const setMonaco = payload => ({
    type: SET_MONACO,
    payload
});
const setEditor = payload => ({
    type: SET_EDITOR,
    payload
});

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case ENABLE_REDO:
            return Object.assign({}, state, {
                redo: true
            });
        case ENABLE_UNDO:
            return Object.assign({}, state, {
                undo: true
            });
        case DISABLE_REDO:
            return Object.assign({}, state, {
                redo: false
            });
        case DISABLE_UNDO:
            return Object.assign({}, state, {
                undo: false
            });
        case SET_EDITOR:
            return Object.assign({}, state, {
                editor: action.payload
            });
        case SET_MONACO:
            return Object.assign({}, state, {
                monaco: action.payload
            });
        default:
            return state;
    }
};

export {
    reducer as default,
    initialState as pythonInitialState,
    enableRedo,
    enableUndo,
    disableRedo,
    disableUndo,
    setMonaco,
    setEditor
};
