/* eslint-disable */
import React, { Component } from 'react';
import Config from 'PublicRepo/utility/generalConfig';
import Modal, { POSITION } from 'PublicRepo/components/Modal/index';
import withAnimation from 'PublicRepo/components/Modal/withAnimation';
import VideoModel from './videoModel';
import Video from 'PublicRepo/components/Video/index';

import 'PublicRepo/node_modules/plyr/dist/plyr.css';

const ModalWithAnimation = withAnimation(Modal);

export default class VideoPlayer extends Component {
  state = {
    contentStyle: this.contentStyle,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateContentStyle);
  }

  componentDidUpdate(oldProps) {
    if (this.props.show && oldProps.show !== this.props.show) {
      this.player && this.player.play();
    }
  }

  updateContentStyle = () => {
    this.setState({ contentStyle: this.contentStyle });
  };

  get contentStyle() {
    const viewPortWidth = document.body.clientWidth;
    const viewPortHeight = document.body.clientHeight;

    const containerHeight =
      parseInt(Math.min(viewPortHeight, (viewPortWidth / 4) * 3), 10) * 0.07;

    return {
      containerHeight: Math.min(65, containerHeight),
    };
  }

  closePlayer(
    currentTime,
    duration,
    isAllCompleted,
    isVideo2WithoutQuestion,
    watchedVideoTime
  ) {
    // 调用父组件的关闭窗口方法
    this.props.closePopup();

    // 是否完成所有题目
    if (
      !isAllCompleted &&
      parseInt(this.props.videoType, 10) === Config.activityType.video2 &&
      !isVideo2WithoutQuestion
    ) {
      return this.props.onended({
        isVideo2NotPassed: true,
        watchedVideoTime,
        duration,
      });
    }
  }

  stoppropagation(e) {
    e.stopPropagation();
    return false;
  }

  render() {
    return (
      <ModalWithAnimation
        shouldStopPropagation
        in={this.props.show}
        onClose={this.closePlayer}
        position={POSITION.CENTER}
        className="video-modal"
        style={{
          width: `${this.state.contentStyle.containerHeight}%`,
          top: '48%',
        }}
      >
        <Video
          autoplay={this.props.autoplay}
          src={this.props.videoPath}
          videoType={parseInt(this.props.videoType, 10)}
          videoPackageId={this.props.videoPackageId}
          videoLessonId={this.props.videoLessonId}
          videoActivityId={this.props.videoActivityId}
          videoClassId={this.props.videoClassId}
          onEnded={() => {}}
          onClose={() => {}}
          onPlay={() => {}}
          onSeeked={() => {}}
          onPause={() => {}}
          onFullScreen={() => {}}
          onVolumechange={() => {}}
          updateContentStyle={this.updateContentStyle}
          videoModel={VideoModel}
          videoName={this.props.videoName}
          videoLocation={this.props.videoLocation}
          openQuiz={this.props.openQuiz}
          openQuizById={this.props.openQuizById}
          controlSide={this.props.controlSide}
        />
      </ModalWithAnimation>
    );
  }
}
