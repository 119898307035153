var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".activity-reset-tips_activity-reset-tips_2Dh4P {\n    width: 380px;\n    background-color: rgb(255, 255, 255);\n    border-radius: 8px;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    -webkit-transform: translate(-50%, -50%);\n        -ms-transform: translate(-50%, -50%);\n            transform: translate(-50%, -50%);\n    padding: 35px;\n    z-index: 500;\n}\n\n.activity-reset-tips_title_2hDK- {\n    font-size: 20px;\n    color: rgb(138, 83, 170);\n}\n\n.activity-reset-tips_content_U-3AO {\n    margin-top: 10px;\n    width: 225px;\n    line-height: 1.5;\n    font-size: 16px;\n    color: rgb(153, 153, 153);\n}\n\n.activity-reset-tips_btn-group_3FzV8 {\n    margin-top: 30px;\n}\n\n.activity-reset-tips_btn-cancel_ZsiUj {\n    color: rgb(255, 255, 255);\n    background-color: rgb(49, 150, 255);\n    border: 2px solid rgb(49, 150, 255);\n}\n\n.activity-reset-tips_btn-reset_x8QaY {\n    width: 120px;\n    margin-left: 30px;\n    color: rgb(49, 150, 255);\n    background-color: rgb(255, 255, 255);\n    border: 2px solid rgb(49, 150, 255);\n}\n\n.activity-reset-tips_close_3-LL4 {\n    display: block;\n    width: 23px;\n    height: 23px;\n    position: absolute;\n    right: 10px;\n    top: 10px;\n    background-image: url(" + escape(require("./close-item.png")) + ");\n}\n\n.activity-reset-tips_btn_2dtM8 {\n    display: block;\n    float: left;\n    border-radius: 18px;\n    width: 110px;\n    height: 38px;\n    font-size: 16px;\n    text-align: center;\n    line-height: 1.15;\n}\n", ""]);

// exports
exports.locals = {
	"activity-reset-tips": "activity-reset-tips_activity-reset-tips_2Dh4P",
	"activityResetTips": "activity-reset-tips_activity-reset-tips_2Dh4P",
	"title": "activity-reset-tips_title_2hDK-",
	"content": "activity-reset-tips_content_U-3AO",
	"btn-group": "activity-reset-tips_btn-group_3FzV8",
	"btnGroup": "activity-reset-tips_btn-group_3FzV8",
	"btn-cancel": "activity-reset-tips_btn-cancel_ZsiUj",
	"btnCancel": "activity-reset-tips_btn-cancel_ZsiUj",
	"btn-reset": "activity-reset-tips_btn-reset_x8QaY",
	"btnReset": "activity-reset-tips_btn-reset_x8QaY",
	"close": "activity-reset-tips_close_3-LL4",
	"btn": "activity-reset-tips_btn_2dtM8"
};