import React from 'react';
import PropTypes from 'prop-types';
import styles from './account-info.css';

const AccountInfo = ({avatar, nickname}) => (
    <div className={styles.accountInfo}>
        <img
            className={styles.profileIcon}
            src={avatar}
        />
        <span>{nickname}</span>
    </div>
);

AccountInfo.propTypes = {
    avatar: PropTypes.string,
    nickname: PropTypes.string
};

export default AccountInfo;
