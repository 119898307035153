import Fetch from '../../public-repo/lib/fetch';
import {getParams} from '../../lib/env-utils';
import GeneralConfig from '../../public-repo/utility/generalConfig';
import {addMakeToolboxXmlMiddlewares} from './blocks/blocks';
import layout from '../../lib/layout-constants';

const installPuzzleEnv = (() => {
    let isEnvInstalled = false;

    return () => {
        if (isEnvInstalled) return;
        addMakeToolboxXmlMiddlewares(() => '<xml />');

        isEnvInstalled = true;
    };
})();
/* eslint-disable babel/camelcase */
const getGuiPuzzle2Info = ({activityId, lessonId, packageId, classId}) =>
    Fetch({
        url: GeneralConfig.serverIo.getGuiPuzzle2ById,
        method: 'get',
        params: {
            activity_id: activityId,
            lesson_id: lessonId,
            package_id: packageId,
            class_id: classId
        }
    });

const getStageDimensionsOnPythonFullScreen = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const marginH = 150;
    let headerH = 60;
    if (window.innerWidth > 1920) {
        headerH = 70;
    }

    const maxH = height - headerH - marginH;
    const maxW = width;

    return {
        heightDefault: layout.standardStageHeight,
        widthDefault: layout.standardStageWidth,
        width: Math.min.apply(null, [maxW, (maxH * 4) / 3, 1178]),
        height: Math.min.apply(null, [maxH, (maxW * 3) / 4, 883.5])
    };
};

const getStageDimensionsOnPython = (isFullscreen = false) => {
    if (isFullscreen) return getStageDimensionsOnPythonFullScreen();
    let leftW = 276;
    let basic = 40 / 107;
    let maxW = 550;
    let maxH = 412.5;
    if (window.innerWidth > 1920) {
        leftW = 400;
        basic = 11 / 30;
        maxW = Infinity;
        maxH = Infinity;
    }
    return {
        heightDefault: layout.standardStageHeight,
        widthDefault: layout.standardStageWidth,
        width: Math.min.apply(null, [
            maxW,
            (window.innerWidth - leftW - 20) * basic
        ]),
        height: Math.min.apply(null, [
            ((window.innerWidth - leftW - 20) * basic * 3) / 4,
            maxH
        ])
    };
};

const getStageDimensions = () => ({
    heightDefault: layout.standardStageHeight,
    widthDefault: layout.standardStageWidth,
    width: Math.min(
        window.innerWidth - 296,
        ((window.innerHeight - 164) * 4) / 3
    ),
    height: Math.min(
        ((window.innerWidth - 296) * 3) / 4,
        window.innerHeight - 164
    )
});

const getStageDimensionsForJuniorPuzzle2 = (isFullscreen = false) => {
    if (isFullscreen) return getStageDimensionsOnPythonFullScreen();
    const {innerWidth, innerHeight} = window;
    const rightPanelMin = {
        0: 580,
        1: 540
    }[+(innerWidth <= 1024)];
    const controlBarHeight = {
        0: 75,
        1: 65
    }[+(innerWidth <= 1024)];
    const allowedWidth = innerWidth * 0.4;
    let computedWidth = innerWidth - rightPanelMin - 2;

    computedWidth = computedWidth > allowedWidth ? allowedWidth : computedWidth;

    let computedHeight = (computedWidth * 3) / 4;
    const restHeight = innerHeight - 80 - controlBarHeight - computedHeight;
    let targetsHeight = restHeight;
    const targetDesc = document.getElementById('puzzle2-target-descriptions');
    const stageEl = document.getElementById('stage-playground');
    let targetDescMaxHeight = null;

    if (restHeight < 180) {
        computedHeight -= 180 - restHeight;
        computedWidth = allowedWidth;
        targetsHeight = 180;
    }

    targetDescMaxHeight = `${targetsHeight - 151}px`;

    if (stageEl && getComputedStyle(stageEl).minHeight !== computedHeight) {
        stageEl.style.minHeight = `${computedHeight}px`;
    }

    if (
        targetDesc &&
        getComputedStyle(targetDesc).maxHeight !== targetDescMaxHeight
    ) {
        targetDesc.style.maxHeight = targetDescMaxHeight;
    }

    return {
        heightDefault: layout.standardStageHeight,
        widthDefault: layout.standardStageWidth,
        width: computedWidth,
        height: computedHeight
    };
};

const payForAnswer = () =>
    Fetch({
        url: GeneralConfig.serverIo.puzzle2PayForAnswer,
        method: 'get',
        params: {
            id: getParams().proId,
            lesson_id: getParams().lessonId,
            class_id: getParams().classId,
            package_id: getParams().packageId
        }
    });

const skipPuzzle = () =>
    Fetch({
        url: GeneralConfig.serverIo.skip_activity,
        method: 'post',
        params: {
            activity_id: getParams().actId,
            class_id: getParams().classId,
            package_id: getParams().packageId
        }
    });

const getWorkspaceNotShadowBlocksNum = workspace =>
    Object.keys(workspace.blockDB_).filter(
        key => !workspace.blockDB_[key].isShadow()
    ).length;

const teacherComplete = () =>
    Promise.resolve({
        score: 3,
        total_score: 3,
        pop_up: {
            code_num: 100,
            gold_num: 100,
            current_code_num: 0,
            current_gold_num: 0,
            ranking: 100,
            user_current_code_num: 100,
            user_current_gold_num: 100,
            list_item: []
        }
    });

const reportPuzzle2StudentAnswerPath = (
    {costTime, errTimes, usedBlocksNum, score, type},
    {blocksJSON}
) => {
    const {actId, lessonId, classId, packageId} = getParams();
    const params = {
        lesson_id: lessonId,
        class_id: classId,
        package_id: packageId,
        activity_id: actId,
        time: costTime,
        extra_info: {
            error_count: errTimes,
            block_num: usedBlocksNum,
            script_info: JSON.stringify([]),
            code_info: blocksJSON,
            score: score
        }
    };
    if (type) {
        params.type = type;
    }
    return Fetch({
        url: GeneralConfig.serverIo.getIDEResult,
        method: 'post',
        newPost: true,
        params: params
    });
};

/**
 *
 * @param {string} str python代码字符串
 * @return {number} python代码有效行数
 */
const getPythonCodeLineCount = str => {
    const arr = str
        .split(/\n/)
        .filter(e => e.trim() !== '' && !e.trim().startsWith('#'));
    const splitIndexArr = arr.reduce((pre, next, index) => {
        if (next.trim().startsWith(`'''`) || next.trim().startsWith(`"""`)) {
            pre.push(index);
        }
        return pre;
    }, []);
    for (let index = Math.floor(splitIndexArr.length / 2); index > 0; index--) {
        const first = (index * 2) - 2;
        const second = first + 1;
        const start = splitIndexArr[first];
        const end = splitIndexArr[second];
        arr.splice(start, end - start + 1);
    }
    return arr.length;
};

export {
    installPuzzleEnv,
    payForAnswer,
    skipPuzzle,
    getStageDimensions,
    getStageDimensionsForJuniorPuzzle2,
    getStageDimensionsOnPython,
    getGuiPuzzle2Info,
    teacherComplete,
    getWorkspaceNotShadowBlocksNum,
    reportPuzzle2StudentAnswerPath,
    getPythonCodeLineCount
};
