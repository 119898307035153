import lottie from 'lottie-web';
import robotSpeaking from '../../animations/robot-speaking/robotSpeaking.json';

export const bellRobotAnimationConfig = {
  renderer: 'svg',
  container: null, // 动画播放的 DOM 元素
  animationData: robotSpeaking, // 动画播放桢 JSON 数据
  loop: true,
  autoplay: true,
  assetsPath: '/animation/robot-speaking/',
};

export const renderBellRobotAnimation = () => {
  const robot = document.createElement('div');
  robot.id = 'bell-robot';
  const introEle = document.querySelector('.intro-tips');
  introEle && introEle.appendChild(robot);
  bellRobotAnimationConfig.container = robot;
  lottie.loadAnimation(bellRobotAnimationConfig);
};
