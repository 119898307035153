const INIT_EVENT_TARGETS = 'puzzle/event-target/INIT_EVENT_TARGETS';
const UPDATE_EVENT_TARGETS_STATUS =
    'puzzle/event-target/UPDATE_EVENT_TARGETS_STATUS';

const initialState = [];

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case INIT_EVENT_TARGETS:
            return payload.map(target => ({
                ...target,
                status: 1 // 1: undone(unlock), 2: done
            }));
        case UPDATE_EVENT_TARGETS_STATUS:
            return state.slice().map((target, index) => ({
                ...target,
                status: payload[index] ? 2 : 1
            }));
        default:
            return state;
    }
};

const initEventTargets = eventTargetArray => ({
    type: INIT_EVENT_TARGETS,
    payload: eventTargetArray
});

const updateEventTargetsStatus = eventTargetsCheckResult => ({
    type: UPDATE_EVENT_TARGETS_STATUS,
    payload: eventTargetsCheckResult
});

export {
    reducer as default,
    initialState as eventTargetInitialState,
    initEventTargets,
    updateEventTargetsStatus
};
