const VERSION = '2';
const META_DATA = `#v${VERSION}#`;
const META_DATA_START_REGEXP = new RegExp(`^${META_DATA}`);
const SOME_UNSTANDARD_CODE_PATH_REGEXP = new RegExp(
    `\\(${META_DATA}|\\|(\\s|\\r\\n|\\r|\\n)+${META_DATA}`
);

const encode = paths =>
    []
        .concat(paths)
        .map(path => `${META_DATA}${path}`)
        .join('\n');

const _unstandardDecode = str =>
    str
        .replace(/\n/g, '')
        .replace(new RegExp(`^(\\s|\\r\\n|\\r|\\n)*${META_DATA}`), '')
        .replace(new RegExp(`(\\s|\\r\\n|\\r|\\n)*$`), '')
        .split(new RegExp(`(?:\\s|\\r\\n|\\r|\\n)+${META_DATA}`))
        .filter(path => path !== '');

const _standardDecode = str =>
    str
        .split(new RegExp('\\r\\n|\\r|\\n'))
        .reduce((target, line) => {
            line = line.trim();
            if (line) {
                if (line.startsWith(META_DATA)) {
                    target.push([]);
                    line = line.replace(META_DATA_START_REGEXP, '');
                }
                target[target.length - 1].push(line);
            }

            return target;
        }, [])
        .map(lines => lines.join(''))
        .filter(path => path !== '');

const decode = str => {
    try {
        // Compatible with unstandard codePath that teachers used.
        if (SOME_UNSTANDARD_CODE_PATH_REGEXP.test(str)) {
            return _unstandardDecode(str);
        }

        try {
            return _standardDecode(str);
        } catch (e) {
            return _unstandardDecode(str);
        }
    } catch (e) {
        return str;
    }
};

const isValid = path => path.indexOf(META_DATA) === 0;

export {encode, decode, isValid};
