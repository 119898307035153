/* eslint-disable*/
import {connect} from 'react-redux';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import debounce from 'lodash.debounce';
import {Modal} from 'antd';
import i18n from 'public-repo/i18n';
import DialogTracker from 'public-repo/components/ActivityDialog/DialogTracker.js';
import WebStorage from 'public-repo/lib/webStorage';
import UserData from 'public-repo/utility/userDataManager';
import log from '../../lib/log';
import {DETECT} from 'public-repo/lib/config.js';
import {getParams} from '../../lib/env-utils';
import {
    setActivity,
    getActivityResult,
    reportActivityComplete
} from '../lib/activity';
import {setLesson, getLessonInfo} from '../lib/lesson';
import {getProjectById, sumbitDiyProject} from '../lib/diy-utils';
import {publishProject, getProjectJson} from '../../lib/project-utils';
import {openAccount} from '../../reducers/modals';
import {showSaveTipsOnce, toggleResetTips} from '../reducers/activity/tips';
import {openCustomGuide} from '../reducers/activity/custom-guide';
import CustomGuideCards from './custom-guide-cards.jsx';
import {guideTypes} from '../components/custom-guide-cards/custom-guide-cards.jsx';
import ContactTeacher, {
    ContactTeacherPages,
    checkIsToCUser
} from 'PublicRepo/components/ContactTeacher/contactTeacher';
import {
    showLeaveWarning,
    hideLeaveWarning
} from '../reducers/activity/leave-warning';
import {
    autoUpdateProject,
    manualUpdateProject,
    getIsUpdating,
    getIsShowingWithId
} from '../../reducers/project-state';
import generalConfig from 'public-repo/utility/generalConfig';
import IntercomHandler from 'public-repo/components/Intercom/intercomHandler';
import locationBuilder from 'public-repo/utility/locationBuilder';
import UModel from 'Models/user';
import ActivityMenuBar from '../components/activity-menu-bar/activity-menu-bar.jsx';
import autoSubscribe from 'PublicRepo/utility/rtc-for-workshop/auto-subscribe';
import subscribeForbiddenShade from 'PublicRepo/utility/rtc-for-workshop/forbidden-shade/hoc';

import '!style-loader!css-loader!PublicRepo/utility/rtc-for-workshop/forbidden-shade/forbidden-shade.css';
import {closeL0Guide} from './../reducers/diy/diy';
import AModel from 'Models/activity';
import Detect from 'Common/detect';
import CustomConfig from '../config';

import '!style-loader!css-loader!antd/dist/antd.css';

const Teacher = '2';

class DiyMenuBar extends React.Component {
    constructor(props) {
        super(props);

        this.trackData = {};

        this.state = {
            diyIsReady: false,
            lessonInfo: null,
            activity: null,
            isActivitySuccessModalVisible: false,
            isVideoModalVisible: false,
            proName: '',
            popData: {},
            diyPre: location.search.includes(
                generalConfig.diySubStepExcludeLastUrlSymbol
            ) // diy分布类型 在url 上添加`diyPre`
        };
        bindAll(this, [
            'loadDiyProject',
            'saveProjectBeforeSubmit',
            'showTips',
            'toggleResetDiyTips',
            'resetDiy',
            'backToPrevious',
            'backToPreviousCallBack',
            'handleOpenActivitySuccessModal',
            'handleRetryActivity',
            'handleCloseActivitySuccessModal',
            'handleShowPanel',
            'handleOpenVideoModal',
            'handleCloseVideoModal',
            'handleDoneActivity',
            'saveAndSettle',
            'settle'
        ]);
        this.initVMJSON = null;
        this.isDiy = true;
        this.manualSave = debounce(this.props.onClickSave.bind(this), 300);
    }

    componentDidMount() {
        const {lessonId, packageId, classId, proId, actId} = getParams();
        const keyString = `${proId}_${actId}_${lessonId}_${classId}_${packageId}`;

        WebStorage.pop(keyString).then(data => {
            if (data) {
                const storageData = JSON.parse(data);
                this.trackData = {...this.trackData, ...storageData};
            }
        });

        this.recordTime();

        if (this.props.account.info.userType == Teacher) {
            // 教师端直接跳转至IDE项目
            const path = DETECT.isJunior ? '/junior' : '';
            location.replace(
                `${location.protocol}//${location.host}${path}?pid=${
                    getParams().proId
                }&edit=0`
            );
        }

        getLessonInfo()
            .then(data => {
                const activity = setActivity(data, getParams());
                const lessonInfo = setLesson(data);
                // 这里的activity主要用于储存diy信息，如是否有video和班主任
                this.setState({lessonInfo, activity});
            })
            .then(() => {
                this.loadDiyProject();
            });

        locationBuilder.searchQuery('classroom_type') !== 'afterclass' &&
            autoSubscribe(classId);

        if (this.props.hasDiyGuide) {
            this.props.onOpenCustomGuide();
        }
    }

    componentDidUpdate(preProps) {
        if (this.props.isShowingWithId && !preProps.isShowingWithId) {
            if (!this.initVMJSON) {
                this.initVMJSON = this.props.vm.toJSON();
                /* eslint-disable react/no-did-update-set-state */
                this.setState({diyIsReady: true}, () => {
                    if (+getParams().diyinh === 0) {
                        Modal.warning({
                            title: '提示',
                            content: (
                                <p>
                                    当前为系统默认代码，因为没有完成项目课中的上一节课。下课后，记得去完成哦
                                </p>
                            ),
                            okText: '我知道了'
                        });
                    }
                });
            }

            // If project save successfully
            if (!this.props.error) {
                this.showTips('saved in cloud');

                if (this.isDiySubstepSaving) {
                    this.isDiySubstepSaving = false;
                    this.settle();
                } else if (this.isSavingBeforeSubmit) {
                    // 跳转视频发布/提交页
                    const {
                        proId,
                        actId,
                        classId,
                        packageId,
                        lessonId
                    } = getParams();
                    const DESTINATION_URL = locationBuilder.buildGuiDIYVideoSubmitUrl(
                        proId,
                        classId,
                        actId,
                        lessonId,
                        packageId
                    );
                    window.onbeforeunload = null;
                    let goto_url = DESTINATION_URL;
                    const {shouldOpenL0Guide, closeL0GuideTips} = this.props;
                    if (shouldOpenL0Guide) {
                        goto_url += '&showGuide=1';
                        closeL0GuideTips();
                    }
                    location.href = goto_url;
                }
            }
        }
    }

    componentWillUnmount() {
        this.timerId && clearTimeout(this.timerId);
        this.recordTimer && clearInterval(this.recordTimer);
    }

    recordTime() {
        let time = 0;
        const {actId, classId, lessonId, packageId} = getParams();
        // WebStorage.delete(`DIY-RECORD-${getParams().actId}`);
        WebStorage.get(`DIY-RECORD-${actId}`).then(duration => {
            if (duration) {
                time = parseInt(duration, 10) || 1;
            } else {
                // 否则请求已经记录的时间
                UModel.getDiyTime({actId, packageId, lessonId, classId}).then(
                    data => {
                        if (!data) {
                            data = {time: 1};
                        }
                        if (data.time) {
                            time = parseInt(data.time, 10) || 1;
                            WebStorage.set(`DIY-RECORD-${actId}`, time);
                        }
                    }
                );
            }
        });
        this.recordTimer = setInterval(() => {
            time += 5;
            WebStorage.set(`DIY-RECORD-${actId}`, time);
        }, 5000);
    }

    loadDiyProject(isDiyReset) {
        // 教研id 与项目id 保持一致，则进行初始化保存
        if (this.props.account) {
            if (isDiyReset) {
                this.showTips('diy restart');
            }
        }

        if (localStorage.getItem('pop-data')) {
            window.onbeforeunload = null;
            this.handleOpenActivitySuccessModal();
        }
    }

    showTips(txt = '') {
        this.props.onShowSaveTipsOnce(i18n.get(txt));
    }

    saveProjectBeforeSubmit() {
        this.isSavingBeforeSubmit = true;
        this.timerId && clearTimeout(this.timerId);
        this.props.autoUpdateProject();
    }

    toggleResetDiyTips() {
        this.props.onToggleResetTips();
    }

    resetDiy() {
        this.props.vm.stopAll();

        getProjectById(getParams().teacherDiyProjectId)
            .then(info => getProjectJson(info.asset_id))
            .then(data => {
                this.props.vm
                    .loadProject(data)
                    .then(() => {
                        this.props.autoUpdateProject();
                    })
                    .then(() => {
                        this.showTips('重置成功！');
                    })
                    .catch(err => {
                        this.showTips(err && err.message);
                    });
            });

        this.toggleResetDiyTips();
    }

    backToPrevious() {
        const isProjectChanged = this.initVMJSON !== this.props.vm.toJSON();
        const {actId, classId, lessonId, packageId} = getParams();
        UModel.getDiyTime({actId, packageId, lessonId, classId}).then(data => {
            if (!data) {
                // 从未提交过作业
                this.props.showLeaveWarning({
                    title: isProjectChanged ? '作品未提交' : '作品未完成',
                    content: isProjectChanged
                        ? '你在DIY关卡的作品还没提交，将无法通关。快提交给老师看看吧~'
                        : '你在DIY关卡的作品还没完成，将无法通关。先去完成作品吧~',
                    confirmBtn: isProjectChanged ? '马上提交' : '继续完成',
                    cancelBtn: isProjectChanged ? '先不提交' : '先不完成',
                    handleCancel: this.backToPreviousCallBack,
                    handleConfirm: isProjectChanged
                        ? this.saveProjectBeforeSubmit
                        : this.props.hideLeaveWarning
                });
            } else if (isProjectChanged) {
                this.props.showLeaveWarning({
                    title: '修改未提交',
                    content: '本次修改还没提交给老师呢，先提交给老师看看吧~',
                    confirmBtn: '马上提交',
                    cancelBtn: '先不提交',
                    handleCancel: this.backToPreviousCallBack,
                    handleConfirm: this.saveProjectBeforeSubmit
                });
            } else {
                this.backToPreviousCallBack();
            }
        });
    }

    backToPreviousCallBack() {
        setTimeout(() => {
            if (Detect.from && Detect.from === CustomConfig.bellplanet) {
                const msgInfo = {
                    key: CustomConfig.postMsgId.closeDiy,
                    value: true
                };
                if (window.parent) {
                    window.parent.postMessage(msgInfo, '*');
                } else {
                    window.postMessage(msgInfo, '*');
                }
                console.log('bell-planet-go-back');
                return;
            }
            location.href = getParams().cbUrl;
        }, 0);
    }
    saveAndSettle() {
        this.timerId && clearTimeout(this.timerId);
        this.isDiySubstepSaving = true;
        this.props.autoUpdateProject();
    }

    settle() {
        const id = getParams().proId;
        const activityResult = getActivityResult({...this.state.activity});
        const classId = getParams().classId;
        return new Promise((resolve, reject) => {
            const params = {
                id: id,
                name: this.props.projectTitle,
                description: 'abababab',
                tags: [1],
                share: 1
            };
            publishProject(params).then(() => {
                sumbitDiyProject(classId, id)
                    .then(() => {
                        WebStorage.get(`DIY-RECORD-${getParams().actId}`).then(
                            costTime => {
                                AModel.reportActivityComplete({
                                    costTime
                                }).then(data => {
                                    if (
                                        Detect.from &&
                                        Detect.from === CustomConfig.bellplanet
                                    ) {
                                        const diyData = {
                                            score: data.score,
                                            timeConsume:
                                                data.pop_up && data.pop_up.time
                                        };
                                        const info = {
                                            key:
                                                CustomConfig.postMsgId
                                                    .diySettle,
                                            value: diyData
                                        };
                                        if (window.parent) {
                                            window.parent.postMessage(
                                                info,
                                                '*'
                                            );
                                        } else {
                                            window.postMessage(info, '*');
                                        }
                                    }
                                    DialogTracker.trackFirstCheck(
                                        data,
                                        this.trackData
                                    );
                                    this.setState(
                                        {
                                            isActivitySuccessModalVisible: true,
                                            activity: update(
                                                this.state.activity,
                                                {
                                                    isCompleted: {
                                                        $set: true
                                                    },
                                                    result: {
                                                        $set: activityResult
                                                    },
                                                    userActivityInfo: {
                                                        $set: {
                                                            goldNum:
                                                                activityResult.goldNum,
                                                            codeNum:
                                                                activityResult.codeNum
                                                        }
                                                    }
                                                }
                                            ),
                                            popData: data
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                });
                            }
                        );
                    })
                    .catch(err => {
                        this.showTips(
                            i18n.get(
                                'Failed to complete project, please retry it later.'
                            )
                        );
                        log.log(err);
                        reject(err);
                    });
            });
        });
    }

    handleOpenActivitySuccessModal() {
        const id = getParams().proId;
        const activityResult = getActivityResult({...this.state.activity});
        const classId = getParams().classId;

        return new Promise((resolve, reject) => {
            const params = {
                id: id,
                name: this.props.projectTitle,
                description: 'abababab',
                tags: [1],
                share: 1
            };
            publishProject(params).then(() => {
                sumbitDiyProject(classId, id)
                    .then(() => {
                        if (!this.state.activity.isCompleted) {
                            reportActivityComplete();
                        }
                    })
                    .then(() => {
                        const popData = JSON.parse(
                            localStorage.getItem('pop-data')
                        );
                        localStorage.removeItem('pop-data');
                        if (
                            Detect.from &&
                            Detect.from === CustomConfig.bellplanet
                        ) {
                            const diyData = {
                                score: popData.score,
                                timeConsume:
                                    popData.pop_up && popData.pop_up.time
                            };
                            const info = {
                                key: CustomConfig.postMsgId.diySettle,
                                value: diyData
                            };
                            if (window.parent) {
                                window.parent.postMessage(info, '*');
                            } else {
                                window.postMessage(info, '*');
                            }
                        }
                        DialogTracker.trackFirstCheck(popData, this.trackData);
                        this.setState(
                            {
                                isActivitySuccessModalVisible: true,
                                activity: update(this.state.activity, {
                                    isCompleted: {$set: true},
                                    result: {$set: activityResult},
                                    userActivityInfo: {
                                        $set: {
                                            goldNum: activityResult.goldNum,
                                            codeNum: activityResult.codeNum
                                        }
                                    }
                                }),
                                popData
                            },
                            () => {
                                resolve();
                            }
                        );
                    })
                    .catch(err => {
                        this.showTips(
                            i18n.get(
                                'Failed to complete project, please retry it later.'
                            )
                        );
                        log.log(err);
                        reject(err);
                    });
            });
        });
    }

    handleRetryActivity() {
        DialogTracker.trackFirstRestart(this.state.popData, this.trackData);
        this.handleCloseActivitySuccessModal();
    }

    handleCloseActivitySuccessModal() {
        this.setState({
            isActivitySuccessModalVisible: false,
            activity: update(this.state.activity, {
                result: {$set: null}
            })
        });
    }

    handleDoneActivity() {
        const cb = () => {
            if (Detect.from && Detect.from === CustomConfig.bellplanet) {
                const msgInfo = {
                    key: CustomConfig.postMsgId.closeDiy,
                    value: true
                };
                if (window.parent) {
                    window.parent.postMessage(msgInfo, '*');
                } else {
                    window.postMessage(msgInfo, '*');
                }
                return;
            }
            location.href = getParams().cbUrl || generalConfig.HomeDomain;
        };

        DialogTracker.trackFirstNext(this.state.popData, this.trackData, cb);
    }

    handleShowPanel() {
        IntercomHandler.showPanel({
            ...UserData.userInfo,
            ...this.state.activity.params
        });
    }

    handleOpenVideoModal() {
        this.setState({
            isVideoModalVisible: true
        });
    }

    handleCloseVideoModal() {
        this.setState({
            isVideoModalVisible: false
        });
    }

    render() {
        const {classId, lessonId} = getParams();
        const componentProps = {
            actorHighlight: this.props.actorHighlight,
            actorName: this.props.actorName,
            activity: this.state.activity,
            backToPrevious: this.backToPrevious,
            hasDiyGuide: this.props.hasDiyGuide,
            handleCloseVideoModal: this.handleCloseVideoModal,
            handleDoneActivity: this.handleDoneActivity,
            handleOpenVideoModal: this.handleOpenVideoModal,
            handleRetryActivity: this.handleRetryActivity,
            handleShowPanel: this.handleShowPanel,
            isUpdating: this.props.isUpdating,
            isActivitySuccessModalVisible: this.state
                .isActivitySuccessModalVisible,
            isVideoModalVisible: this.state.isVideoModalVisible,
            lessonInfo: this.state.lessonInfo,
            manualSave: this.manualSave,
            popData: this.state.popData,
            resetDiy: this.resetDiy,
            saveProjectBeforeSubmit: this.saveProjectBeforeSubmit,
            shouldShowLeaveWarning: this.props.activity.leaveWarning
                .shouldShowLeaveWarning,
            leaveWarningConfig: this.props.activity.leaveWarning
                .leaveWarningConfig,
            tips: this.props.activity.tips,
            customGuideVisible: this.props.customGuideVisible,
            onOpenCustomGuide: this.props.onOpenCustomGuide,
            toggleResetDiyTips: this.toggleResetDiyTips,
            diyPre: this.state.diyPre,
            saveAndSettle: this.saveAndSettle
        };

        return (
            <React.Fragment>
                <ActivityMenuBar {...componentProps} />
                {this.props.customGuideVisible && (
                    <CustomGuideCards
                        vm={this.props.vm}
                        guideType={guideTypes.DIY}
                        onSubmit={this.saveProjectBeforeSubmit}
                    />
                )}
                {this.state.diyIsReady && checkIsToCUser() && (
                    <ContactTeacher
                        classId={classId}
                        lessonId={lessonId}
                        proId={this.props.reduxProjectId}
                        pageType={ContactTeacherPages.DIY}
                    />
                )}
            </React.Fragment>
        );
    }
}

DiyMenuBar.propTypes = {
    account: PropTypes.shape({
        info: PropTypes.shape({
            nickname: PropTypes.string,
            avatar: PropTypes.string
        }),
        authenticated: PropTypes.bool.isRequired
    }),
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isShowingWithId: PropTypes.bool,
    isUpdating: PropTypes.bool
};

const mapStateToProps = state => ({
    actorHighlight: state.activity.customGuide.actorHighlight,
    actorName: state.activity.customGuide.actorName,
    account: state.scratchGui.account,
    activity: state.activity,
    error: state.scratchGui.projectState.error,
    isShowingWithId: getIsShowingWithId(
        state.scratchGui.projectState.loadingState
    ),
    isUpdating: getIsUpdating(state.scratchGui.projectState.loadingState),
    vm: state.scratchGui.vm,
    projectTitle: state.scratchGui.projectTitle,
    customGuideVisible: state.activity.customGuide.visible,
    hasDiyGuide: state.activity.tutorial.tutorialInfo !== null,
    reduxProjectId: state.scratchGui.projectState.projectId,
    shouldOpenL0Guide: state.diy.l0Shouldopen
});

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onOpenAccountModal: () => dispatch(openAccount()),
    onShowSaveTipsOnce: text => dispatch(showSaveTipsOnce(text)),
    onToggleResetTips: () => dispatch(toggleResetTips()),
    showLeaveWarning: config => dispatch(showLeaveWarning(config)),
    hideLeaveWarning: () => dispatch(hideLeaveWarning()),
    onOpenCustomGuide: () => dispatch(openCustomGuide()),
    closeL0GuideTips: () => dispatch(closeL0Guide())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(subscribeForbiddenShade(DiyMenuBar));
