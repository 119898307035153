/* eslint-disable import/no-cycle */
import qa from './qaInject';
import loadCommands from './commands';
import locationBuilder, { getHttpURL } from './locationBuilder';

const hideHeader = locationBuilder.searchQuery('hide_header');
// 云课堂隐藏头部
const isHideHeader = hideHeader === '1';

// 判断是否嵌套在Electron客户端中
const isNestedInElectron = window.navigator.userAgent.indexOf('Electron') > -1;

let defaultLoginPath = '#/login';

let baseDomain;
let cookieDomain;
let loginPath;
let apiOrigin;
let homeOrigin;
let ideOrigin;
let guiOrigin;
let tcOrigin;
let lrOrigin;
let resourceOrigin;
let paymentOrigin;
let beboApiOrigin;
let liveBellCodeHost;
let H5Origin;
let bellplanetIndexURL;
let offcialOrigin;
let bellplanetEnv = 'ready';
let evaluateReportURL; // 测评报告地址
let bellPlanetWorldGameServer;
let accountServer;
let serverListServer;
let uploadLogServer;
let onlineCloudApiOrigin;

if (__DEV__) {
  baseDomain = 'wkcoding.com';
  cookieDomain = baseDomain;
  apiOrigin = `//testapi.${baseDomain}/`;
  homeOrigin = `//testcm.${baseDomain}/`;
  ideOrigin = `//testide.${baseDomain}/`;
  tcOrigin = `//testtc.${baseDomain}/`;
  guiOrigin = `//gui.test.${baseDomain}/`;
  paymentOrigin = `//h5.test.${baseDomain}/payment/`;
  liveBellCodeHost = 'live.wkcoding.com';
  H5Origin = `//h5.test.${baseDomain}/`;
  bellplanetIndexURL = 'bellplanet.wkcoding.com';
  offcialOrigin = 'https://official.bellcode.com/';
  evaluateReportURL = 'https://learning-report.demo.wkcoding.com/index.html';
  loginPath = defaultLoginPath;
  bellPlanetWorldGameServer = 'bg01.bellcode.com/app58475052022104065:3211';
  accountServer = 'ready-account.wkcoding.com';
  serverListServer = 'ready-server-list.wkcoding.com';
  uploadLogServer = 'ready-clientlog.wkcoding.com';
  onlineCloudApiOrigin = 'https://bellonlinetest.bell.ai/offline/v1';
}

if (__DEMO__) {
  baseDomain = 'wkcoding.com';
  cookieDomain = baseDomain;
  apiOrigin = `//demoapi.${baseDomain}/`;
  homeOrigin = `http://democm.${baseDomain}/`;
  ideOrigin = `http://demoide.${baseDomain}/`;
  tcOrigin = `http://demotc.${baseDomain}/`;
  guiOrigin = `http://gui.demo.${baseDomain}/`;
  lrOrigin = `http://learning-report.demo.${baseDomain}/`;
  paymentOrigin = `http://h5.demo.${baseDomain}/payment/`;
  liveBellCodeHost = 'live.wkcoding.com';
  H5Origin = `//h5.demo.${baseDomain}/`;
  bellplanetIndexURL = '//bg-stage.wkcoding.com/readyTest/index.html';
  offcialOrigin = 'https://official.bellcode.com/';
  evaluateReportURL = 'https://learning-report.demo.wkcoding.com/index.html';
  loginPath = defaultLoginPath;
  bellPlanetWorldGameServer = 'bg01.bellcode.com/app58475052022104065:3211';
  accountServer = 'ready-account.wkcoding.com';
  serverListServer = 'ready-server-list.wkcoding.com';
  uploadLogServer = 'ready-clientlog.wkcoding.com';
  onlineCloudApiOrigin = 'https://bellonlinetest.bell.ai/offline/v1';
}

if (__PRO__) {
  baseDomain = 'bellcode.com';
  cookieDomain = baseDomain;
  apiOrigin = `//api.${baseDomain}/`;
  homeOrigin = `https://www.${baseDomain}/`;
  ideOrigin = `https://ide.${baseDomain}/`;
  tcOrigin = `https://tc.${baseDomain}/`;
  guiOrigin = `https://gui.${baseDomain}/`;
  lrOrigin = `https://learning-report.${baseDomain}/`;
  paymentOrigin = `https://h5.${baseDomain}/payment/`;
  liveBellCodeHost = 'live.bellcode.com';
  H5Origin = `https://h5.${baseDomain}/`;
  bellplanetIndexURL = '//bg-stage.bellcode.com/index.html';
  offcialOrigin = 'https://official.bellcode.com/';
  bellplanetEnv = 'release';
  evaluateReportURL = 'https://learning-report.bellcode.com/index.html';
  loginPath = defaultLoginPath;
  bellPlanetWorldGameServer = 'app.wkcoding.com';
  accountServer = 'account.wkcoding.com';
  serverListServer = 'server-list.wkcoding.com';
  uploadLogServer = 'clientlog.wkcoding.com';
  onlineCloudApiOrigin = 'https://bellcode.bell.ai/api';
}

if (__QA__) {
  qa.setup();
  loadCommands();

  const apiOriginFromUri = locationBuilder.searchQuery('apiOrigin');

  if (apiOriginFromUri) {
    apiOrigin = decodeURIComponent(apiOriginFromUri);
  }

  const config = qa.load(
    apiOrigin,
    baseDomain,
    ideOrigin,
    homeOrigin,
    guiOrigin,
  );
  // let {apiOrigin, baseDomain, ideOrigin, guiOrigin} = config;
  apiOrigin = config.apiOrigin; // eslint-disable-line
  baseDomain = config.baseDomain; // eslint-disable-line
  homeOrigin = config.siteOrigin;
  ideOrigin = config.ideOrigin; // eslint-disable-line
  guiOrigin = config.guiOrigin; // eslint-disable-line
}

cookieDomain = baseDomain;

resourceOrigin = 'https://img.wkcoding.com/'; // eslint-disable-line
beboApiOrigin = 'https://liveapi1.bell.ai/'; // eslint-disable-line

if (window.bellcodeGlobalSettings) {
  const settingsOrigins = window.bellcodeGlobalSettings.settingsOrigins;
  if (settingsOrigins) {
    baseDomain = settingsOrigins.baseDomain;
    cookieDomain = settingsOrigins.cookieDomain;
    apiOrigin = settingsOrigins.apiOrigin;
    homeOrigin = settingsOrigins.homeOrigin;
    ideOrigin = settingsOrigins.ideOrigin;
    tcOrigin = settingsOrigins.tcOrigin;
    guiOrigin = settingsOrigins.guiOrigin;
    lrOrigin = settingsOrigins.lrOrigin;
    paymentOrigin = settingsOrigins.paymentOrigin;
    liveBellCodeHost = settingsOrigins.liveBellCodeHost;
    H5Origin = settingsOrigins.H5Origin;
    bellplanetIndexURL = settingsOrigins.bellplanetIndexURL;
    offcialOrigin = settingsOrigins.offcialOrigin;
    bellplanetEnv = settingsOrigins.bellplanetEnv;
    evaluateReportURL = settingsOrigins.evaluateReportURL;
  }
  if (baseDomain.indexOf('icodeworld') > -1) {
    defaultLoginPath = '#/i_code_world';
  }
  loginPath = window.bellcodeGlobalSettings.loginPath || defaultLoginPath;
}

// 去除末尾的 `/`
apiOrigin = apiOrigin.replace(/\/$/, '');

/**
 * 构建完整 api 地址
 */
const api = endpoint => `${apiOrigin}/${endpoint.replace(/^\//, '')}`;

const getBellPlanetDownloadURL = () => {
  const OS = (function () {
    const UserAgent = navigator.userAgent.toLowerCase();
    return {
      isIpad: /ipad/.test(UserAgent),
      isIphone: /iphone os/.test(UserAgent),
      isAndroid: /android/.test(UserAgent),
      isWindowsCe: /windows ce/.test(UserAgent),
      isWindowsMobile: /windows mobile/.test(UserAgent),
      isWin2K: /windows nt 5.0/.test(UserAgent),
      isXP: /windows nt 5.1/.test(UserAgent),
      isVista: /windows nt 6.0/.test(UserAgent),
      isWin7: /windows nt 6.1/.test(UserAgent),
      isWin8: /windows nt 6.2/.test(UserAgent),
      isWin81: /windows nt 6.3/.test(UserAgent),
      isWin: /windows/.test(UserAgent),
      isMac: /mac os/.test(UserAgent),
    };
  }());

  let platform = 'win32';

  if (OS.isMac) {
    platform = 'mac';
  }

  const url = getHttpURL(
    `//wplanet.wkcoding.com/entrance/${bellplanetEnv}/nativeDownload`,
  );
  url.searchParams.set('platform', platform);

  // / TODO hack
  // / 应该改成品牌定制 去定制 特定品牌应该下载客户端内容.
  if (
    ['www.icodeworld.cn', 'www.wukonglab.cn'].includes(window.location.host)
  ) {
    url.searchParams.set('bellBrandType', 'world');
  }

  const jingxiaoziDownloadUrlMaps = {
    mac:
      'https://bg-stage.wkcoding.com/%E9%B2%B8%E5%B0%8F%E5%AD%90%E7%BC%96%E7%A8%8B-0.0.200.dmg',
    win32:
      'https://bg-stage.wkcoding.com/%E9%B2%B8%E5%B0%8F%E5%AD%90%E7%BC%96%E7%A8%8B%20Setup%200.0.200.exe',
  };

  // 鲸小子临时下载
  // TODO 支持通过webconfig 读取
  if (['whale.icodeworld.cn'].includes(window.location.host)) {
    return new URL(jingxiaoziDownloadUrlMaps[platform]);
  }

  return url;
};

const bellplanetDownloadURL = getBellPlanetDownloadURL();

export default {
  baseDomain,
  cookieDomain,
  loginPath,
  apiOrigin,
  ideOrigin,
  homeOrigin,
  bellplanetDownloadURL,
  HomeDomain: homeOrigin,
  H5Domain: H5Origin,
  bellplanetIndexURL,
  resourceOrigin: 'https://img.wkcoding.com/',
  WechatDomain: `${homeOrigin}wechat_index.html`,
  otherResourceOrigin: 'https://other.wkcoding.com/',
  projectResourceOrigin: 'https://project.wkcoding.com/',
  videoResourceOrigin: 'https://cm-video.wkcoding.com/',
  offcialOrigin,
  evaluateReportURL,
  bellPlanetWorldGameServer,
  accountServer,
  serverListServer,
  uploadLogServer,
  onlineCloudApiOrigin,
  serverIo: {
    // ide
    getProjectById: pid => api(`v3/common/project/project-info?proid=${pid}`),
    saveProject: api('common/project/save-project'),

    // homework
    get_homework_of_teacher: api('common/homework.get'),
    getHomework: api('common/homework/info-by-id'),
    completeHomework: api('common/homework/complete'),
    getMedals: api('common/project/select-medal'),
    getRewards: api('common/project/select-reward'),
    receiveReward: api('common/reward/receive'),
    applyMedals: api('common/project/apply-for-medal'),
    homeworkReview: api('teacher/homework/approval'),
    homeworkCommentIsRead: api('common/homework.read'),
    getRecordedMediaGuide: api('common/diy/get-recorded-media-guide'),

    uname_check: api('common/member/username-check'),
    phone_check: api('common/member/mobile-check'),
    send_code: api('common/service/sms-code'),
    register: api('common/member/register'),
    changePassword: api('common/member/modify-pwd'),
    login: api('common/member/login'),
    completeRegistration: api('common/member/complete-registration'),
    getTemporayToken: api('common/member/temporay-token.get'),

    get_stu_classes: api('student/index/all-class'),
    get_stu_projects: api('student/project/all-project'),
    get_stu_tc_projects: api('student/text-coding/all-tc'),
    get_tea_projects: api('teacher/project/all-project'),
    get_tea_tc_projects: api('teacher/text-coding/all-tc'),
    get_stu_clss_projects: api('student/project/class-project'),
    get_stu_clss_tc_projects: api('student/text-coding/class-tc'),
    get_assigned_projects: api('teacher/project/class-project'),
    get_assigned_tc_projects: api('teacher/text-coding/class-tc'),
    get_stu_homework: api('student/lesson/select-homework'),
    remove_stu_project: api('student/project/remove-project'),
    remove_stu_tc_project: api('student/text-coding/remove-tc'),
    remove_tea_project: api('teacher/project/remove-project'),
    remove_tea_tc_project: api('teacher/text-coding/remove-tc'),
    get_lessons: api('teacher/lesson/lesson-list'),
    get_lesson_code_list: api('v2/lesson-code/code.list'),
    manual_release_class_code: api('v2/lesson-code/code.manual-ship'),
    tob_generate_class_code: api('v2/lesson-code/code.tob-free-ship'),
    tob_teacher_lesson_statistics: api('school/index/lesson.statistics'),
    tob_org_license: api('school/index/license.get'),
    // 下载消key列表
    exportEliminateKeyList: api('/school/index/license.download'),
    getElimination: api('school/lesson/elimination-statistics.get'),
    get_order_info: api('v2/lesson-code/order.ship-preview'),
    update_lesson_code_status: api('v2/lesson-code/code.update-status'),
    get_qr_code: api('v2/lesson-code/code.get-qr-code'),
    get_free_order_info: api('v2/lesson-code/order.free-preview'),
    release_free_class_code: api('v2/lesson-code/code.free-ship'),
    join_class: api('student/index/join-class'),
    assign_project: api('teacher/project/assign'),
    assign_tc_project: api('teacher/text-coding/assign'),
    unassign_project: api('teacher/project/unsign'),
    get_tea_classes: api('teacher/index/all-class'),
    get_tea_online_classes: api('teacher/index/all-online-class'),
    create_classroom: api('teacher/index/create-class'),
    create_online_classroom: api('teacher/index/create-online-class'),
    get_students_by_classid: api('teacher/index/all-student'),
    get_stu_rank_by_classid: api('teacher/student/get-rank'),
    stu_set_instructor: api('teacher/index/set-instructor'),
    batch_stu_set_instructor: api('teacher/student/batch-set-instructor'),
    get_stu_detail: api('teacher/student/detail'),
    get_stu_lesson_progress: api(
      'teacher/student/get-lesson-progress-by-class-id',
    ),
    set_stu_ban: api('teacher/index/set-ban'),
    update_stu_remark: api('teacher/student/update-profile'),
    get_class_info: api('teacher/index/class-info'),
    get_class_name: api('teacher/index/class.name'),
    search_class_info: api('lesson-code/index/search-class-info'),
    edit_class_remark: api('teacher/index/edit-class'),
    get_stu_class_info: api('student/index/class-info'),
    update_class_info: api('teacher/index/class-info'),
    remove_class: api('teacher/index/remove-class'),
    remove_student: api('teacher/index/remove-student'),
    stu_class_lesson: api('student/lesson/lesson-list'),
    stu_online_class_lesson: api('student/index/online-class-lesson'),
    stu_offline_class_lesson: api('student/lesson/offline-lesson-list'),
    report_online_class_lesson: api(
      'student/statistics/report-online-class-lesson',
    ),
    tea_lesson_plan: api('teacher/activity.list'),
    tea_lesson_act_info: api('teacher/lesson/class-lesson-act-info'),
    get_activitys_by_lesson: api('common/activity/get-activitys-by-lesson'),
    get_lesson_info: api('common/lesson.info'),
    stu_lesson_plan: api('student/lesson/lesson-info'),

    // 布置单个课程
    tea_assign_lesson: api('teacher/lesson/assign-lesson'),
    // 布置多个课程
    tea_batch_assign_lesson: api('teacher/lesson/batch-assign-lesson'),

    tea_unassign_lesson: api('teacher/lesson/unsign-lesson'),
    tea_class_lesson: api('v2/teacher/lesson/class-lesson'),
    set_lesson_open_time: api('v2/teacher/lesson/class-lesson/opentime.set'),
    batch_set_lesson_open_time: api(
      'v2/teacher/lesson/class-lesson/opentime.batch',
    ),

    class_upgrade_info: api('teacher/class.upgrade-info'),
    lesson_unsign_all: api('/teacher/lesson/lesson.unsign-all'),

    lesson_progress_management: api('teacher/index/lesson-progress-management'),
    open_lesson_for_user: api('teacher/index/open-lesson-to-user'),
    close_lesson_for_user: api('teacher/index/close-lesson-to-user'),
    finish_activity: api('student/activity/activity-complete'),
    skip_activity: api('/student/activity/skip'),
    finish_lesson: api('teacher/index/report-lesson-schedule'),
    auto_assign_bellplanet: api(
      '/common/activity.autoAssignBellPlanetActivity',
    ),
    // 课后管理
    tea_afterclass: api('/teacher/student/lesson'),
    // 课后作业
    tea_afterclass_homework: api('/teacher/student/lesson/homework'),
    get_tea_progress: api('/teacher/lesson/progress.get'),
    update_tea_progress: api('/teacher/lesson/progress.update'),
    stu_concept_summary: api('teacher/concept/summary'),
    stu_chart_data: api('teacher/concept/detail'),
    class_stu_projects: api('teacher/project/project-list'),
    class_stu_tc_projects: api('teacher/text-coding/student-tc'),
    class_stu_list_with_pronum: api('teacher/index/class-student-project'),
    tea_reset_pwd: api('teacher/index/reset-stu-pwd'),
    pkg_list: api('promote/index/get-package-list'),
    pkg_info: api('promote/index/get-package-info'),
    get_work_list: api('creation/index/get-project-list'),
    cm_course: api('teacher/package/cm-pkg'),
    get_online_record_list: api('teacher/class/online-record-list'),
    get_online_live_list: api('teacher/class/online-live-list'),
    get_offline_list: api('teacher/class/offline-list'),
    create_online_class: api('teacher/class/create-online-class'),
    create_offline_class: api('teacher/class/create-offline-class'),
    tea_search_class: api('teacher/class/online-filter-class'),
    tea_search_user: api('teacher/class/online-query-student'),
    tea_get_if_first_lesson_published: api(
      'teacher/class/get-first-lesson-published',
    ),
    tea_set_publish_lesson_schedule: api(
      'teacher/class/auto-publish-lesson-settings',
    ),
    tea_get_publish_lesson_schedule: api(
      'teacher/class/get-auto-publish-lesson-settings',
    ),
    get_if_notified_parents: api(
      'teacher/class/first_published_wechat_notify.get',
    ),
    notify_parents_release_time: api(
      'teacher/class/first_published_wechat_notify.send',
    ),
    get_head_teachers_wechat: api('teacher/class/wechat-teacher.get'),
    get_package_online: api('teacher/package/online-list'),
    get_package_offline: api('teacher/package/offline-list'),
    assign_package: api('teacher/lesson/assign-package'),
    my_course: api('teacher/package/school-pkg'),
    getOfflineSchoolClass: api('teacher/class/offline-school-class'),
    pkg_lesson: api('common/package/pkg-lesson'),
    lesson_inclass_upgrade: api('common/package/lesson.inclass-upgrade'),

    getShareData: api('common/project/get-share-info'),
    getMobileShareData_Experience: api('h5/project/share/experience'),
    getMobileShareData_Spread: api('h5/project/share/spread'),
    getMobileShareData_Conversion: api('h5/project/share/conversion'),
    getTcShareData: api('tc-projects/index/get-share-info'),
    saveShareDesc: api('common/project/set-share-text'),
    likeShare: api('common/project/share-zan'),
    teacherComment: api('teacher/project/project-comment'),

    uploadQiniu: '//up-z2.qbox.me/putb64/-1',
    get_qiniu_token: api('common/upload/get-token'),
    tea_create_stu_account: api('teacher/index/create-student'),
    get_profile: api('v3/common/member/init'),
    update_profile: api('common/member/update-personal-info'),
    get_card_list: api('game_card/index/get-card-list'),
    draw_card: api('game-card/index/draw-card'),

    get_org_members: api('school/index/all-teacher'),
    get_all_instructor: api('/teacher/index/get-instructor'),
    add_instructor: api('/teacher/index/add-instructor-to-class'),
    get_instructor_by_class_id: api(
      '/teacher/index/get-instructor-by-class-id',
    ),
    enable_instructor_from_class: api(
      '/teacher/index/enable-instructor-from-class',
    ),
    disable_instructor_from_class: api(
      '/teacher/index/disable-instructor-from-class',
    ),
    change_teacher_info: api('/school/index/change-teacher-info'),
    create_member: api('school/index/create-teacher'),
    createMemberV2: api('school/index/teacher.create'),
    change_teacher_status: api('school/index/change-teacher-status'),
    change_teacher_passwd: api('school/index/change-teacher-passwd'),
    getTeacherQrCode: api('school/index/teacher.qrcode'),
    unbind_wechat: api('school/index/user.wechat-unbind'),
    getSalesData: api('school/index/experience-registration.list'),
    exportSalesData: api('school/index/experience-registration.export'),

    get_task_list: api('student/task/get-task-list'),
    get_reward: api('student/task/get-reward'),
    get_quiz_info: api('common/quiz/info'),
    post_quiz_result: api('student/quiz/report-quiz-info'),
    get_rank_list: api('common/rank/class-code-num-rank'),
    logout: api('common/member/logout'),

    apply_ex: api('common/teaching-site/sign-up'),
    tcc_time: api('activity/competition/time'),
    download_chrome: api('common/utils/get-chrome-link'),
    mark_project: api('student/project-plaza/collect'),

    getRankListForActivity: api('activity/rank/list'),
    getRankProjects: api('activity/rank/projects'),
    applyRank: api('activity/rank/apply'),
    getRankInfo: api('activity/rank/info'),
    getApplyInfo: api('activity/competition'),
    getCompetitionGroupList: api('activity/competition/group'),
    resetCompetitionExamStatus: api('activity/competition/reset'),
    setCompetitionStatus: api('activity/competition/group/status.set'),
    getQuizQuestions: api('activity/competition/questions'),
    getQuizResult: api('activity/competition/tob/result'),
    submitQuizAnswer: api('activity/competition/question.submit'),
    // 特定机构才能看到小贝星球报名入口
    canShowEntryPoint: api('/activity/bell-planet/can-show-entry-point'),

    get_msg_by_type: api('notify/message/get-by-type'),
    get_all_msg_type: api('notify/message/get-all-type'),
    get_total_unread_msg_num: api('notify/message/unread-total'),
    getUnreadStudyMessageCount: api(
      'notify/message/unread-study-message-count',
    ),
    getUnreadIsForceTotal: api('notify/message/unread-is-force-total'),
    mark_msg_as_read: api('notify/operation/read-message'),
    delete_msg: api('notify/operation/del-message'),
    receive_reward: api('common/reward/receive'),

    // 作品广场相关
    project_square_profile: api('common/member/profile'),
    project_square_game_card: api('common/member/game-card'),
    project_square_project_card: api('common/member/project'),
    project_square_list: api('common/project-plaza/list'),
    project_square_new_list: api('/common/project-plaza/new-list'),
    project_square_tags: api('common/project-plaza/tags'),
    project_square_self_project: api('student/project/management'),
    cancel_project_from_plaza: api('student/project/cancel-plaza'),
    reset_project_name: api('student/project/update-project-name'),
    update_profile_info: api('/common/member/update-profile-info'),
    publish_production: api('student/project-plaza/add'),
    getProjectTags: api('common/project-plaza/tags'),
    saveProjectName: api('student/project/update-project-name'),
    diyManagement: api('student/project/diy-management'),
    update_real_name: api('teacher/student/update-real-name'),
    update_index_real_name: api('v2/lesson-code/user.update-real-name'),
    give_out_gold_num_to_student: api(
      'teacher/index/give-out-gold-num-to-student',
    ),
    clear_project_media: api('common/project/clear-project-media'),

    // 分享页面相关
    getQiniuToken: api('common/upload/get-token'),
    qiniuUpload: '//up-z2.qbox.me/putb64/-1',
    sumbitDiyProject: api('common/project/submit-diy-project'),
    getTutorial: api('common/tutorial/get-info'),
    rewardGold: api('common/project/reward-gold-num-count'),
    saveProjectMedia: api('common/project/save-project-media'),
    activityComplete: api('student/activity/activity-complete'),

    // 全局相关 API
    settings: api('/v3/common/settings'),
    cheatSchool: api('/common/cheat-school'),
    get_recommended_project: api('common/project-plaza/recommended-project'),
    getCoupons: api('v3/common/settings'),

    // 品牌定制配置
    get_brands: api('/v3/common/brands/setting'),

    // DIY项目
    getDiyTutorialInfo: activityId => api(`common/diy/get-guide?activity_id=${activityId}`),
    getDiyTime: api('/common/activity/complete.info'),
    checkIfL0: api('/common/guide/config.info'),
    getL0video: api('/v3/common/settings/L0_VIDEO'),

    // wechat
    getWxAuthUrl: api('thirdparty/wechat/get-wecaht-auth-url'),
    loginByCode: api('common/member/login-by-wx-code'),
    loginByToken: api('/common/member/login-by-temporary-token'),
    getWeChatShareConfig: api('common/service/get-share-info'),
    getH5LessonList: api('h5/v2/lesson/list'),
    getShellCount: api('h5/conch/info'),
    getRewardJigsaw: api('h5/lesson/reward-jigsaw'),
    clockIn: api('h5/lesson/clock-in'),
    prizeProgress: api('h5/lesson/prize-progress'),
    getReports: api('/h5/lesson/reports'),
    getSmsCode: api('common/service/get-sms-code'),
    submitRecordLesson: api('/v2/record-lesson/student-info.fill'),
    checkIfBuy: api('/v2/order/index/check-if-buy'),
    loginPC: api('thirdparty/wechat.login-pc'),
    joinClass: api('/student/class.join'),

    // puzzle2
    getPuzzle2ById: api('common/puzzle2/puzzle-info'),
    puzzle2PayForAnswer: api('student/puzzle2/get-answer'),

    // Gui-puzzle2
    getGuiPuzzle2ById: api('common/activity.info'),

    // bebo课堂直播相关
    createRoom: `${beboApiOrigin}room/createroom`,
    enterRoom: `${beboApiOrigin}room/enterroom`,
    uploadH5Url: `${beboApiOrigin}file/uploadH5Url`,
    attachFile: `${beboApiOrigin}file/attachfile`,
    getEncrypt: api('bell-bo/utils/get-encrypt'),
    getRoomList: `${beboApiOrigin}room/getroomlist`,
    getUserRecord: `${beboApiOrigin}record/userrecord `,
    clearBeboLiveMember: `${beboApiOrigin}system/push_clear_message`,
    updateRoom: `${beboApiOrigin}room/updateroom`,
    // video 答题
    get_questions_by_id: api('common/video/get-questions-by-video-id'),
    submit_question_option: api('common/video/complete'),

    // 教学管理平台

    // 新地图页:
    // 新地图页获取关卡列表
    getNewMapLesson: api('/common/activity/get-activitys-by-lesson'),
    // 获取完课结算信息汇总
    getLessonSummary: api('/student/lesson/summary-pop-up'),
    // 获取宝箱奖励接口
    getLessonReward: api('/student/lesson/reward'),
    // 查看课程总结汇总信息
    checkSummary: api('/student/lesson/look-summary'),
    // 领取完课结算宝箱
    obtainReward: api('/student/lesson/obtain-reward'),
    // 获取已解锁的关卡ids
    getUnlockedActivityIds: api('/common/activity.unlocked-activity.get'),

    getIDEResult: api('/common/activity/complete'),

    getSumInfo: api('/common/activity/complete.info'),

    // 远程存储
    webStorageGet: api('/v3/common/storage/get'),
    webStorageSet: api('/v3/common/storage/set'),
    webStorageHas: api('/common/storage/has'),
    webStorageUnset: api('/common/storage/unset'),
    webStoragePop: api('/common/storage/pop'),
    webStorageAll: api('/common/storage/all'),

    // 素材库
    getMaterial: api('/resource2/resource2.search'),
    getTag: api('/admin/resource2-category/list-category'),
    getLessonMsg: api('student/lesson-message.get'),

    getFaq: api('/common/faq.get'),

    openCourse: api('/teacher/lesson.publish-to-class'),
    replacePackage: api('/teacher/class/package.replace'),
    getPkgCategores: api('teacher/package.get-categores'),
    getPkgTypes: api('/teacher/package.get-types'),
    getPkgLevels: api('/teacher/package.get-levels'),

    // 获取课程包列表
    getPkg: api('/teacher/package.get'),
    // 获取课程包列表 v3 课程包升级
    getPkgV3: api('/teacher/package.getv3'),
    // 获取课程包课程列表
    getPackageLessonList: api('/teacher/package.lesson-map'),
    archiveClass: api('/teacher/class.archive'),
    unarchiveClass: api('/teacher/class.unarchive'),
    // 获取老师证书
    getTeacherCertificate: api('/teacher/certificate'),
    // 获取学生的班级列表
    getNewStuClassMap: api('/student/class.list'),

    // 获取课程消息
    getMesseageByLessonId: api('/notify/message/get-by-idx'),

    // 测试支持相关API
    // @link https://gitlab.com/bellcode/CodingMonkey/issues/328
    testSupport: {
      joinClass: api('/test-support/class.join'),
      publishNextLesson: api('/test-support/class.publish-next-lesson'),
    },
    // 获取课程包、班级、课程信息
    // @link https://gitlab.com/bellcode/CodingMonkey/issues/353
    getClassInfo: api('/v3/common/class.info'),
    getLessonInfo: api('/common/lesson.info'),
    getPackageInfo: api('/common/package.info'),

    // 学生班级信息
    studentClassInfo: api('/v3/common/class.info'),

    // 教师解锁指定班级指定关卡
    unlockToClass: api('/teacher/activity.unlocked-to-class'),

    // 代码块信息
    getBlocksInfo: api('/admin/blocks/get-blocks'),
    deleteBlockInfo: api('/admin/blocks.delete'),
    createOrUpdateBlockInfo: api('/admin/blocks.create-or-update'),

    // 考勤页
    getLessonPublished: api('/student/lesson/published.get'),
    checkInLesson: api('/notify/wechat/message.read'),
    getShiftClassInfo: api('/student/lesson/shift-class-info.get'),
    shiftClass: api('/student/lesson/shift-class'),
    // 从服务器获取当前准确时间
    getServerTime: api('/common/utils/get-server-time'),

    // wallet
    getBill: api('/wallet/bill.get'),

    // 奖励
    checkReward: api('/common/reward/yield'),
    receiveRewardV2: api('/common/reward/receive'),
    walletInfo: api('/common/member/wallet.info'),

    // 学生端：获取地图页排行榜实时数据
    getRealtimeRankingList: api('/student/lesson/ranking.get'),
    // 教师端：开启 / 关闭实时排行榜
    toggleRealTimeRankingStatus: api('/teacher/class/ranks.enable-or-disable'),
    // 获取实时排行榜开启 / 关闭状态
    getRealTimeRankingToggleStatus: api('/teacher/class/ranks.status'),
    // 教师端：获取学生 puzzle 进度信息
    getStudentsPuzzleProgress: api(
      '/teacher/class/lesson.get-challenge-progress',
    ),
    // 老师帮学生调班
    shiftClassForStudent: api('/teacher/index/shift-class'),
    // 获取班级期数
    getPeriodsInfo: api('/teacher/index/periods'),
    // 获取课程签到状态
    getAttendState: api('/teacher/class/attend-state.get'),
    attendStart: api('/teacher/class/attend.start'),
    // 完课报告
    getCompeleteCourseReport: api('/teacher/report/get-user-challenge-summary'),

    // 获取用户证书类型
    getTeacherAuth: api('/teacher/index/certification-package-types.get'),

    // tcc
    submitCompetitionInfo: api('/activity/minigame/rank/save-project'),
    submitCompetitionInfoDraft: api('/activity/competition/rank/apply/draft'),

    // 移动端: 清除通知
    clearNotification: api('/h5/v2/lesson/notification.read'),

    // 获取用户特定课程包类型的班级列表
    getClassListByPackage: api('/student/class.has-package-type'),
    // 选择品牌 id
    getBrands: api('/common/member/brands'),
    setBrandId: api('/common/member/set-brand'),
    // 获取机构学生信息
    getStudentList: api('/teacher/organization/get.stu-list'),
    // 批量学生加入班级
    batchJoinClass: api('/teacher/class/batch-join-class'),
    // 获取老师机构下增值课包列表
    getPaidPkgList: api('/teacher/organization/get.paid-pkg-list'),
    // 获取课程包tab类型
    getCategores: api('teacher/organization/get.categores'),
    // 获取老师所有班级数量
    getAllClassCount: api('/teacher/index/get-all-class-count'),
    // 通过班级id获取班级增值课包消key数据
    getExpendLicenseByclass: api(
      '/license/paid-pkg-license/get.expend-license-by-class',
    ),
    // 获取不同类型的机构key数据
    getExpendLicenseByscope: api(
      '/license/pkg-license/get.expend-license-by-scope',
    ),
    // 获取消key日志
    getExpendLicenseLogs: api('/school/index/expend-license-logs'),
    exportEliminateLessonsList: api('/school/index/download.all-teacher'),
    // 获取鲸幂课后作业信息
    getJimmyHomeworkInfo: api('/common/homework/info-jimmy'),
    // 获取host urls
    getHostUrls: api('/common/host-urls'),

    /* event-system start */
    // 获取赛事信息
    getContestInfo: api('/event-system/info'),
    // 获取用户报名状态
    getUserApplyStatus: api('/event-system/sign-up-status'),
    // 获取准考证
    getAdmissionTicket: api('/event-system/admission-ticket-info'),
    // 获取客服微信
    getServiceWechat: api('/event-system/get-service-wechat'),
    // 添加客服微信
    addServiceWechat: api('/event-system/add-service-wechat'),
    // 领取课包
    getCoursePackage: api('/event-system/draw-lesson_package'),
    // 获取比赛信息
    getRaceInfo: api('/event-system/race-info'),
    // 验证准考证信息
    checkAdmissionTicket: api('/event-system/check-admission-ticket'),
    // 获取模拟考试链接
    getSimulateLink: api('/event-system/race-info-property'),
    /* event-system end */
    // 获取指定教师学习报告统计数据
    getLearnReportList: api('/school/teacher/learn-report'),
    // 通过临时token获取班级列表
    getClassListByTemporaryToken: api(
      '/common/temporary-token/get-class-lists',
    ),
    // 通过临时token获取班级学生列表
    getStudentListByTemporaryToken: api(
      '/common/temporary-token/get-student-lists',
    ),
    // 通过临时token获取指定学生/老师的登录token
    getUserTokenByTemporaryToken: api(
      '/common/temporary-token/get-user-temporary-token',
    ),

    /* 编程世界主页 */
    // 获取banner列表
    getBannerList: api('/common/get-banner-list'),
    // 获取作品列表
    getProjectList: api('/common/get-project-list'),
    // 老师帮助学生跳过关卡
    skipStuCheckpoint: api('/teacher/lesson/activity.skip'),
    // 获取购买编程/进校key链接
    getPurchaseKeyUrl: api('/school/index/sku.get'),
    // 生成进校班级学生账号
    generateInschoolAccount: api('/teacher/class/generate-users'),
    // 获取进校班级学生账号创建进度
    getInschollClassGenerateAccountProgress: api(
      '/teacher/class/batch-create-user-info',
    ),
    // 删除学生
    deleteStu: api('/teacher/student/delete'),
    // 获取指定时间范围内机构老师生成学习报告的数据
    getOrgTeacherLRDetail: api(
      '/school/index/teacher-learning-report-statistics',
    ),
    // 班级锁管理
    manageClassLock: api('/teacher/class/locked.switch'),

    /* 云课堂课后作业 */
    postStuProjectIdToCloud: '/member/exercises/submit',
  },

  abilityRepo: '#/abilityRepo',

  jmrepo: '#/jmrepo',

  hrefPreStr: '/',

  hrefCmStr: '/cm/',

  hrefCmStuLessonMap: '/cm/stu_lesson_pan/',

  hrefMap: '/cm/stu_lesson_map/',

  hrefSequenceMap: '/cm/stu_lesson_sequence/',

  hrefGameStr: '/play/',

  hrefProfileStr: '/profile/',

  hrefWorksStr: '/works/',

  lrUrl: lrOrigin,

  detectUrl: `${homeOrigin}detect/v2/index.html`,

  ideDemoUrl: `${'https://ide.bellcode.com'}`,

  liveBellCodeHost,

  quizUrl: '/quiz',

  planetUrl: '/bell-planet',

  miniGameUrl: '/mini-game',

  teaQuizUrl: '/tea_quiz',

  paymentUrl: `${paymentOrigin}wechat_index.html`,

  // IDE
  ideUrl: ideOrigin,
  ideShareUrl: `${'https://ide.bellcode.com/share'}`,
  shareUrl: `${ideOrigin}share`,
  projectSumitUrl: `${ideOrigin}submit`, // 快速发布
  projectVideoSumitUrl: `${ideOrigin}videoSubmit`, // 录屏发布
  puzzleUrl: `${ideOrigin}puzzle`,
  puzzle2Url: `${ideOrigin}puzzle2`,

  // IDE Junior
  ideJuniorUrl: `${ideOrigin}junior`,
  juniorShareUrl: `${ideOrigin}juniorShare`,
  puzzleJuniorUrl: `${ideOrigin}juniorPuzzle`,

  // GUI
  guiUrl: guiOrigin,
  guiShareUrl: `${guiOrigin}share`,
  guiJuniorShareUrl: `${guiOrigin}junior-share`,
  guiProjectSumitUrl: `${guiOrigin}submit`, // 快速发布
  guiProjectVideoSumitUrl: `${guiOrigin}video-submit`, // 录屏发布
  guiHomeworkUrl: `${guiOrigin}homework`,
  guiDiyUrl: `${guiOrigin}diy`,
  guiPuzzle2Url: `${guiOrigin}puzzle2`,
  guiProxyByH5: `${H5Origin}gui/share`,
  guiJuniorProxyByH5: `${H5Origin}gui/junior-share`,
  guiPythonPuzzle: `${guiOrigin}python-puzzle`,
  guiUploadTcc: `${guiOrigin}tcc-upload`,
  guiPythonScratch: `${guiOrigin}python-scratch`,

  // GUI Junior
  guiJuniorUrl: `${guiOrigin}junior`,
  // GUI Junior DIY
  guiDiyJuniorUrl: `${guiOrigin}diy-junior`,

  // TC
  tcUrl: tcOrigin,
  tcShareUrl: `${tcOrigin}share`,
  tcPuzzleUrl: `${tcOrigin}puzzle`,

  headerbarHeight: isHideHeader ? 0 : 70,

  maxNicknameLength: 32,

  defaultAvatar: `${resourceOrigin}1499248056914`,
  isNestedInElectron,

  userTypeMap: {
    student: '1', // 学生
    teacher: '2', // 老师
    organization: '4', // 机构
    instructor: '5', // 机构指导员
  },

  // 小贝星球伪协议拉起状态
  bellPlanetFakeProtoStatus: {
    FAIL: 1, // 拉起失败
    SUCCESS: 2, // 拉起成功
    UNSUPPORT: 3, // 不支持拉起
    PULLING: 4, // 拉起中
  },

  // 拉起小贝星球的入口类型
  pullBellplanetEntranceType: {
    STUDENT_PERSPECTIVE_VIEW: 1, // 从学生视角查看
  },

  brandIdMap: {
    jimmy: 5, // 鲸幂
    icodeWorld: 19, // 编程世界
  },

  // 课包类型
  packageTypeMap: {
    Jimmy2Extend: 'jimmy2-extend', // 鲸幂2.0线下
  },

  schoolType: {
    ON_LINE: 1, // 线上
    OFF_LINE: 2, // 线下
  },

  hostIdMap: {
    DIRECT: 1, // 直营
    MAKCOO: 2, // 玛酷
    ICODEE: 3, // 加盟
    CODEWORLD: 4, // 编程世界
  },

  noticeTypeMap: {
    system: 1, // 系统通知
    created: 2, // 创作通知
    educational: 3, // 教务通知
  },

  mobileSharePageTypes: {
    experience: 'Experience',
    spread: 'Spread',
    conversion: 'Conversion',
  },

  loginListTypeMap: {
    TEACHER: 0,
    STUDENT: 1,
  },

  // 扫码微信的三种状态
  wechatQrState: {
    SUCCESS: 0, // 扫码登录/绑定成功
    UNBIND: 1, // 微信号已经被绑定
    USED: 2, // 微信登录信息已经被使用
    ORG_TEACHER: 3, // 机构老师辅助学生登录
    NOTBELONG: 4, // 用户不属于当前域名
  },

  // 账号密码登录 状态
  loginState: {
    ACTIVATED_TEACHER: 0, // 认证老师必须使用微信扫码登录
    ACCOUNT_ERROR: 1, // 账号密码错误
    GENERAL_ERROR: 2, // 通用错误状态
    NOTBELONG: 3, // 用户不属于当前域名
  },

  tokenType: {
    OVER_DUE: 0, // //临时登录码已经失效的情况: code: 20000
  },

  publishType: {
    SQUARE: 0,
    DIY: 1,
  },

  // 地图页的类型
  activityType: {
    video: 1, // 视频
    puzzle: 2, // 题库
    project: 3, // 课程
    treasure: 4, // 宝箱
    quiz: 5, // 测试题类型
    tc_ide: 6, // text coding的ide
    tc_puzzle: 7, // text coding的puzzle
    diy: 8, // DIY类型
    puzzle2: 9, // puzzle2.0类型
    video2: 10, // 带有题目的video
    settlement: 11, // 课程汇总（完课结算）
    pdf: 12, // pdf 关卡
    planet: 13, // 小贝星球
    mini_game: 14, // 微信小游戏
    python_diy: 15, // 桌面版python diy
    jimmy_settlement: 16, // 鲸幂结算关卡
  },

  // 关卡完成状态
  levelCompleteState: {
    unlocked: 0, // 未开始，即刚解锁
    locked: 2, // 锁定
    inProgress: 1, // 进行中，即解锁且尝试通关过
    pass: 3, // 已通过
  },

  // 课后作业完成状态完成状态
  homeworkCompleteState: {
    unReview: 1, // 未点评
    unPass: 2, // 未通过
    pass: 3, // 已通过
  },

  jumpType: {
    // 调整到IDE的类型
    normal: 1,
    junior: 2,
    PY_SCRATCH: 3,
  },

  ideType: {
    NORMAL: 1,
    JUNIOR: 2,
    TC: 3,
    // python 翻译至scratch的编辑器
    PY_SCRATCH: 3,
    PY_DIY: 4, // PY DIY 编辑器
  },

  CodingType: {
    BC: 1,
    TC: 2,
  },

  QuizControlSide: {
    TEACHER: 1,
    STUDENT: 2,
  },

  getCodeTypeMap: {
    register: 1,
    forgetPsword: 2,
  },

  classType: {
    LIVE: 0,
    RECORD: 1,
    OFFLINE: 2,
  },

  // 班级key类型Map
  CLASS_KEY_TYPE_MAP: {
    PAID_CLASS: 'paid', // 付费key类型班级
    IN_SCHOOL_CLASS: 'in_school', // 进校key类型班级
    APPRECIATED_CLASS: 'paid_lesson_package', // 增值key类型班级
  },

  classTypeOfBe: {
    RECORD: 1,
    LIVE: 2,
    OFFLINE: 3,
  },

  packageClassType: {
    normal: 0,
    sell: 1,
  },

  courseType: {
    online: 0,
    offline: 1,
    toBOrg: 2,
  },

  projectType: {
    private: 1,
    assigned: 2,
  },

  lessonStatusMap: {
    normal: 1,
    removed: 2,
    locked: 3,
  },

  levelTypeMap: {
    1: 'Learning',
    2: 'Explore',
    3: 'Creation',
    4: 'Prezi',
    5: 'Quiz',
  },

  conceptLevelMap: {
    0: 'flag-none', // 未掌握
    1: 'flag-beginner',
    2: 'flag-intermediate',
    3: 'flag-advanced',
  },

  Sex: {
    0: 'female',
    1: 'male',
    2: 'unknown',
  },

  jigsawType: {
    six: 6,
    eight: 8,
    ten: 10,
  },

  classRoomType: {
    inClass: 1,
    afterClass: 2,
  },

  play: {
    characterRace: {
      1: 'Human',
      2: 'Evilkind',
      3: 'Wizards',
    },

    rarityNumberMap: {
      0: 'unknown',
      1: 'r',
      2: 'sr',
      3: 'ssr',
    },

    rarityFlagClassMap: {
      // 卡片图标左上角的稀有度标识符
      0: 'unknown-flag',
      1: 'r-flag',
      2: 'sr-flag',
      3: 'ssr-flag',
    },

    rarityBgClassMap: {
      // 卡片图标外框
      0: 'unknown-background',
      1: 'r-background',
      2: 'sr-background',
      3: 'ssr-background',
    },

    rarityMap: {
      R: '1',
      SR: '2',
      SSR: '3',
    },

    cardType: {
      character: 1,
      program: 2,
    },

    cardPrice: 100,

    taskType: {
      puzzle: '1',
      quiz: '2',
    },
  },

  delayTime: {
    descAutoSave: 1000,
    mediaAutoSearch: 500,
  },

  Puzzle2Config: {
    TARGET_ANIMATION_DURATION: 1000,
  },

  langMap: {
    en: 'English',
    cn: '简体中文',
  },

  videoPublishCoinReward: 100,
  BCVersion: {
    ide: 2,
    gui: 3,
  },

  puzzleType: {
    guiPuzzle: 1,
    pythonPuzzle: 2,
  },

  defaultProjectSquareTagId: 1, // 默认发布作品时勾选“故事”

  // react-dom-confetti Config [from: https://daniel-lundin.github.io/react-dom-confetti/]
  confettiSetting: {
    angle: 124,
    spread: 322,
    startVelocity: 76,
    elementCount: 124,
    decay: 0.84,
  },

  beboUserType: {
    teacher: 1,
    student: 2,
  },

  activityHeaderbarWidth: 300,
  /**
   * diy 分布类型
   */
  diySubStepType: 1,
  /**
   * diy 分布类型，非最后一个，将其添加在到url
   * 用于 diy 页面 提交按钮 -> 我已完成
   * 跳过录屏，直接结算
   */
  diySubStepExcludeLastUrlSymbol: 'diypre=1',
  /**
   * diy 分布类型
   * 用与隐藏diy引导中的提交按钮
   */
  diySubStepUrlSymbol: 'diysub=1',

  youzanGoodsLink: 'https://j.youzan.com/NFwVfY',
  chromeDownloadPageLink:
    'https://www.yuque.com/books/share/68a626c1-8114-40f7-89d3-003f866ee937/qrxfvx',
  recordExampleLink:
    'https://www.yuque.com/books/share/68a626c1-8114-40f7-89d3-003f866ee937/yx40gi',

  browserDownloadUrl:
    'https://www.yuque.com/books/share/68a626c1-8114-40f7-89d3-003f866ee937/qrxfvx',

  // 双师课堂python编辑器下载链接
  pythonDiyEditorDownLink:
    'https://www.yuque.com/books/share/68a626c1-8114-40f7-89d3-003f866ee937/rfg18l',

  // 编程世界python编辑器下载链接
  icwPythonDiyEditorDownloadLink:
    'https://www.yuque.com/docs/share/d56f8ad9-1566-4d6a-885f-0c76ec64daee?#%20%E3%80%8APython%E8%BD%AF%E4%BB%B6%E4%B8%8B%E8%BD%BD%E3%80%8B',
};
