import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import './withAnimation.css';

const getDisplayName = component => component.displayName || component.name || 'component';

const withAnimation = WrappedComponent => {
  const HOC = ({ children, in: inProp, ...props }) => (
    <CSSTransition
      in={inProp}
      timeout={props.timeout || 500}
      classNames="fade"
      mountOnEnter
      unmountOnExit
    >
      <WrappedComponent {...props}>{children}</WrappedComponent>
    </CSSTransition>
  );

  HOC.displayName = `withAnimation(${getDisplayName(WrappedComponent)})`;
  HOC.propTypes = {
    in: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };
  HOC.defaultProps = {
    children: null,
  };

  return HOC;
};

export default withAnimation;
