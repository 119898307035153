const joinString = (a, b) => `${a}*${b}`;

const splitString = string => {
  const r = string && string.split('*');

  if (!r) return;

  // 兼容旧
  if (r.length === 1) {
    r[1] = 'vertical';
  }

  return r;
};

export { joinString, splitString };
