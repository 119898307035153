/* eslint-disable no-mixed-operators */
import NODE_TYPES from '../parser/node-type';

const {BLOCK, BLOCKS_OPTIONAL, PARAM, PARAM_VALUE_NUM_STRING} = NODE_TYPES;

class Depth {
    constructor() {
        this[BLOCK] = 0;
        this[BLOCKS_OPTIONAL] = 0;
        this[PARAM] = 0;
        this[PARAM_VALUE_NUM_STRING] = 0;
    }

    static BLOCK_WEIGHT = 1;
    static BLOCKS_OPTIONAL_WEIGHT = 0.1;
    static PARAM_WEIGHT = 0.2;
    static PARAM_VALUE_NUM_STRING_WEIGHT = 0.5;

    get value() {
        return this.cal();
    }

    update(type, num = 1) {
        switch (type) {
            case BLOCK:
                this[BLOCK] += num;
                break;
            case BLOCKS_OPTIONAL:
                this[BLOCKS_OPTIONAL] += num;
                break;
            case PARAM:
                this[PARAM] += num;
                break;
            case PARAM_VALUE_NUM_STRING:
                this[PARAM_VALUE_NUM_STRING] += num;
                break;
            default:
        }
    }

    cal() {
        return (
            (this[BLOCK] * Depth.BLOCK_WEIGHT) +
            // Remove deviation
            (((this[BLOCKS_OPTIONAL] * Depth.BLOCKS_OPTIONAL_WEIGHT * 10) +
                (this[PARAM] * Depth.PARAM_WEIGHT * 10) +
                (this[PARAM_VALUE_NUM_STRING] *
                    Depth.PARAM_VALUE_NUM_STRING_WEIGHT *
                    10)) /
                10)
        );
    }
}

class Walker {
    constructor(parent) {
        this.parent = parent || null;

        this.stack = [];
        this._depth = new Depth();
    }

    get depth() {
        return this._depth.cal();
    }

    get steps() {
        return this.stack.filter(
            item =>
                item.type === NODE_TYPES.BLOCK ||
                item.type === NODE_TYPES.BLOCKS_OPTIONAL
        ).length;
    }

    updateDepth(type) {
        this._depth.update(type);
        if (this.parent) {
            this.parent._walker.updateDepth(type);
        }
    }

    peekStack() {
        return this.stack.length === 0
            ? null
            : this.stack[this.stack.length - 1];
    }

    walk(node) {
        node = Object.assign({}, node);
        Object.defineProperty(node, '_walker', {
            value: this,
            enumerable: false
        });
        this.stack.push(node);
        this.updateDepth(node.type);

        return this;
    }

    walkParam(paramNode) {
        const blockNode = this.peekStack();
        if (!blockNode) return null;

        const walker = new Walker(blockNode);
        walker.walk(paramNode);

        (blockNode.params = blockNode.params || []).push(walker);

        return walker;
    }

    createContext(node = this.peekStack()) {
        const formatNode = rawNode => {
            if (!rawNode) return null;

            const parsedNode = Object.assign({}, rawNode);

            if (
                !(
                    parsedNode.type === BLOCK ||
                    parsedNode.type === BLOCKS_OPTIONAL
                )
            ) {
                return parsedNode;
            }

            if (!parsedNode.hasOwnProperty('params')) {
                return parsedNode;
            }

            parsedNode.params = parsedNode.params.reduce(
                (target, paramWalker) => {
                    // For performance, only collect 3 blocks
                    target[paramWalker.stack[0].key] = paramWalker.stack
                        .slice(1, 4)
                        .map(item => formatNode(item));

                    return target;
                },
                {}
            );

            return parsedNode;
        };

        let currentBlock = node;
        if (!(node.type === BLOCK || node.type === BLOCKS_OPTIONAL)) {
            currentBlock = node._walker.parent;
        }

        let previousBlock = null;
        for (let i = currentBlock._walker.stack.length - 1; i > 0; i--) {
            if (currentBlock._walker.stack[i] === currentBlock) {
                previousBlock = currentBlock._walker.stack[i - 1];
                if (
                    !(
                        previousBlock.type === BLOCK ||
                        previousBlock.type === BLOCKS_OPTIONAL
                    )
                ) {
                    previousBlock = null;
                }
                break;
            }
        }

        let parentContext = null;
        if (currentBlock._walker.parent) {
            parentContext = currentBlock._walker.parent._walker.createContext(
                currentBlock._walker.parent
            );
            parentContext.key = currentBlock._walker.stack[0].key;
        }

        let paramKey = null;
        if (node.type === PARAM_VALUE_NUM_STRING) {
            paramKey = node._walker.stack[0].key;
        }

        return Object.assign(
            {
                currentBlock: formatNode(currentBlock),
                previousBlock: formatNode(previousBlock),
                parent: parentContext
            },
            paramKey && {paramKey}
        );
    }

    reset() {
        this.stack = [];
        this._depth = new Depth();
    }

    notate() {
        const processedStack = this.stack.map(item => {
            const paramsNotation = item.hasOwnProperty('params')
                ? {
                      params: item.params.map(inner => inner.notate())
                  }
                : null;

            return Object.assign({}, item, paramsNotation);
        });

        return JSON.stringify(processedStack);
    }

    backtrack(notation) {
        const parsedNotation = JSON.parse(notation);

        this.reset();

        parsedNotation.forEach(({params, ...node}) => {
            this.walk(node);

            if (params) {
                params.forEach(paramNotation => {
                    this.walkParam(null).backtrack(paramNotation);
                });
            }
        });
    }
}

export default Walker;
