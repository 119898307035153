import parseBlocksToTree from './parser/blocks-to-tree';
import parsePathToTree from './parser/path-to-tree';
import parseBlocksToPath from './parser/blocks-to-path';

import treeDiff from './tree-diff/tree-diff';
import ERROR_TYPES from './tree-diff/error-types';

import {encode, decode} from './helpers/path-formatter';

class CodePath {
    constructor() {
        this._treesCache = {};
    }

    generateFromBlocks(blocks, hasProcDef) {
        const path = parseBlocksToPath(blocks, hasProcDef);
        return path ? encode(path) : '';
    }

    parseToTrees(source) {
        if (!this._treesCache[source]) {
            this._treesCache[source] = decode(source).map(path =>
                parsePathToTree(path)
            );
        }

        return this._treesCache[source];
    }

    diff(blocks, source) {
        const blocksTree = parseBlocksToTree(blocks);
        const trees = this.parseToTrees(source);

        if (!blocksTree.length || !trees.length) return null;

        const selectResult = results => {
            let selectedResult = null;

            results.forEach(result => {
                if (!selectedResult) {
                    selectedResult = result;
                    return;
                }

                if (selectedResult.status === result.status) {
                    if (result.depth > selectedResult.depth) {
                        selectedResult = result;
                    } else if (result.depth === selectedResult.depth) {
                        if (
                            result.error &&
                            result.error.type > selectedResult.error.type
                        ) {
                            selectedResult = result;
                        }
                    }
                } else if (!result.status) {
                    selectedResult = result;
                }
            });

            return selectedResult;
        };

        const needDeepSelect = [];
        let selectedResult = null;

        for (let i = 0; i < trees.length; i++) {
            const results = treeDiff(trees[i], blocksTree);

            if (results.every(result => result.status)) {
                selectedResult = results[0];
                break;
            }

            needDeepSelect.push(results);
        }

        if (!selectedResult && needDeepSelect.length === 1) {
            selectedResult = selectResult(needDeepSelect[0]);
        }

        if (!selectedResult) {
            const resultsDataMap = new Map();

            needDeepSelect.forEach(results =>
                resultsDataMap.set(results, {
                    trueCount: results.filter(result => result.status).length,
                    selectedResult: selectResult(results)
                })
            );

            needDeepSelect.sort((a, b) => {
                if (
                    resultsDataMap.get(a).trueCount !==
                    resultsDataMap.get(b).trueCount
                ) {
                    return (
                        resultsDataMap.get(b).trueCount -
                        resultsDataMap.get(a).trueCount
                    );
                }

                if (
                    resultsDataMap.get(a).selectedResult.depth !==
                    resultsDataMap.get(b).selectedResult.depth
                ) {
                    return (
                        resultsDataMap.get(b).depth -
                        resultsDataMap.get(a).depth
                    );
                }

                return (
                    resultsDataMap.get(b).selectedResult.error.type -
                    resultsDataMap.get(a).selectedResult.error.type
                );
            });

            selectedResult = resultsDataMap.get(needDeepSelect[0])
                .selectedResult;
        }

        return selectedResult;
    }
}

export {CodePath as default, ERROR_TYPES};
