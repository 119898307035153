import {combineReducers} from 'redux';
import TipsReducer, {tipsInitialState} from './tips';
import TutorialReducer, {tutorialInitialState} from './tutorial';
import InfoReducer, {infoInitialState} from './info';
import LeaveWarningReducer, {leaveWarningInitialState} from './leave-warning';
import CustomGuideReducer, {customGuideInitialState} from './custom-guide';

const initialState = {
    tips: tipsInitialState,
    tutorial: tutorialInitialState,
    info: infoInitialState,
    leaveWarning: leaveWarningInitialState,
    customGuide: customGuideInitialState
};

const reducer = combineReducers({
    tips: TipsReducer,
    tutorial: TutorialReducer,
    info: InfoReducer,
    leaveWarning: LeaveWarningReducer,
    customGuide: CustomGuideReducer
});

export {reducer as default, initialState as ActivityInitialState};
