import getCostumeUrl from '../../../lib/get-costume-url';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../components/box/box';
import Carousel from 'nuka-carousel';
import styles from './puzzle-sprite-item.css';
import classNames from 'classnames';

const getCostumeData = asset => (asset ? getCostumeUrl(asset) : null);
const SpriteItem = ({active, sprite, handleClickSprite}) => (
    <Box
        onClick={handleClickSprite}
        className={classNames(styles.spriteItem, {[styles.active]: active})}
    >
        <Box className={styles.imgBox}>
            <img
                className={styles.spriteImg}
                src={getCostumeData(sprite.costume && sprite.costume.asset)}
                alt="sprite"
            />
            <p className={styles.name}>{sprite.name}</p>
        </Box>
    </Box>
);

SpriteItem.propTypes = {
    active: PropTypes.bool,
    handleClickSprite: PropTypes.func,
    sprite: PropTypes.shape({
        costume: PropTypes.shape({
            url: PropTypes.string,
            name: PropTypes.string.isRequired,
            bitmapResolution: PropTypes.number.isRequired,
            rotationCenterX: PropTypes.number.isRequired,
            rotationCenterY: PropTypes.number.isRequired
        }),
        name: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired
    })
};

const PuzzleSpriteList = ({activeId, items, handleSelectSprite, ...props}) => (
    <Carousel {...props}>
        {items.length > 0 &&
            items.map((e, index) => (
                <SpriteItem
                    active={activeId === index}
                    key={e.id}
                    name={e.name}
                    sprite={e}
                    handleClickSprite={() => handleSelectSprite(e.id, index)}
                />
            ))}
    </Carousel>
);

PuzzleSpriteList.propTypes = {
    activeId: PropTypes.number,
    handleSelectSprite: PropTypes.func,
    items: PropTypes.arrayOf(SpriteItem.propTypes.sprite)
};

export default PuzzleSpriteList;
