import React from 'react';
import PropTypes from 'prop-types';

import styles from './account-coin.css';
import codeIcon from 'public-repo/images/code-icon.svg';
import coinIcon from 'public-repo/images/coin-icon.svg';

const AccountCoin = ({goldNum, codeNum}) => (
    <div className={styles.accountCoin}>
        <div className={styles.codeBlock}>
            <img
                src={coinIcon}
                className={styles.coinImg}
            />
            <p>{goldNum}</p>
        </div>
        <div className={styles.goldBlock}>
            <img
                src={codeIcon}
                className={styles.coinImg}
            />
            <p>{codeNum}</p>
        </div>
    </div>
);

AccountCoin.propTypes = {
    codeNum: PropTypes.number,
    goldNum: PropTypes.number
};

export default AccountCoin;
