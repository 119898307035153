/* eslint-disable camelcase */
import Fetch from 'PublicRepo/lib/fetch';
import Config from 'PublicRepo/utility/generalConfig';

export default {
  // video_id=1&activity_id=233&class_id=29&package_id=11
  getQuestionsById(video_id, activity_id, class_id, package_id) {
    return Fetch({
      url: Config.serverIo.get_questions_by_id,
      params: {
        video_id,
        activity_id,
        class_id,
        package_id,
      },
    });
  },

  submitQuestionOption(
    video_id,
    question_id,
    class_id,
    lesson_id,
    package_id,
    activity_id,
    answer_list,
  ) {
    return Fetch({
      url: Config.serverIo.submit_question_option,
      method: 'post',
      true_array: true,
      params: {
        video_id,
        question_id,
        lesson_id,
        class_id,
        activity_id,
        package_id,
        answer_list,
      },
    });
  },
};
