import {USERMODIFY, PRESETBLOCK} from '../modify-type';
export default class VMBridge {
    constructor(workspaceVMBlocks, targets) {
        this.workspaceVMBlocks = workspaceVMBlocks;
        this.editableTargets = targets.editable;
        this.interactiveTargets = targets.interactive;
        this._deleteBlocks = targets.deleteEditableBlocks;

        this._interactiveTargetsBlocksCache = {};
        this._originEditableTargetsBlocksCache = {};
        this._userModifyEditableTargetsBlocksCache = {};

        this._preprocessInteractiveTargets();
        this._preprocessEditableTargets();
    }

    get _editableTargetId() {
        return this.workspaceVMBlocks.runtime._editingTarget.id;
    }

    setBlockCache(name, id) {
        let cache = null;
        switch (name) {
            /**
             * 修改工作区代码
             */
            case USERMODIFY:
                cache = this._userModifyEditableTargetsBlocksCache;
                break;
            /**
             * 预置的默认代码
             */
            case PRESETBLOCK:
                cache = this._originEditableTargetsBlocksCache;
                break;
            default:
                // eslint-disable-next-line no-console
                console.error('类型错误,检查');
                return;
        }
        cache[id ? id : this._editableTargetId] = JSON.stringify(
            this.workspaceVMBlocks._blocks
        );
    }

    _preprocessEditableTargets() {
        if (this._deleteBlocks && this._deleteBlocks.length > 0) {
            this.editableTargets.forEach((target, index) => {
                try {
                    const deleteBlocks = this._deleteBlocks[index];
                    deleteBlocks.forEach(blockId => {
                        target.blocks.deleteBlock(blockId);
                    });
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            });
        }
    }

    _preprocessInteractiveTargets() {
        this.interactiveTargets.forEach(target => {
            this._interactiveTargetsBlocksCache[target.id] = JSON.stringify(
                target.blocks._blocks
            );

            // Currently remove all blocks.
            Object.keys(target.blocks._blocks).forEach(blockId => {
                target.blocks.deleteBlock(blockId);
            });
        });
    }

    _processEditableTargets() {
        this.editableTargets.forEach(target => {
            const data =
                this._userModifyEditableTargetsBlocksCache[target.id] ||
                this._originEditableTargetsBlocksCache[target.id];
            let parsedBlocks = data ? JSON.parse(data) : null;
            if (this.workspaceVMBlocks.runtime._editingTarget === target) {
                parsedBlocks = JSON.parse(
                    JSON.stringify(this.workspaceVMBlocks._blocks)
                );
            }
            if (parsedBlocks) {
                Object.keys(parsedBlocks).forEach(blockId => {
                    target.blocks.createBlock(parsedBlocks[blockId]);
                });
            }
        });
    }

    _processInteractiveTargets() {
        this.interactiveTargets.forEach(target => {
            const blocks = JSON.parse(
                this._interactiveTargetsBlocksCache[target.id]
            );

            Object.keys(blocks).forEach(blockId => {
                target.blocks.createBlock(blocks[blockId]);
            });
        });
    }

    _resetEditableTargets() {
        this.editableTargets.forEach(target => {
            const data =
                this._userModifyEditableTargetsBlocksCache[target.id] ||
                this._originEditableTargetsBlocksCache[target.id];
            let parsedBlocks = data ? JSON.parse(data) : null;
            if (this.workspaceVMBlocks.runtime._editingTarget === target) {
                parsedBlocks = JSON.parse(
                    JSON.stringify(this.workspaceVMBlocks._blocks)
                );
            }
            if (parsedBlocks) {
                Object.keys(parsedBlocks).forEach(blockId => {
                    target.blocks.deleteBlock(blockId);
                });
            }
        });
    }

    _resetInteractiveTargets() {
        this.interactiveTargets.forEach(target => {
            const blocks = JSON.parse(
                this._interactiveTargetsBlocksCache[target.id]
            );

            Object.keys(blocks).forEach(blockId => {
                target.blocks.deleteBlock(blockId);
            });
        });
    }

    connect() {
        this._processEditableTargets();
        this._processInteractiveTargets();
    }

    disconnect() {
        this._resetEditableTargets();
        this._resetInteractiveTargets();
    }

    reset(resetAllRoles = false) {
        this.disconnect();
        if (resetAllRoles) {
            this._userModifyEditableTargetsBlocksCache = {
                ...this._originEditableTargetsBlocksCache
            };
        } else {
            this._userModifyEditableTargetsBlocksCache[
                this._editableTargetId
            ] = null;
        }

        // This op only delete blocks in workspaceVMBlocks,
        // Blocks rendered on the workspace are reset by method below `this.workspace.reset`.
        Object.keys(this.workspaceVMBlocks._blocks).forEach(blockId => {
            this.workspaceVMBlocks.deleteBlock(blockId);
        });
    }
}
