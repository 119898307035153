import React from 'react';
import {connect} from 'react-redux';
import PythonScratchTipsComponent from '../components/python-puzzle-components/python-scratch-tips/python-scratch-tips';
const PythonScratchTips = props => <PythonScratchTipsComponent {...props} />;

const mapStateToProps = state => ({
    sprites: state.scratchGui.targets.sprites,
    vm: state.scratchGui.vm,
    text: state.puzzle.tip.tipData.text,
    onClickBTN: state.puzzle.tip.tipData.onClickBTN,
    isOpen: state.puzzle.tip.shouldShowTip,
    buttonText: state.puzzle.tip.tipData.buttonText
});
export default connect(mapStateToProps)(PythonScratchTips);
