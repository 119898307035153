import blockToImage from './block-to-image';
import opCodeToImage from './opcode-to-image';

const generateBlockImageHTML = imgSrc =>
    `<img style="zoom: .6;" src=${imgSrc} >`;

const getBlockHTMLById = (blockId, blockParam) =>
    blockToImage(blockId, blockParam).then(generateBlockImageHTML);

const getBlockHTMLByOpCode = (opCode, procCode) =>
    opCodeToImage(opCode, procCode).then(generateBlockImageHTML);

export {getBlockHTMLByOpCode, getBlockHTMLById};
