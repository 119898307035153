import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import VM from 'scratch-vm';
import locationBuilder from 'public-repo/utility/locationBuilder';
import Draggable from 'react-draggable';
import FurtherStep from './further-step.jsx';
import classNames from 'classnames';
import styles from './custom-guide-cards.css';
import rightArrow from './icon--next.svg';
import leftArrow from './icon--prev.svg';

const guideTypes = {
    DIY: 'DIY',
    HOMEWORK: 'HOMEWORK'
};

const VideoStep = ({videoUrl}) => (
    <div className={styles.stepVideo}>
        <video
            key={videoUrl}
            controls
            autoPlay
            className={styles.videoBox}
        >
            <source
                src={videoUrl}
                type="video/mp4"
            />
        </video>
    </div>
);
VideoStep.propTypes = {
    videoUrl: PropTypes.string.isRequired
};

const TextStep = ({text}) => (
    <p
        className={styles.stepTitle}
        /* eslint-disable react/no-danger */
        dangerouslySetInnerHTML={{__html: text}}
    />
);
TextStep.propTypes = {
    text: PropTypes.node.isRequired
};

const ImageStep = ({image, zoomInImage}) => (
    <div
        key={image}
        className={styles.stepImageContainer}
    >
        <img
            onClick={zoomInImage}
            className={styles.stepImage}
            draggable={false}
            src={image}
        />
    </div>
);
ImageStep.propTypes = {
    image: PropTypes.string.isRequired,
    zoomInImage: PropTypes.func
};

const NextPrevButtons = ({isRtl, onNextStep, onPrevStep}) => (
    <Fragment>
        {onNextStep ? (
            <div>
                <div className={isRtl ? styles.leftCard : styles.rightCard} />
                <div
                    className={isRtl ? styles.leftButton : styles.rightButton}
                    onClick={onNextStep}
                >
                    <img
                        draggable={false}
                        src={isRtl ? leftArrow : rightArrow}
                    />
                </div>
            </div>
        ) : null}
        {onPrevStep ? (
            <div>
                <div className={isRtl ? styles.rightCard : styles.leftCard} />
                <div
                    className={isRtl ? styles.rightButton : styles.leftButton}
                    onClick={onPrevStep}
                >
                    <img
                        draggable={false}
                        src={isRtl ? rightArrow : leftArrow}
                    />
                </div>
            </div>
        ) : null}
    </Fragment>
);
NextPrevButtons.propTypes = {
    isRtl: PropTypes.bool,
    onNextStep: PropTypes.func,
    onPrevStep: PropTypes.func
};

const CardHeader = ({onCloseCards, totalSteps, step, gotoStep, guideType}) => (
    <div className={styles.headerButtons}>
        <div className={styles.headerTextWrapper}>
            <span className={styles.headerText}>{`${
                guideType === guideTypes.DIY
                    ? 'DIY'
                    : guideType === guideTypes.HOMEWORK
                    ? '作业'
                    : ''
            }引导`}</span>
        </div>
        {totalSteps > 1 ? (
            <div className={styles.stepsList}>
                {Array(totalSteps)
                    .fill(0)
                    .map((_, i) => (
                        <div
                            className={
                                i === step
                                    ? styles.activeStepPip
                                    : styles.inactiveStepPip
                            }
                            key={`pip-step-${i}`}
                            onClick={() => gotoStep(i)}
                        />
                    ))}
            </div>
        ) : null}
        <div
            className={styles.removeButton}
            onClick={onCloseCards}
        >
            <span className={styles.headerText}>{'最小化'}</span>
        </div>
    </div>
);
CardHeader.propTypes = {
    onCloseCards: PropTypes.func.isRequired,
    step: PropTypes.number,
    totalSteps: PropTypes.number
};

const PreviewsStep = ({cover, proId}) => (
    <Fragment>
        <div className={styles.stepTitle}>
            <span>我们看看别人是怎么做的吧，点击图片查看</span>
        </div>
        <div>
            <a
                className={classNames(styles.deck, styles.deckLink)}
                href={locationBuilder.buildGuiShareUrl(proId)}
                target="_blank"
            >
                <img
                    className={styles.deckImage}
                    draggable={false}
                    src={cover}
                />
            </a>
        </div>
    </Fragment>
);
PreviewsStep.propTypes = {
    cover: PropTypes.string.isRequired,
    proId: PropTypes.string.isRequired
};

class CustomGuideCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onMinimize: false,
            isImageZoomedIn: false,
            zoomInImgSrc: null
        };
        this.minimizeOnExit = this.minimizeOnExit.bind(this);
        this.zoomOutImage = this.zoomOutImage.bind(this);
        this.renderZoomInImage = this.renderZoomInImage.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const {step, guideData} = newProps;
        const newStepData = guideData[step];
        if (step !== this.props.step) {
            if (newStepData.role) {
                this.props.addActorHighlight(
                    newStepData.role - 1,
                    this.props.vm.runtime.targets[newStepData.role].sprite.name
                );
            } else {
                this.props.removeActorHighlight();
            }
        }
    }

    minimizeOnExit() {
        this.setState(
            {
                onMinimize: true
            },
            () => {
                setTimeout(this.props.onCloseCards, 200);
            }
        );
    }

    zoomInImage(imgSrc) {
        this.setState({
            isImageZoomedIn: true,
            zoomInImgSrc: imgSrc
        });
    }

    zoomOutImage() {
        this.setState({
            isImageZoomedIn: false
        });
    }

    renderZoomInImage(imgSrc) {
        return ReactDOM.createPortal(
            <div
                className={styles.guideImgZoomWrapper}
                onClick={this.zoomOutImage}
            >
                <img
                    className={styles.zoomInImg}
                    src={imgSrc}
                />
            </div>,
            document.body
        );
    }

    renderSteps(step) {
        const resources = step.content_list;
        if (!resources || !resources[0]) {
            return null;
        }
        return (
            <Fragment>
                {resources.map(({content, type}, index) => {
                    switch (type) {
                        case 'text':
                            return (<TextStep
                                key={index}
                                text={content}
                            />);
                        case 'image':
                            return (
                                content && (
                                    <ImageStep
                                        key={index}
                                        image={content}
                                        isZoom={this.state.isImageZoomedIn}
                                        zoomInImage={this.zoomInImage.bind(
                                            this,
                                            content
                                        )}
                                    />
                                )
                            );
                        case 'video':
                            return (<VideoStep
                                key={index}
                                videoUrl={content}
                            />);
                        case 'project':
                            return (
                                <PreviewsStep
                                    key={index}
                                    proId={content.id}
                                    cover={content.cover}
                                />
                            );
                        case 'further':
                            return (
                                <FurtherStep
                                    key={index}
                                    gotoStep={this.props.gotoStep}
                                    onSubmit={this.props.onSubmit}
                                />
                            );
                        default:
                            return null;
                    }
                })}
                {this.state.isImageZoomedIn &&
                    this.renderZoomInImage(this.state.zoomInImgSrc)}
            </Fragment>
        );
    }

    render() {
        const {
            isRtl,
            onDrag,
            onStartDrag,
            onEndDrag,
            onShowAll,
            onNextStep,
            onPrevStep,
            gotoStep,
            step,
            guideData,
            guideType,
            ...posProps
        } = this.props;
        let {x, y} = posProps;
        if (x === 0 && y === 0) {
            // initialize positions
            x = isRtl ? -292 : 292;
            y = 100;
        }
        const steps = guideData;

        return (
            <Draggable
                bounds="parent"
                position={{x: x, y: y}}
                onDrag={onDrag}
                onStart={onStartDrag}
                onStop={onEndDrag}
            >
                <div
                    className={classNames(
                        styles.cardContainer,
                        this.state.onMinimize ? styles.minimizeOnExit : ''
                    )}
                >
                    <div className={styles.card}>
                        <CardHeader
                            step={step}
                            guideType={guideType}
                            totalSteps={steps.length}
                            onCloseCards={this.minimizeOnExit}
                            onShowAll={onShowAll}
                            gotoStep={gotoStep}
                        />
                        <div className={styles.stepBody}>
                            {this.renderSteps(steps[step])}
                        </div>
                        <NextPrevButtons
                            isRtl={isRtl}
                            onNextStep={
                                step < steps.length - 1 ? onNextStep : null
                            }
                            onPrevStep={step > 0 ? onPrevStep : null}
                        />
                    </div>
                </div>
            </Draggable>
        );
    }
}

CustomGuideCards.propTypes = {
    addActorHighlight: PropTypes.func,
    gotoStep: PropTypes.func,
    guideData: PropTypes.Array,
    guideType: PropTypes.string,
    isRtl: PropTypes.bool.isRequired,
    onCloseCards: PropTypes.func.isRequired,
    onDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    onNextStep: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    onShowAll: PropTypes.func,
    onStartDrag: PropTypes.func,
    onSubmit: PropTypes.func,
    removeActorHighlight: PropTypes.func,
    step: PropTypes.number.isRequired,
    vm: PropTypes.instanceOf(VM),
    x: PropTypes.number || PropTypes.string,
    y: PropTypes.number || PropTypes.string
};

export {CustomGuideCards as default, guideTypes};
