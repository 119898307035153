import '!style-loader!css-loader!intro.js/introjs.css';
import {startIntro} from 'PublicRepo/components/intro/index';
import '!style-loader!css-loader!PublicRepo/components/intro/index.css';
import '!style-loader!css-loader!PublicRepo/components/intro/eleTip.css';

let intro = null;

const GeneratePythonGuideConfig = (
    editorPressReturn,
    targetBlockName,
    toggleTarget
) => [
    {
        step: {
            element: document.getElementById('target-content'),
            intro: '<div class="intro-box">创作之前，先看看目标吧</div>',
            position: 'right'
        },
        highlightClass: 'with-white-background',
        overlayOpacity: 0.7,
        nextLabel: '下一步',
        styleFn: () => {
            document.querySelector('.intro-tips').classList.add('py-intro');
        }
    },
    {
        step: {
            element: document.getElementById('target-content'),
            intro: '<div class="intro-box">目标清楚了，我们开始创作吧</div>',
            position: 'right'
        },
        highlightClass: 'with-white-background',
        styleFn: () => {}
    },
    {
        step: {
            element: document.getElementById('stage-playground'),
            intro:
                '<div class="intro-box">这里是舞台区，作品将会在这里演示哦</div>',
            position: 'left'
        },
        highlightClass: 'with-white-background',

        styleFn: () => {
            toggleTarget();
        }
    },
    {
        step: {
            element: document.getElementById('code-pool'),
            intro: `<div class="intro-box">这里是代码池，点击right函数块，看看效果吧</div>`,
            position: 'left'
        },
        highlightClass: 'with-white-background',
        styleFn: () => {
            document.getElementsByClassName(
                'introjs-nextbutton'
            )[0].style.display = 'none';
            const targetMethod = [
                ...document.getElementsByClassName('method-block')
            ].filter(block => block.innerHTML === targetBlockName)[0];

            targetMethod.className += ' spring-motion';
            targetMethod.addEventListener('click', function onClick() {
                intro.nextStep();
                targetMethod.classList.remove('spring-motion');
                targetMethod.removeEventListener('click', onClick);
            });

            const otherMethods = [
                ...document.getElementsByClassName('method-block')
            ].filter(block => block.innerHTML !== targetBlockName);

            otherMethods.forEach(dom => {
                dom.style.pointerEvents = 'none';
            });
        }
    },
    {
        step: {
            element: document.getElementById('editor-area'),
            intro:
                '<div class="intro-box">在这里编辑代码，快把right(15)改为right(90)吧</div>',
            position: 'right'
        },
        highlightClass: 'with-white-background',
        styleFn: () => {
            Array.from(document.getElementsByClassName('method-block')).forEach(
                dom => {
                    dom.style.pointerEvents = 'auto';
                }
            );

            editorPressReturn().then(() => {
                intro.nextStep();
            });

            document
                .getElementById('start-button')
                .addEventListener('click', function onClick() {
                    intro.exit();
                    document
                        .getElementById('start-button')
                        .removeEventListener('click', onClick);
                });
        }
    },
    {
        step: {
            element: document.getElementById('start-button'),
            intro: '<div class="intro-box">创作完成啦，快点击运行看看吧</div>',
            position: 'top'
        },
        highlightClass: 'with-white-background',
        styleFn: () => {
            document.getElementsByClassName(
                'introjs-donebutton'
            )[0].style.display = 'none';

            const startButton = document.getElementById('start-button');
            startButton.classList.add('spring-motion');

            startButton.addEventListener('click', function onClick() {
                startButton.classList.remove('spring-motion');
                startButton.removeEventListener('click', onClick);
                intro.exit();
            });
        }
    }
];

const pythonGuide = {
    /**
     * @param { function } editorPressReturn 编辑器换行
     * @param { function } onComplete 完成回调
     * @param { string } targetBlockName 答案代码块名字
     * @param { function } toggleTarget 切换目标区域是否显示
     */
    start(editorPressReturn, onComplete, targetBlockName, toggleTarget) {
        intro = startIntro(
            GeneratePythonGuideConfig(
                editorPressReturn,
                targetBlockName,
                toggleTarget
            ),
            onComplete
        );
        intro.setOption('tooltipClass', 'intro-tips py-intro');
    },

    _currentIndex: 0,

    /**
     * @description 获取当前引导的索引
     */
    get currentIndex() {
        return this._currentIndex;
    },

    /**
     * @description 下一步
     */
    forward() {
        this._currentIndex += 1;
    },

    showHint() {
        intro = startIntro([
            {
                step: {
                    element: document.querySelector('.hint-button'),
                    intro:
                        '<div class="intro-box" style="width: 230px">遇到问题，这里可以查看提示哦</div>',
                    position: 'left'
                },
                highlightClass: 'with-white-background',
                overlayOpacity: '0.5',
                styleFn: () => {
                    const hint = document.querySelector('.hint-button');
                    document.getElementsByClassName(
                        'introjs-donebutton'
                    )[0].style.display = 'none';

                    hint.addEventListener('click', function onClick() {
                        intro.exit();
                        hint.removeEventListener('click', onClick);
                    });
                }
            }
        ]);
    }
};

export default pythonGuide;
