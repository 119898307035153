import Fetch from 'public-repo/lib/fetch';
import GeneralConfig from 'public-repo/utility/generalConfig';

const getTutorial = pid =>
    Fetch({
        url: GeneralConfig.serverIo.getTutorial,
        method: 'get',
        params: {
            id: pid
        }
    });

const getDiyTutorialInfo = pid =>
    Fetch({
        method: 'get',
        url: GeneralConfig.serverIo.getDiyTutorialInfo(pid)
    });

const addFurtherStep = tutorialData => {
    if (!tutorialData || tutorialData.length === 0) return null;
    tutorialData.push({
        title: '',
        role: '',
        content_list: [
            {
                content: '',
                type: 'further'
            }
        ]
    });
    return tutorialData;
};
const formatTutorial = tutorialData => ({
    tutorialInfo: tutorialData || null,
    totalStep: (tutorialData && tutorialData.length) || 1,
    imgSrc: '',
    isShowContent: false,
    enableToggleContentAnimation: false,
    enableDrag: true,
    showDetailImage: false,
    curStep: 1,
    curContent: null,
    curTips: null
});

export {getTutorial, getDiyTutorialInfo, formatTutorial, addFurtherStep};
