/* eslint-disable babel/camelcase*/
import log from 'Lib/log';

const DROPDOWN = '下拉框';
const OVAL_DROPDOWN = '椭圆下拉框';
const OVAL_INPUT = '椭圆输入框';
const RHOMBUS_INPUT = '菱形输入框';

const motion = {
    motion_movesteps: {
        STEPS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_turnright: {
        DEGREES: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_turnleft: {
        DEGREES: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_goto: {
        TO: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    motion_gotoxy: {
        X: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        Y: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    motion_glideto: {
        SECS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        },
        TO: {
            index: 2,
            only: true,
            type: DROPDOWN
        }
    },
    motion_glidesecstoxy: {
        SECS: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        X: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        },
        Y: {
            index: 3,
            only: false,
            type: OVAL_INPUT
        }
    },
    motion_pointindirection: {
        DIRECTION: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_pointtowards: {
        TOWARDS: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    motion_changexby: {
        DX: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_setx: {
        X: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_changeyby: {
        DY: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_sety: {
        Y: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    motion_ifonedgebounce: null,
    motion_setrotationstyle: {
        STYLE: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    motion_xposition: null,
    motion_yposition: null,
    motion_direction: null
};

const looks = {
    looks_sayforsecs: {
        MESSAGE: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        SECS: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    looks_say: {
        MESSAGE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    looks_thinkforsecs: {
        MESSAGE: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        SECS: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    looks_think: {
        MESSAGE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    looks_switchcostumeto: {
        COSTUME: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    looks_nextcostume: null,
    looks_switchbackdropto: {
        BACKDROP: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    looks_nextbackdrop: null,
    looks_changesizeby: {
        CHANGE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    looks_setsizeto: {
        SIZE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    // square dropdown
    looks_changeeffectby: {
        EFFECT: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        CHANGE: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    looks_seteffectto: {
        EFFECT: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        CHANGE: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    looks_cleargraphiceffects: null,
    looks_show: null,
    looks_hide: null,
    looks_gotofrontback: {
        FRONT_BACK: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    looks_goforwardbackwardlayers: {
        FORWARD_BACKWARD: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        NUM: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    looks_costumenumbername: {
        NUMBER_NAME: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    looks_backdropnumbername: {
        NUMBER_NAME: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    looks_size: null
};

const sound = {
    sound_playuntildone: {
        SOUND_MENU: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    sound_play: {
        SOUND_MENU: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    sound_stopallsounds: null,
    sound_changeeffectby: {
        EFFECT: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        VALUE: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    sound_seteffectto: {
        EFFECT: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        VALUE: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    sound_cleareffects: null,
    sound_changevolumeby: {
        VOLUME: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    sound_setvolumeto: {
        VOLUME: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    sound_volume: null
};

const event = {
    event_whenflagclicked: null,
    event_whenkeypressed: {
        KEY_OPTION: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    event_whenthisspriteclicked: null,
    event_whenbackdropswitchesto: {
        index: 1,
        only: true,
        type: DROPDOWN
    },
    event_whengreaterthan: {
        WHENGREATERTHANMENU: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        VALUE: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    event_whenbroadcastreceived: {
        BROADCAST_OPTION: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    event_broadcast: {
        BROADCAST_INPUT: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    event_broadcastandwait: {
        BROADCAST_INPUT: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    }
};

const control = {
    control_wait: {
        DURATION: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    control_repeat: {
        TIMES: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    control_forever: null,
    control_if: {
        CONDITION: {
            index: 1,
            only: true,
            type: RHOMBUS_INPUT
        }
    },
    control_if_else: {
        CONDITION: {
            index: 1,
            only: true,
            type: RHOMBUS_INPUT
        }
    },
    control_wait_until: {
        CONDITION: {
            index: 1,
            only: true,
            type: RHOMBUS_INPUT
        }
    },
    control_repeat_until: {
        CONDITION: {
            index: 1,
            only: true,
            type: RHOMBUS_INPUT
        }
    },
    control_stop: {
        STOP_OPTION: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    control_start_as_clone: null,
    control_create_clone_of: {
        CLONE_OPTION: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    control_delete_this_clone: null
};

const sensing = {
    sensing_touchingobject: {
        TOUCHINGOBJECTMENU: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    sensing_touchingcolor: {
        COLOR: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    sensing_coloristouchingcolor: {
        COLOR: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        COLOR2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    sensing_distanceto: {
        DISTANCETOMENU: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    sensing_askandwait: {
        QUESTION: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    sensing_answer: null,
    sensing_keypressed: {
        KEY_OPTION: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    sensing_mousedown: null,
    sensing_mousex: null,
    sensing_mousey: null,
    sensing_setdragmode: {
        DRAG_MODE: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    sensing_loudness: null,
    sensing_timer: null,
    sensing_resettimer: null,
    sensing_of: {
        PROPERTY: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        OBJECT: {
            index: 2,
            only: true,
            type: DROPDOWN
        }
    },
    sensing_current: {
        CURRENTMENU: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    sensing_dayssince2000: null,
    sensing_username: null
};

const operators = {
    operator_add: {
        NUM1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        NUM2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_subtract: {
        NUM1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        NUM2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_multiply: {
        NUM1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        NUM2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_divide: {
        NUM1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        NUM2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_random: {
        FROM: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        TO: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_lt: {
        OPERAND1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        OPERAND2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_equals: {
        OPERAND1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        OPERAND2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_gt: {
        OPERAND1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        OPERAND2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_and: {
        OPERAND1: {
            index: 1,
            only: false,
            type: RHOMBUS_INPUT
        },
        OPERAND2: {
            index: 2,
            only: false,
            type: RHOMBUS_INPUT
        }
    },
    operator_or: {
        OPERAND1: {
            index: 1,
            only: false,
            type: RHOMBUS_INPUT
        },
        OPERAND2: {
            index: 2,
            only: false,
            type: RHOMBUS_INPUT
        }
    },
    operator_not: {
        OPERAND: {
            index: 1,
            only: true,
            type: RHOMBUS_INPUT
        }
    },
    operator_join: {
        STRING1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        STRING2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_letter_of: {
        LETTER: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        STRING: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_length: {
        STRING: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    operator_contains: {
        STRING1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        STRING2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_mod: {
        NUM1: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        NUM2: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    operator_round: {
        NUM: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    operator_mathop: {
        OPERATOR: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        NUM: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    }
};

const data = {
    data_variable: null,
    data_setvariableto: {
        VARIABLE: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        VALUE: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    data_changevariableby: {
        VARIABLE: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        VALUE: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    data_showvariable: {
        VARIABLE: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    data_hidevariable: {
        VARIABLE: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    data_listcontents: null,
    data_addtolist: {
        ITEM: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        },
        LIST: {
            index: 2,
            only: true,
            type: DROPDOWN
        }
    },
    data_deleteoflist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        INDEX: {
            index: 2,
            only: true,
            type: OVAL_INPUT
        }
    },
    data_deletealloflist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    data_insertatlist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        INDEX: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        ITEM: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    data_replaceitemoflist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        INDEX: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        ITEM: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    },
    data_itemoflist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        INDEX: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    data_itemnumoflist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        ITEM: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    data_lengthoflist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    data_listcontainsitem: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        },
        ITEM: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    data_showlist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    },
    data_hidelist: {
        LIST: {
            index: 1,
            only: true,
            type: DROPDOWN
        }
    }
};

// extensions
const music = {
    music_playDrumForBeats: {
        DRUM: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        BEATS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    music_restForBeats: {
        BEATS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    music_playNoteForBeats: {
        NOTE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        },
        BEATS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    music_setInstrument: {
        INSTRUMENT: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    music_setTempo: {
        BEATS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    music_changeTempo: {
        BEATS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    music_getTempo: null
};

const pen = {
    pen_clear: null,
    pen_stamp: null,
    pen_penDown: null,
    pen_penUp: null,
    pen_setPenColorToColor: {
        COLOR: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    pen_changePenColorParamBy: {
        COLOR_PARAM: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        VALUE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    pen_setPenColorParamTo: {
        COLOR_PARAM: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        VALUE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    pen_changePenSizeBy: {
        SIZE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    pen_setPenSizeTo: {
        SIZE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    }
};

const videoSensing = {
    videoSensing_whenMotionGreaterThan: {
        REFERENCE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    videoSensing_videoOn: {
        SUBJECT: {
            index: 1,
            only: false,
            type: OVAL_DROPDOWN
        },
        ATTRIBUTE: {
            index: 2,
            only: false,
            type: OVAL_DROPDOWN
        }
    },
    videoSensing_videoToggle: {
        VIDEO_STATE: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    videoSensing_setVideoTransparency: {
        TRANSPARENCY: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    }
};

const text2speech = {
    text2speech_speakAndWait: {
        WORDS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    text2speech_setVoice: {
        VOICE: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    text2speech_setLanguage: {
        LANGUAGE: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    }
};

const translate = {
    translate_getTranslate: {
        WORDS: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        },
        LANGUAGE: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    translate_getViewerLanguage: null
};

const microbit = {
    microbit_whenButtonPressed: {
        BTN: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    microbit_isButtonPressed: {
        BTN: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    microbit_displaySymbol: {
        MATRIX: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    microbit_displayText: {
        TEXT: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    microbit_displayClear: null,
    microbit_whenTilted: {
        DIRECTION: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    microbit_isTilted: {
        DIRECTION: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    microbit_getTiltAngle: {
        DIRECTION: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    microbit_whenPinConnected: {
        PIN: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    }
};

const ev3 = {
    ev3_motorTurnClockwise: {
        PORT: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        TIME: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    ev3_motorTurnCounterClockwise: {
        PORT: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        TIME: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    ev3_motorSetPower: {
        PORT: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        POWER: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    ev3_getMotorPosition: {
        PORT: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    ev3_whenButtonPressed: {
        PORT: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    ev3_whenDistanceLessThan: {
        DISTANCE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    ev3_whenBrightnessLessThan: {
        DISTANCE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    ev3_buttonPressed: {
        PORT: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    ev3_getDistance: null,
    ev3_getBrightness: null,
    ev3_beep: {
        NOTE: {
            index: 1,
            only: false,
            type: OVAL_INPUT
        },
        TIME: {
            index: 2,
            only: false,
            type: OVAL_INPUT
        }
    }
};

const wedo2 = {
    wedo2_motorOnFor: {
        MOTOR_ID: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        DURATION: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    wedo2_motorOn: {
        MOTOR_ID: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    wedo2_motorOff: {
        MOTOR_ID: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    wedo2_startMotorPower: {
        MOTOR_ID: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        POWER: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    wedo2_setMotorDirection: {
        MOTOR_ID: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        MOTOR_DIRECTION: {
            index: 2,
            only: false,
            type: OVAL_DROPDOWN
        }
    },
    wedo2_setLightHue: {
        MOTOR_ID: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    wedo2_whenDistance: {
        OP: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        },
        REFERENCE: {
            index: 1,
            only: true,
            type: OVAL_INPUT
        }
    },
    wedo2_whenTilted: {
        TILT_DIRECTION_ANY: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    wedo2_getDistance: null,
    wedo2_isTilted: {
        TILT_DIRECTION_ANY: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    },
    wedo2_getTiltAngle: {
        TILT_DIRECTION: {
            index: 1,
            only: true,
            type: OVAL_DROPDOWN
        }
    }
};

const extensions = Object.assign(
    {},
    music,
    pen,
    videoSensing,
    text2speech,
    translate,
    microbit,
    ev3,
    wedo2
);

const blocks = Object.assign(
    {},
    motion,
    looks,
    sound,
    event,
    control,
    sensing,
    operators,
    data,
    extensions
);

const OVAL_INPUT_MARK = '%s';
const RHOMBUS_INPUT_MARK = '%b';
const MARK_REG_EXP = new RegExp(
    `(?:^|\\s)(${OVAL_INPUT_MARK}|${RHOMBUS_INPUT_MARK})`,
    'g'
);
const ProcedureBlocksCache = Object.create(null);
const parseProcedureBlock = procCode => {
    if (Reflect.has(ProcedureBlocksCache, procCode)) {
        return ProcedureBlocksCache[procCode];
    }

    const procedureBlock = {};
    const ovalInputCollect = [];
    const rhombusInputCollect = [];
    let execResultTemp;
    let argTemp;

    while ((execResultTemp = MARK_REG_EXP.exec(procCode))) {
        if (execResultTemp) {
            const ArgName = `ARG${ovalInputCollect.length +
                rhombusInputCollect.length}`;
            switch (execResultTemp[1]) {
                case OVAL_INPUT_MARK:
                    argTemp = {
                        [ArgName]: {
                            index: ovalInputCollect.length + 1,
                            only: false,
                            type: OVAL_INPUT
                        }
                    };
                    ovalInputCollect.push(argTemp[ArgName]);
                    Object.assign(procedureBlock, argTemp);
                    break;
                case RHOMBUS_INPUT_MARK:
                    argTemp = {
                        [ArgName]: {
                            index: rhombusInputCollect.length + 1,
                            only: false,
                            type: RHOMBUS_INPUT
                        }
                    };
                    rhombusInputCollect.push(argTemp[ArgName]);
                    Object.assign(procedureBlock, argTemp);
                    break;
                default:
                    log.warn(`Unknown arg ${execResultTemp[1]}`);
            }
        }
    }

    if (ovalInputCollect.length === 1) {
        ovalInputCollect[0].only = true;
    }

    if (rhombusInputCollect.length === 1) {
        rhombusInputCollect[0].only = true;
    }

    ProcedureBlocksCache[procCode] = procedureBlock;
    return procedureBlock;
};

export {blocks as default, parseProcedureBlock};
