import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomButton, {VariantType} from 'PublicRepo/material_components/button';
import styles from './custom-guide-cards.css';
import Config from 'PublicRepo/utility/generalConfig';
class FurtherStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.setState({
            disabled: true
        });
        this.props.onSubmit();
    }

    render() {
        return (
            <div className={styles.furtherGuide}>
                <i className={styles.furtherGuideIcon} />
                <p
                    className={classNames(
                        styles.stepTitle,
                        styles.furtherGuideTitle
                    )}
                >
                    你已经看到了所有引导啦，快去继续创作吧～
                </p>
                <div className={styles.guideBtnGroup}>
                    <CustomButton
                        variantType={VariantType.outlined}
                        onClick={this.props.gotoStep.bind(this, 0)}
                    >
                        再看一遍
                    </CustomButton>
                    {!location.search.includes(Config.diySubStepUrlSymbol) && (
                        <CustomButton
                            raised
                            onClick={this.handleSubmit}
                            disabled={this.state.disabled}
                        >
                            做完啦~去交作业
                        </CustomButton>
                    )}
                </div>
            </div>
        );
    }
}
FurtherStep.propTypes = {
    gotoStep: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default FurtherStep;
