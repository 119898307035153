export default target => new Promise((resolve, reject) => {
  const tagName = target.tagName.toLowerCase();
  if (tagName === 'input' || tagName === 'textarea') {
    target.select();

    try {
      target.setSelectionRange(0, target.value.length);
      document.execCommand('copy');

      resolve(target.value);
    } catch (e) {
      reject();
    }
  }
});
