import i18n from './index';
import enUs from './common/en-US';
import zhCN from './common/zh-CN';

const maps = {
  'zh-CN': Object.assign({}, zhCN),
  cn: Object.assign({}, zhCN),
  en: Object.assign({}, enUs),
};

i18n.setLangMaps(maps);

export default i18n;
