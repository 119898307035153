import Puzzle2LeftPanel from '../components/puzzle2-left-panel/puzzle2-left-panel';

import {connect} from 'react-redux';

const mapStateToProps = state => ({
    info: state.puzzle.info,
    isPuzzlePlaying: state.puzzle.control.isPuzzlePlaying,
    targets: state.puzzle.targets,
    pythonMode: state.puzzle.python.pythonMode
});

export default connect(mapStateToProps)(Puzzle2LeftPanel);
