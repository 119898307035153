/**
 * Get all target blockSvgs in workspace.
 * @param {Object} workspace
 * @param {string} type Target blockSvg type
 * @return {array} Return all blockSvgs matched.
 */
const getWorkspaceTopBlocksByType = (workspace, type) => {
  const blockSvgs = workspace.getTopBlocks();

  return blockSvgs.filter(blockSvg => blockSvg.type === type);
};

/**
 * First block type layout in flyout workspace.
 * @type {string}
 */
const FLYOUT_WORKSPACE_FIRST_BLOCK_TYPE = 'motion_movesteps';

/**
 * Get target blockSvg in flyout workspace.
 * By default there are no repeat blocks in flyout workspace except IDE project
 * for common category. >>>>>>   Just ignore it.
 * @param {Object} workspace
 * @param {string} [type] Target blockSvg type
 * @return {Object|null} Return blockSvg.
 */
const getFlyoutWorkspaceBlockByType = (
  workspace,
  type = FLYOUT_WORKSPACE_FIRST_BLOCK_TYPE,
) => {
  const flyout = workspace.getFlyout();

  if (!flyout) return null;

  return getWorkspaceTopBlocksByType(flyout.getWorkspace(), type)[0];
};

/**
 * Get all next blocks of target-type blocks in workspace.
 * @param workspace
 * @param type
 * @return {Object[]}
 */
const getWorkspaceBlocksNext = (workspace, type) => {
  const blockSvgs = getWorkspaceTopBlocksByType(workspace, type);

  return blockSvgs.map(blockSvg => {
    const nextTargetConnection = blockSvg.nextConnection.targetConnection;

    return nextTargetConnection ? nextTargetConnection.getSourceBlock() : null;
  });
};

/**
 * Get blockSvg dom data with position & dimension.
 * @param {Object} blockSvg
 * @return {Object|ClientRect|DOMRect|*}
 */
const getBlockRectData = blockSvg => blockSvg.getSvgRoot().getBoundingClientRect();

/**
 * Scroll blockSvg into view.
 * @param {Object} workspace
 * @param {Object} blockSvg
 */
const scrollFlyoutBlockInView = (workspace, blockSvg) => {
  const flyout = workspace.getFlyout();

  const { y: blockY } = blockSvg.getRelativeToSurfaceXY();
  const blockHeight = blockSvg.height;

  const blockTopY = blockY;
  const blockBottomY = blockY + blockHeight;
  // Flyout height should scale back to real size.
  const flyoutHeight = flyout.getHeight() / workspace.scale;
  const flyoutScrollY = flyout.getScrollPos();

  // Do not trigger scrolling if block already in view.
  // Add flyout.MARGIN to make smooth interaction for the target block.
  if (
    blockBottomY - flyoutScrollY <= flyoutHeight - flyout.MARGIN
    && blockTopY - flyoutScrollY >= flyout.MARGIN
  ) return;

  // In the underlying code scrollValue should be lt 0 or it would not trigger scrolling.
  const targetScrollY = Math.max(blockBottomY - (flyoutHeight / 2), 1);

  flyout.scrollTo(targetScrollY);
};

/**
 * Scroll blockSvg into view.
 * @param {Object} workspace
 * @param {Object} blockSvg
 * @param {Boolean} forceScroll
 */
const scrollBlockInView = (workspace, blockSvg, forceScroll = false) => {
  const flyout = workspace.getFlyout();

  const { y: blockY } = blockSvg.getRelativeToSurfaceXY();
  const blockHeight = blockSvg.height;

  const blockTopY = blockY;
  const blockBottomY = blockY + blockHeight;
  // Flyout height should scale back to real size.
  const flyoutHeight = flyout.scrollbar_.scrollViewSize_;
  const flyoutScrollY = flyout.getScrollPos();

  // Do not trigger scrolling if block already in view.
  // Add flyout.MARGIN to make smooth interaction for the target block.
  if (
    !forceScroll
    || (blockBottomY - flyoutScrollY <= flyoutHeight - flyout.MARGIN
      && blockTopY - flyoutScrollY >= flyout.MARGIN)
  ) return;

  // In the underlying code scrollValue should be lt 0 or it would not trigger scrolling.
  const targetScrollY = Math.max(blockBottomY - (flyoutHeight / 2), 1);

  flyout.scrollTo(targetScrollY);
};

export default {
  getWorkspaceTopBlocksByType,
  getFlyoutWorkspaceBlockByType,
  getWorkspaceBlocksNext,
  getBlockRectData,
  scrollFlyoutBlockInView,
  scrollBlockInView,
};
