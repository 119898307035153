import UAParser from 'ua-parser-js';

const parser = new UAParser();

export default () => {
  const ua = navigator.userAgent;
  if (/micromessenger/i.test(ua)) {
    return false;
  }
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)
    || [];

  // detect IE/Edge
  if (/trident/i.test(M[1]) || /Edge/.test(ua)) {
    // tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
    // return {name:'IE',version:(tem[1]||'')};
    return false;
  }

  // 2020-06-22 合作商测试 暂时去掉
  return true;

  // detect opera (based on chromium 50 at least)
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null && parseInt(tem[1], 10) < 37) {
      return false;
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  tem = ua.match(/version\/(\d+)/i);
  if (tem !== null) {
    M.splice(1, 1, tem[1]);
  }

  if (
    (M[0].toLowerCase() === 'chrome' && parseInt(M[1], 10) < 58)
    || (M[0].toLowerCase() === 'firefox' && parseInt(M[1], 10) < 52)
    || (M[0].toLowerCase() === 'safari' && parseInt(M[1], 10) < 9)
  ) {
    return false;
  }
  return true;
};

export const isIpad = () => {
  const ua = navigator.userAgent;
  if (/iPad/i.test(ua)) {
    return true;
  }
  return false;
};

export const isLowVersionIpad = () => {
  const os = parser.getOS();

  if (os.name.toLowerCase() === 'ios') {
    return parseFloat(os.version) < 11;
  }
  return false;
};
