import React from 'react';
import './forbidden-shade.css';

const LockModal = () => (
  <div className="lock-modal">
    <div className="paysage" />
    <p>认真听课，不要动鼠标哦</p>
  </div>
);

export default LockModal;
