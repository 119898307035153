exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".puzzle-sprite-item_sprite-item_pIDb0 {\n    width: 68px;\n    height: 63px;\n    border: solid 2px #979797;\n    background-color: #33333c;\n    border-radius: 8px;\n}\n.puzzle-sprite-item_active_2e-iL {\n    background-color: #ffffff;\n    border: 2px solid hsla(215, 100%, 65%, 1);\n}\n.puzzle-sprite-item_active_2e-iL .puzzle-sprite-item_name_2Qbc2 {\n    color: #fff;\n    background: hsla(215, 100%, 65%, 1);\n}\n.puzzle-sprite-item_imgBox_7QTpQ {\n    width: 100%;\n    height: 100%;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n}\n.puzzle-sprite-item_spriteImg_102BN {\n    width: 100%;\n    height: calc(100% - 18px);\n    -o-object-fit: contain;\n       object-fit: contain;\n}\n.puzzle-sprite-item_name_2Qbc2 {\n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n    -o-text-overflow: ellipsis;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    min-width: 0;\n    font-size: 12px;\n    display: -webkit-inline-box;\n    display: -webkit-inline-flex;\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    height: 18px;\n    color: rgba(255, 255, 255, 0.87);\n}\n", ""]);

// exports
exports.locals = {
	"sprite-item": "puzzle-sprite-item_sprite-item_pIDb0",
	"spriteItem": "puzzle-sprite-item_sprite-item_pIDb0",
	"active": "puzzle-sprite-item_active_2e-iL",
	"name": "puzzle-sprite-item_name_2Qbc2",
	"imgBox": "puzzle-sprite-item_imgBox_7QTpQ",
	"spriteImg": "puzzle-sprite-item_spriteImg_102BN"
};