import i18n from 'public-repo/i18n';

let userInteraction = {
    keyboard: [],
    mousemove: false,
    mousedown: false,
    clickSprite: false
};
let isSpriteClicked = false;
let userNoInteractionDetected = false;
let noInteractionTimestamp = 0;

const resetUserInteraction = () => {
    isSpriteClicked = false;
    noInteractionTimestamp = 0;
    userNoInteractionDetected = false;
    userInteraction = {
        keyboard: [],
        mousemove: false,
        mousedown: false,
        clickSprite: false
    };
};

const registerScriptGlowOn = (vm, puzzleEngine) => {
    vm.on('SCRIPT_GLOW_ON', data => {
        const block = puzzleEngine.workspaceVMBlocks.getBlock(data.id);
        if (block && block.opcode === 'event_whenthisspriteclicked') {
            isSpriteClicked = true;
        }
    });
};

const checkIfNeedInteraction = userAnswerPath => {
    // todo add editableTargets blocks detect
    userAnswerPath.forEach(path => {
        let matches = /\[KEY_OPTION="([^\]]+)"/gi.exec(path);
        matches = matches ? [matches[1]] : [];
        matches
            .map(key => key.replace(/"/g, ''))
            .forEach(key => {
                if (userInteraction.keyboard.indexOf(key) === -1) {
                    userInteraction.keyboard.push(key);
                }
            });

        if (
            /\[(towards|to|touchingobjectmenu|distancetomenu)="mouse"\]/i.test(
                path
            )
        ) {
            userInteraction.mousemove = true;
        }
        if (/event_whenthisspriteclicked/i.test(path)) {
            userInteraction.clickSprite = true;
        }
        if (/mousedown/i.test(path)) {
            userInteraction.mousedown = true;
        }
    });

    noInteractionTimestamp = Date.now();

    return (
        userInteraction.keyboard.length > 0 ||
        userInteraction.mousedown ||
        userInteraction.mousemove ||
        userInteraction.clickSprite
    );
};

const generateNoInteractionTip = () => {
    if (userInteraction.keyboard.length) {
        let tipString = '';
        const keys = userInteraction.keyboard;
        if (keys.indexOf('any') > -1) {
            tipString = i18n.get('Try to press any keys');
        } else {
            tipString = `${i18n.get('Try to press')} ${
                keys.length > 1
                    ? `${keys.slice(0, -1).join(', ')} ${i18n.get('and')} ${
                          keys[keys.length - 1]
                      } ${i18n.get('keys')}`
                    : `${keys[0]} ${i18n.get('key')}`
            }`;
        }
        return tipString;
    }
    if (userInteraction.mousedown) {
        return i18n.get('Try to click on playground');
    }
    if (userInteraction.clickSprite) {
        return i18n.get('Try to click the actor');
    }
    return null;
};

const detectUserAction = (vm, handleUserHasAction, handleUserNoAction) => {
    if (
        userInteraction.keyboard.some(key =>
            vm.runtime.ioDevices.keyboard.getKeyIsDown(key)
        ) ||
        (userInteraction.mousedown && vm.runtime.ioDevices.mouse.getIsDown()) ||
        (userInteraction.clickSprite && isSpriteClicked)
    ) {
        userNoInteractionDetected = false;
        if (userInteraction.clickSprite) isSpriteClicked = false;
        noInteractionTimestamp = Date.now();
        handleUserHasAction();
    }
    if (
        Date.now() - noInteractionTimestamp > 5000 &&
        !userNoInteractionDetected
    ) {
        userNoInteractionDetected = true;
        const tip = generateNoInteractionTip(userInteraction);
        handleUserNoAction(tip);
    }
};

export {
    resetUserInteraction,
    registerScriptGlowOn,
    checkIfNeedInteraction,
    detectUserAction
};
