import React from 'react';
import Confetti from 'react-dom-confetti';
import PropTypes from 'prop-types';
import {
  AudioManager,
  AudioManagerType,
} from 'PublicRepo/components/AudioManager/audioManager';
import Coin from './Coin';

import Stars from './Stars';
import BellMan from './BellMan';

import 'magic.css/dist/magic.min.css'; //eslint-disable-line
import './index.less';

// 烟花参数
const ConfettiConfig = {
  angle: 90,
  spread: 230,
  startVelocity: 40,
  elementCount: 30,
  decay: 0.92,
};
/**
 * codeInfo: {
 *     gold: 现有金币,
 *     code: 现有编程币,
 *     goldGrow: 金币增加,
 *     codeGrow: 编程币增加,
 *     goldMax: 金币上限,
 *     codeMax: 编程币上限,
 * }
 * button: 要渲染的按钮,可参开 Puzzle1Popup 的写法
 * showConfetti: 显示烟花的标志
 * behaviorInfo: 完成表现信息
 * usedInfo: 使用情况(编程币左侧的 ul)
 * tips: 提示信息,若无则不传入
 */
class BaseDialog extends React.Component {
  static propTypes = {
    stars: PropTypes.number.isRequired,
    tips: PropTypes.string,
    coinInfo: PropTypes.object.isRequired,
    listItem: PropTypes.array,
    behaviorInfo: PropTypes.object,
    buttons: PropTypes.object,
    showConfetti: PropTypes.bool.isRequired,
    completeAnimationCallback: PropTypes.func, // 播放动画完成后的回调
    isLast: PropTypes.bool.isRequired,
    bellManType: PropTypes.number, // 人物形象
  };

  constructor(props) {
    super(props);
    this.state = {
      confetti: false,
      status: 0,
    };
    this.timer = 0;
  }

  get getSuccess() {
    return this.props.stars !== 0;
  }

  // 人物形象 1为小贝形象 2为鲸幂形象
  get bellManType() {
    if (this.props.bellManType) {
      return this.props.bellManType;
    }

    return 1;
  }

  startConfetti = () => {
    this.setState({ confetti: true });
  };

  componentDidMount() {
    // 阻止滚动
    document.body.style.overflow = 'hidden';

    const { completeAnimationCallback } = this.props;

    if (this.props.stars === 0) {
      AudioManager.play(AudioManagerType.DEFEAT);
    }

    if (this.props.showConfetti && this.getSuccess && !this.props.isLast) {
      // 开始放烟花
      setTimeout(() => {
        AudioManager.play(AudioManagerType.CONFETTI);
        this.startConfetti();
      }, 1000);
    }

    if (this.props.isLast) {
      this.setState({ status: 4 });
    } else {
      // status increase
      this.timer = setInterval(() => {
        this.setState({
          status: this.state.status + 1,
        });
        if (this.state.status > 4 || !this.state.status) {
          clearInterval(this.timer);
          completeAnimationCallback && completeAnimationCallback();
        }
      }, 1500);
    }
  }

  componentWillUnmount() {
    // 恢复滚动
    document.body.style.overflow = '';
    clearInterval(this.timer);
  }

  renderUsedInfo() {
    const { listItem } = this.props;
    if (this.getSuccess && listItem.length !== 0) {
      return (
        <ul className="use">
          {listItem.map((item, index) => (
            <li
              key={index}
              className="use-block"
              dangerouslySetInnerHTML={{
                __html: item.text.replace(/({\w*})/g, match => {
                  const str = match.slice(1, match.length - 1);
                  return `<span class="important">${item[str]}</span>`;
                }),
              }}
            />
          ))}
        </ul>
      );
    }
    return null;
  }

  render() {
    const {
      stars,
      tips,
      coinInfo,
      behaviorInfo,
      buttons,
      showConfetti,
      isLast,
    } = this.props;
    let flag = 0; // 用于分步显示

    let popupBorder;
    if (this.bellManType === 2) {
      // 鲸幂
      popupBorder = 'jimmy';
    } else {
      // 小贝
      popupBorder = 'bellMan';
    }

    return (
      <div
        className="base-popup"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="mask" />
        <div
          className={`popup-window ${popupBorder} ${
            this.getSuccess ? 'success' : 'defeat'
          }`}
        >
          <div>
            <BellMan stars={stars} bellManType={this.bellManType} />

            {this.getSuccess && showConfetti && (
              <Confetti
                className="confetti"
                config={ConfettiConfig}
                active={this.state.confetti}
              />
            )}
          </div>

          <Stars number={stars} isLast={isLast} />

          <div className="dialog-content">
            <div
              className={`behavior ${
                this.state.status > flag ? 'show' : 'hide'
              } ${!isLast && 'spaceInDown'} ${
                this.getSuccess ? 'success' : 'defeat'
              }`}
            >
              {this.state.status > flag ? behaviorInfo : 'default'}
            </div>

            {this.getSuccess && ++flag && (
              <div
                className={`info-wrapper ${
                  this.state.status > flag ? 'show' : 'hide'
                } ${!isLast && 'spaceInDown'}`}
              >
                {this.renderUsedInfo()}

                <div className="coins">
                  <div className="gold-wrapper">
                    <Coin
                      success={this.getSuccess}
                      active={this.state.status > flag}
                      type="gold"
                      now={coinInfo.gold || 0}
                      grow={isLast ? 0 : coinInfo.goldGrow}
                      max={coinInfo.goldMax || 0}
                    />
                  </div>
                  <div className="code-wrapper">
                    <Coin
                      success={this.getSuccess}
                      active={this.state.status > flag}
                      type="code"
                      now={coinInfo.code || 0}
                      grow={isLast ? 0 : coinInfo.codeGrow}
                      max={coinInfo.codeMax || 0}
                    />
                  </div>
                </div>
              </div>
            )}

            {tips && ++flag && (
              <div
                className={`tips ${
                  this.state.status > flag ? 'show' : 'hide'
                } ${!isLast && 'spaceInDown'}`}
              >
                <div
                  className="tips-content"
                  dangerouslySetInnerHTML={{ __html: tips }}
                />
              </div>
            )}

            {buttons}
          </div>
        </div>
      </div>
    );
  }
}

export default BaseDialog;
