import { from, Subject, combineLatest } from 'PublicRepo/node_modules/rxjs';

import {
  switchMap,
  withLatestFrom,
} from 'PublicRepo/node_modules/rxjs/operators';
import { Event } from 'PublicRepo/node_modules/leancloud-realtime';
import {
  realtimeInstance,
  ActionMessageType,
  StateUpdateMessageType,
} from './init';

// 是否显示日志在控制台
const showLog = __DEMO__ || __DEV__;
let connected = false;
let cb = () => {};
let controlState = {};

const whenConnectedStateChange = newCb => {
  cb = newCb;
};
/*

whenConnectedStateChange( connected => this.setState({ connected }));

*/
const getConnectedState = () => connected;

/** networkStatusSubject 根据网络可用情况发送 true / false 事件 */
const networkStatusSubject = new Subject();

/**
 * 根据 leancloud 网络状态转化为 可用 / 不可用 networkStatus
 */
(() => {
  realtimeInstance.on(Event.DISCONNECT, () => networkStatusSubject.next(false));
  realtimeInstance.on(Event.OFFLINE, () => networkStatusSubject.next(false));
  realtimeInstance.on(Event.ONLINE, () => networkStatusSubject.next(true));
  realtimeInstance.on(Event.SCHEDULE, () => networkStatusSubject.next(false));
  realtimeInstance.on(Event.RETRY, () => networkStatusSubject.next(false));
  realtimeInstance.on(Event.RECONNECT, () => networkStatusSubject.next(true));
})();

/*
    rtc 事件流
*/
/*
初始化客户端
userHashid -> initClient(by hashId)
uid ----- uid ---- uid ------ uid
|          |          |          |
----- client ---client-----client----client (考虑到切换账号的情况)
永远获取最新的 uid 新建客户端
*/

const hashIdSubject = new Subject();

const userClientObservable = hashIdSubject
  .asObservable()
  .pipe(switchMap(hashId => from(realtimeInstance.createIMClient(hashId))));

/*
加入班级
classId ---> joinConversation(by classId)
 cid --------- cid ------- cid
  |             |           |
 ------ conversation ------ conversation ------ conversation (考虑到切换班级的情况)
 永远获取最新的 classId 建立新的群聊会话
*/
const classIdSubject = new Subject();

const conversationObservable = combineLatest(
  classIdSubject.asObservable(),
  userClientObservable,
).pipe(
  switchMap(([classid, userClientInstance]) => from(userClientInstance.getConversation(classid))),
);

/*
消息分为两种
 * 动作型: 比如让客户端更新数据，这种不需要关心历史
 * 状态更新: 全新的 global state 作为 payload 传入，初始化的时候需要找到最新的历史记录

 action msg ---- action msg ---- action msg ---- action msg ----
 |                  |                  |               |
 action  ------- action  -------- action  ------- action  ------
*/

// 动作事件监听
const actionMessageListenerSubject = new Subject();

// 状态更新事件更新
const stateUpdateMessageListenerSubject = new Subject();

// 监听群聊中的动作事件
const actionMessageCallerSubject = new Subject();

// 监听群聊中的状态更新事件
const stateUpdateMessageCallerSubject = new Subject();

const messageHandler = message => {
  switch (message.type) {
    case ActionMessageType:
      showLog && console.log('receive new action message,', message);
      actionMessageListenerSubject.next(message.op);
      return;
    case StateUpdateMessageType:
      showLog && console.log('receive new state update message,', message);
      stateUpdateMessageListenerSubject.next(message.op);
      return;
    default:
      throw new Error('no match message type.');
  }
};

let previousConversation = null;
conversationObservable.subscribe(conversation => {
  showLog && console.log('receive new conversation,', conversation);
  if (previousConversation) {
    previousConversation.off(Event.MESSAGE, messageHandler);
  }
  previousConversation = conversation;
  conversation.on(Event.MESSAGE, messageHandler);

  conversation
    .queryMessages({
      limit: 1,
      type: StateUpdateMessageType,
    })
    .then(messages => {
      const lastMessage = messages && messages[0];
      showLog
        && console.log('receive latest state update message,', lastMessage);
      lastMessage && stateUpdateMessageListenerSubject.next(lastMessage.op);
      connected = true;
      cb(connected);
    });
});

actionMessageCallerSubject
  .asObservable()
  .pipe(withLatestFrom(conversationObservable))
  .subscribe(([actionMessage, conversation]) => {
    conversation.send(actionMessage);
  });

stateUpdateMessageCallerSubject
  .asObservable()
  .pipe(withLatestFrom(conversationObservable))
  .subscribe(([stateUpdateMessage, conversation]) => {
    conversation.send(stateUpdateMessage);
  });

const actionMessageObservable = actionMessageListenerSubject.asObservable();
const networkStatusObservable = networkStatusSubject.asObservable();

networkStatusObservable.subscribe(status => console.log('network status update, is network avaliable:', status));

const stateUpdateMessageObservable = stateUpdateMessageListenerSubject.asObservable();

stateUpdateMessageObservable.subscribe(newState => (controlState = newState));

/*
const state =  Object.assign({}, { studentAbleToOp: true } , getState())

stateUpdateMessageObservable.next( state )
*/
const getState = () => controlState;

const modifyState = state => (controlState = { ...controlState, ...state });

export {
  actionMessageObservable, // 动作型消息订阅源
  stateUpdateMessageObservable, // 状态更新消息订阅源
  classIdSubject, // 需要更新 classId 时，使用 classIdSubject.next(classId) 更新
  hashIdSubject, // 需要更新 hashId 时，使用 hashIdSubject.next(hashId) 更新
  actionMessageCallerSubject, // 需要发送消息时，使用 actionMessageCallerSubject.next(new ActionMessage()) 发送
  stateUpdateMessageCallerSubject, // 需要发送消息时，使用 stateUpdateMessageCallerSubject.next(new ActionMessage()) 发送
  getState,
  whenConnectedStateChange,
  getConnectedState,
  modifyState,
  networkStatusObservable, // 网络状态订阅源
};
