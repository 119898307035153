export default {
  'actor-info_X': 'X',
  'actor-info_Y': 'Y',
  'actor-info_Size': 'Size',
  'actor-info_Direction': 'Direction',
  puzzle2_intro_next: 'next',
  puzzle2_intro_back: 'back',
  puzzle2_intro_skip: 'skip',
  puzzle2_intro_done: 'done',
  'work-adapt': 'ADAPT',
  'work-edit': 'EDIT',
  'work-re-edit': 'RE-EDIT',
  'work-mark': 'MARK',
  'work-liked': 'Liked',
  'work-compose': 'COMPOSE',
  'share-JOIN-NOW': 'JOIN NOW',
  "share_Creator's words": "Creator's words",
  share_Play: 'Play',
  'puzzle-answer_I want skip it': 'I want skip it',
  'puzzle-answer_Skip it': 'Skip it',
  "puzzle-answer_I'll try it": "I'll try it",
  'puzzle-answer_On the right side is the answer of other students,':
    'On the right side is the answer of other students,',
  'puzzle-answer_Do you have any other ideas?': 'Do you have any other ideas?',
  'puzzle-answer_Your gold coins is not enough, maybe you need to solve it by yourself':
    'Your gold coins is not enough, maybe you need to solve it by yourself',
  "puzzle-answer_OK, I'll try it again": "OK, I'll try it again",
  'puzzle-answer_Have you already tried your best? ':
    'Have you already tried your best? ',
  'puzzle-answer_Little Monkey': 'Little Monkey',
  'puzzle-answer_ can show you how other students do it.':
    ' can show you how other students do it.',
  'puzzle-answer_But you have to pay a certain amount of gold~~':
    'But you have to pay a certain amount of gold~~',
  "puzzle-answer_Forget it, I'll try again": "Forget it, I'll try again",
  'puzzle-answer_Take a glance（': 'Take a glance（',
  'puzzle-answer_coins': ' ',
  'puzzle-answer_If you find it difficult, try to pay attention to the ':
    'If you find it difficult, try to pay attention to the ',
  'puzzle-answer_, or you can look back the ': ', or you can look back the ',
  'puzzle-answer_Believe yourself, you can do it!':
    'Believe yourself, you can do it!',
  'puzzle-answer_Tips': 'Tips',
  'puzzle-answer_Video': 'Video',
  "puzzle-answer_OK, I'll have a look": "OK, I'll have a look",
  'puzzle-answer_I still do not understand': 'I still do not understand',
  'puzzle-answer_If you skip this puzzle, you can learn the next lesson directly, but unfortunately you can not get any coins now. you can redo this puzzle at any time later.':
    'If you skip this puzzle, you can learn the next lesson directly, but unfortunately you can not get any coins now. you can redo this puzzle at any time later.',

  '__Submit Back to IDE': 'Back',
  '__Scratch-Playground Play': 'Play',
  '__Scratch-Playground Stop': 'Stop',

  '__Submit-Pages Next': 'Next',
  '__Submit-Pages Previous': 'Previous',
  '__Submit-Pages Confirm Submit': 'Confirm',
  '__Submit-Pages Submitting': 'Submitting',
  '__Submit-Pages Confirm Publish': 'Confirm',
  '__Submit-Pages Publish': 'Publishing',
  '__Submit-Pages Submit Project': 'Submit Project',
  '__Submit-Pages Please give your project a name and write an introduction.':
    'Please give your project a name and write an introduction.',
  '__Submit-Pages Show your teachers and friends your great project by video.':
    'Show your teachers and friends your great project by video.',
  '__Submit-Pages Share it with your friends！': 'Share it with your friends！',
  '__Submit-Pages Lesson': 'Lesson',
  '__Submit-Pages Loading': 'Loading',

  '__Submit-Pages Confirm and get the rewards': 'Confirm and get the rewards',
  '__Submit-Pages Video Publish': 'Video Publish',
  '__Submit-Pages Swicth to quick publish': 'Swicth to quick publish',
  '__Submit-Pages Can not get 100 coins if your switch to quick publish!':
    'Can not get 100 coins if your switch to quick publish!',
  '__Submit-Pages Continue recording': 'Continue recording',
  '__Submit-Pages Give up': 'Give up',

  '__Submit-Pages Already get rewards~': 'Already get rewards~',
  '__Submit-Pages The upper limit has been reached today.':
    'The upper limit has been reached today.',
  'share Recommended': 'Recommend',
  'share New User': 'New User Only',
  'share Listen to the creator': 'Listen to the creator',
  'share What is Coding for Kids': 'What is Coding for Kids?',
  'share About BellCode': 'About BellCode',
  'share Learn Us': 'Learn Us',
  'teacher-comment_Please confirm the comment is correct!':
    'Please confirm the comment is correct!',
  'teacher-comment_After confirming the comments, the comments will be sent to the student account immediately. Please confirm that the comment is correct.':
    'After confirming the comments, the comments will be sent to the student account immediately. Please confirm that the comment is correct.',
  'teacher-comment_Check again': 'Check again',
  'teacher-comment_Confirmed and submit': 'Confirmed and submit',

  // 录屏相关
  '__Record-status Start': 'Start',
  '__Record-status Restart': 'Restart',
  '__Record-status Complete': 'Complete',
  '__Record-status Completed': 'Completed',
  '__Record-status Preparing': 'Preparing',
  '__Record-status Recording': 'Recording',
  '__Record-evaluate great': 'great',
  '__Record-evaluate good': 'good',
  '__Record-evaluate bad': 'bad',
  '__Record-evaluate noise': 'noise',
  '__Record-status noSound': 'no-sound',
  '__Record-status recorded': 'recorded',
  '__Record-status unRecord': 'un-record',

  // 录屏提示相关
  '__Record-tips Lowly Sound':
    'Background music will be added since the sound is too low to be recorded',
  '__Record-tips Click here to record': 'Click here to record',

  // 权限弹框
  '__Permission-box Permission': 'Please click “allow” to use the microphone',
  '__Permission-box I got it': 'I GOT IT',

  // 保存提示弹窗
  '__Save-box Want Save':
    'The recording has been completed. Do you want to save the video?',
  '__Save-box Save': 'Save',
  '__Save-box Restart Cancel': 'Cancel',
  '__Save-box Restart Yes': 'Yes',

  // 重新录制弹窗
  '__Restart-box Restart': 'Restart',
  '__Restart-box Restart Record':
    'To restart the recording, the completed video will be delected',
  '__Restart-box Restart Cancel': 'Cancel',
  '__Restart-box Restart Yes': 'Yes',

  // 离开弹窗
  '__Leave-box Leave': 'Leave',
  '__Leave-box Leave Page':
    'The video will not be saved if you leave this page',
  '__Leave-box Cancel': 'Cancel',
  '__Leave-box Yes': 'Yes',

  // 首次录制弹窗
  '__First-record-box Project will start':
    'Next, your project will start running, and the microphone will start recording at the same time (15 seconds to 2 minutes)',
  '__First-record-box Unable to record':
    'Unable to record sound with no microphone device detected',
  '__First-record-box Operate your project': 'Operate your project',
  '__First-record-box Introduce your project': 'Introduce your project',
  '__First-record-box Cancel': 'Cancel',
  '__First-record-box Ok': 'Ok',
  '__First-record-box Follow me': 'Follow with me',

  // 保存进度
  '__Save-process Need time':
    'It needs 1 to 5 minutes to save the video.Please do not close the page.',
  '__Save-process Check network':
    'Please check your network connection then try again',
  '__Save-process Cancel': 'Cancel',
  '__Save-process Try again': 'Try again',
  '__Save-process Saving video': 'Saving video',
  '__Save-process Failed': 'Failed',
  '__Save-process Succeeded': 'Succeeded',

  // 跳过录屏
  '__Record Skip': 'Skip',

  '__Record-example example': 'example',
  '__Record no voice input': 'no voice input',

  // 录屏ipad相关
  '__Record-ipad Ipad cant use': "Oops! It's unable to record a video in IPad.",
  '__Record-ipad Cant see':
    'Teacher and mate can not see your great introduction',
  '__Record-ipad Use computer': 'using a computer ',
  '__Record-ipad record': 'to record.',

  // 录屏浏览器支持相关
  '__Record-browser Browser cant use':
    "Oops! It's unable to record a video in your browser.",
  '__Record-browser Cant see':
    'Teacher and mate can not see your great introduction',
  '__Record-browser Use Chrome': 'Using Chrome ',
  '__Record-browser To record': 'to record',
  '__Record-browser Download': 'Download',

  // 提交作品提示
  '__Submit-homework Submit Homework': 'Submit Homework',
  '__Submit-homework I know': 'I know',
  '__Submit-homework Teacher mark homework':
    'Teacher will mark your homework within 24 hours, and inform you on wechat or website.',
  '__Submit-homework Resubmit tip':
    'If resubmit, the previous comments will be removed, teacher will mark your homework again.',
};
