import Fetch from 'public-repo/lib/fetch';
import Detect from 'Common/detect';
import Config from '../config';

export default {
    getLessonInfo () {
        return Fetch({
            url: Config.serverIo.getLessonInfo,
            params: {
                lessid: Detect.lessonId,
                classid: Detect.classId,
                package_id: Detect.packageId,
                // Add timestamp to disable cache when back from teacher_website
                timestamp: +new Date()
            }
        });
    },
    checkIfL0 () {
        return Fetch({
            url: Config.serverIo.checkIfL0,
            params: {
                type: 'diy2',
                id: Detect.diyId
            }
        });
    },
    getL0video () {
        return Fetch({
            url: Config.serverIo.getL0video
        });
    }
};
