// import WebStorage from 'PublicRepo/lib/webStorage';
import Track from 'PublicRepo/components/Track/Track';

const formatTrackInfo = (popData, data) => {
  let popInfo = {};

  if (popData) {
    const star = popData.score;
    const {
      code_num,
      gold_num,
      current_code_num,
      current_gold_num,
    } = popData.pop_up;
    popInfo = {
      round_Star_get: star,
      round_CodeCoin_get: current_code_num,
      round_GoldCoin_get: current_gold_num,
      round_CodeCoin_total: code_num,
      round_GoldCoin_total: gold_num,
    };
  }

  return {
    ...popInfo,
    ...data,
  };
};

export default {
  trackFirstCheck(popData, storageData) {
    Track.track('Map_Sum_check_first', formatTrackInfo(popData, storageData));
  },

  trackFirstSkip(popData, storageData) {
    Track.track('Map_Sum_skip_first', formatTrackInfo(popData, storageData));
  },

  trackFirstRestart(popData, storageData) {
    Track.track('Map_Sum_restart_first', formatTrackInfo(popData, storageData));
  },

  trackFirstNext(popData, storageData, cb) {
    Track.track(
      'Map_Sum_next_first',
      formatTrackInfo(popData, storageData),
      cb,
    );
  },
};
