import React, {Component} from 'react';
import PropTypes from 'prop-types';

import style from './puzzle2-tips.css';
import {ToastContainer, toast} from 'react-toastify';
import puzzle2AnswerIcon from 'Asset/img/puzzle2-answer-icon.svg';
import puzzle2PuzzledIcon from 'Asset/img/puzzle2-puzzled-icon.svg';
import closeIcon from 'Asset/img/tips-close.svg';
import i18n from 'PublicRepo/i18n/ide';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import InlineSVG from 'react-inlinesvg';

import bindall from 'lodash.bindall';

const imgMap = {
    empty: puzzle2PuzzledIcon,
    answer: puzzle2AnswerIcon
};
const hasButtonList = ['answer'];

const obj = {
    clearId() {
        obj.type = null;
        obj.id = null;
    }
};

const noop = function() {};

class Text extends Component {
    constructor(props) {
        super(props);
        bindall(this, ['handleAnswerClick', 'handleCloseIconClick']);
    }
    handleAnswerClick() {
        const {btnCb} = this.props;
        if (btnCb) btnCb();
        this.handleCloseIconClick();
    }
    handleCloseIconClick() {
        const {closeCb, closeToast} = this.props;
        if (closeCb) closeCb();
        closeToast();
        obj.clearId();
    }
    render() {
        const {type, text} = this.props;
        return (
            <div className={style.tips}>
                <img
                    className={style.icon}
                    src={imgMap[type]}
                />
                <span className={style.text}>{text}</span>
                {hasButtonList.includes(type) && (
                    <button
                        className={style.btn}
                        onClick={this.handleAnswerClick}
                    >
                        {i18n.get('See history answer')}
                    </button>
                )}
                <span
                    onClick={this.handleCloseIconClick}
                    className={style.close}
                >
                    <InlineSVG src={closeIcon} />
                </span>
            </div>
        );
    }
}
Text.propTypes = {
    btnCb: PropTypes.func,
    closeCb: PropTypes.func,
    closeToast: PropTypes.func,
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['empty', 'answer']).isRequired
};
Text.defaultProps = {
    type: 'empty'
};

const tipsCall = ({type, text, btnCb = noop, closeCb = noop}) => {
    if (obj.type === type) {
        obj.repeat++;
        toast.update(obj.id, {
            className: obj.repeat % 2 === 0 ? style.anotherShake : style.shake,
            render: (
                <Text
                    type={type}
                    text={text}
                    btnCb={btnCb}
                    closeCb={closeCb}
                />
            )
        });
    } else {
        obj.repeat = 0;
        let lastId = false;
        if (obj.id) {
            lastId = obj.id;
        }
        obj.type = type;
        obj.id = toast(
            <Text
                type={type}
                text={text}
                btnCb={btnCb}
                closeCb={closeCb}
            />,
            {
                autoClose: false,
                position: toast.POSITION.TOP_CENTER,
                pauseOnHover: false,
                draggable: false,
                onOpen: setTimeout(() => {
                    if (lastId) {
                        toast.dismiss(lastId);
                    }
                }, 200),
                closeOnClick: false,
                pauseOnFocusLoss: false,
                newestOnTop: true,
                closeButton: false,
                className: style.container
            }
        );
    }
};

tipsCall.dismiss = () => {
    if (obj.id) {
        toast.dismiss(obj.id);
    }
    obj.clearId();
};
const anotherToastContainer = () => <ToastContainer newestOnTop />;
export {tipsCall, anotherToastContainer as ToastBox};
