import {pythonOpcodeDic as POD} from 'py-to-scratch';
export const pythonOpcodeDic = POD;
export const snippets = {
    elif: {
        prefix: 'elif',
        body: ['elif ${1:condition}:', '    $0']
    },
    else: {
        prefix: 'else',
        body: ['else:', '    $0']
    },

    ...pythonOpcodeDic
};
