import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ScratchBlocks from 'scratch-blocks';
import VM from 'scratch-vm';

// import log from '../../lib/log';
import Puzzle from './puzzle/puzzle';
import {puzzleReady} from '../reducers/puzzle/status.js';

const PuzzleManagerHOC = function(WrappedComponent) {
    class PuzzleManager extends React.Component {
        constructor(props) {
            super(props);
            this.loadPuzzleConfig = this.loadPuzzleConfig.bind(this);
        }

        componentDidMount() {
            this.props.puzzleEngine.connectVMWorkspace(
                this.props.vm,
                ScratchBlocks.getMainWorkspace()
            );
        }

        loadPuzzleConfig() {
            if (this.isConfigInit) {
                return;
            }

            this.isConfigInit = true;

            try {
                this.props.puzzleEngine.load();
            } catch (err) {
                console.log(err); // eslint-disable-line
            } finally {
                if (this.props.pythonMode) {
                    this.props.puzzleEngine.mutilPythonRoleInit(
                        this.props.setPuzzleReady
                    );
                } else {
                    this.props.setPuzzleReady();
                }
            }
        }

        render() {
            return (
                <WrappedComponent
                    onload={this.loadPuzzleConfig}
                    {...this.props}
                />
            );
        }
    }

    PuzzleManager.propTypes = {
        puzzleEngine: PropTypes.instanceOf(Puzzle).isRequired,
        setPuzzleReady: PropTypes.func.isRequired,
        vm: PropTypes.instanceOf(VM).isRequired,
        pythonMode: PropTypes.bool
    };

    const mapStateToProps = state => ({
        puzzleEngine: state.puzzle.engine,
        vm: state.scratchGui.vm,
        pythonMode: state.puzzle.python.pythonMode
    });

    const mapDispatchToProps = dispatch => ({
        setPuzzleReady: () => dispatch(puzzleReady())
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(PuzzleManager);
};

export {PuzzleManagerHOC as default};
