import {connect} from 'react-redux';
import {
    activateDeck,
    closeCards,
    nextStep,
    prevStep,
    gotoStep,
    dragCard,
    startDrag,
    endDrag,
    addActorHighlight,
    removeActorHighlight
} from '../reducers/activity/custom-guide';

import CardsComponent from '../components/custom-guide-cards/custom-guide-cards.jsx';

const mapStateToProps = state => ({
    visible: state.activity.customGuide.visible,
    content: state.activity.customGuide.content,
    activeDeckId: state.activity.customGuide.activeDeckId,
    step: state.activity.customGuide.step,
    x: state.activity.customGuide.x,
    y: state.activity.customGuide.y,
    isRtl: state.locales.isRtl,
    locale: state.locales.locale,
    dragging: state.activity.customGuide.dragging,
    customGuideVisible: state.activity.customGuide.visible,
    guideData: state.activity.tutorial.tutorialInfo
});

const mapDispatchToProps = dispatch => ({
    onActivateDeckFactory: id => () => dispatch(activateDeck(id)),
    onCloseCards: () => dispatch(closeCards()),
    onNextStep: () => dispatch(nextStep()),
    onPrevStep: () => dispatch(prevStep()),
    gotoStep: step => dispatch(gotoStep(step)),
    onDrag: (e_, data) => dispatch(dragCard(data.x, data.y)),
    onStartDrag: () => dispatch(startDrag()),
    onEndDrag: () => dispatch(endDrag()),
    addActorHighlight: (actorIndex, actorName) =>
        dispatch(addActorHighlight(actorIndex, actorName)),
    removeActorHighlight: () => dispatch(removeActorHighlight())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardsComponent);
