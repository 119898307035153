import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';

const ShowAnswer = props => (
  <div className="answer answer-show">
    <span className="answer-bg" />
    <div className="answer-txt_wrapper">
      <p className="answer-txt">
        {i18n.get(
          'puzzle-answer_On the right side is the answer of other students,',
        )}
        <br />
        {i18n.get('puzzle-answer_Do you have any other ideas?')}
      </p>
      <div className="answer-btn_wrapper">
        <span
          role="button"
          tabIndex="0"
          className="answer-btn answer-btn--better"
          onClick={props.onClose}
        >
          {i18n.get("puzzle-answer_I'll try it")}
        </span>
        {!props.isPuzzleCompleted && (
          <span
            role="button"
            tabIndex="1"
            className="answer-btn"
            onClick={props.showSkipConfirm}
          >
            {i18n.get('puzzle-answer_I want skip it')}
          </span>
        )}
      </div>
    </div>
    <div className="answer-key_wrapper">
      <div className="answer-key">
        <img
          className="answer-key_img"
          src={props.answer.answer || props.answer}
          alt={i18n.get('answer')}
          title={i18n.get('answer')}
        />
      </div>
    </div>
  </div>
);

ShowAnswer.propTypes = {
  onClose: PropTypes.func.isRequired,
  answer: PropTypes.string.isRequired,
  showSkipConfirm: PropTypes.func,
  isPuzzleCompleted: PropTypes.bool,
};

export default ShowAnswer;
