import React from 'react';
import PropTypes from 'prop-types';
import VM from 'scratch-vm';

import styles from './puzzle-playground.css';
import Alerts from '../../../containers/alerts';
import {STAGE_DISPLAY_SIZES} from '../../../lib/layout-constants';
import Box from '../../../components/box/box';
import Stage from '../../../containers/stage';
import Blocks from '../../../containers/blocks';

import puzzle2PlaygroundRetract from './puzzle2-playground-retract.svg';

const PuzzlePlaygroundComponent = ({
    alertsVisible,
    isRendererSupported,
    isWorkspaceExpended,
    handleToggleWorkspaceExpend,
    vm,
    pythonMode,
    isRtl
}) => (
    <Box
        className={`${styles.stageContainer} ${
            pythonMode ? styles.pythonMode : ''
        }`}
        id="stage-playground"
    >
        {alertsVisible ? <Alerts className={styles.alertsContainer} /> : null}
        <Box
            className={styles.stageCanvasWrapper}
            dir={isRtl ? 'rtl' : 'ltr'}
        >
            {isRendererSupported ? (
                <Stage
                    stageSize={STAGE_DISPLAY_SIZES.large}
                    vm={vm}
                />
            ) : null}
        </Box>
        {!pythonMode && (
            <Box
                className={`${styles.blockAreaWrapper}
            ${isWorkspaceExpended ? '' : styles.hidden}`}
            >
                <Blocks
                    grow={1}
                    options={{
                        media: `static/blocks-media/`,
                        scrollbars: true,
                        trashcan: false
                    }}
                    stageSize={STAGE_DISPLAY_SIZES.large}
                    vm={vm}
                    pythonMode={pythonMode}
                />
                <Box
                    className={styles.retractContainer}
                    onClick={handleToggleWorkspaceExpend}
                >
                    <img
                        src={puzzle2PlaygroundRetract}
                        className={`${styles.retract} ${
                            isWorkspaceExpended
                                ? styles.retractActive
                                : styles.retractStash
                        }`}
                    />
                </Box>
            </Box>
        )}
    </Box>
);

PuzzlePlaygroundComponent.propTypes = {
    alertsVisible: PropTypes.bool,
    handleToggleWorkspaceExpend: PropTypes.func.isRequired,
    isRendererSupported: PropTypes.bool,
    isWorkspaceExpended: PropTypes.bool,
    pythonMode: PropTypes.bool,
    stageDimensions: PropTypes.shape({
        scale: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        widthDefault: PropTypes.number,
        heightDefault: PropTypes.number
    }),
    vm: PropTypes.instanceOf(VM).isRequired,
    isRtl: PropTypes.bool
};

export default PuzzlePlaygroundComponent;
