import {LESSON, DETECT} from 'public-repo/lib/config';
import {getParams} from '../../lib/env-utils';
import Fetch from 'public-repo/lib/fetch';
import Config from 'public-repo/utility/generalConfig';

const setActivity = (
    source,
    {proId, taskId, packageId, actId, lessonId, classId}
) => {
    const data = {};

    const activities = source.activity;
    if (activities) {
        activities.some((activity, index) => {
            if (String(activity.id) !== actId) return false;

            Object.assign(data, {
                params: {
                    id: proId,
                    taskId,
                    actId,
                    lessonId,
                    classId,
                    packageId
                },
                isCompleted: !!activity.finished,
                isAnswerPaid: !!activity.is_act_answer_payed,
                payCoinNum: source.base_num * (source.payed_num - -1),
                rewardInfo: {
                    codeNum: parseInt(activity.code_num, 10),
                    goldNum: parseInt(activity.gold_num, 10),
                    knowledgeCard: activity.knowledge_card || []
                },
                userActivityInfo: {
                    codeNum: parseInt(
                        activity.user_activity_info.current_code_num,
                        10
                    ),
                    goldNum: parseInt(
                        activity.user_activity_info.current_gold_num,
                        10
                    )
                },
                result: null
            });

            if (
                index > 0 &&
                (activities[index - 1].type === LESSON.TYPE.LEARNING ||
                    activities[index - 1].type === LESSON.TYPE.LEARNING2)
            ) {
                data.videoType = activities[index - 1].type;
                data.videoUrl = activities[index - 1].video;
                data.videoActId = activities[index - 1].id;
            }

            // data.videoUrl = '//cm-video.wkcoding.com/%27Code%20Stars%27%20-%20Short%20Film%20.mp4';
            return true;
        });
    } else {
        Object.assign(data, {
            params: {
                id: proId,
                taskId,
                actId,
                lessonId,
                classId,
                packageId
            },
            isCompleted: false,
            rewardInfo: {
                codeNum: parseInt(source.reward_info.code_num, 10),
                goldNum: parseInt(source.reward_info.gold_num, 10),
                knowledgeCard: source.reward_info.knowledge_card || []
            },
            userActivityInfo: {
                codeNum: parseInt(source.user_activity_info.code_num, 10),
                goldNum: parseInt(source.user_activity_info.gold_num, 10)
            }
        });
    }

    return data;
};

const getActivityResult = ({
    errTimes,
    costTime,
    usedBlocksNum,
    thresholdTime,
    thresholdBlocks,
    rewardInfo,
    userActivityInfo
}) => {
    let scoreRatio;

    if (DETECT.isPuzzle) {
        scoreRatio = 0.6;

        if (costTime <= thresholdTime) {
            scoreRatio += 0.2;
        }
        if (usedBlocksNum <= thresholdBlocks) {
            scoreRatio += 0.1;
        }
        if (errTimes <= 1) {
            scoreRatio += 0.1;
        }
    } else {
        scoreRatio = 1;
    }

    let goldNum = 0;
    let goldNumGrow = 0;
    if (rewardInfo.goldNum > 0) {
        goldNum = Math.max(
            Math.round(rewardInfo.goldNum * scoreRatio),
            userActivityInfo.goldNum
        );

        goldNumGrow = Math.max(0, goldNum - userActivityInfo.goldNum);
    }

    let codeNum = 0;
    let codeNumGrow = 0;
    if (rewardInfo.codeNum > 0) {
        codeNum = Math.max(
            Math.round(rewardInfo.codeNum * scoreRatio),
            userActivityInfo.codeNum
        );

        codeNumGrow = Math.max(0, codeNum - userActivityInfo.codeNum);
    }

    const result = {
        goldNum,
        goldNumGrow,
        codeNum,
        codeNumGrow
    };

    if (DETECT.isPuzzle) {
        result.errTimes = errTimes;
        result.costTime = costTime;
        result.usedBlocksNum = usedBlocksNum;
        result.score = 100 * scoreRatio;
        result.total = result.score + costTime + errTimes + usedBlocksNum;
    }

    return result;
};

const getPuzzle2ActivityResult = ({
    errTimes,
    costTime,
    usedBlocksNum,
    expectBlocksNum,
    maxBlocksNum,
    blocksNumExcellent,
    blocksNumPass
}) => {
    let scoreRatio;
    expectBlocksNum = expectBlocksNum || blocksNumExcellent;
    maxBlocksNum = maxBlocksNum || blocksNumPass;
    if (usedBlocksNum <= expectBlocksNum) {
        scoreRatio = 1;
    } else if (
        usedBlocksNum > expectBlocksNum &&
        usedBlocksNum <= maxBlocksNum
    ) {
        scoreRatio = 0.8;
    } else {
        scoreRatio = 0.6;
    }

    return {
        usedBlocksNum,
        errTimes,
        costTime,
        score: 100 * scoreRatio
    };
};

const reportActivityComplete = ({
    costTime,
    errTimes,
    usedBlocksNum,
    score,
    total
} = {}) => {
    let commonParam = {};
    const {actId, lessonId, classId, taskId, packageId} = getParams();

    if (total) {
        commonParam = {
            time: costTime,
            error: errTimes,
            block: usedBlocksNum,
            score,
            total
        };
    }

    return Fetch({
        /* eslint-disable babel/camelcase*/
        url: Config.serverIo.finish_activity,
        method: 'post',
        params: taskId
            ? {task_id: taskId, ...commonParam}
            : {
                  act_id: actId,
                  lesson_id: lessonId,
                  class_id: classId,
                  package_id: packageId,
                  ...commonParam
              }
    });
};

export {
    setActivity,
    getActivityResult,
    getPuzzle2ActivityResult,
    reportActivityComplete
};
