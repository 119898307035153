import React from 'react';
import PropTypes from 'prop-types';

import './index.less';
import '../global.css';

export const ColorButtonType = {
  default: 'translate',
  hoverd: 'hoverd',
};

export const ColorType = {
  yellow: 'yellow',
  purple: 'purple',
  orange: 'orange',
  blue: 'blue',
  brown: 'brown',
  disable: 'disable',
};

const ColorButton = ({
  style,
  className,
  colorButtonType = ColorButtonType.default,
  children,
  onClick,
  colorType = 'normal',
  disable = false,
  onTouchStart = () => {},
}) => (
  <span
    onTouchStart={onTouchStart}
    onClick={disable ? null : onClick}
    style={style}
    className={`color-button 
      ${colorType} 
      ${className || ''} 
      ${colorButtonType} 
      ${disable ? 'disable' : ''}
    `}
  >
    {children}
  </span>
);

ColorButton.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  colorButtonType: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  colorType: PropTypes.oneOf(Object.values(ColorType)),
  disable: PropTypes.bool,
  onTouchStart: PropTypes.func,
};

export default ColorButton;
