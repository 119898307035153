import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';

class MCountUp extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    end: PropTypes.number,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   // 增长不为0的时候发出声音
  //   if (this.props.end !== 0) {
  //     for (let i = 0; i < 10; i++) {
  //       setTimeout(() => {
  //         AudioManager.play(AudioManagerType.BEEP, 1);
  //       }, 100 * i);
  //     }
  //   }
  // }

  render() {
    const { end, className, active } = this.props;
    return (
      <span className={`m-count-up ${className}`}>
        {active && (
          <CountUp useEasing={false} duration={1} start={0} end={end} />
        )}
      </span>
    );
  }
}

export default MCountUp;
