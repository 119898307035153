import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

import VM from 'scratch-vm';
import {STAGE_DISPLAY_SIZES} from '../../../lib/layout-constants';
import Blocks from '../../../containers/blocks';
import PuzzlePlayground from '../../containers/puzzle-playground.jsx';
import PythonEditor from '../python-editor/python-editor';
import Box from '../../../components/box/box.jsx';
import PythonScratchTips from '../../containers/python-scratch-tips';
import {ToastBox} from '../../components/puzzle-tips/puzzle2-tips';
import Dialog from 'PublicRepo/components/ActivityDialog/Puzzle2Dialog';
import PuzzleLoading from '../puzzle-loading';
import UModel from 'Models/user';

import {getParams} from '../../../lib/env-utils';
// import ContactTeacher, {
//     ContactTeacherPages,
//     checkIsToCUser
// } from 'PublicRepo/components/ContactTeacher/contactTeacher';
import autoSubscribe from 'PublicRepo/utility/rtc-for-workshop/auto-subscribe';

import classNames from 'classnames';

import '!style-loader!css-loader!PublicRepo/utility/rtc-for-workshop/forbidden-shade/forbidden-shade.css';

import pythonGuide from '../python-guide/index.js';
import styles from './python-puzzle.css';
import {pythonOpcodeDic} from '../python-editor/snippets.js';
import ReactSVG from 'react-svg';
import PythonLeftTargetBar from '../python-puzzle-components/python-left-target-bar/python-left-target-bar';
import PythonLeftControlGroup from '../python-puzzle-components/python-left-control-group/python-left-control-group.jsx';
import PythonTargetArea from '../python-puzzle-components/python-target-area/python-target-area.jsx';
import PythonPuzzleCode from '../python-puzzle-components/python-puzzle-code-pool/python-puzzle-code-pool.jsx';
import AnswerModal from '../lesson/AnswerModal.jsx';
import {payForAnswer} from '../../lib/puzzle-utils.jsx';
import UserData from 'PublicRepo/utility/userDataManager.js';
import subscribeForbiddenShade from 'PublicRepo/utility/rtc-for-workshop/forbidden-shade/hoc';

import locationBuilder from 'public-repo/utility/locationBuilder';
import WebStorage from 'public-repo/lib/webStorage';
import InlineSVG from 'react-inlinesvg';

const videoLocation = 'python puzzle';
const IntroManager = {
    newerIntroPendingTime: 8 * 1000,

    highLightBlock: 'right',

    rightAnswer: 'right(90)',

    // 是否显示点击“提示”的引导
    get shouldShowHintIntro() {
        return false;
        // return (
        //     !localStorage.getItem('python-puzzle-hide-hint-intro') &&
        //     localStorage.getItem('python-puzzle-hide-newer-intro')
        // );
    },

    hideHintIntro() {
        localStorage.setItem('python-puzzle-hide-hint-intro', true);
    },

    // 是否显示新手引导
    get shouldShowNewerIntro() {
        // const guidePid = __DEMO__ ? '434' : '553';
        return false;
        // return (
        //     !localStorage.getItem('python-puzzle-hide-newer-intro') &&
        //     Detect.proId === guidePid
        // );
    },

    hideNewerIntro() {
        localStorage.setItem('python-puzzle-hide-newer-intro', true);
    }
};

class Puzzle2Component extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipsModalOpen: false,
            currentIndex: 0,
            showTips: true,
            showTargetMenu: true,
            isVideoShow: false,
            fold: true
        };
        const appDiv = document.querySelector('body>div[class^=index]');
        if (appDiv) {
            document.body.parentElement.style.minHeight = '0px';
            document.body.style.minHeight = '0px';
            appDiv.style.minHeight = '0px';
        }
    }

    componentDidMount() {
        if (this.props.pythonDiy && localStorage.getItem('pop-data')) {
            window.onbeforeunload = null;
            this.settle();
        }
        if (this.props.pythonDiy) {
            this.recordTime();
        }
        const {classId} = getParams();
        locationBuilder.searchQuery('classroom_type') !== 'afterclass' &&
            autoSubscribe(classId);
        this.initObserverLeftContainer();
    }

    componentDidUpdate(preProps) {
        if (!preProps.isPuzzleReady && this.props.isPuzzleReady) {
            if (IntroManager.shouldShowNewerIntro) {
                pythonGuide.start(
                    this.whenTextAreaPress,
                    IntroManager.hideNewerIntro,
                    IntroManager.highLightBlock,
                    () => this.switchTargetMenu()
                );
            }
        }
        const {pythonDiy, isUpdating} = this.props;
        if (pythonDiy && preProps.isUpdating && !isUpdating) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                saveLoading: false
            });
        }
        if (
            pythonDiy &&
            preProps.isUpdating &&
            !isUpdating &&
            this.isSavingBeforeSubmit
        ) {
            this.saving = false;
            this.gotoVideoSubmitUrl();
        }
    }

    componentWillUnmount() {
        if (this.recordTimer) {
            clearInterval(this.recordTimer);
        }
        this.unobserveLeftContainer();
    }

    setLeftContainerRef = node => {
        this.leftContainerRef = node;
    };

    setCodePoolContainerRef = node => {
        this.codePoolContainerRef = node;
    };

    gotoVideoSubmitUrl = () => {
        const {proId, actId, classId, packageId, lessonId} = getParams();
        if (!this.isSavingBeforeSubmit) return;
        this.isSavingBeforeSubmit = false;
        const DESTINATION_URL = locationBuilder.buildGuiDIYVideoSubmitUrl(
            proId,
            classId,
            actId,
            lessonId,
            packageId
        );
        this.isSavingBeforeSubmit = false;
        window.onbeforeunload = null;
        location.href = DESTINATION_URL;
    };

    whenTextAreaPress = () =>
        new Promise(resolve => {
            const dd = () => {
                if (this.textAreaPress === true) {
                    resolve();
                } else {
                    requestAnimationFrame(dd);
                }
            };
            requestAnimationFrame(dd);
        });

    calculateCodePoolFold = height => {
        const {pythonPool, customBlocks} = this.props;
        const allCodePool = pythonPool ? [...pythonPool, ...customBlocks] : [];
        const length = allCodePool.length;
        const boxHeight = 36;
        const boxMarginTop = 25;
        const outerPaddingTop = 20;
        const count = Math.ceil(length / 3);
        const codePoolHeight =
            outerPaddingTop + (count * boxHeight) + ((count - 1) * boxMarginTop);
        this.setState({
            shouldShowFold: codePoolHeight > height
        });
    };

    initObserverLeftContainer = () => {
        if (this.codePoolContainerRef) {
            const {
                height: codeHeight
            } = this.codePoolContainerRef.getBoundingClientRect();
            this.calculateCodePoolFold(codeHeight);
            this.codePoolObserver = new ResizeObserver(mutations => {
                for (const item of mutations) {
                    const {height: changeHeight} = item.contentRect;
                    this.calculateCodePoolFold(changeHeight);
                }
            });

            this.codePoolObserver.observe(this.codePoolContainerRef, {
                attributes: true, // 检测属性变动
                childList: true, // 检测子节点变动
                characterData: true // 节点内容或节点文本的变动。
            });
        }
        if (this.leftContainerRef) {
            this.containerObserver = new ResizeObserver(() => {
                if (this.editor) {
                    this.editor.layout();
                }
            });

            this.containerObserver.observe(this.leftContainerRef, {
                attributes: true, // 检测属性变动
                childList: true, // 检测子节点变动
                characterData: true // 节点内容或节点文本的变动。
            });
        }
    };

    unobserveLeftContainer = () => {
        if (this.leftContainerRef && this.containerObserver) {
            this.containerObserver.unobserve(this.leftContainerRef);
        }
        if (this.codePoolContainerRef && this.codePoolObserver) {
            this.codePoolObserver.unobserve(this.leftContainerRef);
        }
    };

    getAlternativeVersionId = () =>
        this.editor.getModel().getAlternativeVersionId();

    editorDidMount = (editor, monaco) => {
        const {
            enableRedo,
            enableUndo,
            disableRedo,
            disableUndo,
            setMonaco,
            setEditor
        } = this.props;
        editor.focus();
        this.editor = editor;
        this.monaco = monaco;
        setMonaco(monaco);
        setEditor(editor);
        const initialVersion = this.getAlternativeVersionId();
        this.currentVersion = initialVersion;
        this.lastVersion = initialVersion;

        const onChangeContent = () => {
            const userInput = editor.getValue();
            if (userInput.includes(IntroManager.rightAnswer)) {
                // 用户输入包含了正确答案
                setTimeout(() => {
                    this.textAreaPress = true;
                }, 2000);
                clearTimeout(this.timer);
            }

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.timer = null;
                    pythonGuide.showHint();
                    IntroManager.hideHintIntro();
                }, IntroManager.newerIntroPendingTime);
            } else if (IntroManager.shouldShowHintIntro) {
                this.timer = setTimeout(() => {
                    // 用户超过 10 秒未操作，显示点击“提示”指引
                    this.timer = null;
                    pythonGuide.showHint();
                    IntroManager.hideHintIntro();
                }, IntroManager.newerIntroPendingTime);
            }

            const versionId = this.getAlternativeVersionId();
            // undoing
            if (versionId < this.currentVersion) {
                enableRedo();
                if (this.currentVersion > this.lastVersion) {
                    this.lastVersion = this.currentVersion;
                }
                // no more undo possible
                if (versionId === initialVersion) {
                    disableUndo();
                }
            } else {
                // redoing
                if (versionId <= this.lastVersion) {
                    // redoing the last change
                    if (versionId === this.lastVersion) {
                        disableRedo();
                    }
                } else {
                    // adding new change, disable redo when adding new changes
                    disableRedo();
                    if (this.currentVersion > this.lastVersion) {
                        this.lastVersion = this.currentVersion;
                    }
                }
                enableUndo();
            }
            this.currentVersion = versionId;
        };
        editor.onDidChangeModelContent(onChangeContent);
    };
    handleRedoOrUndo = name => {
        if (this.editor) {
            this.editor.trigger('wtf', name);
        }
    };

    insertText = (e, text) => {
        e.stopPropagation();
        this.editor.focus();
        if (this.editor) {
            this.editor.getContribution('snippetController2').insert(text);
        }
    };
    handleRedo = () => {
        if (this.props.redo) {
            this.handleRedoOrUndo('redo');
        }
    };
    handleUndo = () => {
        if (this.props.undo) {
            this.handleRedoOrUndo('undo');
        }
    };
    onResetPuzzle = () => {
        const {onResetPuzzle} = this.props;
        onResetPuzzle(this.initCode);
    };

    initCode = () => {
        const {currentTargetIndex, puzzleEngine} = this.props;
        puzzleEngine.resetPythonCode(currentTargetIndex);
        this.editor.setValue(
            puzzleEngine.config.preCode[currentTargetIndex] || ''
        );
    };

    handleRetry = () => {
        this.setState({
            isResetting: true
        });
        const {pythonMode, handleRetry} = this.props;
        handleRetry(pythonMode ? this.initCode : false);
        setTimeout(() => {
            this.setState({
                isResetting: false
            });
        }, 400);
    };
    svgInjectionClassName = className => svg => svg.classList.add(className);
    handleClickStart = () => {
        const {isPuzzlePlaying, onStartPuzzle, onStopPuzzle} = this.props;
        if (isPuzzlePlaying) {
            onStopPuzzle();
        } else {
            onStartPuzzle();
        }
    };
    handleSwitch = (e, index) => {
        e.stopPropagation();
        this.setState({
            currentIndex: index
        });
    };
    elStopPropagation = e => e.stopPropagation();

    renderTips = () => {
        const {hints, answerImgUrl} = this.props;
        const {tipsModalOpen, currentIndex, showTips} = this.state;
        return ReactDOM.createPortal(
            <div
                className={classNames(styles.tipsPanel, {
                    [styles.right2left]: tipsModalOpen
                })}
                onClick={this.elStopPropagation}
            >
                <ReactSVG
                    src={require('./assets/pop_close.svg')}
                    className={classNames(styles.svg, styles.popClose)}
                    onClick={this.switchModal}
                />
                {answerImgUrl && !showTips && (
                    <img
                        className={styles.anwserImg}
                        src={answerImgUrl}
                        ali="anwser"
                    />
                )}

                {showTips && (
                    <div className={styles.tipsComtainer}>
                        {/* ---tips--- */}
                        <div className={styles.tabs}>
                            {hints.map((tip, index) => (
                                <span
                                    className={classNames(styles.tabItem, {
                                        [styles.activeItem]:
                                            index === currentIndex
                                    })}
                                    key={`${tip}${index}`}
                                    onClick={e => this.handleSwitch(e, index)}
                                >
                                    {(hints &&
                                        hints[index] &&
                                        hints[index].title) ||
                                        `提示${index + 1}`}
                                </span>
                            ))}
                        </div>
                        <pre
                            className={styles.content}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html:
                                    (hints &&
                                        hints[currentIndex] &&
                                        hints[currentIndex].content) ||
                                    ''
                            }}
                        />
                        {/* ---tips-end-- */}
                    </div>
                )}
                {/* ---tips---- */}
                {answerImgUrl && !UserData.is2bStudent && (
                    <ReactSVG
                        src={require('./assets/reference_answer.svg')}
                        className={classNames(
                            styles.svg,
                            styles.switchTipsAndAnwser
                        )}
                        onClick={this.props.onToggleHelpState}
                    />
                )}
                {/* ---tips--end-- */}
            </div>,
            document.body
        );
    };

    setOppositeState = name =>
        this.setState(pre => ({
            [name]: !pre[name]
        }));

    switchModal = () => this.setOppositeState('tipsModalOpen');
    switchTipsAndAnwser = () => this.setOppositeState('showTips');
    switchTargetMenu = () => {
        this.setOppositeState('showTargetMenu');
    };

    allProceduresCall = () =>
        this.props.puzzleEngine.puzzleWorkspace.workspace.flyout_.workspace_.topBlocks_.filter(
            e => e.type === 'procedures_call'
        );

    getPythonCode = item => {
        const value = pythonOpcodeDic[item] && pythonOpcodeDic[item].body;
        if (Array.isArray(value)) {
            return value.map(i => i.trim()).join('\n');
        }
        if (!value) {
            return `${item}()`;
        }
        return value;
    };

    handleVideoShow = () => {
        const {info} = this.props;
        if (!info.videoDescription) {
            return;
        }
        this.setState({
            isVideoShow: true
        });
    };

    hanldeVideoClose = () => {
        this.setState({
            isVideoShow: false
        });
    };

    closeTipsModal = e => {
        e.stopPropagation();
        if (this.state.tipsModalOpen) {
            this.setState({
                tipsModalOpen: false
            });
        }
    };

    handlePayForAnswer = () =>
        payForAnswer().then(({answer}) => {
            this.props.payForAnswerAction(answer);
        });
    /**
     * 跳转到cbUrl的地址
     */
    backToMap = () => {
        const {pythonDiy} = this.props;
        const {actId, classId, lessonId, packageId} = getParams();
        const callbackUrl = decodeURIComponent(getParams().cbUrl);
        if (pythonDiy) {
            UModel.getDiyTime({actId, packageId, lessonId, classId})
                .then(() => {
                    window.location.href = callbackUrl;
                })
                .catch(() => {
                    window.location.href = callbackUrl;
                });
        } else {
            window.location.href = callbackUrl;
        }
    };

    /**
     * python作业
     * 保存按钮处理
     */
    saveProject = () => {
        if (!this.hijack) {
            this.props.hijackVmToJSON();
            this.hijack = true;
        }
        this.setState({
            saveLoading: true
        });
        this.saving = true;
        this.props.manualUpdateProject();
    };
    /**
     * python作业
     * 提交按钮处理
     */
    submitProject = () => {
        if (this.props.isUpdating) return;
        this.isSavingBeforeSubmit = true;
        this.saveProject();
    };

    // diy 需要记录时长
    // reference src/cm/containers/diy-menu-bar.jsx #recordTime
    recordTime() {
        let time = 0;
        const {actId, classId, lessonId, packageId} = getParams();
        // WebStorage.delete(`DIY-RECORD-${getParams().actId}`);
        WebStorage.get(`DIY-RECORD-${actId}`).then(duration => {
            if (duration) {
                time = parseInt(duration, 10) || 1;
            } else {
                // 否则请求已经记录的时间
                UModel.getDiyTime({actId, packageId, lessonId, classId}).then(
                    data => {
                        if (!data) {
                            data = {time: 1};
                        }
                        if (data.time) {
                            time = parseInt(data.time, 10) || 1;
                            WebStorage.set(`DIY-RECORD-${actId}`, time);
                        }
                    }
                );
            }
        });
        this.recordTimer = setInterval(() => {
            time += 5;
            WebStorage.set(`DIY-RECORD-${actId}`, time);
        }, 5000);
    }

    settle() {
        this.props.pyDiySuccess();
    }

    switchFoldStatus = () => {
        this.setState(pre => ({fold: !pre.fold}));
    };

    savingLoad = () => (
        <div className={styles.savingMask}>
            <div className={styles.saving} />
        </div>
    );

    render() {
        // const {classId, lessonId} = getParams();
        const {
            onStopPuzzle,
            shouldShowSuccessDialog,
            dialogData,
            finishAndContinue,
            isPuzzleReady,
            redo,
            undo,
            preCode,
            pythonMode,
            isPuzzlePlaying,
            pythonPool,
            customBlocks,
            info,
            isAudioOpen,
            pythonDiy,
            currentTargetIndex,
            handleToggleAudioState,
            vm,
            onSetStageFull,
            isFullScreen,
            exitFullScreen
        } = this.props;

        const {
            showTargetMenu,
            isVideoShow,
            isResetting,
            fold,
            shouldShowFold,
            saveLoading
        } = this.state;
        const allCodePool = pythonPool ? [...pythonPool, ...customBlocks] : [];
        return (
            <>
                {!isPuzzleReady && <PuzzleLoading />}
                {pythonDiy && saveLoading && this.savingLoad()}
                <Box
                    className={styles.box}
                    onClick={this.closeTipsModal}
                >
                    <PythonLeftTargetBar
                        exitFullScreen={exitFullScreen}
                        isFullScreen={isFullScreen}
                        className={styles.targetBar}
                        pythonDiy={pythonDiy}
                        goBack={this.backToMap}
                        saveProject={this.saveProject}
                        submitProject={this.submitProject}
                        switchTarget={this.switchTargetMenu}
                        isPuzzlePlaying={isPuzzlePlaying}
                        handleStartBtn={this.handleClickStart}
                    />
                    <Box className={styles.bodyContainer}>
                        <Box className={styles.pythonLeftPanel}>
                            <PythonTargetArea
                                className={styles.targetArea}
                                handleMaskClick={this.switchTargetMenu}
                                info={info}
                                videoLocation={videoLocation}
                                handleTarget={this.switchTargetMenu}
                                iconClassName={styles.svg}
                                isVideoShow={isVideoShow}
                                handleVideoShow={this.handleVideoShow}
                                hanldeVideoClose={this.hanldeVideoClose}
                                show={showTargetMenu}
                                switchTargetMenu={this.switchTargetMenu}
                            />
                            <Box className={styles.tipBtn}>
                                <button
                                    className={styles.tips}
                                    onClick={this.switchModal}
                                >
                                    <ReactSVG
                                        src={require('./assets/prompt.svg')}
                                        className={classNames(
                                            styles.svg,
                                            styles.promptTips
                                        )}
                                    />
                                    提示
                                </button>
                            </Box>
                        </Box>
                        <Box className={styles.editorContainer}>
                            <Box
                                className={styles.editor}
                                componentRef={this.setLeftContainerRef}
                            >
                                {isPuzzleReady && (
                                    <PythonEditor
                                        isPuzzleReady={isPuzzleReady}
                                        pythonDiy={pythonDiy}
                                        currentTargetIndex={currentTargetIndex}
                                        onStopPuzzle={onStopPuzzle}
                                        errorPositonContainer={styles.editor}
                                        pre={preCode}
                                        editorDidMount={this.editorDidMount}
                                        puzzleEngine={this.props.puzzleEngine}
                                        isPuzzlePlaying={isPuzzlePlaying}
                                        customeBlocksContext={
                                            this.allProceduresCall
                                        }
                                    />
                                )}
                            </Box>
                            <PythonLeftControlGroup
                                isPuzzlePlaying={isPuzzlePlaying}
                                onStopPuzzle={onStopPuzzle}
                                onResetPuzzle={this.handleRetry}
                                isResetting={isResetting}
                                undoIconClassName={classNames(
                                    styles.svg,
                                    styles.undo,
                                    {[styles.disable]: !undo}
                                )}
                                redoIconClassName={classNames(
                                    styles.svg,
                                    styles.redo,
                                    {[styles.disable]: !redo}
                                )}
                                handleUndo={this.handleUndo}
                                handleRedo={this.handleRedo}
                            />
                            <Box
                                className={classNames(styles.pythonCodePool, {
                                    [styles.unfold]: !fold
                                })}
                                componentRef={this.setCodePoolContainerRef}
                            >
                                <Box
                                    className={classNames(styles.foldCodePool, {
                                        [styles.hidden]: !shouldShowFold
                                    })}
                                    onClick={this.switchFoldStatus}
                                >
                                    <img
                                        src={require('./assets/unfold-icon.svg')}
                                        className={classNames(
                                            styles.unfoldIcon,
                                            {[styles.hidden]: !fold}
                                        )}
                                    />
                                    <img
                                        src={require('./assets/shrink-icon.svg')}
                                        className={classNames(styles.foldIcon, {
                                            [styles.hidden]: fold
                                        })}
                                    />
                                </Box>
                                <Box
                                    className={styles.pythonCodePoolContainer}
                                    id="code-pool"
                                >
                                    {isPuzzleReady &&
                                        allCodePool.map(item => (
                                            <PythonPuzzleCode
                                                key={item}
                                                type={
                                                    (pythonOpcodeDic[item] &&
                                                        pythonOpcodeDic[item]
                                                            .origin) ||
                                                    item
                                                }
                                                text={
                                                    pythonOpcodeDic[item]
                                                        ? pythonOpcodeDic[item]
                                                              .prefix
                                                        : item
                                                }
                                                getPythonCode={
                                                    this.getPythonCode
                                                }
                                                item={item}
                                                handleTargetClick={
                                                    this.insertText
                                                }
                                            />
                                        ))}
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className={classNames(styles.stageContainer, {
                                [styles.isFullScreen]: isFullScreen
                            })}
                        >
                            <PuzzlePlayground
                                onStopPuzzle={onStopPuzzle}
                                pythonMode={pythonMode}
                            />
                            <Box className={styles.stageControlGroup}>
                                <PythonScratchTips
                                    className={styles.pythonTips}
                                />

                                <ReactSVG
                                    src={
                                        isAudioOpen
                                            ? require('./assets/volume.svg')
                                            : require('./assets/unvolume.svg')
                                    }
                                    className={classNames(
                                        styles.svg,
                                        styles.volume
                                    )}
                                    onClick={handleToggleAudioState}
                                />
                                <div
                                    className={`${classNames(
                                        styles.svg,
                                        styles.glossary,
                                        styles.prompt
                                    )} hint-button`}
                                    onClick={onSetStageFull}
                                >
                                    <InlineSVG
                                        src={require('./assets/zoom.svg')}
                                    />
                                </div>

                                {this.renderTips()}
                                <AnswerModal
                                    key="answer_modal"
                                    in={this.props.isHelpOpen}
                                    onClose={this.props.onToggleHelpState}
                                    isAnswerVisible={
                                        this.props.hasCompletedBefore ||
                                        this.props.isAnswerPaid ||
                                        this.props.answerPrice === 0
                                    }
                                    answer={this.props.answerImgUrl || ''}
                                    onPay={this.handlePayForAnswer}
                                    payCoinNum={this.props.answerPrice}
                                    isPuzzleCompleted={
                                        this.props.hasCompletedBefore
                                    }
                                    skipPuzzle={this.props.skipPuzzle}
                                    isPuzzle2
                                />
                            </Box>
                            <Box className={styles.blcoksArea}>
                                <Blocks
                                    grow={1}
                                    options={{
                                        media: `static/blocks-media/`,
                                        scrollbars: true,
                                        trashcan: false
                                    }}
                                    stageSize={STAGE_DISPLAY_SIZES.large}
                                    vm={vm}
                                    pythonMode={pythonMode}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* {isPuzzleReady && checkIsToCUser() && (
                    <ContactTeacher
                        classId={classId}
                        lessonId={lessonId}
                        pageType={ContactTeacherPages.PUZZLE}
                    />
                )} */}
                <ToastBox />
                {shouldShowSuccessDialog && (
                    <Dialog
                        stars={dialogData.score}
                        popUpData={dialogData.pop_up}
                        showConfetti
                        isLast={false}
                        onRetry={this.handleRetry}
                        onDone={finishAndContinue}
                    />
                )}
            </>
        );
    }
}

Puzzle2Component.propTypes = {
    currentTargetIndex: PropTypes.number,
    customBlocks: PropTypes.array,
    dialogData: PropTypes.object,
    disableRedo: PropTypes.func,
    disableUndo: PropTypes.func,
    enableRedo: PropTypes.func,
    enableUndo: PropTypes.func,
    finishAndContinue: PropTypes.func,
    handleRetry: PropTypes.func,
    handleToggleAudioState: PropTypes.func,
    info: PropTypes.object,
    isAudioOpen: PropTypes.bool,
    isPuzzlePlaying: PropTypes.bool,
    isPuzzleReady: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool,
    manualUpdateProject: PropTypes.func,
    onResetPuzzle: PropTypes.func,
    onStartPuzzle: PropTypes.func,
    onStopPuzzle: PropTypes.func,
    onToggleHelpState: PropTypes.func,
    preCode: PropTypes.array,
    puzzleEngine: PropTypes.object,
    pythonDiy: PropTypes.bool,
    pythonMode: PropTypes.bool,
    pythonPool: PropTypes.array,
    redo: PropTypes.bool,
    shouldShowSuccessDialog: PropTypes.bool,
    undo: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    onSetStageFull: PropTypes.func,
    isFullScreen: PropTypes.bool,
    exitFullScreen: PropTypes.func
};
Puzzle2Component.defaultProps = {
    pythonDiy: false
};

export default subscribeForbiddenShade(Puzzle2Component);
