import Config from 'Config';
import GeneralConfig from 'PublicRepo/utility/generalConfig';
import {DETECT} from 'PublicRepo/lib/config';

const buildResAbsoluteUri = path => {
    return `${/^(?!(?:\w+:)?\/\/)/.test(path) ? `${Config.resourceOrigin}${path.replace(/^\//, '')}` : path}`;
};

const buildShareUrl = (id, isJunior = false) => {
    return `${Config.ideOrigin}${isJunior  ? 'share-junior' : 'share'}.html?pid=${id}`;
};

const buildWechatShareUrl = id => {
    return `${GeneralConfig.ideUrl}gui/${DETECT.isJunior ? 'share-junior' : 'share'}.html?pid=${id}`;
};

const buildProjectUrl = id => {
    return `${Config.ideOrigin}${DETECT.isJunior ? 'junior' : ''}?pid=${id}`;
};

const parseQueryString = str => {
    return str.replace(/^\?/, '').split('&').reduce((pre, next)=> {
        if (next) {
            let pair = next.split('=');
            pre[pair[0]] = decodeURIComponent(pair[1]);
        }
        return pre;
    }, {});
};

const detectMobileAndOrientation = () => {
    if (
        !/micromessenger|Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        || /ipad.*micromessenger/i.test(navigator.userAgent)
    ) {
        return false;
    }
    return window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait';
};

const detectMobileOrientation = () => {
    return window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait';
};

const checkIsMobile = () => {
    const reg = new RegExp(/micromessenger|Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i);
    const ipadReg = new RegExp(/ipad.*micromessenger/i);
    return reg.test(navigator.userAgent) && !ipadReg.test(navigator.userAgent);
};

const getType = data => Object.prototype.toString.call(data).slice(8, -1).toLowerCase();

const buildQueryString = obj => {
    let result = [];
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            result.push(`${i}=${encodeURIComponent(obj[i] !== undefined ? obj[i] : '')}`);
        }
    }

    return result.join('&');
};

const focusEle = ele => {
    if (
        ['input', 'textarea'].indexOf(ele.tagName.toLowerCase()) === -1 &&
        ['true', 'plaintext-only'].indexOf(ele.contentEditable.toLowerCase()) === -1 &&
        !ele.getAttribute('tabindex')
    ) {
        ele.setAttribute('tabindex', '0');
    }

    ele.focus();
};

export {
    buildResAbsoluteUri,
    buildShareUrl,
    buildWechatShareUrl,
    buildProjectUrl,
    buildQueryString,
    detectMobileAndOrientation,
    detectMobileOrientation,
    checkIsMobile,
    getType,
    focusEle,
    parseQueryString
};
