import i18n from './index';
import './common';
import zhCN from './www/zh-CN';
import enUS from './www/en-US';

const maps = {
  'zh-CN': Object.assign({}, zhCN),
  cn: Object.assign({}, zhCN),
  en: Object.assign({}, enUS),
};

i18n.setLangMaps(maps);

export default i18n;
