import log from '../../../lib/log';

const PUZZLE_SUCCESS = 'puzzle/status/PUZZLE_SUCCESS';
const PUZZLE_FAIL = 'puzzle/status/PUZZLE_FAIL';
const PUZZLE_READY = 'puzzle/status/PUZZLE_READY';
const SET_PUZZLE_TIMEOUT = 'puzzle/status/SET_PUZZLE_TIMEOUT';

const initialState = {
    isPuzzleReady: false,
    isPuzzleSuccess: false,
    isPuzzleFail: false,
    isPuzzleRunTimeout: false,
    timeSinceOpen: null
};

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case PUZZLE_SUCCESS:
            log.log('PuzzleSuccess');
            return Object.assign({}, state, {
                isPuzzleSuccess: true
            });
        case PUZZLE_FAIL:
            log.log('PuzzleFail');
            return Object.assign({}, state, {
                isPuzzleFail: true
            });
        case PUZZLE_READY:
            return Object.assign({}, state, {
                isPuzzleSuccess: false,
                isPuzzleFail: false,
                isPuzzleReady: true,
                timeSinceOpen: Date.now()
            });
        case SET_PUZZLE_TIMEOUT:
            return Object.assign({}, state, {
                isPuzzleRunTimeout: action.payload
            });
        default:
            return state;
    }
};

const puzzleSuccess = () => ({
    type: PUZZLE_SUCCESS
});

const puzzleFail = () => ({
    type: PUZZLE_FAIL
});

const puzzleReady = () => ({
    type: PUZZLE_READY
});

const setPuzzleTimeout = isPuzzleTimeout => ({
    type: SET_PUZZLE_TIMEOUT,
    payload: isPuzzleTimeout
});

export {
    reducer as default,
    initialState as statusInitialState,
    puzzleSuccess,
    puzzleFail,
    puzzleReady,
    setPuzzleTimeout
};
