import isObject from 'lodash/isObject';
import log from '../../../../lib/log';

export default class HandlerUtils {
    constructor(vm, workspace, utils = {}) {
        this.vm = vm;
        this.workspace = workspace;

        if (isObject(utils)) {
            Object.assign(this, utils);
        }
    }

    getVm() {
        return this.vm;
    }

    getTargetAttr(name, targetIndex = 1) {
        if (!this.vm.runtime.targets[targetIndex]) {
            log.warn(`Required target with index ${targetIndex} do not exist.`);
            return null;
        }

        return this.vm.runtime.targets[targetIndex][name];
    }

    getVariableValue(name, index = 0) {
        const variable = this.workspace.getVariable(name);
        if (!variable) {
            return null;
        }

        return this.vm.runtime.targets[index].variables[variable.getId()].value;
    }
}
