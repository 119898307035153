const SHOW_SAVE_TIPS = 'SHOW_SAVE_TIPS';
const CLOSE_SAVE_TIPS = 'CLOSE_SAVE_TIPS';
const TOGGLE_RESET_TIPS = 'TOGGLE_RESET_TIPS';

const initialState = {
    isShowSaveTip: false,
    isShowResetActivityTips: false,
    saveTipsText: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SAVE_TIPS:
            return Object.assign({}, state, {
                isShowSaveTip: true,
                saveTipsText: action.text
            });
        case CLOSE_SAVE_TIPS:
            return Object.assign({}, state, {
                isShowSaveTip: false
            });
        case TOGGLE_RESET_TIPS:
            return Object.assign({}, state, {
                isShowResetActivityTips: !state.isShowResetActivityTips
            });
        default:
            return state;
    }
};

const showSaveTips = text => ({
    type: SHOW_SAVE_TIPS,
    text
});

const closeSaveTips = () => ({
    type: CLOSE_SAVE_TIPS
});

const showSaveTipsOnce = text => dispatch => {
    dispatch(showSaveTips(text));

    setTimeout(() => {
        dispatch(closeSaveTips());
    }, 2000);
};

const toggleResetTips = () => ({
    type: TOGGLE_RESET_TIPS
});

export {
    reducer as default,
    initialState as tipsInitialState,
    toggleResetTips,
    showSaveTipsOnce,
    closeSaveTips
};
