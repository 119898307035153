/* eslint camelcase: "off" */
/* eslint no-restricted-globals: "off" */
/* eslint-disable import/no-cycle */

import Config from './generalConfig';
import tokenStorage from './tokenStorage';
// url 跳转与传参相关

const { getSessionTokenSync, TOKEN_QUERY_KEY } = tokenStorage;
const bellcodeEnvKeyByFgateway = 'bellcode_env';

export const redirect2WithMiddleware = function (
  target,
  open_new_window = false,
  needToken = true,
) {
  // TODO:: 判断一下host合法性

  const url = new URL(target, window.location.href);
  const token = getSessionTokenSync();
  if (token && needToken) {
    url.searchParams.set(TOKEN_QUERY_KEY, token);
  }

  const redirectHandlers = {
    [true]: url => window.open(url),
    [false]: url => window.location.replace(url),
  };
  redirectHandlers[open_new_window](url);
};

// 确保拿到的路径是http协议
export const getHttpURL = val => {
  const url = new URL(val, window.location.href);
  const httpProtocol = ['http:', 'https:'];
  if (!httpProtocol.includes(url.protocol)) {
    url.protocol = __PRO__ ? 'https:' : 'http:';
  }
  return url;
};

export const buildQueryString = (obj, needToken = true) => {
  const result = [];
  const token = getSessionTokenSync();
  if (token && needToken) {
    obj[TOKEN_QUERY_KEY] = token;
  }
  Object.keys(obj).forEach(e => result.push(
      `${e}=${encodeURIComponent(obj[e] !== undefined ? obj[e] : '')}`,
    ));
  return result.join('&');
};

function getBellCodeEnv() {
  return window.__bellcode_env__;
}

export const buildJumpURL = (originalURL, needToken = true) => {
  const token = getSessionTokenSync();
  const url = new URL(originalURL, window.location.href);
  if (token && needToken) {
    url.searchParams.set('token', token);
  }
  console.debug(url.toString());
  return url.toString();
};

const locationBuilder = {
  // 获取 url hash 里的 query 值, 找不到的话返回 null
  searchHashQuery: (url, queryKey) => {
    const { hash } = new URL(url);
    return new URL(`http://foo.bar${hash.slice(1)}`).searchParams.get(queryKey);
  },

  // 获取当前 url hash 里的 query 值, 找不到的话返回 null
  currSearchHashQuery: queryKey => locationBuilder.searchHashQuery(location.href, queryKey),

  searchQuery: queryKey => {
    // 先去 query 找
    // 找不到再去 hash 找
    let value = new URL(location.href).searchParams.get(queryKey);

    if (value === null) {
      value = locationBuilder.searchHashQuery(location.href, queryKey);
    }

    return value;
  },

  buildProjectVideoPublishUrl: pid => `${Config.projectVideoSumitUrl}?${buildQueryString({ pid })}`,

  buildProjectQuickPublishUrl: pid => `${Config.projectSumitUrl}?${buildQueryString({ pid })}`,

  buildGuiProjectVideoPublishUrl: pid => `${Config.guiProjectVideoSumitUrl}?${buildQueryString({ pid })}`,

  buildGuiProjectQuickPublishUrl: pid => `${Config.guiProjectSumitUrl}?${buildQueryString({ pid })}`,

  buildGuiUploadTccUrl: pid => `${Config.guiUploadTcc}?${buildQueryString({ pid })}`,

  buildGUIUrlWithPid: pid => `${Config.guiUrl}?${buildQueryString({ pid })}`,

  // / 构建gui分享页链接
  buildGuiShareUrl: (pid, isJunior = false) => {
    let basicShareUrl = Config.guiProxyByH5;
    const bellcodeEnv = getBellCodeEnv();
    const params = {};
    if (isJunior) {
      basicShareUrl = Config.guiJuniorProxyByH5;
    }
    if (bellcodeEnv) {
      params[bellcodeEnvKeyByFgateway] = bellcodeEnv;
    }
    return `${basicShareUrl}?pid=${pid}&${buildQueryString(params, false)}`;
  },

  // / 构建ide分享页链接
  buildIDEShareUrl: (pid, isJunior = false) => {
    const basicShareUrl = `${Config.ideOrigin}${
      isJunior ? 'juniorShare' : 'share'
    }.html`;
    const bellcodeEnv = getBellCodeEnv();
    const params = {};
    if (bellcodeEnv) {
      params[bellcodeEnvKeyByFgateway] = bellcodeEnv;
    }
    return `${basicShareUrl}?pid=${pid}&${buildQueryString(params, false)}`;
  },

  buildProjectVideoSubmitUrl: (pid, classid, actid, lessonid, package_id) => `${Config.projectVideoSumitUrl}?${buildQueryString({
      pid,
      classid,
      actid,
      lessonid,
      package_id,
      cburl: location.href.replace(/#(.*)$/i, ''),
    })}`,

  // FIXME GUI using this, remove it. @Gong Lixian
  buildGuiDIYVideoSubmitUrl: (
    pid,
    classid,
    actid,
    lessonid,
    package_id,
    cburl,
  ) => `${Config.guiProjectVideoSumitUrl}?${buildQueryString({
      pid,
      classid,
      actid,
      lessonid,
      package_id,
      cburl: cburl || location.href.replace(/#(.*)$/i, ''),
    })}`,

  buildHomeworkVideoSubmitUrl: (
    pid,
    classid,
    lessonid,
    package_id,
    homework_id,
  ) => `${Config.projectVideoSumitUrl}?${buildQueryString({
      pid,
      classid,
      lessonid,
      package_id,
      homework_id,
      cburl: location.href.replace(/#(.*)$/i, ''),
    })}`,

  bulidNewMapRelativeUrl: (packageId, lessonId, classId) => `${Config.hrefMap}?${buildQueryString({
      package_id: packageId,
      lesson_id: lessonId,
      class_id: classId,
    })}`,

  bulidSequenceMapRelativeUrl: (packageId, lessonId, classId, actId) => `${Config.hrefSequenceMap}?${buildQueryString({
      package_id: packageId,
      lesson_id: lessonId,
      class_id: classId,
      act_id: actId,
    })}`,

  buildQuizUrl: (
    quizId,
    actId,
    lessonId,
    classId,
    packageId,
    afterClassReview,
  ) => `${Config.HomeDomain}#${Config.quizUrl}?${buildQueryString({
      quiz_id: quizId,
      actid: actId,
      classId,
      packageId,
      lessid: lessonId,
      cburl: location.href,
      ...(afterClassReview ? { classroom_type: 'afterclass' } : {}),
    })}`,

  buildPuzzleUrl: (
    projectId,
    actId,
    lessonId,
    classId,
    packageId,
    isJunior,
    afterClassReview,
  ) => `${isJunior ? Config.puzzleJuniorUrl : Config.puzzleUrl}?${buildQueryString(
      {
        pid: projectId,
        actid: actId,
        classid: classId,
        package_id: packageId,
        lessonid: lessonId,
        cburl: location.href,
        ...(afterClassReview ? { classroom_type: 'afterclass' } : {}),
      },
    )}`,

  buildPuzzle2Url: (
    projectId,
    actId,
    lessonId,
    classId,
    packageId,
    afterClassReview,
  ) => `${Config.puzzle2Url}?${buildQueryString({
      pid: projectId,
      actid: actId,
      classid: classId,
      package_id: packageId,
      lessonid: lessonId,
      cburl: location.href,
      ...(afterClassReview ? { classroom_type: 'afterclass' } : {}),
    })}`,

  buildGuiPuzzle2Url: (
    projectId,
    actId,
    lessonId,
    classId,
    packageId,
    isJunior,
    afterClassReview,
  ) => `${Config.guiPuzzle2Url}?${buildQueryString({
      pid: projectId,
      actid: actId,
      classid: classId,
      package_id: packageId,
      lessonid: lessonId,
      junior: isJunior ? 1 : 0,
      ...(afterClassReview ? { classroom_type: 'afterclass' } : {}),
      cburl: location.href,
    })}`,

  buildpythonPuzzleUrl: (...arg) => locationBuilder
      .buildGuiPuzzle2Url(...arg)
      .replace(Config.guiPuzzle2Url, Config.guiPythonPuzzle),

  buildDiyUrl: (
    projectId,
    actId,
    lessonId,
    classId,
    packageId,
    editorVersion,
    isJunior,
    teacherDiyProjectId,
    diyId = 0,
    projectType,
    afterClassReview,
  ) => {
    let origin;
    if (editorVersion === Config.BCVersion.gui) {
      origin = isJunior ? Config.guiDiyJuniorUrl : Config.guiDiyUrl;
      if (projectType === Config.ideType.PY_SCRATCH) {
        origin = Config.guiPythonScratch;
      }
    } else {
      origin = isJunior ? Config.ideJuniorUrl : Config.ideUrl;
    }

    return `${origin}?${buildQueryString({
      pid: projectId,
      actid: actId,
      lessonid: lessonId,
      classid: classId,
      package_id: packageId,
      teacher_diy_project_id: teacherDiyProjectId,
      cburl: location.href,
      diy_id: diyId,
      ...(afterClassReview ? { classroom_type: 'afterclass' } : {}),
    })}`;
  },

  /**
   * @params {{package_id: integer, lesson_id: integer, class_id: integer, type_id: integer, planet_type: string, back_url: string,}} params
   * 构建bellplanet url
   * params 是url上需要携带的参数列表
   * example 1:
   * buildPlanetUrl({
   *   package_id: 1,
   *   lesson_id: 1,
   *   class_id: 1,
   *   type_id: 1, //类型为level(关卡)传activity_id,类型为homework(课后作业)传homework_id,
   *   planet_type:'level'
   *   back_url: 1
   * });
   * example 2:
   * backURL => 通关后跳转url
   * buildPlanetUrl(packageId, lessonId, classId, activityId, planetType, backURL)
   * */
  buildPlanetUrl: (...params) => {
    let urlParams = params[0];
    // 兼容旧的调用
    // buildPlanetUrl(packageId, lessonId, classId, typeId(activityId/homeworkId), planetType, backURL);
    if (params.length > 1) {
      urlParams = {
        package_id: params[0],
        lesson_id: params[1],
        class_id: params[2],
        type_id: params[3],
        planet_type: params[4],
        back_url: params[5],
        ...(params[6] ? { classroom_type: 'afterclass' } : {}),
      };
    }
    return `${Config.HomeDomain}#${Config.planetUrl}?${buildQueryString(
      urlParams,
    )}`;
  },

  buildPlanetFakeProtoSearch: params => {
    let urlParams = params;
    const apiOrigin = Config.apiOrigin.replace(/\//g, '');
    urlParams = Object.assign(urlParams, {
      bell_origin: apiOrigin,
    });
    // bellplanet://student?temporary_token=AWRl2okDEQ:fYHQv&class_id=410&package_id=1&lesson_id=1&act_id=1&bell_origin=demoapi.wkcoding.com&local_network=127.0.0.1:8080&internet_network=democm.wkcoding.com
    return `lesson?${buildQueryString(urlParams)}`;
  },

  buildMiniGame: class_id => `${Config.HomeDomain}#${Config.miniGameUrl}?${buildQueryString({
      class_id,
    })}`,

  buildGuiHomeworkVideoSubmitUrl: (
    pid,
    classid,
    lessonid,
    package_id,
    homework_id,
  ) => `${Config.guiProjectVideoSumitUrl}?${buildQueryString({
      pid,
      classid,
      lessonid,
      package_id,
      homework_id,
      cburl: location.href.replace(/#(.*)$/i, ''),
    })}`,
  buildHomeUrl: () => Config.HomeDomain,
  buildGUIUrl: () => Config.guiUrl,
  bulidStudentProfile: () => `${Config.HomeDomain}#${Config.hrefCmStr}stu_profile`,
  buildDetectUrl: isLive => `${Config.detectUrl}?_=${new Date().getTime()}${
      isLive ? '&isLive=1' : ''
    }&cbUrl=${encodeURIComponent(window.location.href)}`,

  buildDiscount: paramsObj => `${Config.paymentUrl}/invite-to-friends?&${buildQueryString(paramsObj)}`,
  buildLanding: paramsObj => `${Config.paymentUrl}/record-lesson-landing?&${buildQueryString(
      paramsObj,
    )}`,

  // h5 统一转发
  buildGUIShare: (pid, paramsObj, trackQuery, isJunior = false) => {
    const baseURL = isJunior ? Config.guiJuniorProxyByH5 : Config.guiProxyByH5;

    paramsObj = Object.assign(paramsObj, trackQuery);

    // / fgateway 网关会注入
    const bellcodeEnv = getBellCodeEnv();
    if (bellcodeEnv) {
      paramsObj[bellcodeEnvKeyByFgateway] = bellcodeEnv;
    }

    return `${baseURL}?pid=${pid}&${buildQueryString(paramsObj, false)}`;
  },
  buildActivityRank: paramsObj => `${Config.H5Domain}#/activity-details?${buildQueryString(paramsObj)}`,
  buildNewMiniGame: id => {
    const proxy = Config.H5Domain.startsWith('http') ? '' : 'http:';
    return `${proxy}${
      Config.H5Domain
    }payment/wechat_index.html/mini-game-detail-page/${id}`;
  },
  /**
   * 生成Python DIY课程链接
   * 旧的Python编辑器课程链接：bellcode://ms-python.python/diy?activity_id=8135&class_id=&package_id=219&lesson_id=745&temporary_token=AWRlmOWzqQ:OL6s1
   * vscode插件版课程链接：vscode://bellcode.python-course-plugin/?activity_id=8135&class_id=&package_id=219&lesson_id=745&temporary_token=AWRlmOWzqQ:OL6s1
   *
   * @param use_vscode_plugin boolean
   * @param ...paramsObj { activity_id, class_id, package_id, lesson_id, temporary_token }
   */
  buildPythonDIYUrl: ({ use_vscode_plugin, ...paramsObj }) => {
    const bellcodeProtocol = 'bellcode://ms-python.python/diy?';
    const vscodePluginProtocol = 'vscode://bellcodedev.python-course-plugin/?';
    if (use_vscode_plugin) {
      return `${vscodePluginProtocol}${buildQueryString(
        { ...paramsObj },
        true,
      )}`;
    }
    return `${bellcodeProtocol}${buildQueryString({ ...paramsObj }, false)}`;
  },
};

export default locationBuilder;
