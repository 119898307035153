import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'PublicRepo/i18n/ide';
import {getParams} from '../../../lib/env-utils';
import passRibbon from 'Asset/img/homework-pass.png';
import nopassRibbon from 'Asset/img/homework-nopass.png';
import homeworkCommentIp from 'Asset/img/homework-comment-ip.png';
import '!style-loader!css-loader!./teacherComment.css';

const TeacherComment = props => {
    const {
        isComposer,
        diyInfo,
        homeworkInfo,
        isHomework,
        projectComment,
        isPortraitIDE,
        isMobile
    } = props;
    let className = '';
    let ribbon = null;
    let resultCorner = null;
    if (!projectComment) {
        return null;
    }
    if (isHomework) {
        if (isMobile) className = 'mobile-homework ';
        if (projectComment.is_pass) {
            className += 'homework-pass';
            ribbon = (
                <img
                    alt="pass"
                    className="homework-ribbon"
                    src={passRibbon}
                />
            );
            resultCorner = (
                <img
                    alt="congratulations"
                    className="homework-comment-result-corner"
                    src={homeworkCommentIp}
                />
            );
        } else {
            className += 'homework-nopass';
            ribbon = (
                <img
                    alt="nopass"
                    className="homework-ribbon"
                    src={nopassRibbon}
                />
            );
            if (
                !isMobile &&
                isComposer &&
                (diyInfo || homeworkInfo) &&
                !isPortraitIDE
            ) {
                const {
                    activity_id = '',
                    package_id,
                    class_id,
                    lesson_id,
                    homework_id = ''
                } = diyInfo || homeworkInfo;
                resultCorner = (
                    <a
                        className="fail-link"
                        href={`${props.uri}?pid=${
                            getParams().proId
                        }&cburl=${encodeURIComponent(
                            window.location.href
                        )}&actid=${activity_id}&classid=${class_id}&lessonid=${lesson_id}&package_id=${package_id}&homework_id=${homework_id}`}
                    >
                        {i18n.get('try again')}
                        {'>'}
                    </a>
                );
            }
        }
    }

    return (
        <div className={['teacher-comment-wrapper', className].join(' ')}>
            <div className="teacher-comment-content">
                {ribbon}
                <div className="teacher-comment-text">
                    {projectComment.content !== '' && (
                        <span className="quote fquote" />
                    )}
                    {projectComment.content ||
                        i18n.get(
                            'There is no teacher comment here. Write down your comment for this work.'
                        )}
                    {projectComment.content !== '' && (
                        <span className="quote equote" />
                    )}
                </div>
                <div className="teacher-comment-footer">
                    <div className="teacher-info">
                        <img
                            className="teacher-avatar"
                            src={
                                projectComment.avatar ||
                                projectComment.teacher_cover
                            }
                        />
                        <div className="teacher-name">
                            {projectComment.nickname ||
                                projectComment.teacher_name}
                        </div>
                        <img
                            className="teacher-signature"
                            src={
                                projectComment.signature ||
                                projectComment.teacher_signature
                            }
                        />
                    </div>
                    {resultCorner}
                </div>
            </div>
        </div>
    );
};

TeacherComment.propTypes = {
    diyInfo: PropTypes.object,
    homeworkInfo: PropTypes.object,
    isComposer: PropTypes.bool,
    isHomework: PropTypes.bool,
    isMobile: PropTypes.bool,
    isPortraitIDE: PropTypes.bool,
    projectComment: PropTypes.object,
    uri: PropTypes.string
};

export default TeacherComment;
