export default {
    BLOCK_PARAM_OPENING: '[',
    BLOCK_PARAM_CLOSING: ']',
    BLOCK_NEXT: '>',
    BLOCK_DISORDER: '~',
    PARAM_ASSIGNMENT: '=',
    GROUP_OPENING: '(',
    GROUP_CLOSING: ')',
    ESCAPE: '\\',
    DOUBLE_QUOTES: '"',
    ALTERNATION: '|',

    ASTERISK: '*',
    PLUS: '+',
    RANGE_OPENING: '{',
    RANGE_CLOSING: '}',
    RANGE_SEPARATOR: '-',
    PATH_SEPARATOR: ',',

    OLD_PARAM_VALUE_NUM_STRING_ASSIGNMENT: ':='
};
