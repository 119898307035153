import React from 'react';
import PropTypes from 'prop-types';

import './index.less';

import i18n from 'PublicRepo/i18n/common';
import {
  AudioManager,
  AudioManagerType,
} from 'PublicRepo/components/AudioManager/audioManager';
import CountUp from '../CountUp';

/**
 * active: 为true时开始播放动画
 * now: 现在的数量
 * grow: 增加的数量
 * success: 根据成功或失败显示不同颜色的币
 * type: 'gold','code' 显示为金币或者编程币
 */
class Coin extends React.PureComponent {
  static propTypes = {
    grow: PropTypes.number.isRequired,
    now: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { max, now, grow } = this.props;
    this.state = {
      per: max === 0 ? 0 : ((now - grow) / max) * 100,
      show: false,
    };
    this.showDesc = this.toggleDesc.bind(this, true);
    this.hideDesc = this.toggleDesc.bind(this, false);
  }

  toggleDesc(bool) {
    this.setState({ show: bool });
  }

  componentDidUpdate(prevProps) {
    const { max, now, grow } = this.props;

    // 开始加载动画
    if (this.props.success && !prevProps.active && this.props.active) {
      setTimeout(() => {
        this.setState({ per: max === 0 ? 0 : (now / max) * 100 });
        if (grow !== 0) {
          for (let i = 0; i < 10; i++) {
            setTimeout(() => {
              AudioManager.play(AudioManagerType.BEEP, 1);
            }, 100 * i);
          }
        }
      }, 700);
    }
  }

  render() {
    const {
 now, grow, max, type, active, success,
} = this.props;
    const oldPer = now <= max ? ((now - grow) / max) * 100 : 100; // 旧百分比
    return (
      <div
        className={`coin-wrapper ${success ? 'success' : 'defeat'}`}
        onClick={this.state.show ? this.hideDesc : this.showDesc}
        onMouseOver={this.showDesc}
        onMouseLeave={this.hideDesc}
      >
        <div className={`coin ${type}`}>
          {active && (
            <CountUp
              className="number"
              active={active}
              start={0}
              end={now < 0 ? 0 : now}
            />
          )}
        </div>
        <div className="progress-bar">
          <div
            className="new"
            style={{ transform: `translateX(${this.state.per - 100}%)` }}
          />
          <div
            className="old"
            style={{
              transform: `translateX(${(max === 0 ? 0 : oldPer) - 100}%`,
            }}
          />
        </div>
        {this.props.grow !== 0 && this.state.show && (
          <div className="hover-desc">
            <p className="text">{`${i18n.get('New Dialog Get')}${grow}`}</p>
            <p className="text">
              {`${i18n.get('New Dialog Rest')}${max - now}`}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Coin;
