const SET_PUZZLE_INFO = 'puzzle/info/SET_PUZZLE_INFO';
const initialState = {};

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_PUZZLE_INFO:
            return Object.assign({}, state, {
                info: action.info
            });
        default:
            return state;
    }
};

const setPuzzleInfo = function(info) {
    return {
        type: SET_PUZZLE_INFO,
        info
    };
};

export {reducer as default, initialState as infoInitialState, setPuzzleInfo};
