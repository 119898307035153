import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './target-block.css';
import {replaceStringToBlockBoxComponent} from '../puzzle2-block-box/puzzle2-block-box';
import bodymovin from 'bodymovin';

import failAnimation from '../../assets/animation/puzzle2-fail-icon-animation.json';
import successAnimation from '../../assets/animation/puzzle2-success-icon-animation.json';
import successToFailAnimation from '../../assets/animation/puzzle2-successToFail-icon-animation.json';
import unlockAnimation from '../../assets/animation/puzzle2-unlock-icon-animation.json';

import {targetStatus} from '../../reducers/puzzle/code-target';

const getRootStyle = state => {
    switch (state) {
        case targetStatus.LOCKED:
            return '';
        case targetStatus.FAIL:
            return styles.fail;
        case targetStatus.UNLOCK:
            return styles.unlock;
        case targetStatus.SUCCESS:
            return styles.success;
    }
};

class targetBlock extends Component {
    componentDidMount() {
        this.animationConfig.animationData =
            this.props.targetState == targetStatus.SUCCESS
                ? successAnimation
                : unlockAnimation;
        this.animation = bodymovin.loadAnimation(this.animationConfig);
        this.animation.setSpeed(2);
        if (
            this.props.targetState == targetStatus.UNLOCK ||
            this.props.targetState == targetStatus.SUCCESS
        ) {
            // 初始状态可能是当前、未解锁、成功（逆向事件目标默认成功）
            // 如果为当前态或成功，则停在解锁动画的最后一桢
            this.animation.goToAndStop(50, true);
        }
    }

    componentWillReceiveProps(newprops) {
        let hasChanged = false;
        let shouldDelay = false;

        if (newprops.targetState == targetStatus.LOCKED) {
            // 停止检测，归位
            this.initAnimationStatus();
            return;
        }

        const statusResults = [targetStatus.SUCCESS, targetStatus.FAIL];

        if (
            newprops.targetState == targetStatus.UNLOCK &&
            statusResults.includes(this.props.targetState)
        ) {
            // 将第一个代码目标归位
            this.initAnimationStatus(true);
        }

        if (this.props.targetState == targetStatus.LOCKED) {
            // 前一个状态为锁住
            if (newprops.targetState == targetStatus.UNLOCK) {
                // 从被锁住解锁为当前态
                this.animationConfig.animationData = unlockAnimation;
                hasChanged = true;
                shouldDelay = true; // 解锁动画应该等上一个成功动画播放完之后播放
            }
        } else if (this.props.targetState == targetStatus.UNLOCK) {
            // 前一个状态为当前
            if (newprops.targetState == targetStatus.SUCCESS) {
                // 从当前态转为成功态
                this.animationConfig.animationData = successAnimation;
                hasChanged = true;
            } else if (newprops.targetState == targetStatus.FAIL) {
                // 从当前态转为失败态
                this.animationConfig.animationData = failAnimation;
                hasChanged = true;
            }
        } else if (this.props.targetState == targetStatus.SUCCESS) {
            if (newprops.targetState == targetStatus.FAIL) {
                // 从成功变为失败
                this.animationConfig.animationData = successToFailAnimation;
                hasChanged = true;
            }
        } else if (
            this.props.isPlaying &&
            !newprops.isPlaying &&
            newprops.targetState == targetStatus.SUCCESS
        ) {
            // 点击停止运行后，重置逆向事件目标动画
            this.animationConfig.animationData = successAnimation;
            hasChanged = true;
        } else if (
            this.props.targetState == targetStatus.FAIL &&
            newprops.targetState == targetStatus.SUCCESS
        ) {
            this.animationConfig.animationData = successAnimation;
            hasChanged = true;
        }

        if (hasChanged) {
            this.animation && this.animation.destroy();
            this.animation = bodymovin.loadAnimation(this.animationConfig);
            this.animation.setSpeed(2);
            if (shouldDelay) {
                setTimeout(() => {
                    this.animation.play();
                }, 500);
            } else {
                this.animation.play();
            }
        }
    }

    animation = null; // 动画对象索引

    animationConfig = {
        renderer: 'svg',
        container: null, // 动画播放的 DOM 元素
        animationData: null, // 动画播放桢 JSON 数据
        loop: false,
        autoplay: false
    };

    initAnimationStatus(isFirst) {
        this.animationConfig.animationData = unlockAnimation;
        this.animation && this.animation.destroy();
        this.animation = bodymovin.loadAnimation(this.animationConfig);
        this.animation.setSpeed(2);
        if (isFirst) {
            // 初始状态不是当前就是未解锁
            // 如果为当前态，则停在解锁动画的最后一桢
            this.animation.goToAndStop(50, true);
        }
    }

    render() {
        const {className} = this.props;
        return (
            <div
                ref={this.props.domRef ? this.props.domRef : null}
                className={`
                    ${className}
                    ${styles.baseTargetBlock}
                    ${getRootStyle(this.props.targetState)}
                `}
            >
                <div
                    ref={animationRef =>
                        (this.animationConfig.container = animationRef)
                    }
                    // 用于播放动画的 DOM 元素
                    className={styles.icon}
                />
                <div className={styles.blockDescription}>
                    {replaceStringToBlockBoxComponent(
                        this.props.content,
                        this.props.emoji,
                        styles.baseTargetBlock
                    )}
                </div>
            </div>
        );
    }
}

targetBlock.propTypes = {
    content: PropTypes.string, // 0:locked, 1:unlock, 2: success, 3: fail
    domRef: PropTypes.any, // 文字描述
    // emoji: PropTypes.object,
    isPlaying: PropTypes.bool,
    targetState: PropTypes.number,
    className: PropTypes.string
};
targetBlock.defaultProps = {
    className: ''
};

export default targetBlock;
