import VMBridge from './vm-bridge/vm-bridge';
import projectModeWrapper from '../helpers/project-mode';

class PuzzleRuntime {
    constructor(vm, workspaceVMBlocks, targets) {
        this.isRunning = false;

        this.vmBridge = new VMBridge(workspaceVMBlocks, targets);
        this.projectController = projectModeWrapper(vm);
    }

    setBlockCache(name, id) {
        this.vmBridge.setBlockCache(name, id);
    }

    run() {
        this.projectController.startProject();
        this.isRunning = false;
    }

    start() {
        this.projectController.stopProject();
        this.vmBridge.connect();
        this.projectController.startProject();
        this.isRunning = true;
    }

    stop() {
        this.projectController.stopProject();
        this.vmBridge.disconnect();
        this.projectController.startProject();
        this.isRunning = false;
    }

    freeze() {
        this.projectController.freezeProject();
        this.isRunning = false;
    }

    reset() {
        this.projectController.stopProject();
        this.vmBridge.reset();
        this.projectController.startProject();
        this.isRunning = false;
    }
}

export default PuzzleRuntime;
