import React from 'react';
import PropTypes from 'prop-types';
import ColorButton from 'PublicRepo/material_components/colorButton';
import { nextLevelTip } from 'PublicRepo/components/intro/tips';

import i18n from 'PublicRepo/i18n/common';
import CountUp from './CountUp';
import BasePopup from './BaseDialog';

class Puzzle2Dialog extends React.Component {
  state = {
    animationComplete: false,
  };

  static propTypes = {
    stars: PropTypes.number.isRequired,
    showConfetti: PropTypes.bool.isRequired,
    popUpData: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired, // 判断右侧按钮显示下一关还是完成
    onRetry: PropTypes.func,
    onDone: PropTypes.func,
    showTip: PropTypes.func,
    // hideTip: PropTypes.func,
    isNeedGuide: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.animationComplete = this.animationComplete.bind(this);
  }

  get getSuccess() {
    return this.props.stars !== 0;
  }

  componentDidMount() {
    if (this.props.isNeedGuide && this.props.showTip) {
      this.props.showTip(nextLevelTip());
    }
  }

  renderBehavior() {
    const { stars } = this.props;
    const ranking = this.props.popUpData.ranking;
    if (stars === 0) {
      return <div>{i18n.get('New Dialog Bad')}</div>;
    }
    if (stars === 3) {
      return (
        <div>
          {i18n.get('New Dialog Perfect')[0]}
          <CountUp className="percent" active end={ranking} />
          <span className="symbol">%</span>
          {i18n.get('New Dialog Perfect')[1]}
        </div>
      );
    }
    if (stars === 2) {
      return (
        <div>
          {i18n.get('New Dialog Good')[0]}
          <CountUp className="percent" active end={ranking} />
          <span className="symbol">%</span>
          {i18n.get('New Dialog Good')[1]}
        </div>
      );
    }
    if (stars === 1) {
      return (
        <div>
          {i18n.get('New Dialog Normal')[0]}
          <CountUp className="percent" active end={ranking} />
          <span className="symbol">%</span>
          {i18n.get('New Dialog Normal')[1]}
        </div>
      );
    }
  }

  renderButtons() {
    const { stars, isLast } = this.props;
    if (stars === 3) {
      return (
        <div className="buttons-group">
          <ColorButton
            onClick={this.props.onRetry}
            style={{ width: '30%', color: '#f67e04' }}
            colorType="yellow"
          >
            <span className="refresh icon" />
            {i18n.get('New Dialog Play Again')}
          </ColorButton>
          <ColorButton
            onClick={this.props.onDone}
            style={{ width: '60%' }}
            colorType="orange"
          >
            {!isLast && <span className="next icon" />}
            {isLast
              ? i18n.get('New Dialog Done')
              : i18n.get('New Dialog Next Level')}
          </ColorButton>
        </div>
      );
    }
    if (stars === 0) {
      return (
        <div className="buttons-group">
          <ColorButton
            onClick={this.props.onDone}
            style={{ width: '30%', color: '#f67e04' }}
            colorType="yellow"
          >
            <span className="back icon" />
            {i18n.get('New Dialog Give Up The Prize')}
          </ColorButton>
          <ColorButton
            onClick={this.props.onRetry}
            style={{ width: '60%' }}
            colorType="orange"
          >
            <span className="three-stars icon" />
            {i18n.get('New Dialog Play Again And Win The Prize')}
          </ColorButton>
        </div>
      );
    }
    return (
      <div className="buttons-group">
        <ColorButton
          onClick={this.props.onRetry}
          style={{ width: '30%', color: '#f67e04' }}
          colorType="yellow"
        >
          <span className="three-stars icon" />
          {i18n.get('New Dialog Do Better')}
        </ColorButton>
        <ColorButton
          onClick={() => this.props.onDone(this.state.animationComplete)}
          style={{ width: '60%' }}
          colorType="orange"
        >
          {!isLast && <span className="next icon" />}
          {isLast
            ? i18n.get('New Dialog Done')
            : i18n.get('New Dialog Next Level')}
        </ColorButton>
      </div>
    );
  }

  animationComplete() {
    this.setState({ animationComplete: true });
  }

  render() {
    const { stars, showConfetti } = this.props;
    const { tips, list_item } = this.props.popUpData;
    const coinInfo = {
      gold:
        this.props.popUpData.user_current_gold_num
        + this.props.popUpData.current_gold_num,
      goldMax: this.props.popUpData.gold_num,
      goldGrow: this.props.popUpData.current_gold_num,
      code:
        this.props.popUpData.user_current_code_num
        + this.props.popUpData.current_code_num,
      codeMax: this.props.popUpData.code_num,
      codeGrow: this.props.popUpData.current_code_num,
    };
    return (
      <div className="puzzle2-popup">
        <BasePopup
          showConfetti={showConfetti}
          stars={stars}
          tips={tips}
          coinInfo={coinInfo}
          buttons={this.renderButtons()}
          listItem={list_item}
          behaviorInfo={this.renderBehavior()}
          completeAnimationCallback={this.animationComplete}
          isLast={this.props.isLast}
        />
      </div>
    );
  }
}

export default Puzzle2Dialog;
