/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'PublicRepo/i18n/www';
import Config from 'PublicRepo/utility/generalConfig';
import UserData from 'PublicRepo/utility/userDataManager';
import Plyr from 'plyr/dist/plyr';
import Track from 'PublicRepo/components/Track/Track';
import { isIpad } from 'PublicRepo/utility/browserDetection';

import 'plyr/dist/plyr.css';
import './index.css';

import loadingGif from './img/video-loading.gif';
import iconCnButton from './img/cn-button.svg';
import iconEnButton from './img/en-button.svg';
import iconEnDisable from './img/en-disable.svg';
import iconCnDisable from './img/cn-disable.svg';
import mark from './img/mark.svg';

import icon720pButton from './img/720p-button.svg';
import icon480pButton from './img/480p-button.svg';
import icon360pButton from './img/360p-button.svg';

import Controls from './video-custom-dom';
import webStorage from '../../lib/webStorage';
import classRelatedInfo from '../../models/classRelatedInfo';
import { splitString } from './formatField';

const { getClassInfo, getLessonInfo, getPackageInfo } = classRelatedInfo;

const LanguageButtonType = {
  cn: {
    ButtonDom: '.control-lang-cn',
    SrcType: 'cn',
    Language: 'Chinese',
    IconButton: iconCnButton,
    SwitchTipText: '正在拼命切换至：普通话',
    SwitchTipSucceedText: '切换成功',
  },
  en: {
    ButtonDom: '.control-lang-en',
    SrcType: 'en',
    Language: 'English',
    IconButton: iconEnButton,
    SwitchTipText: 'Switching to：English',
    SwitchTipSucceedText: 'Switch Successfully',
  },
};

const QuizVersion = {
  OLD: 1,
  NEW: 2,
};

const QuizOptionType = {
  TEXT: 'text',
  IMG: 'image',
};

const QuizOptionDisplayStyle = {
  '2x2': '2x2',
  '1x4': '1x4',
  '4x1': '4x1',
};

const MapIndexToAlphabet = index => {
  switch (index) {
    case 0:
      return 'A';
    case 1:
      return 'B';
    case 2:
      return 'C';
    case 3:
      return 'D';
    default:
      return undefined;
  }
};
const ClarityOptions = {
  '360p': '360p',
  '480p': '480p',
  '720p': '720p',
  origin: 'origin',
};

const ClarityOptionsButton = {
  '360p': icon360pButton,
  '480p': icon480pButton,
  '720p': icon720pButton,
};

const DefaultClarity = ClarityOptions['480p'];
const languageOptions = { English: 'en', Chinese: 'cn' };
const languageTips = { en: '英语', cn: '普通话' };
const localStorageVideoLang = 'video-userSelectedLang';
const localStorageVideoClarity = 'video-userSelectedClarity';

function $(seletor) {
  return document.querySelector(seletor);
}

function $$(seletor) {
  return document.querySelectorAll(seletor);
}

function show(dom) {
  if (!dom) return;
  dom.style.visibility = 'visible';
  dom.style.opacity = '1';
}

function hide(dom) {
  if (!dom) return;
  dom.style.visibility = 'hidden';
  dom.style.opacity = '0';
}

function stopPropagation(e) {
  e.cancelBubble = true;
  if (e.stopPropagation) e.stopPropagation();
}

function getWatchedTime(watchedVideoTime, duration) {
  return watchedVideoTime < duration
    ? parseInt(watchedVideoTime)
    : parseInt(duration);
}

function toggleButton(button, wrapper) {
  $(button).addEventListener('click', () => {
    const Wrapper = $(wrapper);
    if (Wrapper.classList.contains('active') === true) {
      Wrapper.classList.remove('active');
    } else {
      Wrapper.classList.add('active');
    }
  });
}

function setLangToLocalStorage(userSelectedLang) {
  window.localStorage.setItem(localStorageVideoLang, userSelectedLang);
}

function setClarityToLocalStorage(userSelectedClarity) {
  window.localStorage.setItem(localStorageVideoClarity, userSelectedClarity);
}

// function isPassed(watchedVideoTime, duration) {
//   return watchedVideoTime / duration > 0.6;
// }

class Video extends PureComponent {
  constructor(props) {
    super(props);

    this.watchedVideoTime = 0;
    this.PreScrollTop = 0;
    this.onceTime = 0;

    this.state = {
      isShowVideoDialog:
        this.props.controlSide !== Config.QuizControlSide.STUDENT,
      source: '',
      switchTipText: '',
      currentLanguage: '',
      duration: 0,
      questionsMap: {},
      quizPathMap: {},
      currentQuestion: [],
      currentTip: '',
      video2WithoutQuestion: false,
      isTeacher:
        parseInt(UserData.userInfo.usertype)
          === parseInt(Config.userTypeMap.instructor)
        || parseInt(UserData.userInfo.usertype)
          === parseInt(Config.userTypeMap.teacher),
      isOfflineTeacher:
        UserData.userInfo.usertype === Config.userTypeMap.instructor
        && UserData.userInfo.school
        && UserData.userInfo.school.school_type === Config.schoolType.OFF_LINE,
    };
    this.handleClose = this.handleClose.bind(this);
    this.initializeVideoSrc = this.initializeVideoSrc.bind(this);
    this.initializeVideoSrcWithClarity = this.initializeVideoSrcWithClarity.bind(
      this,
    );
    this.initializeVideoSrcWithoutClarity = this.initializeVideoSrcWithoutClarity.bind(
      this,
    );
    this.hideQuiz = this.hideQuiz.bind(this);
    this.initializeVideoQuestion = this.initializeVideoQuestion.bind(this);
    this.getOnlyVideoSrc = this.getOnlyVideoSrc.bind(this);
    this.appendOptions = this.appendOptions.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.trackVideoClose = this.trackVideoClose.bind(this);
  }

  _answered = {};

  _prevAnswered = null;

  componentWillUnmount() {
    this.exitQuizCloud();

    this.fixedPopupScroll(false);

    this.setState({
      source: '',
      switchTipText: '',
      currentLanguage: '',
      duration: 0,
    });
  }

  componentDidMount() {
    const { autoplay = true } = this.props;
    const ua = navigator.userAgent.toLowerCase();

    // 确保学生被锁屏状态下，视频 zindex 高于锁屏cover
    if (this.props.controlSide === Config.QuizControlSide.STUDENT) {
      $('.modal').style.zIndex = '10000';
    }

    this.fixedPopupScroll(true);

    // 设置 player
    this.instance = new Plyr('.video-ele', {
      debug: false,
      tooltips: {
        controls: false,
        seek: true,
      },
      controls: Controls,
      ratio: '16:9',
      fullscreen: {
        enabled: true, // Allow fullscreen?
        fallback: true, // Fallback for vintage browsers
        iosNative: false, // Use the native fullscreen in iOS (disables custom controls)
      },
      hideControls: true,
      autoplay:
        ua.match(/version\/([\d.]+).*safari/) || ua.match(/iPad/i)
          ? true
          : autoplay,
    });
    const {
      videoActivityId,
      videoClassId,
      videoLessonId,
      videoPackageId,
    } = this.props;
    this.shouldFetch = !!(
      videoActivityId
      && videoClassId
      && videoLessonId
      && videoPackageId
    );
    if (this.shouldFetch) {
      this.videoRemoteId = [
        videoActivityId,
        videoClassId,
        videoLessonId,
        videoPackageId,
      ].join('');
      Promise.all([
        webStorage.get(this.videoRemoteId),
        getClassInfo(videoClassId),
        getLessonInfo(videoLessonId),
        getPackageInfo(videoPackageId),
      ])
        .then(
          ([
            totalTime,
            { name: className },
            { name: lessonName },
            { name: packageName },
          ]) => {
            this.totalTime = totalTime ? +totalTime : 0;
            this.className = className;
            this.lessonName = lessonName;
            this.packageName = packageName;
            // this.instance.play();
          },
        )
        .catch(() => {
          // this.instance.play();
          this.shouldFetch = false;
        });
    } else {
      // this.instance.play();
    }

    const playerInstance = this.instance;

    if (this.props.activeFullScreen) {
      playerInstance.fullscreen.enter();
      this.props.onFullScreen && this.props.onFullScreen();
    }

    playerInstance.on('ended', () => {
      hide($('.play-button-wrapper'));
      if (!this.props.inlineMode) {
        show($('.operation-button-wrapper'));
      }
      $('.operation-replay').style.transform = 'none';
      // $('.operation-next').style.transform = 'none';
      this.trackVideoClose();
      this.props.onEnded({
        watchedVideoTime: getWatchedTime(
          this.watchedVideoTime,
          playerInstance.duration,
        ),
        duration: parseInt(playerInstance.duration),
      });
    });

    playerInstance.on('play', () => {
      this.watchTimer = setInterval(() => {
        this.watchedVideoTime++;
      }, 1000);
      playerInstance.volume = 1;

      if ($('.video-question-wrapper').style.opacity === '1') {
        playerInstance.pause();
      }
      hide($('.play-button-wrapper'));
      hide($('.operation-button-wrapper'));
      $('.operation-replay').style.transform = 'translate(50%)';
      // $('.operation-next').style.transform = 'translate(-50%)';
      const {
        videoActivityId,
        videoClassId,
        videoLessonId,
        videoPackageId,
        videoLocation,
        videoName,
        onPlay,
      } = this.props;

      Track.track('Video_Play', {
        currentTime: +playerInstance.currentTime,
        duration: +playerInstance.duration,
        activityId: +videoActivityId,
        lessonid: +videoLessonId,
        activityName: videoName,
        class_id: +videoClassId,
        class_name: this.className,
        package_id: +videoPackageId,
        package_name: this.packageName,
        lesson_name: this.lessonName,
        videoLocation,
        totalWatchingTime:
          typeof this.totalTime === 'number' ? this.totalTime : undefined,
      });

      // 解决课后回顾的时候视频的播放问题
      document.body.removeAttribute('style');
      onPlay();
    });

    playerInstance.on('volumechange', () => {
      Track.track('Video_Volumn', {
        currentTime: +playerInstance.currentTime,
        duration: +playerInstance.duration,
        activityId: this.props.videoActivityId,
        lessonid: +this.props.videoLessonId,
      });
    });

    playerInstance.on('pause', () => {
      clearInterval(this.watchTimer);
      Track.track('Video_Pause', {
        currentTime: +playerInstance.currentTime,
        duration: +playerInstance.duration,
        activityId: this.props.videoActivityId,
        lessonid: +this.props.videoLessonId,
      });
      show($('.play-button-wrapper'));
      this.props.onPause();
    });

    playerInstance.on('waiting', () => {
      this.setState(
        {
          switchTipText: i18n.get('Loading'),
        },
        () => {
          show($('.switch-tip-wrapper'));
        },
      );
    });

    playerInstance.on('controlshidden', () => {
      if (!playerInstance.fullscreen.active) {
        playerInstance.toggleControls(true);
      }
    });

    playerInstance.on('canplay', () => {
      hide($('.switch-tip-wrapper'));

      // 初始判断有无题目
      if (
        !this.props.videoType
        || this.props.videoType !== Config.activityType.video2
      ) {
        this.canCloseVideo = true;
        return;
      }

      if (!this.state.duration) {
        this.setState(
          {
            duration: ~~playerInstance.duration,
          },
          () => {
            this.initializeVideoQuestion();
          },
        );
      }
    });

    playerInstance.on('seeked', () => {
      Track.track('Video_Seek', {
        currentTime: +playerInstance.currentTime,
        duration: +playerInstance.duration,
        activityId: this.props.videoActivityId,
        lessonid: +this.props.videoLessonId,
      });

      // this.onceTime = 0;

      const allowControl = this.getAllowControl();

      if (this.state.isOfflineTeacher || !allowControl) return;

      const currentTime = ~~playerInstance.currentTime;
      /* eslint-disable no-restricted-syntax */
      for (const key in this.state.questionsMap) {
        if (
          ~~key < currentTime
          && !this.state.questionsMap[key].selectedCorrect
        ) {
          this.appendOptions(this.state.questionsMap[key], true, false, false);
          break;
        }
      }
    });

    playerInstance.on('timeupdate', () => {
      const currentTime = ~~playerInstance.currentTime;

      if (Math.abs(currentTime - this.onceTime) <= 1) return;

      if (this.state.questionsMap[currentTime]) {
        this.onceTime = currentTime;
        this.appendOptions(
          this.state.questionsMap[currentTime],
          false,
          this.state.isTeacher,
          true,
        );
      }
    });

    playerInstance.on('enterfullscreen', () => {
      Track.track('Video_Fullscreen', {
        currentTime: +playerInstance.currentTime,
        duration: +playerInstance.duration,
        activityId: this.props.videoActivityId,
        lessonid: +this.props.videoLessonId,
      });

      // this.width = $('.modal__content').style.width;

      if ($('.video .plyr--video .plyr__controls')) {
        $('.video .plyr--video .plyr__controls').style.borderRadius = '0';
        $('.video .plyr--video .plyr__controls').style.bottom = '0px';
      }
      $('.modal__content').style.width = '100%';
      $('.modal__content').style.height = '100%';
      $('.video .video-question-wrapper').style.height = '100%';
      $('.video .video-question-wrapper .screen-button').classList.add(
        'full-screen',
      );
      this.props.onFullScreen && this.props.onFullScreen();
    });

    playerInstance.on('exitfullscreen', () => {
      if ($('.video .plyr--video .plyr__controls')) {
        $('.video .plyr--video .plyr__controls').style.borderRadius = '0 0 8px 8px';
        $('.video .plyr--video .plyr__controls').style.bottom = '-82px';
      }
      $('.modal__content').style.width = '69%';
      $('.modal__content').style.height = 'auto';
      $('.video .video-question-wrapper').style.height = 'calc(100% + 82px)';
      $('.video .video-question-wrapper .screen-button').classList.remove(
        'full-screen',
      );
      this.props.updateContentStyle && this.props.updateContentStyle();
      this.props.exitFullScreen && this.props.exitFullScreen();
    });

    this.initializeVideoSrc();
    this.initializeLanguageButton();

    this.handleLanguageButton(LanguageButtonType.cn);
    this.handleLanguageButton(LanguageButtonType.en);

    $('.video-question-wrapper').addEventListener(
      'dblclick',
      stopPropagation,
      false,
    );

    $('.video-question-wrapper').addEventListener(
      'click',
      stopPropagation,
      false,
    );

    $('.video .video-question-wrapper .close-button').addEventListener(
      'click',
      () => {
        const { onHideQuiz } = this.props;

        onHideQuiz && onHideQuiz();
        this.hideQuiz();
      },
    );

    $('.video .video-question-wrapper .screen-button').addEventListener(
      'click',
      () => {
        if (playerInstance.fullscreen.active) {
          playerInstance.fullscreen.exit();
          this.props.onFullScreen && this.props.onFullScreen();
          return;
        }
        playerInstance.fullscreen.enter();
        this.props.exitFullScreen && this.props.exitFullScreen();
      },
    );

    $('.video-ele').addEventListener('webkitendfullscreen', () => {
      playerInstance.play();
    });

    $('.operation-replay').addEventListener('click', () => {
      playerInstance.restart();
      playerInstance.play();
      this.props.onRestart();
    });

    $('.operation-play').addEventListener('click', () => {
      playerInstance.play();
    });

    $('.video-question-title').addEventListener('click', () => {
      const { questionTitle } = this.state;
      let version = QuizVersion.OLD;

      if (typeof questionTitle === 'object') {
        version = QuizVersion.NEW;
      }

      this.showZoomInContent(questionTitle, version);
    });

    $('.video .video-question-wrapper .zoom-in').addEventListener(
      'click',
      () => {
        this.hideZoomInContent();
      },
    );

    this.mousewheelListener();

    // $('.operation-next').addEventListener('click', () => {
    //   this.handleClose();
    // });

    if (/iPad/gi.test(navigator.userAgent)) {
      $('#control-volume').style.display = 'none';
      toggleButton('#control-lang', '#control-lang .control-lang');
    } else {
      $('#control-lang').addEventListener('mouseenter', () => {
        show($('.control-lang'));
      });

      $('#control-lang').addEventListener('mouseleave', () => {
        hide($('.control-lang'));
      });

      $('#control-play').classList.add('hover');
      $('#control-lang').classList.add('hover');
      $('#control-clarity').classList.add('hover');
      $('#control-volume').classList.add('hover');
      $('#control-fullscreen').classList.add('hover');
      $('#control-play').classList.add('active');
      $('#control-lang').classList.add('active');
      $('#control-volume-button').classList.add('active');
      $('#control-fullscreen').classList.add('active');
    }

    if ($('.video .plyr--video .plyr__controls')) {
      const allowControl = this.getAllowControl();
      if (!allowControl) {
        $('.video .plyr--video .plyr__controls').style.display = 'none';
      } else {
        $('.video .plyr--video .plyr__controls').style.display = 'flex';
        $('.video .plyr--video .plyr__controls').style.opacity = 1;
      }
    }
  }

  hideQuiz() {
    hide($('.video-question-wrapper'));
    hide($('.video .video-question-wrapper .close-button'));
    hide($('.video .video-question-wrapper .screen-button'));
    this.hideCorrectPopup();
    this.hideTipBar();
    this.instance.play();

    this.exitQuizCloud();
  }

  mousewheelEvent = e => {
    const { deltaY } = e;
    if (Math.abs(deltaY) < 5) return;

    const isIncrease = deltaY > 0;

    this.calcZoomRatio(isIncrease);
  };

  mousewheelListener = (isListener = true) => {
    if (isListener) {
      $('.zoom-in .content').addEventListener(
        'mousewheel',
        this.mousewheelEvent,
      );
    } else {
      $('.zoom-in .content').removeEventListener(
        'mousewheel',
        this.mousewheelEvent,
      );
    }
  };

  /*
   * name: calcZoomRatio
   * desc: 鼠标每次滚轮 或 双指缩放 所触发 计算当前不同长宽比例图片变更倍率
   * */
  calcZoomRatio = isIncrease => {
    const currentWidth = parseInt(
      getComputedStyle($('.zoom-in .content')).width,
    );
    const currentHeight = parseInt(
      getComputedStyle($('.zoom-in .content')).height,
    );

    const wrapperHeight = parseInt(
      getComputedStyle($('.video .video-question-wrapper .zoom-in')).height,
    );
    const wrapperWidth = parseInt(
      getComputedStyle($('.video .video-question-wrapper .zoom-in')).width,
    );

    // 最高放大倍率
    if (
      isIncrease
      && (currentWidth >= wrapperWidth || currentHeight >= wrapperHeight)
    ) return;
    // 最低缩小倍率
    if (
      !isIncrease
      && (currentWidth <= wrapperWidth * 0.2
        || currentHeight <= wrapperHeight * 0.2)
    ) return;

    let increasingBasicValue;
    let increasingValue;

    const ratio = currentHeight / currentWidth;
    // 长小于宽
    if (ratio < 1) {
      increasingBasicValue = 10;
      increasingValue = Math.round(
        ((currentWidth + increasingBasicValue) * ratio) - currentHeight,
      );
    } else if (ratio >= 1) {
      // 宽小于长
      increasingValue = 10;
      increasingBasicValue = Math.round(
        ((currentHeight + increasingValue) / ratio) - currentWidth,
      );
    }

    $('.zoom-in .content').style.width = isIncrease
      ? `${currentWidth + increasingBasicValue}px`
      : `${currentWidth - increasingBasicValue}px`;

    $('.zoom-in .content').style.height = isIncrease
      ? `${currentHeight + increasingValue}px`
      : `${currentHeight - increasingValue}px`;
  };

  showZoomInContent = (content, version) => {
    const isNewVersion = version === QuizVersion.NEW;
    let optionType;
    let details;
    if (isNewVersion) {
      details = content.content;
      optionType = content.type;
    } else {
      // 旧版本 兼容
      details = content;
      optionType = details.includes('img')
        ? QuizOptionType.IMG
        : QuizOptionType.TEXT; // 类型 Enum: image(图片) text(文字)
    }

    $('.video .video-question-wrapper .zoom-in .content').innerHTML = details;
    $('.video .video-question-wrapper .zoom-in .content').classList.remove(
      'img',
    );
    $('.video .video-question-wrapper .zoom-in .content').classList.remove(
      'text',
    );
    $('.video .video-question-wrapper .zoom-in .content').classList.remove(
      'center',
    );
    $('.video .video-question-wrapper .zoom-in .content').style.height = 'auto';
    $('.video .video-question-wrapper .zoom-in .content').style.width = '72%';

    if (optionType === QuizOptionType.TEXT) {
      // 取出内容
      const dom = document.createElement('div');
      dom.innerHTML = details;
      const num = dom.textContent;

      // 短文字答案居中
      if (this.getSringNum(num) - 7 < 35) {
        $('.video .video-question-wrapper .zoom-in .content').classList.add(
          'center',
        );
      }

      $('.video .video-question-wrapper .zoom-in').style.overflowY = 'scroll';
      $('.video .video-question-wrapper .zoom-in .content').classList.add(
        'text',
      );
      // 监听鼠标滚轮事件，缩放
      this.mousewheelListener(false);

      const zoomInHeight = $('.video .video-question-wrapper .zoom-in')
        .offsetHeight;
      const zoomInContentHeight = $(
        '.video .video-question-wrapper .zoom-in .content',
      ).offsetHeight;
      // 文字溢出容纳区域的处理
      if (zoomInContentHeight > zoomInHeight) {
        $('.video .video-question-wrapper .zoom-in .content').classList.add(
          'overflow',
        );
      } else {
        $('.video .video-question-wrapper .zoom-in .content').classList.remove(
          'overflow',
        );
      }
    } else if (optionType === QuizOptionType.IMG) {
      $('.video .video-question-wrapper .zoom-in').style.overflowY = 'hidden';
      $('.video .video-question-wrapper .zoom-in .content').classList.add(
        'img',
      );
      // 监听鼠标滚轮事件，缩放
      this.mousewheelListener();
      // 计算 图片高宽比大于1 的初始化数值
      this.calcImgSideRatio();
    }

    $('.video .video-question-wrapper .zoom-in').scrollTo(0, 0);
    show($('.video .video-question-wrapper .zoom-in'));
    hide($('.video .video-question-wrapper .screen-button'));
    $('.video .video-question-wrapper .close-button').classList.add('hidden');
  };

  hideZoomInContent = () => {
    hide($('.video .video-question-wrapper .zoom-in'));
    show($('.video .video-question-wrapper .screen-button'));
    $('.video .video-question-wrapper .close-button').classList.remove(
      'hidden',
    );

    this.props.onHideZoomInContent && this.props.onHideZoomInContent();
  };

  remoteHideZoomInContent = () => {
    hide($('.video .video-question-wrapper .zoom-in'));
    show($('.video .video-question-wrapper .screen-button'));
    $('.video .video-question-wrapper .close-button').classList.remove(
      'hidden',
    );
  };

  calcImgSideRatio = () => {
    // 计算图片比例：高度大于宽度的情况，因CSS模型默认以宽度为准，以高度为准 需要JS控制

    // const wrapperWidth = parseInt(getComputedStyle($('.video .video-question-wrapper .zoom-in')).width);
    const wrapperHeight = parseInt(
      getComputedStyle($('.video .video-question-wrapper .zoom-in')).height,
    );
    const contentWidth = parseInt(
      getComputedStyle($('.video .video-question-wrapper .zoom-in .content'))
        .width,
    );
    const contentHeight = parseInt(
      getComputedStyle($('.video .video-question-wrapper .zoom-in .content'))
        .height,
    );

    if (contentHeight > contentWidth) {
      const calcContentHeight = Math.round(wrapperHeight * 0.8);
      const calcContentWidth = Math.round(
        (calcContentHeight * contentWidth) / contentHeight,
      );

      $(
        '.video .video-question-wrapper .zoom-in .content',
      ).style.height = `${calcContentHeight}px`;
      $(
        '.video .video-question-wrapper .zoom-in .content',
      ).style.width = `${calcContentWidth}px`;
    }
  };

  getSringNum = string => {
    let len = 0;
    for (let i = 0; i < string.length; i++) {
      if (string.charCodeAt(i) > 127 || string.charCodeAt(i) === 94) {
        len += 2;
      } else {
        len++;
      }
    }
    return len;
  };

  handleLanguageButton(languageType) {
    const {
      ButtonDom,
      SrcType,
      Language,
      IconButton,
      SwitchTipText,
      SwitchTipSucceedText,
    } = languageType;

    $(ButtonDom).addEventListener('click', () => {
      $('#control-lang').dispatchEvent(new Event('mouseleave'));
      if (
        !this.props.src[SrcType]
        || this.state.currentLanguage === languageOptions[Language]
      ) return;

      show($('.video .loading-wrapper'));
      show($('.video .switch-tip-wrapper'));
      // 更换切换语言按钮图标
      $('#lang-button').setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        IconButton,
      );

      setLangToLocalStorage(languageOptions[Language]);

      this.setState(
        {
          currentLanguage: languageOptions[Language],
          switchTipText: SwitchTipText,
          source: this.props.src[SrcType],
        },
        () => {
          const video = $('.video-ele');
          video.load();
          video.addEventListener('canplay', () => {
            setTimeout(() => {
              hide($('.video .loading-wrapper'));
              show($('.video .switch-tip-wrapper'));
              this.setState(
                {
                  switchTipText: SwitchTipSucceedText,
                },
                () => {
                  video.play();
                  setTimeout(() => hide($('.video .switch-tip-wrapper')), 1000);
                },
              );
            }, 500);
          });
        },
      );
    });
  }

  fixedPopupScroll = (isDidMount = true) => {
    // 初始化时
    // iPad 使用此方案阻止滚动视频加载不出来
    if (isDidMount && !isIpad()) {
      // 在弹出层显示之前，记录当前的滚动位置
      this.PreScrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      // 使body脱离文档流
      document.body.style.cssText = `position: fixed;width: 100%;top: ${-this
        .PreScrollTop}px;`;
    } else {
      // 卸载时
      document.body.style.cssText = '';
      // body回滚到之前位置
      document.body.scrollTop = document.documentElement.scrollTop = this.PreScrollTop; //eslint-disable-line
      window.scrollTo(0, this.PreScrollTop);
    }
  };

  trackVideoClose() {
    const playerInstance = this.instance;
    const {
      videoActivityId,
      videoClassId,
      videoLessonId,
      videoPackageId,
      videoLocation,
      videoName,
    } = this.props;
    Track.track('Video_Close', {
      currentTime: +playerInstance.currentTime,
      duration: +playerInstance.duration,
      activityId: +videoActivityId,
      lessonid: +videoLessonId,
      activityName: videoName,
      class_id: +videoClassId,
      class_name: this.className,
      package_id: +videoPackageId,
      package_name: this.packageName,
      lesson_name: this.lessonName,
      videoLocation,
      totalWatchingTime:
        typeof this.totalTime === 'number'
          ? this.totalTime + this.watchedVideoTime
          : undefined,
    });
    if (this.shouldFetch) {
      webStorage.set(
        this.videoRemoteId,
        `${+this.totalTime + this.watchedVideoTime}`,
      );
    }
  }

  handleClose(e) {
    e && e.stopPropagation && e.stopPropagation();
    // 在未获取视频长度 或 没获取是否有题目的状态 前 不能关闭
    if (
      ((this.instance && !this.instance.duration) || !this.canCloseVideo)
      && !this.hasErrorOnVideoLoading
    ) return;

    this.trackVideoClose();

    // TODO：目前需求暂时处理
    // if 类型为video2 但无实际题目
    if (
      !this.state.video2WithoutQuestion
      && this.props.videoType === Config.activityType.video2
    ) {
      const keys = Object.keys(this.state.questionsMap).sort((a, b) => a - b);

      const keysLength = keys.length;
      // 若 最后一个题目的状态已经是答对状态，说明所有题目已完成
      if (
        this.state.questionsMap[keys[keysLength - 1]]
        && this.state.questionsMap[keys[keysLength - 1]].selectedCorrect
      ) {
        return this.props.onClose(
          this.instance.currentTime,
          parseInt(this.instance.duration),
          true, // isAllCompleted
          this.state.video2WithoutQuestion,
          getWatchedTime(this.watchedVideoTime, this.instance.duration),
        );
      }
    }

    this.props.onClose(
      this.instance.currentTime,
      parseInt(this.instance.duration),
      false, // isAllCompleted
      this.state.video2WithoutQuestion,
      getWatchedTime(this.watchedVideoTime, this.instance.duration),
    );
  }

  showCorrectPopup() {
    $('.video .video-question-wrapper .video-correct-popup').classList.add(
      'show',
    );

    this.correctPopupTimer = setTimeout(() => {
      this.hideCorrectPopup();
      clearTimeout(this.correctPopupTimer);
    }, 1500);
  }

  hideCorrectPopup() {
    $('.video .video-question-wrapper .video-correct-popup').classList.remove(
      'show',
    );
  }

  submitAnswer(option, questionId, isUpdateQuestions) {
    // toB：线下老师可重复答题
    if (this.state.isOfflineTeacher) return;

    this.props.videoModel
      .submitQuestionOption(
        this.props.src.id,
        questionId,
        this.props.videoClassId || 1,
        this.props.videoLessonId,
        this.props.videoPackageId,
        this.props.videoActivityId,
        [option],
      )
      .then(() => {
        isUpdateQuestions && this.initializeVideoQuestion();
      });
  }

  showTipBar() {
    if (!this.state.currentTip) {
      $('.video-tips-bar').innerHTML = '<p>再想想吧，要仔细看题目喔~</p><p><br></p>';
    } else {
      $('.video-tips-bar').innerHTML = this.state.currentTip;
    }
    $('.video .video-question-wrapper .video-tips-bar').classList.add('show');
    show($('.video .video-question-wrapper .bell-cartoon'));
  }

  hideTipBar() {
    $('.video .video-question-wrapper .video-tips-bar').classList.remove(
      'show',
    );
    hide($('.video .video-question-wrapper .bell-cartoon'));
  }

  showOptionZoomIn = (e, questionOptions, version, isDirect) => {
    const currentIndex = e.currentTarget.dataset.index;
    if (isDirect) {
      this.props.onOptionZoomIn
        && this.props.onOptionZoomIn(
          currentIndex,
          questionOptions,
          version,
          isDirect,
        );
      this.showZoomInContent(questionOptions[currentIndex], version);
    } else if (
      $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.contains('correct')
      || $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.contains('error')
    ) {
      this.showZoomInContent(questionOptions[currentIndex], version);
    }
  };

  reshowOptionZoomIn = (clickIndex, questionOptions, version, isDirect) => {
    const currentIndex = clickIndex;
    if (isDirect) {
      this.showZoomInContent(questionOptions[currentIndex], version);
    } else if (
      $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.contains('correct')
      || $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.contains('error')
    ) {
      this.showZoomInContent(questionOptions[currentIndex], version);
    }
  };

  getStyleFromString = optionStyle => splitString(optionStyle);

  getOptionDisplayStyle = optionDisplayStyle => {
    switch (optionDisplayStyle) {
      case QuizOptionDisplayStyle['2x2']:
        return `style_${QuizOptionDisplayStyle['2x2']}`;
      case QuizOptionDisplayStyle['1x4']:
        return `style_${QuizOptionDisplayStyle['1x4']}`;
      case QuizOptionDisplayStyle['4x1']:
        return `style_${QuizOptionDisplayStyle['4x1']}`;
      default:
        return `style_${QuizOptionDisplayStyle['2x2']}`;
    }
  };

  // 创建quiz面板
  createQuizPannel = (question, isSelectedCorrect, answered) => {
    const fragment = document.createDocumentFragment();
    const {
      options,
      version,
      answers,
      option_display_style: optionDisplayStyle,
    } = question;
    const isNewVersionQuiz = version === QuizVersion.NEW;

    let optionStyle;
    let structure;

    if (isNewVersionQuiz) {
      // eslint-disable-next-line prefer-const
      [optionStyle, structure] = [
        this.getOptionDisplayStyle(
          this.getStyleFromString(optionDisplayStyle)[0],
        ),
        this.getStyleFromString(optionDisplayStyle)[1],
      ];
    }

    $('.video-question-content')
      && $('.video-question-content').classList.remove('vertical');
    $('.video-question-content')
      && $('.video-question-content').classList.remove('horizontal');
    $('.video-question-content')
      && $('.video-question-content').classList.remove('new_style');

    if (isNewVersionQuiz) {
      $('.video-question-content')
        && $('.video-question-content').classList.add('new_style');
      $('.video-question-content')
        && $('.video-question-content').classList.add(`${structure}`);
    }

    options.forEach((item, index) => {
      const optionBox = document.createElement('div');
      optionBox.classList.add('option-box');

      if (isNewVersionQuiz) {
        optionBox.classList.add(optionStyle);
      }

      const allowControl = this.getAllowControl();

      optionBox.innerHTML = `<div class="option-symbol">${MapIndexToAlphabet(
        index,
      )}</div>
         <div data-index="${index}" class="option-content ${
        this.props.controlSide === Config.QuizControlSide.STUDENT
        || !allowControl
          ? 'disabled'
          : ''
      } ${
        answered
          ? +answered.correct === index
            ? 'correct'
            : '' || (answered.error && answered.error.includes(String(index)))
            ? 'error'
            : ''
          : isSelectedCorrect && answers.includes(String(index))
          ? 'correct'
          : isSelectedCorrect
          ? 'error'
          : ''
      }">
         <div class="option-content-wrapper">${
           isNewVersionQuiz ? item.content : item
         }</div>

            <div data-index="${index}" class="zoom-in-button"></div>
         </div>`;

      fragment.appendChild(optionBox);
    });

    $('.video-question-title').innerHTML = isNewVersionQuiz
      ? question.title.content
      : question.title;
    $('.video-options-wrapper').innerHTML = '';
    $('.video-options-wrapper').appendChild(fragment);

    return fragment;
  };

  // 打开quiz
  appendOptions(
    question,
    isSetCurrentTime = false,
    isTeacher = false,
    isAutoOpen = true,
  ) {
    const {
      version,
      play_at,
      selectedCorrect,
      answers,
      tips,
      title,
      options,
      id,
    } = question;

    if (isSetCurrentTime) this.instance.currentTime = play_at;

    if (!selectedCorrect) {
      this.quizDuration = 0;
      this.interval = setInterval(() => {
        this.quizDuration += 1;
      }, 1000);
    }

    // 老师角色 是否 直接选择答案
    // isSelectedCorrect = isTeacher ? true : question.selectedCorrect,

    const isSelectedCorrect = isTeacher ? selectedCorrect : selectedCorrect;

    if (!this._answered[id]) {
      this._prevAnswered = id;
      this.createQuizPannel(question, isSelectedCorrect);
    } else if (id !== this._prevAnswered) {
      this._prevAnswered = id;
      this.createQuizPannel(question, isSelectedCorrect, this._answered[id]);
    }

    if (this.props.controlSide === Config.QuizControlSide.STUDENT) {
      console.log('video-dialog');
      this.setState({
        isShowVideoDialog: true,
      });
    }

    this.setState(
      {
        currentAnswer: [...answers],
        currentTip: tips,
        questionTitle: title,
        questionOptions: options,
      },
      () => {
        this.props.openQuiz
          && this.props.openQuiz({
            openQuizById: id,
          });

        if (
          question.selectedCorrect
          || (isTeacher && id in this.state.quizPathMap)
        ) {
          show($('.video .video-question-wrapper .close-button'));
        } else {
          hide($('.video .video-question-wrapper .close-button'));
        }

        // 每个选项的放大显示监听
        Array.from(document.querySelectorAll('.zoom-in-button')).forEach(
          item => {
            item.addEventListener('click', e => {
              e.stopPropagation();
              this.showOptionZoomIn(
                e,
                this.state.questionOptions,
                version,
                true,
                question,
              );
            });
          },
        );

        // 每个选项事件监听
        Array.from(document.querySelectorAll('.option-content')).forEach(
          item => {
            item.addEventListener('click', e => {
              if (question.selectedCorrect) {
                this.showOptionZoomIn(
                  e,
                  this.state.questionOptions,
                  version,
                  true,
                );
              } else {
                this.showOptionZoomIn(
                  e,
                  this.state.questionOptions,
                  version,
                  false,
                );
                this.selectOption(e, question);
              }
            });
          },
        );
      },
    );

    this.instance.pause();
    show($('.video-question-wrapper'));
    show($('.video .video-question-wrapper .screen-button'));
    this.openQuizTracker(question.id, isAutoOpen, question.selectedCorrect);
  }

  remoteSelectOption(currentIndex, questionID) {
    const id = questionID;
    let l;
    // 记录答题路径
    if (!this.state.quizPathMap[id]) {
      l = [];
      l.push(+currentIndex + 1);
    } else if (!this.state.quizPathMap[id].includes(+currentIndex + 1)) {
      l = [...this.state.quizPathMap[id]];
      l.push(+currentIndex + 1);
    }

    this.setState({
      quizPathMap: {
        ...this.state.quizPathMap,
        [id]: l,
      },
    });

    const _currentAnswered = this._answered[id] || {};

    // 选择答案正确
    if (this.state.currentAnswer.includes(currentIndex)) {
      if (!_currentAnswered || !_currentAnswered.correct) {
        this._answered[id] = _currentAnswered;
        this._answered[id].correct = currentIndex;
      }

      this.submitAnswer(~~currentIndex, id, true);

      this.finishQuizTracker(id, this.quizDuration);
      clearInterval(this.interval);

      $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.add('correct');
      this.showCorrectPopup();
      this.hideTipBar();
    } else {
      this._answered[id] = _currentAnswered;
      this._answered[id].error = _currentAnswered.error || [];
      !this._answered[id].error.includes(currentIndex)
        && this._answered[id].error.push(currentIndex);

      // 选择答案错误
      this.submitAnswer(~~currentIndex, id, false);

      window.a = $('.video-options-wrapper').children[currentIndex].children[1];

      $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.add('error');
      this.showTipBar();
    }
  }

  selectOption(e, question) {
    const allowControl = this.getAllowControl();
    if (
      this.props.controlSide === Config.QuizControlSide.STUDENT
      || !allowControl
    ) return;

    const currentIndex = e.currentTarget.dataset.index;

    const id = question.id;

    this.props.onOptionClick && this.props.onOptionClick(currentIndex, id);

    let l;
    // 记录答题路径
    if (!this.state.quizPathMap[id]) {
      l = [];
      l.push(+currentIndex + 1);
    } else if (!this.state.quizPathMap[id].includes(+currentIndex + 1)) {
      l = [...this.state.quizPathMap[id]];
      l.push(+currentIndex + 1);
    }

    this.setState({
      quizPathMap: {
        ...this.state.quizPathMap,
        [id]: l,
      },
    });

    const _currentAnswered = this._answered[id] || {};

    // 选择答案正确
    if (this.state.currentAnswer.includes(currentIndex)) {
      if (!_currentAnswered || !_currentAnswered.correct) {
        this._answered[id] = _currentAnswered;
        this._answered[id].correct = currentIndex;
      }

      this.submitAnswer(~~currentIndex, id, true);

      this.finishQuizTracker(id, this.quizDuration);
      clearInterval(this.interval);

      $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.add('correct');
      this.showCorrectPopup();
      this.hideTipBar();
      show($('.video .video-question-wrapper .close-button'));
    } else {
      this._answered[id] = _currentAnswered;
      this._answered[id].error = _currentAnswered.error || [];
      !this._answered[id].error.includes(currentIndex)
        && this._answered[id].error.push(currentIndex);

      // 选择答案错误
      this.submitAnswer(~~currentIndex, id, false);

      $('.video-options-wrapper').children[
        currentIndex
      ].children[1].classList.add('error');
      this.showTipBar();
    }
  }

  initializeVideoQuestion() {
    // 获取当前 video 的 quiz 信息
    this.props.videoModel
      .getQuestionsById(
        this.props.src.id,
        this.props.videoActivityId,
        this.props.videoClassId || 1,
        this.props.videoPackageId,
      )
      .then(data => {
        // 处理 video type 为 10，但无题目的情况
        if (data.length === 0) {
          return this.setState(
            {
              video2WithoutQuestion: true,
            },
            () => {
              this.canCloseVideo = true;
            },
          );
        }
        this.canCloseVideo = true;

        const newQuestionsMap = { ...this.state.questionsMap };

        // 遍历题目信息
        data.forEach(item => {
          // 创建DOM
          const anchor = document.createElement('div');
          const img = document.createElement('img');
          anchor.classList = 'video-mark';
          anchor.dataset.time = item.play_at;
          img.classList = 'video-mark-img';
          img.src = mark;
          anchor.appendChild(img);

          const allowControl = this.getAllowControl();

          const answersList = item.answers;
          const studentAnswerList = item.answer_from_student;
          // 用户是否答对过某题目，方便之后利用 （支持多选）
          item.selectedCorrect = this.props.controlSide === Config.QuizControlSide.STUDENT
              ? false
              : answersList.every(item => studentAnswerList.includes(item));

          // todo:: 求重构啊
          if (!allowControl) {
            item.selectedCorrect = false;
          }

          // 设置题目锚点
          // 答对过的题目 或 老师角色 会显示题目埋点，其他情况隐藏
          if (item.play_at <= this.state.duration) {
            anchor.style.display = `${
              item.selectedCorrect
                ? 'block'
                : this.state.isTeacher
                ? 'block'
                : 'none'
            }`;
            // 设置锚点位置
            anchor.style.left = `${((item.play_at * 100) / this.state.duration)
              + 1}%`;
            $('.video .plyr__progress').appendChild(anchor);
          }
          // 设置key为时间锚点，方便调用
          newQuestionsMap[item.play_at] = item;
        });
        // 每个锚点的监听事件
        Array.from(document.querySelectorAll('.video-mark')).forEach(item => {
          item.addEventListener('click', e => {
            const timeIndex = ~~e.currentTarget.dataset.time;
            // 如果题目map中有当前触发埋点的题目
            if (this.state.questionsMap[timeIndex]) {
              // 打开当前题目
              this.appendOptions(
                this.state.questionsMap[timeIndex],
                false,
                this.state.isTeacher,
                false,
              );
            }
          });
        });

        this.setState(
          {
            questionsMap: newQuestionsMap,
          },
          () => {
            // 检测当前是否被老师控制，打开quiz
            this.detectIsOpenQuiz();
          },
        );
      })
      .catch(error => {
        console.error(error);
        // 老师以学生视角查看视频，视频中含有题目时无法关闭屏幕
        if (!this.canCloseVideo && this.state.isTeacher) {
          this.canCloseVideo = true;
        }
      });
  }

  detectIsOpenQuiz = () => {
    // 存在需要打开的Quiz
    if (this.props.openQuizById) {
      let openQuizInfo;

      // eslint-disable-next-line guard-for-in
      for (const i in this.state.questionsMap) {
        const item = this.state.questionsMap[i];

        if (item.id === this.props.openQuizById) {
          openQuizInfo = item;
          break;
        }
      }

      this.appendOptions(openQuizInfo, false, this.state.isTeacher, false);
    }
  };

  initializeVideoSrc() {
    const { src } = this.props;

    const isWithClarity = !!src.extra;

    isWithClarity
      ? this.initializeVideoSrcWithClarity(src)
      : this.initializeVideoSrcWithoutClarity();
  }

  initializeVideoSrcWithClarity(src) {
    // 加载 多清晰度版 video 源
    let sourceData;
    let currentLanguage;
    let clarityButtonSvg;
    const ChineseSrc = src.extra[languageOptions.Chinese];
    const EnglishSrc = src.extra[languageOptions.English];

    {
      // 兼容extra内容为空、回退方案
      const isMultilingual = !!(ChineseSrc || EnglishSrc);
      if (!isMultilingual) {
        return this.initializeVideoSrcWithoutClarity();
      }
    }

    // 用户上次选择
    const userSelectClarity = window.localStorage.getItem(
      localStorageVideoClarity,
    );

    // 默认优先语言：中文（由于预期 中英语言切换功能 未来将被产品阉割，这里简单处理）
    const priorityLangSrc = ChineseSrc || EnglishSrc;

    // 视频源 优先规则
    // eslint-disable-next-line
    sourceData =
      ((clarityButtonSvg = userSelectClarity)
        && priorityLangSrc[userSelectClarity])
      || ((clarityButtonSvg = DefaultClarity)
        && priorityLangSrc[DefaultClarity])
      || ((clarityButtonSvg = ClarityOptions['720p'])
        && priorityLangSrc[ClarityOptions['720p']])
      || ((clarityButtonSvg = ClarityOptions['360p'])
        && priorityLangSrc[ClarityOptions['360p']]);

    // eslint-disable-next-line
    currentLanguage = ChineseSrc
      ? languageOptions.Chinese
      : languageOptions.English;

    $$('.control-clarity-option').forEach(dom => {
      const clarity = dom.dataset.clarity;

      const isExistOption = priorityLangSrc[clarity];

      if (!isExistOption) {
        dom.classList.add('disabled');
      }
    });

    if (!sourceData) {
      return this.initializeVideoSrcWithoutClarity();
    }

    // 初始化对应的按钮svg
    this.replaceButtonSvg(
      $('#clarity-button'),
      ClarityOptionsButton[clarityButtonSvg],
    );

    this.setState({
      source: sourceData,
      currentLanguage,
    });

    this.initializeClarityOption();
  }

  initializeVideoSrcWithoutClarity() {
    // 1. 根据用户上次的选择，视频显示相应的语言
    // userSelectLang => en / cn
    // 模拟用户上次选择
    const userSelectLang = window.localStorage.getItem(localStorageVideoLang);

    let sourceData;
    let currentLanguage;

    if (userSelectLang) {
      // 返回对应语言 src
      sourceData = this.props.src[userSelectLang];
      currentLanguage = userSelectLang;
      // 3. 当前视频没有则提示用户没有该类型，并跳转有视频的语言
      sourceData
        || ({ sourceData, currentLanguage } = this.getOnlyVideoSrc(
          userSelectLang,
        ));
    } else if (!userSelectLang) {
      // 2. 若用户未进行过，默认的视频语言同系统的语言设定是相同的
      const currentSystemLang = i18n.getCurrentLanguage();
      sourceData = this.props.src[currentSystemLang];
      currentLanguage = currentSystemLang;
      // 3. 当前视频没有则提示用户没有该类型，并跳转有视频的语言
      sourceData
        || ({ sourceData, currentLanguage } = this.getOnlyVideoSrc(
          currentSystemLang,
        ));
    }

    currentLanguage === languageOptions.English
      ? $('#lang-button').setAttributeNS(
          'http://www.w3.org/1999/xlink',
          'xlink:href',
          iconEnButton,
        )
      : $('#lang-button').setAttributeNS(
          'http://www.w3.org/1999/xlink',
          'xlink:href',
          iconCnButton,
        );

    this.replaceButtonSvg($('#clarity-button'), ClarityOptionsButton['720p']);

    this.setState({
      source: sourceData,
      currentLanguage,
    });

    this.setClarityDisabled();
  }

  switchToClarity = clarityOption => {
    const { src } = this.props;
    const { currentLanguage } = this.state;
    const videoCurrentTime = this.instance.currentTime;

    let sourceData;
    // eslint-disable-next-line
    sourceData = src.extra[currentLanguage][clarityOption];

    if (!sourceData) return;

    show($('.video .loading-wrapper'));
    show($('.video .switch-tip-wrapper'));

    // 存储用户选择清晰度
    setClarityToLocalStorage(clarityOption);
    // 更换切换清晰度按钮图标
    this.replaceButtonSvg(
      $('#clarity-button'),
      ClarityOptionsButton[clarityOption],
    );

    this.setState(
      {
        switchTipText: '切换清晰度中',
        source: sourceData,
      },
      () => {
        const video = $('.video-ele');
        video.load();
        // 切换清晰度 对应相同播放进度
        video.currentTime = videoCurrentTime;

        const switchedClarity = () => {
          setTimeout(() => {
            hide($('.video .loading-wrapper'));
            show($('.video .switch-tip-wrapper'));
            this.setState(
              {
                switchTipText: '切换清晰度完成',
              },
              () => {
                video.play();
                video.removeEventListener('canplay', switchedClarity);
                setTimeout(() => hide($('.video .switch-tip-wrapper')), 1000);
              },
            );
          }, 500);
        };

        video.addEventListener('canplay', switchedClarity);
      },
    );
  };

  initializeClarityOption = () => {
    $('.control-clarity-option-wrapper').addEventListener('click', e => {
      const selectedClarity = e.target.dataset.clarity;

      this.switchToClarity(selectedClarity);
    });
  };

  setClarityDisabled = () => {
    $$('.control-clarity-option').forEach(dom => {
      dom.classList.add('disabled');
    });
  };

  replaceButtonSvg = (dom, svg) => {
    dom.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', svg);
  };

  getOnlyVideoSrc(missingType) {
    const onlyVideoType = missingType === languageOptions.Chinese
        ? languageOptions.English
        : languageOptions.Chinese;
    this.showJumpingPopup(missingType, onlyVideoType);
    return {
      sourceData: this.props.src[onlyVideoType],
      currentLanguage: onlyVideoType,
    };
  }

  initializeLanguageButton() {
    if (!this.props.src[languageOptions.Chinese]) {
      $('.control-lang-cn-img').style.backgroundImage = `url(${iconCnDisable})`;
      $('.control-lang-cn').style.cursor = 'default';
    } else if (!this.props.src[languageOptions.English]) {
      $('.control-lang-en-img').style.backgroundImage = `url(${iconEnDisable})`;
      $('.control-lang-en').style.cursor = 'default';
    }
  }

  showJumpingPopup(missingType, onlyVideoType) {
    show($('.jumping-popup'));
    this.setState(
      {
        missingLang: languageTips[missingType],
        jumpToLang: languageTips[onlyVideoType],
      },
      () => {
        setTimeout(() => {
          hide($('.jumping-popup'));
        }, 2000);
      },
    );
  }

  openQuizTracker(quizId, isAutoOpen = true, hasDone = false) {
    const trackData = {
      entranceWay: isAutoOpen ? 'playTo' : 'clickTo', // 进入方式 (点击进度条进入 / 视频播放到对应地方，自动弹出Quiz)
      hasDone, // 是否已经正确作答过
      quizId,
      videoCurrentTime: ~~this.instance.currentTime,
      videoActivityId: this.props.videoActivityId,
      videoName: this.props.videoName,
      lessonid: +this.props.videoLessonId,
      lessonName: this.props.lessonName,
    };

    this.props.onOpenQuiz && this.props.onOpenQuiz(quizId);

    Track.track('videoQuiz_openQuiz', trackData);
  }

  finishQuizTracker(quizId, duration) {
    if (
      !this.state.quizPathMap[quizId]
      || !this.state.quizPathMap[quizId].length
    ) return;

    const trackData = {
      answerPath: JSON.stringify(this.state.quizPathMap[quizId]),
      attemptCount: this.state.quizPathMap[quizId].length,
      duration,
      quizId,
      videoCurrentTime: ~~this.instance.currentTime,
      videoActivityId: this.props.videoActivityId,
      videoName: this.props.videoName,
      lessonid: +this.props.videoLessonId,
      lessonName: this.props.lessonName,
    };

    Track.track('videoQuiz_finishQuiz', trackData);
  }

  /*
   * 重置为quiz关闭状态
   * */
  exitQuizCloud = () => {
    this.props.openQuiz && this.props.openQuiz({});
  };

  /**
   * 使用控制属性进行下一步。
   * 所有需要控制权限操作都应该使用这个中间方法来进行
   * @example this.continueWithControl(() => { // your normal logic }, () => { // reject logic. })
   * @param {*} continueCallback
   * @param {*} rejectCallback
   */
  continueWithControl(continueCallback, rejectCallback) {
    const control = this.getAllowControl();

    if (!control) {
      if (this.props.onNotAllowControl) {
        this.props.onNotAllowControl();
      } else {
        console.warn('not allow control');
      }
      rejectCallback();
      return;
    }

    return continueCallback();
  }

  /**
   * return allow control video
   * @returns {boolean}
   */
  getAllowControl() {
    let { allowControl } = this.props;

    // if not inject in components of parent
    // default control value as true
    if (allowControl === undefined || allowControl === null) {
      allowControl = true;
    }
    return allowControl;
  }

  render() {
    const isControlled = this.props.controlSide !== Config.QuizControlSide.STUDENT;
    return (
      <div>
        <div
          className="video video-dialog"
          style={{
            opacity: `${this.state.isShowVideoDialog ? '1' : '0'}`,
          }}
        >
          {/* eslint-disable-next-line */}
          <video
            className="video-ele"
            playsInline
            muted={isControlled}
            onError={() => (this.hasErrorOnVideoLoading = true)}
          >
            <source src={this.state.source} />
          </video>
          {!this.props.inlineMode && (
            <div className="video-btn_close" onClick={this.handleClose}>
              <div className="video-btn_close-btn" />
            </div>
          )}
          <div className="jumping-popup">
            <p className="jumping-popup-text">
              {`${this.state.missingLang} 版本的视频正在制作中，为您跳转 ${
                this.state.jumpToLang
              } 教学视频`}
            </p>
          </div>
          <div className="loading-wrapper">
            <img className="loading-img" src={loadingGif} alt="" />
          </div>
          <div className="switch-tip-wrapper">
            <p className="switch-tip-text">{this.state.switchTipText}</p>
          </div>
        </div>
      </div>
    );
  }
}

// TODO:: 这里的事件没有调用的。要用自己实现
Video.propTypes = {
  src: PropTypes.object.isRequired,
  onPlay: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSeeked: PropTypes.func,
  onFullScreen: PropTypes.func,
  onVolumechange: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  onRestart: PropTypes.func,
  exitFullScreen: PropTypes.func,
  poster: PropTypes.string,
  videoPackageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  videoLessonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const emptyFn = () => {};
Video.defaultProps = {
  poster: '',
  onRestart: emptyFn,
  onPlay: emptyFn,
  onSeeked: emptyFn,
  onFullScreen: emptyFn,
  onVolumechange: emptyFn,
  onPause: emptyFn,
  onEnded: emptyFn,
};

export default Video;
