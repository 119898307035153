import Runtime from './runtime/runtime';
import PuzzleWorkspace from './workspace/workspace';
import Detector from './detector/detector';
import puzzleVMScratchBlocks from './helpers/puzzle-blocks';
import createVMBlocksOrphan from './helpers/create-vm-blocks-orphan';
import puzzleDetectorHandlerUtils from './helpers/puzzle-detector-handler-utils';
import log from '../../../lib/log';
import {getXmlresult} from 'py-to-scratch';

import {USERMODIFY, PRESETBLOCK} from '../puzzle/runtime/modify-type';
import layoutWorkspaceBlocks, {
    layoutWorkspaceBlocksTem
} from './helpers/layout-ws-blocks';

class Puzzle {
    constructor() {
        this.config = null;
        this.runtime = null;
        this.puzzleWorkspace = null;

        this._isInPreviewMode = false;
        this.vm = null;
        this.workspace = null;
        this.workspaceVMBlocks = null;
        this.current = 0;
    }

    get isRunning() {
        return this.runtime.isRunning;
    }

    /**
     * In puzzle mode,  vm no longer connect to the workspace.
     * Create a extra 'workspaceVMBlocks' to connect the workspace.
     * @param {VirtualMachine} vm Vm
     * @param {Workspace} workspace Workspace
     */
    connectVMWorkspace(vm, workspace) {
        vm.setCompatibilityMode(true);

        const workspaceVMBlocks = createVMBlocksOrphan(vm);
        const flyoutWorkspace = workspace.getFlyout().getWorkspace();

        // Remove listeners bind in 'src/containers/blocks.jsx'
        workspace.removeChangeListener(vm.blockListener);
        flyoutWorkspace.removeChangeListener(vm.flyoutBlockListener);

        workspace.addChangeListener(e => {
            if (
                e.element !== 'stackclick' &&
                e.type !== 'var_rename' &&
                e.type !== 'var_delete'
            ) {
                workspaceVMBlocks.blocklyListen(e, vm.runtime);
            }
        });
        flyoutWorkspace.addChangeListener(e => {
            if (
                e.type === 'create' ||
                e.type === 'delete' ||
                e.type === 'change'
            ) {
                vm.flyoutBlockListener(e);
            }
        });

        vm.removeAllListeners('targetsUpdate');
        vm.removeAllListeners('workspaceUpdate');
        // vm.removeListener('EXTENSION_ADDED', scratch.blocks.handleExtensionAdded);
        vm.removeAllListeners('SCRIPT_GLOW_ON');
        vm.removeAllListeners('SCRIPT_GLOW_OFF');
        vm.addListener('SCRIPT_GLOW_ON', data => {
            // Only puppet blocks display on the workspace
            if (workspaceVMBlocks.getBlock(data.id)) {
                workspace.glowStack(data.id, true);
            }
        });
        vm.addListener('SCRIPT_GLOW_OFF', data => {
            if (workspaceVMBlocks.getBlock(data.id)) {
                workspace.glowStack(data.id, false);
            }
        });

        puzzleVMScratchBlocks(vm, workspaceVMBlocks);

        this.vm = vm;
        this.workspace = workspace;
        this.workspaceVMBlocks = workspaceVMBlocks;
    }

    setConfig(config) {
        if (this.config) {
            Object.assign(this.config, config);
        } else {
            this.config = config;
        }

        return this.config;
    }

    load() {
        if (!this.config) {
            log.warn('Need set config before load!');
        }

        const {
            number = 1,
            targets,
            preCode,
            workspace: workspaceConfig,
            ...detectorConfig
        } = this.config;
        const editableTargets = [];
        const interactiveTargets = [];
        this.config.userPythonCode = preCode ? preCode.slice() : [];
        targets.forEach((targetIndex, index) => {
            const target = this.vm.runtime.targets[parseInt(targetIndex, 10)];
            if (!target) {
                // alert(`Target ${targetIndex} out of range.`); // eslint-disable-line no-alert
                if (confirm('由于网络问题，资源加载失败，请刷新当前页面')) {
                    location.reload();
                }

                return;
            }

            (index < number ? editableTargets : interactiveTargets).push(
                target
            );
        });
        this.config.editableTargetsIndexArray = targets.slice(0, number);

        const parsedTargets = {
            editable: editableTargets,
            interactive: interactiveTargets,
            deleteEditableBlocks: this.config.deleteBlocks
        };

        if (editableTargets[0]) {
            this.vm.setEditingTarget(editableTargets[0].id);
        }

        this.runtime = new Runtime(
            this.vm,
            this.workspaceVMBlocks,
            parsedTargets
        );
        this.puzzleWorkspace = new PuzzleWorkspace(
            this.vm,
            this.workspace,
            workspaceConfig
        );
        this.detector = new Detector(this.vm, this.workspace, {
            detectThreadTargets: editableTargets
                .slice(0, number)
                .map(target => ({
                    target: target,
                    blocks: this.workspaceVMBlocks
                })),
            handlerUtilsArg: puzzleDetectorHandlerUtils(this),
            ...detectorConfig
        });
        this.setConfig({
            editableTargets,
            interactiveTargets
        });
        this.runtime.run();
        this.config.pythonPuzzleVariables = this.puzzleWorkspace
            .getBlocks()
            .querySelector('variables').innerHTML;
    }

    switchTargetEditor(id) {
        this.vm.setEditingTarget(id);
    }

    setBlockCache(type, id) {
        if (this.runtime) {
            this.runtime.setBlockCache(type, id);
        }
    }

    start() {
        if (this.runtime) {
            this.runtime.start();
            if (!this._isInPreviewMode) {
                this.detector.run();
            }
        }
    }

    stop() {
        if (this.runtime) {
            if (!this._isInPreviewMode) {
                this.detector.cancel();
            }
            this.runtime.stop();
        }
    }

    freeze() {
        if (this.runtime) {
            if (!this._isInPreviewMode) {
                this.detector.cancel();
            }
            this.runtime.freeze();
        }
    }

    reset(resetAllRoles = true) {
        if (this.runtime) {
            if (!this._isInPreviewMode) {
                this.detector.cancel();
            }
            this.runtime.reset(resetAllRoles);
            this.puzzleWorkspace.reset();
        }
    }

    setPreviewMode(isPreview) {
        this._isInPreviewMode = !!isPreview;
    }

    setWorkspaceBlocks(blocks, clearWorkspace) {
        if ((typeof blocks).toLowerCase() === 'string') {
            this.puzzleWorkspace.setBlocksWithXmlStr(blocks, clearWorkspace);
        } else {
            this.puzzleWorkspace.setBlocks(blocks, clearWorkspace);
        }
    }

    get PythonPuzzleVariables() {
        if (!this.config.pythonPuzzleVariables) {
            this.config.pythonPuzzleVariables = this.puzzleWorkspace
                .getBlocks()
                .querySelector('variables').innerHTML;
        }
        return this.config.pythonPuzzleVariables;
    }

    get allProceducers() {
        return this.puzzleWorkspace.workspace.flyout_.workspace_.topBlocks_.filter(
            e => e.type === 'procedures_call'
        );
    }

    get currentEditingTargetId() {
        return this.workspaceVMBlocks.runtime._editingTarget.id;
    }

    /**
     *
     * @param {string} str python string
     * @param {boolean} clearWorkspace 是否清空工作区代码块
     * @param {boolean} autoLayout 代码块居中排版
     */
    pythonString2Block(str, clearWorkspace = false, autoLayout = false) {
        this.setWorkspaceBlocks(
            this.keepPythonVariables(
                getXmlresult(str || '', {
                    autoStart: true,
                    preFunctionList: this.customBlocksList
                })
            ),
            clearWorkspace
        );
        if (autoLayout) {
            layoutWorkspaceBlocks(this.workspace);
            layoutWorkspaceBlocksTem(this.workspace);
        }
    }
    /**
     * 获取可使用的预配置函数
     */
    get customBlocksList() {
        if (this.config.customBlocks && this.config.customBlocks.length > 0) {
            if (!this.config.pythonPreFnList) {
                this.config.pythonPreFnList = this.config.customBlocks.map(
                    e => {
                        const proceducerCall = this.allProceducers.filter(
                            item => item.procCode_.startsWith(e)
                        )[0];
                        return {
                            callName: e,
                            args: proceducerCall.argumentIds_
                        };
                    }
                );
            }
        }
        return this.config.pythonPreFnList || [];
    }

    keepPythonVariables(str) {
        return str.replace(
            '<xml xmlns="http://www.w3.org/1999/xhtml">',
            () =>
                `<xml xmlns="http://www.w3.org/1999/xhtml"><variables>${this.PythonPuzzleVariables}</variables>`
        );
    }
    /**
     * 初始化python puzzle
     * @param {funciton|undefined} cb 回调
     */
    mutilPythonRoleInit(cb) {
        this.PythonRoleInit = true;

        const {preCode, lastSavePythonCode} = this.config;
        this.config.userPythonCode = preCode.slice(0) || [];
        this.config.userPythonCode =
            lastSavePythonCode && lastSavePythonCode.length > 0
                ? lastSavePythonCode.slice(0)
                : preCode.slice(0);
        if (this.config.userPythonCode) {
            this.loadPython2Blocks(this.config.userPythonCode, PRESETBLOCK, cb);
        }
    }

    /**
     * 设置python puzzle 上次提交的答案
     * @param {array} lastAnwser 设置python puzzle 上次提交的答案
     * @param {funciton|undefined} cb 回调
     */
    setLastSubmitPythonBlocks(lastAnwser, cb) {
        this.loadPython2Blocks(lastAnwser, USERMODIFY, cb);
    }

    setPythonCode(value, index) {
        if (!this.config.userPythonCode) {
            this.config.userPythonCode = [];
        }
        this.config.userPythonCode[index] = value;
    }

    resetPythonCode(index) {
        this.config.userPythonCode[index] =
            (this.config.preCode.slice(0) || [])[index] || '';
        return this.config.userPythonCode[index];
    }

    loadPython2Blocks(arr, type, cb) {
        const {number} = this.config;
        if (arr && number > 1) {
            /*
             * 1.获取将要编辑目标
             * 2.切换编辑目标
             * 3.关闭workspace只读模式
             * 4.将python 转化为blocks,写入workspace,读取blocks
             * 5.获取blocks放入缓存
             */
            this.workspace.options.readOnly = false;
            const copy = arr.slice(0, number);
            this.continueSet(copy.length - 1, arr, type, cb);
        } else if (cb) cb();
    }

    continueSet(index, arr, type, cb) {
        if (index < 0) {
            this.workspace.options.readOnly = true;
            if (cb) cb();
            return;
        }
        const {editableTargetsIndexArray} = this.config;
        try {
            this.pythonString2Block(arr[index], true);
            const renderTargetIndex = editableTargetsIndexArray[index];
            this.timerToGetBlock = setTimeout(() => {
                this.setBlockCache(
                    type,
                    this.vm.runtime.targets[renderTargetIndex].id
                );
                this.continueSet(index - 1, arr, type, cb);
            }, 0);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            const renderTargetIndex = editableTargetsIndexArray[index];
            this.timerToGetBlock = setTimeout(() => {
                this.setBlockCache(
                    type,
                    this.vm.runtime.targets[renderTargetIndex].id
                );
                this.continueSet(index - 1, arr, type, cb);
            }, 0);
        }
    }
}

export default Puzzle;
