import React, {Component} from 'react';
import {checkIsMobile} from 'Common/utility';
import GeneralConfig from 'PublicRepo/utility/generalConfig';

import '!style-loader!css-loader!Asset/css/reset.import.css';
import '!style-loader!css-loader!./index.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: checkIsMobile(),
            Module: null
        };
        if (
            !__LOCAL__ &&
            (this.state.isMobile && location.pathname.indexOf('/share') === 0)
        ) {
            // fix wechat js-sdk domain problem
            window.location.replace(
                location.href.replace(
                    location.origin,
                    `${GeneralConfig.guiProxyByH5}gui`
                )
            );
        }
        this.dynamicImportModule = this.dynamicImportModule.bind(this);
    }

    componentDidMount() {
        this.dynamicImportModule().then(mod => {
            this.setState({
                Module: mod.default
            });
        });
    }

    dynamicImportModule() {
        if (this.state.isMobile) {
            return import('./mobileShare');
        }
        return import('./pcShare');
    }

    render() {
        const {Module: ChildComponent} = this.state;
        return (
            <div className="app-wrapper">
                {ChildComponent && <ChildComponent />}
            </div>
        );
    }
}

export default App;
