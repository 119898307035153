import ScratchBlocks from 'scratch-blocks';
import {
    guiTypes,
    makeToolboxXmlMiddlewares,
    makeToolboxXML
} from '../../blocks/blocks';
import isItemUsable from '../helpers/is-item-usable';

/**
 * Set current target variables into workspace.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @param {Blockly.Workspace} workspace ScratchBlocks mainWorkspace.
 * @return {string[]} New dom ids.
 */
const _setWorkspaceVariables = (vm, workspace) => {
    // Add variable to workspace
    const variableMap = Object.assign(
        {},
        vm.runtime.getTargetForStage().variables,
        vm.editingTarget.variables
    );

    const variablesXmlStr = Object.keys(variableMap)
        // remove explicit var
        .filter(id => isItemUsable(variableMap[id].name))
        .map(k => variableMap[k])
        .map(v => v.toXML())
        .join('');

    const xmlString = `<xml xmlns="http://www.w3.org/1999/xhtml">${variablesXmlStr}</xml>`;

    return ScratchBlocks.Xml.domToVariables(
        ScratchBlocks.Xml.textToDom(xmlString),
        workspace
    );
};

/**
 * Set current target public procedure definition into workspace.
 * `setFlyoutWorkspaceBlocks` need this and after would clear workspace.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @param {Blockly.Workspace} workspace ScratchBlocks mainWorkspace.
 * @return {string[]} New dom ids.
 */
const _setWorkspaceProcedure = (vm, workspace) => {
    const targetBlocks = vm.editingTarget.blocks;

    // Find out public procedure definition in target blocks.
    const procedureXml = targetBlocks.getScripts().reduce((str, script) => {
        const topBlock = targetBlocks.getBlock(script);

        if (topBlock.opcode !== 'procedures_definition') return str;

        const procedurePrototypeBlock = targetBlocks.getBlock(
            topBlock.inputs.custom_block.block
        );

        if (isItemUsable(procedurePrototypeBlock.mutation.proccode)) {
            const originBlockNext = topBlock.next;
            // If procedure blocks have some variables that we had removed in initContext.
            // `BlockToHTML` would import that again, so temporarily set next to null
            // to prevent dirtying context.
            topBlock.next = null;

            const blockXmlStr = targetBlocks.blockToXML(script, null);
            topBlock.next = originBlockNext;

            return `${str},${blockXmlStr}`;
        }

        return str;
    }, '');

    const xmlString = `<xml>${procedureXml}</xml>`;

    return ScratchBlocks.Xml.domToWorkspace(
        ScratchBlocks.Xml.textToDom(xmlString),
        workspace
    );
};

/**
 * Init puzzle workspace context (For vm editingTarget) by
 * rendering all flyout workspace blocks for current target.
 * @param {VirtualMachine} vm Instance of VirtualMachine.
 * @param {Blockly.Workspace} workspace ScratchBlocks mainWorkspace.
 * @param {boolean} justSetWorkspaceVariables is just set workspace variables
 * @param {boolean} isProceduresDefinitionUse  procedures_definition use or not
 * @param {array} ProceduresDefinitionNames procedures_definition name
 */
const initContext = (
    vm,
    workspace,
    justSetWorkspaceVariables,
    isProceduresDefinitionUse,
    ProceduresDefinitionNames
) => {
    // Render flyout blocks that filter out category tag & 'common' category for editingTarget.
    // START -- Use a hack way to get source toolboxXml.
    const middlewaresCopy = Object.assign({}, makeToolboxXmlMiddlewares);
    Object.values(guiTypes).forEach(
        type => (makeToolboxXmlMiddlewares[type] = [])
    );
    const dynamicBlocksXML = vm.runtime.getBlocksXML();
    const dom = ScratchBlocks.Xml.textToDom(
        makeToolboxXML(false, vm.editingTarget.id, dynamicBlocksXML)
    );
    Object.values(guiTypes).forEach(
        type => (makeToolboxXmlMiddlewares[type] = middlewaresCopy[type])
    );
    // END --

    // Set variable first to avoid showing some unexpected options.
    // Such as the default option `message1` of `event_broadcast`.
    _setWorkspaceVariables(vm, workspace);
    if (justSetWorkspaceVariables) return;
    // Add all normal blocks.
    const filteredDom = [];
    Array.prototype.forEach.call(dom.children, child => {
        if (child.tagName.toLowerCase() !== 'category') return;
        if (
            child.getAttribute('id') &&
            child.getAttribute('id').toLowerCase() === 'common'
        ) {
            return;
        }

        Array.prototype.forEach.call(child.children, subChild => {
            if (subChild.tagName.toLowerCase() === 'block') {
                filteredDom.push(subChild);
            }
        });
    });
    // Add variable blocks.
    filteredDom.push(ScratchBlocks.VARIABLE_CATEGORY_NAME);

    // Add procedure blocks.
    const procedureBlocksId = _setWorkspaceProcedure(vm, workspace);
    filteredDom.push(ScratchBlocks.PROCEDURE_CATEGORY_NAME);

    workspace.getFlyout().show(filteredDom);
    if (isProceduresDefinitionUse) {
        procedureBlocksId.forEach(id => {
            const block = workspace.getBlockById(id);
            if (
                !ProceduresDefinitionNames.includes(
                    block.childBlocks_[0].getProcCode()
                )
            ) {
                block.dispose();
            }
        });
    } else {
        procedureBlocksId.forEach(id => workspace.getBlockById(id).dispose());
    }
};

export default initContext;
