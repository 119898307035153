import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import styles from './python-puzzle-code-pool.css';
import classNames from 'classnames';
import getBlockColour from '../../../lib/block-helper/get-block-colour';
import opCodeToImage from '../../../lib/block-helper/opcode-to-image';
import customeBlockImg from '../../../assets/img/custome-block.svg';

export default class PythonPuzzleCode extends Component {
    static propTypes = {
        className: PropTypes.string,
        handleTargetClick: PropTypes.func,
        targetClassName: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }
    componentDidMount() {
        this.getBlockHTMLByOpCode();
    }

    showPop = () => {
        this.setState({
            show: true
        });
    };

    closePop = () => {
        this.setState({
            show: false
        });
    };

    getBlockHTMLByOpCode = () => {
        const {type} = this.props;
        if (type === 'def') {
            this.setState({imgSrc: customeBlockImg});
        } else {
            opCodeToImage(`${type}`).then(imgSrc => {
                this.setState({imgSrc: imgSrc});
            });
        }
    };

    insertCode = e => {
        const {handleTargetClick, getPythonCode, item} = this.props;
        handleTargetClick(e, getPythonCode(item));
    };

    render() {
        const {
            text,
            type,
            targetClassName,
            className,
            ...otherProps
        } = this.props;
        const {show, imgSrc} = this.state;
        const popProps = {
            isOpen: show,
            className: classNames(
                styles.popover,
                styles[type.split('_')[0]],
                className
            ),
            body: <img
                style={{zoom: '.7'}}
                src={imgSrc}
                alt=""
            />,
            preferPlace: 'column',
            place: 'column',
            ...otherProps
        };
        return (
            <Popover {...popProps}>
                <div className={styles.textBox}>
                    <div
                        className={`${classNames(
                            styles.text,
                            targetClassName
                        )} method-block`}
                        onClick={this.insertCode}
                        onMouseEnter={this.showPop}
                        onMouseLeave={this.closePop}
                        style={{
                            backgroundColor: `${getBlockColour(type).primary}`
                        }}
                    >
                        {text}
                    </div>
                </div>
            </Popover>
        );
    }
}
