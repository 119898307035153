import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'PublicRepo/i18n/common';

const TryLookTips = props => (
  <div className="answer answer-try">
    <span className="answer-bg" />
    <p className="answer-txt">
      {i18n.get(
        'puzzle-answer_If you find it difficult, try to pay attention to the ',
      )}
      <span className="answer-txt--strong">
        {i18n.get('puzzle-answer_Tips')}
      </span>
      {i18n.get('puzzle-answer_, or you can look back the ')}
      <span className="answer-txt--strong">
        {i18n.get('puzzle-answer_Video')}
      </span>
      {i18n.get('，')}
      <br />
      {i18n.get('puzzle-answer_Believe yourself, you can do it!')}
    </p>
    <div>
      <span
        role="button"
        tabIndex="0"
        className="answer-btn answer-btn--better"
        onClick={props.onClose}
      >
        {i18n.get("puzzle-answer_OK, I'll have a look")}
      </span>
      <span
        role="button"
        tabIndex="0"
        className="answer-btn"
        onClick={props.onSaw}
      >
        {i18n.get('puzzle-answer_I still do not understand')}
      </span>
    </div>
  </div>
);

TryLookTips.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSaw: PropTypes.func.isRequired,
};

export default TryLookTips;

// {i18n.get('')}
