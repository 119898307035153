export default {
  'Submit Time': '提交时间',
  'teacher-stu-project-Name': '姓名',
  'teacher-stu-project-Video': '是否录屏',
  YES: '是',
  NO: '否',
  'GO TO': '前往',
  'Go to Detail Page': '前往详情页',
  'Last Comment': '最后点评',
  'Is Commented': '是否点评',
  Commented: '已点评',
  Uncommented: '未点评',
  'teacher-stu-project-Total': '总计',
  'teacher-stu-project-records': '条记录',
  //  作品管理：我的主页相关
  'my_profile All Cards': '所有卡片',
  'my_profile Card List': '卡片簿',
  'my_profile Project List': '作品',

  'my_profile My Published': '我的发布',
  "my_profile He's Published": '他的发布',
  'my_profile Her Published': '她的发布',

  'my_profile My Collections': '我的收藏',
  "my_profile He's Collections": '他的收藏',
  'my_profile Her Collections': '她的收藏',

  'my_profile My Stars': '我的点赞',
  "my_profile He's Stars": '他的点赞',
  'my_profile Her Stars': '她的点赞',

  'my_profile Publish': '发布',
  'my_profile View': '浏览',
  'my_profile Recreate': '改编',
  'my_profile Likes': '获赞',
  'my_profile Stars': '收藏',
  Unpublished: '未发布',
  Published: '已发布',
  Edit: '编辑',
  Unpublish: '取消发布',
  Publish: '发布',
  Status: '状态',
  Type: '类型',
  'There are no published project yet. Go and create one ~':
    '暂时还没有已发布的作品喔，快去创建一个吧 ~',
  'There are no collected project yet. Go and collect one ~':
    '暂时还没有收藏的作品喔，快去收藏一个吧 ~',
  'There are no stared project yet. Go and star one ~':
    '暂时还没有点赞的作品喔，快去作品广场逛逛吧 ~',
  'There are currently no heroes ~': '暂时没有获得的英雄喔~',
  'Click here to setting profile signature': '点击此处设置主页签名',
  'Total in here~': '全部作品都加载完了哦～',
  Latest: '最新',
  'Most Popular': '最热',

  "Nothing's here": '这个人很神秘，没有留下线索~ ',
  'Show All Projects': '显示所有作品',

  'Nickname can not be empty.': '昵称不能为空',
  'Nickname should less than 32 characters or 16 Chinese characters.':
    '昵称最多32个英文字符或16个中文汉字',

  // 作品管理
  '__Project-Management Free Creation': '自由创作',
  '__Project-Management Lesson Creation': '课程创作',
  '__Project-Management Lesson': '前往课程',
  '__Project-Management go to select curriculums': '去选课',
  '__Project-Management Status': '状态',
  '__Project-Management Type': '类型',
  '__Project-Management Publish': '发布',
  '__Project-Management Unpublish': '取消发布',
  '__Project-Management Edit': '编辑',
  '__Project-Management Publish project': '发布作品',
  '__Project-Management Publish to Plaza': '发布到作品广场',
  '__Project-Management All': '全部',
  '__Project-Management Published': '已发布',
  '__Project-Management Unpublished': '未发布',
  '__Project-Management Create Project': '创建项目',
  '__Project-Management Classes': '班级',
  '__Project-Management My Projects': '作品管理',
  '__Project-Management Creat Project': '创建作品',
  '__Project-Management Post the interesting video introduction':
    '快去发布补充有趣的视频介绍吧~',
  '__Project-Management suspend for commenting': '待点评',
  '__Project-Management passed': '已通过',
  '__Project-Management failed': '未通过',
  '__Project-Management Homework': '作业',

  '__Project-Management Scratch': '创想作品（新）',
  '__Project-Management Block coding': '创想作品',
  '__Project-Management Junior': '创想作品（幼龄）',
  '__Project-Management Text coding': '源码作品',
  'create-project_Scratch Editor': '创想编辑器',
  'create-project_JrScratch Editor': '创想编辑器(幼龄)',
  'create-project_Text Coding': '源码编辑器',
  'create-project_PyDiy Coding': 'Python编辑器',

  'new editor': '新版编辑器',
  'old editor': '旧版编辑器',

  'new junior editor': '新版幼龄编辑器',
  'old junior editor': '旧版幼龄编辑器',

  'teacher-progress_Progress Management': '进度管理',
  'teacher-progress_Student ID / nickname / name': '学生ID / 昵称 / 姓名',
  'teacher-progress_Unrelease': '未开启',
  'teacher-progress_Unlearn': '未学习',
  'teacher-progress_In Progress': '进行中',
  'teacher-progress_Finished': '已完成',
  'teacher-progress_Open': '开启',
  'teacher-progress_Take class': '上课',
  'teacher-progress_Planed': '计划',
  'teacher-progress_Generate report': '生成报告',
  'teacher-progress_Generate ending report': '生成结课报告',
  'teacher-progress_Generated': '已生成',
  'teacher-progress_Informed': '已通知',
  'teacher-progress_Review report': '查看报告',
  'teacher-progress_Review ending report': '查看结课报告',
  'teacher-progress_Please enter the correct time':
    '请输入正确的时间.格式为： hh : mm.例如：16:00',
  'teacher-progress_Unassigned room number': '未分配房间号',
  'teacher-progress_Are you sure you want to enter the classroom to start class?':
    '确认要进入教室开始上课吗？',
  'teacher-progress_After opening, the following message will be sent to the student WeChat':
    '开启后将会给学生微信推送以下消息：',
  "teacher-progress_Dear parents, name's course is about to begin, please be prepared.":
    '亲爱的家长,名字的课程即将开始了，请做好准备哦',
  'teacher-progress_Course': '课程',
  'teacher-progress_Time': '时间',
  'teacher-progress_OK': '确定',
  'teacher-progress_Cancel': '取消',
  'teacher-progress_Cancel take class': '取消',
  'teacher-progress_OK take class': '确定',
  'teacher-progress_Take the kids and go to www.bellcode to learn':
    '带着孩子，去 www.bellcode.com 学习吧',
  'teacher-progress_Please fill in the  time and click OK.':
    '请填写学生上课时间，点击确定以开启课程',
  'teacher-classroom-tab_Lesson Plans': '课程计划',
  'teacher-lesson-plan_Prepare Lesson': '备课',
  'student-online-classroom_The instructor is waiting for class, click to enter class':
    '指导师正在等候上课，点击进入上课',
  'student-online-classroom_Ready to go to class': '准备上课了',

  // 班级二维码
  Buyer: '买家',
  'Order Operation': '订单操作',

  'msg_all message': '全部消息',
  'msg_system message': '系统消息',
  'msg_compose message': '创作消息',
  'msg_Mark all as read': '全部设为已读',
  'msg_Clear all': '清空',
  'msg_Mark as read': '标为已读',
  msg_read: '已读',
  msg_coin_receive: '领取金币',
  "msg_There's no message": "暂无消息通知哦(〃'▽'〃)~",

  'prev-page': '上一页',
  'next-page': '下一页',

  'detect_In order to ensure your learning experience, please check your equipment first':
    '为了保证您的上课体验，请先进行设备检测哦~',
  'detect_Please prepare your ': '请先准备好：',
  "detect_I'm ready, let's begin": '准备好了，开始检测',
  detect_camera: '摄像头',
  detect_microphone: '麦克风',
  detect_speaker: '扬声器',
  detect_skip: '跳过',

  'Your account have been logged out, please login again.':
    '您的账号已被登出，请重新登录.',

  'challenging it': '快来挑战吧',
  'wait for commenting': '等待老师点评',
  'failed, checkout comment': '未通过，查看点评',
  'passed, checkout comment': '已通过，查看点评',
  homework: '作业',
  'submit on time and you will get more prize after your homework is commented':
    '及时提交，获得老师点评，还能领取更多奖励',
  'homework completed: ': '完成进度：',
  'you have to finish all puzzles before you get ultra challenges':
    '暂无作业\n你需要先完成所有关卡哦~',

  "__PlayGround You don't have this card,  can't set your avater.":
    '你还没有这张卡片，不能设置头像',
  '__PlayGround Set AVATAR successfully': '设置头像成功',

  // 作业管理
  'do homework': '去做作业',
  'review homework': '查看作品',
  'pass the homework to get awards': '通过作业即可领取',
  colapse: '收起',
  expand: '展开',

  // 新上课页
  '__CourseGallery about to begin': '即将开课',
  '__CourseGallery begin learning': '开始学习',
  '__CourseGallery continue learning': '继续学习',
  '__CourseGallery do homework': '去做作业',
  '__CourseGallery review': '去复习',
  '__CourseGallery level': '关卡',
  '__CourseGallery community': '社区',
  '__CourseGallery create': '创作',
  '__CourseGallery project': '作品',
  '__CourseGallery you passed the equipment test!': '恭喜你,成功通过设备检测！',
  '__CourseGallery Click on the course card to go to class.':
    '快点击课程卡片去上课吧～',
  '__CourseGallery Scan the qrcode for help': '扫码获得帮助',
  '__CourseGallery return equipment detection': '返回设备检测',
  "__CourseGallery There's something wrong.": '你的设备有点问题哦',
  '__CourseGallery check again': '重新检查',
  '__CourseGallery Ask parents to help consult teachers':
    '快让爸爸妈妈帮助咨询老师吧',
  "__CourseGallery You haven't finished testing the equipment":
    '你还没完成设备检测',
  '__CourseGallery Immediately detect': '立即检测',
};
