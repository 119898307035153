import {LESSON} from 'public-repo/lib/config';
import Fetch from 'public-repo/lib/fetch';
import GeneralConfig from 'public-repo/utility/generalConfig';
import {getParams} from '../../lib/env-utils';

/* eslint-disable babel/camelcase */
const setLesson = data => {
    if (!data.activity || !data.activity.length) {
        return data;
    }

    data.activity.sort(
        (a, b) => a.mainorder - b.mainorder || a.suborder - b.suborder
    );

    if (!data.is_online) {
        data.activity = data.activity.filter(
            activity => activity.type !== LESSON.TYPE.LEARNING
        );
    }

    data.activity.map((item, index) => {
        if (index === 0 || data.activity[index - 1].finished) {
            data.activity[index].locked = false;
        } else {
            data.activity[index].locked = true;
        }
        return data.activity[index];
    });

    return data;
};

const getLessonInfo = () =>
    Fetch({
        url: GeneralConfig.serverIo.stu_lesson_plan,
        params: {
            lessid: getParams().lessonId,
            classid: getParams().classId,
            package_id: getParams().packageId,
            // Add timestamp to disable cache when back from teacher_website
            timestamp: +new Date()
        }
    });

export {setLesson, getLessonInfo};
