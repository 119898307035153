export default {
  Search: '搜索',
  'Search Result': '搜索结果',
  'No search result': '无搜索结果',
  name: '名字',
  'Project Name': '作品名称',
  'Search Code Blocks': '搜索代码积木',
  Share: '分享',
  Save: '保存',
  'Saving...': '保存中',
  Complete: '完成',
  'Add Actor': '添加角色',
  Play: '开始',
  Stop: '停止',
  Reload: '重新播放',
  Close: '关闭',
  'Please enter the project name': '请输入作品名称',
  'Sign in': '登录',
  'Log Out': '登出',
  'LOGIN/SIGNUP': '注册/登录',
  'Media Library': '素材库',
  'Search Media Library': '搜索素材',
  Characters: '角色',
  Music: '音效',
  Props: '道具',
  'Play Music': '播放',
  Fullscreen: '全屏',

  'Full screen': '全屏',
  'Exit full screen': '退出全屏',
  'Show grid': '显示网格',
  'Hide grid': '隐藏网格',
  Background: '背景',
  Costumes: '造型',
  Sounds: '声音',
  'Add background': '添加背景',
  'Add costume': '添加造型',
  'Add sound': '添加声音',

  // ide category
  all: '全部',
  'My material': '我的素材',
  'Local Upload': '本地上传',
  adventure: '冒险',
  city: '城市',
  festival: '节日',
  water: '海洋',
  ocean: '海洋',
  fantasy: '梦幻',
  space: '太空',
  farm: '农场',
  food: '食物',
  jungle: '丛林',

  // ide tips
  'There is no project data!': '找不到你要的作品！',
  'Please enter the project name!': '忘记写作品名字啦！',
  'Create new project successfully.': '创建新作品成功！',
  'Save successfully.': '保存成功。',
  'Project saved failed.': '作品保存出了点问题。',
  'Failed to share current project, please retry it later.':
    '分享作品出了点问题，请等一会儿再试下。',
  'Failed to complete project, please retry it later.':
    '完成作品出了点问题，请等一会儿再试下。',
  'The file name is duplicated.': '文件名重复了呢～',
  'File is too large, please select again.': '文件过大，请重新选择。',
  'Image upload failed': '图片上传失败',
  'Audio upload failed': '音频上传失败',
  'Fail to delete material.': '删除素材失败',
  'Can not add this costume.': '无法添加此造型',

  // diy tips
  attention: '注意',
  'project couldn‘t use it again.':
    '确定要重新开始？重新开始后，之前的修改都会丢失。',
  'think about it': '再想想',
  'can not loading Diy beacuse of network problem': '网络问题无法加载DIY',
  'please try later': '请稍后在地图重进进入',
  'saved in cloud': '已保存到云端',
  'publish to collection square': '发布到作品广场',
  published: '已经发布到作品广场',
  'diy restart': '成功回到diy起点',
  'network problem ,please try later': '网络出问题啦，请稍后重试',
  'load project success': '加载项目成功',
  // diy tutorial
  Previous: '上一步',
  "All done, let's start playing": '全部完成啦，开始自由发挥吧',
  'Let me see the effect of other fellow': '点我查看其它小伙伴的效果吧',
  Toggle: '收起',
  'How?': '怎么做？',
  Done: '做好咯',
  'DIY!': '自己做咯',
  // share
  'Publish project': '发布作品',
  'Submit project': '提交作品',
  'Project name': '作品名称',
  'Project title': '作品名字',
  Description: '作品介绍',
  'Project type': '作品类型',
  'Click to build this game >': '去参与创造这个作品吧 >',
  'Click "like" to encourage': '为我点个赞吧！',
  'I like this!': '我喜欢这个！',
  Likes: '点赞人数',
  Views: '已看人数',
  'The description is too long, try to write this within 100 words.':
    '作品描述太长啦，只可以写100字以内的描述哦～',

  'Share to Social Platforms': '通过以下方式分享给好友',
  'Join the creation': '参与创作',
  'Project Url': '通过链接分享给好友',
  qq: 'QQ',
  qqzone: 'QQ空间',
  'Horizontal cell phone will get a better viewing experience':
    '翻转手机观看效果更佳',
  'Learn more about BellCode': '了解贝尔编程',
  'Publish to Square': '发布到广场',
  'Published to Square': '已发布到广场',
  'Allow Edit': '允许改编',
  'Turn to IDE': '转到设计页',
  'Publish to Plaza': '确认发布',
  'pulbish-submit': '确认提交',
  'Publish successfully': '发布成功!',
  'Pulbish failed': '发布失败，再试一遍吧~',
  'Submit successfully': '提交成功',
  'Submit failed': '提交失败，再试一次吧~',
  'Publish failed，Please add project name': '发布失败，想一个作品名字吧',
  // puzzle
  'Congratulations!! You get:': '恭喜你，你获得了 ',
  'PITY,YOU CAN DO IT BETTER': '很遗憾，挑战失败。',
  Tips: '小提示',
  Home: '返回',
  Reset: '重新开始',
  RETRY: '再学一次',
  QUIT: '退出',
  CONTINUE: '继续',
  'Connect Block': '组合积木',
  'Connect Blocks': '组合积木',
  'Complete Task': '完成任务',
  'Complete Tasks': '完成任务',
  'RETRY FOR PRIZE': '我要拿满分！',
  'Did you like this puzzle?': '喜欢这个挑战吗？',
  'Is it missing this code block?': '是不是少了这个代码块呢？',
  'Check if there is the wrong content? Should be ':
    '看看是不是填错了内容呢？应该是',
  'See history answer': '查看历史答案',
  'The number of code blocks used is a little bit more, try to use ':
    '使用的代码块数量有些多，尝试使用',
  ' blocks to complete the challenge.': '个代码块完成挑战。',
  "That's great! you did very well.": '太棒了！你做得非常好。',
  'Try to press any keys': '尝试按一下键盘上的任意按键',
  'Try to press': '尝试按下',
  and: '和',
  keys: '键',
  key: '键',
  'Try to click on playground': '尝试用鼠标点击一下舞台',
  'Try to click the actor': '尝试用鼠标点击一下角色',
  'It looks like spent time too long! So what is wrong? Hmm... Try again?':
    '使用了太长时间了！是哪里出错了呢？尝试修改一下吧。',
  "There's no code block yet, try to drag some code blocks.":
    '没有代码块运行不了哦，先去拖动代码块尝试下吧',
  'Concat teacher': '联系老师',
  'Open music': '打开音乐',
  'Close music': '关闭音乐',
  'Show answer': '查看答案',

  // cm_teaching_site
  LESSONS: '课程',
  Classes: '班级',
  CLASSES: '班级',
  ASSIGNED: '已分配项目',
  PROJECTS: '作品',
  Settings: '设置',
  'LOG IN': '登录',
  'LOG OUT': '退出登录',
  'JOIN FOR FREE': '注册',
  Enter: '登录',
  "Don't have an account?": '还没有帐号?',
  'JOIN A CLASS': '加入班级',
  Assign: '布置',
  Details: '详情',
  'Lesson Activity': '课程关卡',
  Students: '学生',
  Teacher: '教师',
  'Setup your classrooms in simple ways': '快速简单设置班级信息',
  'Set up your classrooms in simple ways': '快速简单设置班级信息',
  'New Classroom': '新增班级',
  'Manage Classroom': '管理班级',
  Projects: '作品',
  projects: '作品',

  // HeaderBar
  'HeaderBar2 Home': '首页',
  'HeaderBar2 Take Class': '上课',
  'HeaderBar2 Choose Class': '选课',
  'HeaderBar2 Game': '游乐场',
  'HeaderBar2 More': '了解我们',
  'HeaderBar2 School Cooperation': '学校合作',
  'HeaderBar2 Address': '线下机构',
  'HeaderBar2 Courses Structure': '课程结构',
  'HeaderBar2 Coding Education': '编程教育',
  // 'HeaderBar2 Dual Teachers': '双师课堂',
  'HeaderBar2 Competition': '我的比赛',
  'HeaderBar2 Create': '创作',
  'HeaderBar2 My Courses': '我的课程',
  'HeaderBar2 My Works': '我的作品',
  'HeaderBar2 My Homepage': '我的主页',
  'HeaderBar2 Setting': '个人中心',
  'HeaderBar2 Language': '切换英文',
  'HeaderBar2 Logout': '退出',
  'HeaderBar2 Login or Register': '注册/登录',
  'HeaderBar2 Feedback': '意见反馈',
  'HeaderBar2 Upload': '上传作品',

  // 'Class Code': '班级代码',
  'All course assign': '整包布置',
  'Single course assign': '单课布置',
  'Creation date': '创建日期',
  'Assign package': '布置课程包',
  'Assign course': '布置课程',
  'Switch to class': '切换到该班',
  'Switch Class': '切换班级',
  Class_type: '类型',
  Class_stu_num: '人数',
  Class_teacher_name: '老师',
  'Live Class': '直播班',
  'Record Class': '录播班',
  Course_Online: '线上课程',
  Course_Offline: '线下课程',
  Class_Live: '直播',
  Class_Record: '录播',
  'ALL Courses': '所有课程',
  'My Courses': '我的课程',
  'CREATE A PROJECT': '创建作品',
  'My Classroom': '我的班级',
  'Class Code Management': '班级码管理',
  'Class Code / User Name': '班级码/用户名',
  'User Name': '用户名',
  'Course Package': '课程包',
  Remark: '备注',
  'Class Name': '班级名称',
  'Specific Course Package': '对应课程包',
  'Course Number': '课程数目',
  'Max Student Number': '最大人数',
  'Default Max Student Number': '最多人数：默认100人',
  'Student Number': '学生数目',
  'Instructor Number': '指导师数目',
  'Starting Time': '首发时间',
  'To be Release': '当前待发布',
  'Class Code Status': '班级码状态',
  Unrelease: '未发放',
  'To be Used': '待使用',
  Used: '已使用',
  'Has Disabled': '已停用',
  restore: '恢复',
  'Select All': '全选',
  'Class Code': '班级码',
  'User ID': '用户ID',
  'Released Timestamp': '发放时间',
  'Used Timestamp': '使用时间',
  Release: '发放',
  Revoke: '收回',
  Disabled: '停用',
  Enable: '启用',
  Delete: '删除',
  'Student Projects': '学生的作品',
  Assigned: '已布置内容',
  Concept: '概念',
  'Instructor Management': '指导师管理',
  'All Students': '全部学生',
  'My Classes': '我的班级',
  'The lesson has been assigned successfully.': '课程已成功布置。',
  'The classroom has been created successfully.': '班级已成功创建。',
  Creations: '作品',
  Courses: '课程',
  Unlogin: '游客',

  // 空页面内容
  'No content': '暂无内容',
  'No project': '暂无作品',
  "You haven't created your class yet. Go ahead and create a class":
    '您还未创建班级，快去创建一个班级吧',
  'You need to buy a bellcode course package to view it':
    '需要购买bellcode课程包才能查看',
  'Contact bellcode for a course package: ': '联系bellcode购买课程包：',

  // classroom assigned
  'Are you confirm to delete ': '确定删除',
  'Are you confirm to unpublish ': '确定取消发布',
  'It will be removed from the assigned list!': '将从布置列表移出!',
  'The project will be removed from the project square!':
    '该作品将从作品广场移出！',
  'Only the lessons assigned to this classroom could be used by students':
    '学生可以看到老师布置到该班级的课程',
  'Assign More': '布置更多',
  Lessons: '课程',
  Lesson: '课程',
  // 'Projects': '作品',
  Name: '名称',
  'DIY Name': 'DIY 名称',
  'Assign Time': '布置时间',
  Unassign: '删除布置',

  // ClassroomConcept
  'Concept Mastery': '概念掌握情况',
  // 'key': '初级',
  Beginner: '初级',
  Junior: '初级',
  Intermediate: '中级',
  Senior: '高级',
  Advanced: '高级',
  'By clicking the circle you can get more detail': '点击查看详细掌握情况',
  Student: '学生',
  "'s Projects": '的作品',
  'The lesson will be removed from the assigned lesson list!':
    '该课程将会从布置的课程列表移除!',
  'Only the lesson assigned to this classroom could be used by students':
    '学生可以看到老师布置到该班级的的课程',
  'Lessons name': 'Lessons name',

  // ClassroomProject
  'The project will be removed from the assigned project list!':
    '该作品将会从作品列表移除!',
  'Assigned Projects': '布置项目',
  'Only the project assigned to this classroom could be used by students':
    '学生可以看到老师布置到该班级的的项目',
  'Projects name': '项目名称',

  // ClassroomSetting
  'Class Code:': '班级代码：',
  'Class Name:': '班级名称：',
  'update classroom successfully': '成功更新班级信息',
  'remove classroom successfully': '成功删除班级',
  'Are you confirm to remove classroom': '你确定删除该班级吗',
  'classroom Settings': '班级设置',
  'Classroom Settings': '班级设置',
  'Remove this classroom': '删除该班级',
  'Change Public Student Account Password': '修改共用学生账号密码',
  'Create Public Student Account Password': '创建共用学生账号密码',
  'Save Settings': '保存设置',

  // ClassroomStudents
  'Are you confirm to remove ': '你确认在该班级中删除 ',
  ' from this classroom?': ' ?',
  'Reset ': '重置 ',
  "'s password": '的密码',
  'Added Students': '已添加的学生',
  'You can assign project to them.': '你可以布置项目给学生们',
  'Existing Students': '已添加的学生',
  'You can assign projects or lessons to them.': '你可以布置项目给学生们。',
  'Add More Students': '添加更多的学生',
  'Student username': '学生账号',
  'Student name': '学生姓名',
  'Mobile number': '手机号码',
  'Student coins': '金币',
  'Student skill': '编程力',
  Operation: '操作',
  Remove: '删除',
  ResetPassword: '重置密码',
  'Reset Password': '重置密码',
  'Reward gold': '发放金币',
  'Batch rewarding gold': '批量发送金币',
  'Reward gold for ': '发放金币 向 ',
  'Batch rewarding gold for ': '共计为 ',
  ' users.': ' 个用户发送金币',
  'Positive number used for rewarding, negative for punishing.':
    '正数奖励金币，负数扣除金币',
  'Give out golds successfully': '发放金币成功',
  'Check rank': '查看排行榜',

  // concept
  'Concept Total Points': '该概念所得到的总分',
  Date: '日期',
  'Finish ': '完成 ',
  ' and get ': ' 并且获取了 ',
  ' points': ' 分数',
  "'s Concept Detail": '的概念掌握细节',
  All: '全部',
  'Last 7 Days': '过去7天',
  'Last 30 Days': '过去30天',

  // CLassroomInstructorManagement
  Instructor_avatar: '头像',
  Instructor_add: '添加指导师',
  'New Teacher': '添加新教师',
  'New Instructor': '添加新指导师',
  Index: '序号',
  'Are you confirm to operate': '你确定这样操作吗',

  // ClassroomStuProject
  'by ': 'by ',
  ' projects': ' 作品',

  // cardbook
  CHARACTERS: '人物卡',
  PROGRAM: '编程卡',
  'character cards collected, beating': '种英雄已被你收入麾下，击败了',
  'of all the programmer.': '的召唤师。',
  'Go to the ': '前往',
  ' page and unlock more cards.': '召唤更多英雄。',
  'Working...': '召唤中...',

  // paly nav
  'BONUS MISSION': '额外奖励任务',
  CARDBOOK: '英雄联盟',
  SUMMON: '英雄召唤机',
  summon: '英雄召唤机',
  RANK: '排行榜',

  // accountForm
  'Username should not be null': '用户名为空',
  'Password should not be null': '密码为空',
  'Telephone number should be 11 numbers.': '手机号码需由11个数字组成',
  'Something wrong with your phone number': '你的手机号码出错了',
  'please fix the field ': '请修改你的输入项',
  'FREE STUDENT ACCOUNT': '学生帐号',
  'FREE TEACHER ACCOUNT': '老师帐号',
  'Username (at least 6 characters)': '用户名称 (最少6个字符)',
  'Password (at least 6 characters)': '密码 (最少6个字符)',
  Next: '下一步',
  'Already have a account?': '已经拥有帐号了？',
  'Log in': '登录',
  'username was registered': '用户名已经被注册了',
  'username is invalid, 6-16 charactors, [0-9],[a-z] are avaliable':
    '用户名不可用，必须 6 到 16 位小写字母或数字',
  'Password should be at least 6 characters': '密码必须至少 6 位字符',
  'Login-Scan to login': '扫码登录',
  'Login-Normal login': '账号登录',
  'Login-Login or register using WeChat scan': '使用微信扫一扫登录或注册',
  "Login-If you don't have WeChat, ask your mom or dad for help":
    '如果没有微信，快让爸爸妈妈帮忙~',
  'Login-Welcome to using bellcode': '微信绑定',
  'Login-Use WeChat scan to bind bellcode': '获取更优质的服务',
  'Login-Skip': '跳过',
  'Login-Binding Wechat': '绑定微信',
  'Login-Bound': '已绑定',
  'Login-Unbind': '未绑定',
  'Login-Bind': '点击绑定',
  'Login-This WeChat ID has been bound': '你的微信已绑定其他账号，换一个微信吧',
  'Login-This WeChat ID has been used': '此微信号已经被使用',
  'Login-WeChat ID has been successfully bound': '微信号已成功绑定',
  'Login-Forgot password?': '忘记密码？',
  'Login-Back Log': '返回登录',
  // 'Login-If you forget your password, please scan the QR code to contact the Head Teacher.':
  //   '如果你忘记了自己的密码，请扫码联系小贝班主任哦',
  'Login-If you forget your password, please scan the QR code to contact the Head Teacher.':
    '忘记密码，请联系班主任重置密码',
  "Login-She'll get back the password.": '她会找回你的密码',
  'Login-* If you do not have an account, Please sign in with WeChat scan~':
    '* 若还没有账号，请用微信扫一扫登录哦~',
  'Login-refresh': '刷新',
  'Login-QR code has expired': '二维码已过期',
  'Login-Please click': '请点击',
  'Login-Please remember your login account : ': '请记住你的登录账号 ：',
  'Login-And set your own password~': '并设置自己的密码',
  'Login-When mom and dad are not around, you can log in directly using account numbers and passwords':
    '爸爸妈妈不在身边时，可以使用账号和密码直接登录哟',
  'Login-Input password': '请输入密码',
  'Login-confirm password': '请确认密码',
  'Login-confirm': '确认',
  'Login-Binding WeChat, get the most timely learning progress and report~':
    '微信绑定，实时获取最及时的孩子学习进度和报告',
  'Login-There are also the latest course coupons': '还有最新课程优惠哦',

  'Password-The length of the password is 6 to 12 characters':
    '密码长度为6至12位',
  'Password-The password can only be made up of letters and numbers':
    '密码只能由字母和数字构成',
  'Password-Confirm password and password are inconsistent': '确认密码不一致',
  'Password-Input password': '请输入密码',
  'Password-Confirm password': '请确认密码',
  'Password-Password should not be empty': '密码不能为空',
  'Password-Confirm password should not be empty': '确认密码不能为空',
  'Password-Unsuccessful password settings': '密码设置不成功',
  'Password-Successful password settings': '密码设置成功',

  // addStudent
  'Add Students to Your Class': '添加学生到你的班级',
  'Add Student': '添加学生',

  // reward gold coins
  'Gold number cannot be null': '金币数量不能为空',
  'Gold number cannot be the decimal': '金币数量不能为小数',
  'Gold number can only be the numeric types': '金币数量只能为数字',

  // assign popup
  'Please choose a class': '请选择一个班级',
  'Please create a class first.': '请首先创建班级',
  'Students of the class can learn the lesson after assignment.':
    '在布置后学生可以学习该课程内容。',
  'Assign to class': '布置到班级',
  'The students of the class can use the project after the assignment':
    '布置项目后，项目将出现在学生主页上',
  'Assign project successfully': '成功布置项目',

  // confirm
  'Please Confirm': '请确认',
  OK: '确认',
  CANCEL: '取消',

  // contact
  'CONTACT US': '联系我们',

  // createAccount
  'Create Your FREE Account': '创建你的帐号',
  "You can't change the account type later, please choose carefully!":
    '账号类型不能修改，请认真选择！',
  'Work on school/home courses and build cool projects': '尽情享受编程的乐趣吧',
  'Manage classrooms,lesson and student': '0成本开展STEM教学',
  'I have a bellcode account': '我已经拥有bellcode的帐号',

  // crete class
  'Class name cannot be null': '班级名称为空',
  'New Live Classroom': '新增直播课班级',
  'New Record Classroom': '新增录播课班级',
  'Please Input the Class Name': '请输入班级名称',
  'Create Classroom': '创建班级',
  'First Lesson': '第一节课',
  'create class_first lesson open': '开启',
  'select lesson package': '选课程包',
  'If checked, the first lesson will be opened immediately after creating the class. If not checked, the first class will be opened on the next release lesson day.':
    '若勾选，建班后第一堂课立即开启。若未勾选，第一堂课在下一个发课日开启。',

  // joinClass
  'Code cannot be null': '班级代码为空',
  "Classroom doesn't exit": '班级不存在',
  'Join class successfully': '成功加入班级',
  // 'Join a Class': '加入班级',
  'Ask your teacher for a class code and enter it to join the class':
    '购买贝尔课程后，班主任会将班级码发送给你',
  // 'Class Code': '班级代码',
  'Join Class': '加入班级',

  // login
  'Username should not be empty': '用户名为空',
  'Password should not be empty': '密码为空',
  'Username or password is wrong': '用户名或者密码错误',
  'Please input your account': '请输入你的帐号',
  'Please input your username': '请输入你的帐号',
  Password: '密码',
  Username: '用户名',

  // resetPwd
  'Password cannot be null': '密码为空',
  'Reset password successfully': '成功重置密码',
  'Reset password': '重置密码',
  'Please Input the New Password': '请输入新的密码',

  // setting
  'current password cannot be null': '当前密码为空',
  'new password cannot be null': '新密码为空',
  'Account Profile': '帐号信息',
  'Game Profile': '个人资料',
  'Username:': '账号：',
  'Mobile:': '手机：',
  'Update profile successfully': '成功修改个人信息',
  'New Password': '新密码',
  'Confirm New Password': '确认新密码',
  'Change Password': '更改密码',
  'Current Password': '当前密码',
  Uploading: '上传中',
  Upload: '上传',
  Nickname: '昵称',
  SAVE: '保存',

  // videoPlayer
  "Video will play from beginning when reopened. \n \n Are you sure to close? \n\n You can't get the prize until finishing the video!":
    '下次看视频会重头开始噢 \n \n 你确定关闭吗?\n\n只有完整的看完视频才能获取奖励哦！',
  video_play: '播放',
  video_pause: '暂停',

  // share config
  'Please describe your production here ...': '这个人很懒，什么都没写',
  'Copy successfully!': '复制成功',
  'Please press Ctrl + C to copy.': '请按 Ctrl（⌘） + C 复制',
  'Production Url': '作品地址',
  copy: '复制',

  // footer
  // 'CONTACT US': '联系我们',

  // headerBar
  PLAY: '游戏',
  TV: '视频',
  DASHBOARD: '课堂',
  Game: '游戏',
  Dashboard: '课堂',
  'My Projects': '我的作品',
  Instructor: '指导师',
  RestoreCostume: '恢复角色',

  // organization
  'My Teacher': '我的老师',
  'My Instructor': '我的指导师',
  'Setup your teacher in simple ways': '快速设置我的老师成员',
  'Setup your instructor in simple ways': '快速设置我的指导师成员',
  'org-name': '姓名',
  New: '新建',
  'Create Member': '确认创建',
  'Please Input the Password': '请输入密码',
  'Please Input the Member Name': '请输入用户名',
  'Phone number': '手机号',

  // classroom
  "You don't have a classroom yet": '你还未加入任何班级',
  'Ask your teacher for a class code': '向你的老师索要班级代码',

  // projects
  "You don't have a project yet": '你还没有一个作品',
  'Create one!!': '赶紧创建一个',

  // StuAssignedLesson
  'Assigned Lessons': '已经布置的课程',
  'If you want to learn more lessons , please contact your teacher.':
    '如果你想学习更多的课程，可以联系你的老师',
  Detail: '详情',

  // packageDetail
  'Lessons of ': '',
  'Introduction of ': '介绍',
  'Introduction to ': '介绍',
  'If you want to unlock more lesson plans , please': '获取更多课程，请',
  'If you want to unlock more lessons, please': '获取更多课程，请',
  'contact bellcode': '联系 bellcode',

  // StuClassroom
  'Assigned projects': '已经布置的项目',
  'This lesson plans': '本班课程计划',

  // StuLessonDetail
  'Video:': '视频：',
  'Project:': '探索：',
  'Puzzle:': '挑战：',
  'Treasure:': '宝箱：',
  'Please finish the previous step': '请完成上一关',
  NEXT: '下一关',
  // 'Lesson Activity': '关卡',

  // stuProfile
  // 'CREATE A PROJECT': 'CREATE A PROJECT',
  'My Classrooms': '我的班级',
  'Classroom Name': '班级名',

  // TeaAssignedProjects
  // 'CREATE A PROJECT': '创建作品',
  'School Assigned projects': '学校布置项目',

  // tealesDetail
  'Student View': '从学生视角查看',
  Answer: '答案',
  'ASSIGN TO CLASS': '布置到班级',
  'Lesson Plans': '教案',
  'Lesson Plan can help prepare your lesson,please read it carefully.':
    '教案可以帮助你快速了解、准备课程',
  View: '查看',
  Hide: '隐藏',

  // teaLessonPackage
  'Only after cooperating with bellcode, can you get the course package':
    '只有加盟bellcode才能获得课程包',
  'All Courses': '所有课程',
  'Coming soon': '马上出炉',

  'Will be released soon': '即将开课',

  // teaLessons
  'My Lesson Plans': '我的教案',
  // "Junior": "初级",
  // "Intermediate": "中级",
  // "Senior": "高级",

  // CardPopup
  Collected: '已收集',
  Cards: '张',
  'Biographies:': '小传：',
  'Pet Phrase:': '口头禅：',
  'SET AS AVATAR': '设置为头像',
  'Finish more lesson activities or bonus missions to get more coins':
    '完成更多的课程或者额外奖励任务就能得到更多金币啦',

  // map level popup
  'You can get:': '闯关奖励：',
  'You got:': '闯关成功，获得：',
  Learning: '学习',
  Explore: '探索',
  Prezi: '宝藏',
  Creation: '发散／创新',
  'Try again': '再做一次',
  Start: '开始',

  // Bonus MISSION
  "There isn't any uncompleted task": '暂时没有未完成的奖励任务',
  'Just check it again tomorrow': '明天再来看看吧',
  'Get Prezi': '领取',
  Go: '去完成',

  // rank
  Rank: '排行榜',
  Leaderboard: '班级编程力排行榜',
  'Honorary Title': '荣誉称号',
  'Coding Exp': '编程力',
  'Class Rank': '班级排行',
  Level: '荣誉称号',
  Player: '玩家',

  // quiz
  ' Quiz Result': '试卷得分',
  'You are right!': '你答对啦！',
  'Sorry, The right option is ': '正确答案应该是：',
  'Score : ': '得分：',
  'Sorry, you are not passed in this quiz': '很遗憾，本次测验不通过',
  'Congratulation, you passed this quiz': '恭喜，通过本次测验',
  Refresh: '重新开始',
  Back: '返回',
  'I See': '朕知道了',
  Submit: '提交',
  Submiting: '提交中...',
  Loading: '加载中...',
  Submited: '已完成',
  'Go default': '切换到学龄后',
  'Go junior': '切换到学龄前',

  // 宝箱状态
  Open: '开启宝箱',
  Continue: '已开启',
  Locked: '未解锁',

  // IDE popup
  'Playing time': '用时',
  'Playing time (min) ': '用时（分钟）',
  'Block number': '代码块数目',

  // 课程列表介绍
  'Let children learn and play games online and then make their own games':
    '让我们的孩子在游戏中学习知识，尽情发挥创意，随时随地享受编程的乐趣~',
  'Enjoy the fun of learning programming anytime and anywhere~': '',
  Discovery: '作品广场',
  Study: '上课',
  Curriculums: '选课',
  show: '演示',
  play: '播放',

  'Buy Now': '立即购课',
  'Go Study': '继续上课',
  'Free Trial of Bellcode Online': '免费领取贝尔Online限量体验课',
  'Scan the QRcode to Buy': '微信扫码购课',
  'Wechat Account of the Head Teacher': '添加小贝班主任微信',
  'Free Trial of Bellcode Online Course':
    '免费领取价值388元的贝尔Online限量体验课',
  'purchased course': '已购课',
  people: '人',

  'reserve now': '立即预约',
  'Start Learning': '开始学习',
  'Bell Online': '贝尔online',
  'The course will be published every Friday night. If you have any questions, please contact our head teacher using WeChat':
    '课程每周五晚上发布 , 如有疑问请微信咨询班主任',
  'Google Chrome browser': '谷歌Chrome浏览器',
  'Use the latest version of the Chrome browser to enjoy programming fun':
    '上课使用最新版本Chrome浏览器,尽情享受编程乐趣',
  'Download now': '立即下载',
  'Choose the type': '选择类型',
  'The building blocks are running, click to stop': '积木运行中，点击停止',
  // 登录错误提示
  'Already logged on other devices': '已在其他设备登录',
  'Login status expired': '登录状态过期',
  'Join Us': '加入我们',
  'E-mail': '邮箱',
  'PythonEditor Download': 'Python编辑器下载',
  BBS: '贝尔编程官方论坛',
  Blog: '贝尔编程官方博客',
  'Environment Detect': '电脑设备检测',
  'Contact Phone': '联系电话',
  'Business Cooperation': '商务合作',
  'Courses Consultation': '课程咨询',
  'Working Time': '工作时间',
  Link: '链接',
  'Software Download': '软件下载',
  'Browser Download': '浏览器下载',
  'NOIP Homework': 'NOIP作业提交',
  'User Payment Protocol': '用户付费协议',
  'Useful Links': '友情链接',
  WeChat: '微信',

  'For better experience BellCode, go download Chrome please.':
    '为了更好地体验BellCode，快去下载一个最新的Chrome浏览器吧。(如果你正在使用Chrome，是因为其版本太低，也请麻烦下载最新的版本)',
  'Download Chrome': '下载最新Chrome浏览器',
  'Continue to use': '我是电脑达人,继续使用',
  // quiz
  quiz: '做题',
  answer: '答案',
  'No.1': '第一题',
  'No.2': '第二题',
  'No.3': '第三题',
  'No.4': '第四题',
  'No.5': '第五题',
  'No.6': '第六题',
  'No.7': '第七题',
  'No.8': '第八题',
  'No.9': '第九题',

  Profile: '个人主页',

  // 性别设置
  Male: '男',
  Female: '女',
  'Sex setting': '性别设置',

  // header bar
  '__Header-Bar Home': '首页',
  // Footer
  '__Footer Location: ': '公司地址：',
  '__Footer Hanking Center 12F, Shenzhen nanshan district.':
    '深圳市南山区同方信息港 C 栋 01 单元 2 楼',
  // '__Footer About Us': '关于贝尔编程',

  // 快速发布与录屏发布选择弹框
  '__Publish-Popup Publish': '快速发布',
  '__Publish-Popup Publish with Video': '录屏发布',
  '__Publish-Popup rewards 100 ': '可获取 100 ',
  '__Publish-Popup Up to 300 gold coins a day.': '每天最多可获取 300 金币。',
  '__Publish-Popup download': '下载',
  "__Publish-Popup your browser doesn't support": '你使用的浏览器无法录屏哦~',
  '__Publish-Popup Use Chrome': '请使用谷歌浏览器',
  '__Publish-Popup Your device does not support.': '无法录屏哦 (＞д＜)',
  '__Publish-Popup Please use PC instead.': '请使用电脑操作发布',

  // 快速发布页面
  '__Submit Add some Video?': '切换录屏发布',

  // New Dialog
  'New Dialog Perfect': ['完美通关 超过全球', '的人'],
  'New Dialog Good': ['表现良好 超过全球', '的人'],
  'New Dialog Normal': ['惊险通关 超过全球', '的人'],
  'New Dialog Bad': '没有通关 到手的奖励飞走了~',
  'New Dialog Fail Because Of Carelessness': '太粗心了 到手的奖励飞走了~',
  'New Dialog Next Level': '下一关',
  'New Dialog Done': ' 完成',
  'New Dialog Do Better': '力求完美',
  'New Dialog Check The Answer': '查看答案',
  "New Dialog What's The Wrong": '错在哪了',
  'New Dialog Play Again': '再学一次',
  'New Dialog Play Again And Win The Prize': '再学一次,赢取奖励',
  'New Dialog Give Up The Prize': '放弃奖励',
  'New Dialog Use Blocks': ['使用了', '个代码块'],
  'New Dialog Cost Time': ['费时', '秒', '分钟'],
  'New Dialog Gold': '金币',
  'New Dialog Code': '编程力',
  'New Dialog Rest': '剩余',
  'New Dialog Get': '获得',
  'New Dialog Accuracy': '准确率',
  'New Dialog Behave Well': '表现得真棒!',
  'New Dialog First Answer Correct': ['一击即中', '道'],
  'New Dialog Watch Video Again': '再看一次',
  'New Dialog Use Knowledge': ['熟练使用', '等知识点'],
  'New Dialog Study Cost Time': ['学习时长', '秒', '分钟'],
  'New Dialog Back To Map': '返回地图',
  'New Dialog Stay In Scene': '留在场景',

  // submit
  '_VideoSubmit Publish to Plaza': '发布到作品广场',

  // 官网信息相关
  'Brand Name': '贝尔编程',
  'Code block dictionary': '代码块字典',
};
