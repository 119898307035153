export default {
  'actor-info_X': 'X',
  'actor-info_Y': 'Y',
  'actor-info_Size': '大小',
  'actor-info_Direction': '方向',
  'Star Tours': '星际之旅',
  Junior: '学龄前',
  Default: '学龄',
  Landscape: '横屏',
  Portrait: '竖屏',
  'Add Actor': '添加角色',
  'Watch Video': '观看视频',
  'See the last code block scheme': '查看上次代码块方案',
  'Code Mission': '组合积木',
  'Puzzle Mission': '完成任务',
  'It spent a long time! Is there something wrong? Try to solve the puzzle again.':
    '花了好长时间，是不是哪里出了问题呢？尝试重新解决问题吧！',
  'You need to use these blocks to achieve missions':
    '你需要用到里面提及的代码块，完成后会打上一个勾',
  'Try to achieve these mission too': '看看能否完成这些挑战',
  'This is the toolbox': '这里是工具栏，看看能不能用里面的代码块完成任务吧',
  "Let's run our code to check the result": '点击开始看看代码运行结果吧',
  puzzle2_intro_next: '下一步',
  puzzle2_intro_back: '上一步',
  puzzle2_intro_skip: '跳过',
  puzzle2_intro_done: '完成',
  Lesson: '课程',
  'Posted at': '发布于',
  'work-adapt': '改编作品',
  'work-mark': '收藏',
  'Like this work': '喜欢作品',
  'work-liked': '已喜欢',
  "'s work": '的作品',
  'Project Introduction': '作品介绍',
  'work-compose': '转到设计页',
  'work-edit': '编辑',
  'work-re-edit': '重新编辑',
  'Do you like my work? Just give me a like ~~':
    '你喜欢我的作品吗？快为我点个赞~~',
  'Save Comment': '确认点评',
  'Edit Comment': '修改点评',
  'please input your comment': '请输入点评内容',
  'There is no teacher comment here. Write down your comment for this work.':
    '这里还没有老师点评，快写下对这个作品的点评吧~',
  'teacher-comment_Please confirm the comment is correct!':
    '请确认点评内容无误！',
  'teacher-comment_After confirming the comments, the comments will be sent to the student account immediately. Please confirm that the comment is correct.':
    '确认点评后，点评内容将即时推送给学生账号，请确认点评内容无误，再进行提交。',
  'teacher-comment_Check again': '再看一下',
  'teacher-comment_Confirmed and submit': '确认无误，提交',
  'Let your child becomes creator': '让孩子成为创造者',
  "share_Creator's words": '创造师有话说',
  share_Play: '体验作品',
  'share-JOIN-NOW': '马上报名',
  'puzzle-answer_I want skip it': '卡住了，先跳过吧',
  'puzzle-answer_Skip it': '先跳过吧',
  "puzzle-answer_I'll try it": '这就去试试',
  'puzzle-answer_On the right side is the answer of other students,':
    '右边是其他同学的办法，',
  'puzzle-answer_Do you have any other ideas?': '你还有什么好办法吗？',
  'puzzle-answer_Your gold coins is not enough, maybe you need to solve it by yourself':
    '你的金币太少了，还是再去想想吧',
  "puzzle-answer_OK, I'll try it again": '好，我再努力想想',
  'puzzle-answer_Have you already tried your best? ': '你真的尽力了吗？',
  'puzzle-answer_Little Monkey': '小悟空',
  'puzzle-answer_ can show you how other students do it.':
    '可以带你看一下别的同学是怎么完成的。',
  'puzzle-answer_But you have to pay a certain amount of gold~~':
    '但是，你需要支付一定的金币哦~~',
  "puzzle-answer_Forget it, I'll try again": '算了，再思考一下',
  'puzzle-answer_Take a glance（': '偷瞄一眼（',
  'puzzle-answer_coins': '个',
  'puzzle-answer_If you find it difficult, try to pay attention to the ':
    '如果觉得很难的话，尝试认真读一下',
  'puzzle-answer_, or you can look back the ': '，或者再学一遍',
  'puzzle-answer_Believe yourself, you can do it!': '相信你一定可以的！',
  'puzzle-answer_Tips': '小提示',
  'puzzle-answer_Video': '视频',
  "puzzle-answer_OK, I'll have a look": '好的，这就去看',
  'puzzle-answer_I still do not understand': '认真看了，还是不会',
  'puzzle-answer_If you skip this puzzle, you can learn the next lesson directly, but unfortunately you can not get any coins now. you can redo this puzzle at any time later.':
    '直接跳过的话，可以学习下一个关卡，本关暂时无法获得金币和编程力奖励，可以下次重新进入挑战。',

  '__Submit Back to IDE': '返回设计页',

  '__Scratch-Playground Play': '开始',
  '__Scratch-Playground Stop': '停止',

  '__Submit-Pages Next': '下一步',
  '__Submit-Pages Previous': '上一步',
  '__Submit-Pages Confirm Submit': '确认提交',
  '__Submit-Pages Submitting': '提交中',
  '__Submit-Pages Confirm Publish': '确认发布',
  '__Submit-Pages Publishing': '发布中',
  '__Submit-Pages Submit Project': '提交作品',
  '__Submit-Pages Please give your project a name and write an introduction.':
    '为你的作品取个名字，并添加作品介绍',
  '__Submit-Pages Show your teachers and friends your great project by video.':
    '录屏分享，向老师和创造师们炫耀一下～',
  '__Submit-Pages Share it with your friends！': '将作品分享给同学们吧~',
  '__Submit-Pages Confirm and get the rewards': '录屏发布领取',
  '__Submit-Pages Already get rewards~': '该作品已领取过奖励啦～',
  '__Submit-Pages The upper limit has been reached today.':
    '今日领取已达上限，明天再来哦～',
  '__Submit-Pages Lesson': '课程',
  '__Submit-Pages Loading': '作品加载中，请稍候',
  '__Submit-Pages Video Publish': '录屏发布',
  '__Submit-Pages Swicth to quick publish': '切换快速发布',
  '__Submit-Pages Can not get 100 coins if your switch to quick publish!':
    '选择快捷发布，无法获得奖励金币，你确定要这么做吗？',
  '__Submit-Pages Continue recording': '继续录屏',
  '__Submit-Pages Give up': '放弃录屏',

  'share Recommended': '精选推荐',
  'share New User': '新用户专享',
  'share Listen to the creator': '创造师有话说',
  'share What is Coding for Kids': '少儿编程是什么?',
  'share About BellCode': '关于贝尔编程',
  'share Learn Us': '了解一下',

  // 录屏相关
  '__Record-status Start': '开始录制',
  '__Record-status Restart': '重新录制',
  '__Record-status Complete': '录制完成',
  '__Record-status Completed': '录制完成',
  '__Record-status Preparing': '准备录制',
  '__Record-status Recording': '录制中',
  '__Record no voice input': '没有声音录入？试试大声介绍吧',
  recording: '录制中',
  'record evaluate': '录音评价',

  // 录屏提示相关
  '__Record-tips Lowly Sound': '声音太小，将为你自动添加背景音乐',
  '__Record-tips Click here to record': '点这里录制作品',

  // 权限弹框
  '__Permission-box Permission': '要点击“允许”哦',
  '__Permission-box I got it': '我记住了',

  // 保存提示弹窗
  '__Save-box Save': '保存视频',
  '__Save-box Want Save': '视频已录制完成，需要保存吗?',
  '__Save-box Restart Cancel': '取消',
  '__Save-box Restart Yes': '确定',

  // 重新录制弹窗
  '__Restart-box Restart': '重新录制',
  '__Restart-box Restart Record': '重新录制视频，确认后将删除已录制视频',
  '__Restart-box Restart Cancel': '取消',
  '__Restart-box Restart Yes': '确定',

  // 离开弹窗
  '__Leave-box Leave': '离开当前页面',
  '__Leave-box Leave Page': '现在离开，录制的视频不会保存哦～',
  '__Leave-box Cancel': '取消',
  '__Leave-box Yes': '确定',

  // 首次录制弹窗
  '__First-record-box Project will start':
    '接下来，作品会开始运行（录制时间最短15秒，最长两分钟）',
  '__First-record-box Unable to record': '未检测出录音设备，无法录制录音',
  '__First-record-box Operate your project': '一边操作作品',
  '__First-record-box Introduce your project': '介绍并操作作品',
  '__First-record-box Cancel': '取消',
  '__First-record-box Ok': '确定',
  '__First-record-box Follow me': '跟随小贝引导',

  // 保存进度
  '__Save-process Need time': '不要关闭网页，大约需要1～5分钟',
  '__Save-process Check network': '请检查网络连接后重新尝试',
  '__Save-process Cancel': '放弃保存',
  '__Save-process Try again': '重新保存',
  '__Save-process Saving video': '视频保存中',
  '__Save-process Failed': '保存失败',
  '__Save-process Succeeded': '保存成功',

  // 跳过录屏
  '__Record Skip': '跳过录屏',

  '__Record-example example': '录屏示例',

  // 录屏ipad相关
  '__Record-ipad Ipad cant use': '哎呀，iPad上无法录制',
  '__Record-ipad Cant see': '老师同学看不到你的精彩介绍了...o(╥﹏╥)o ',
  '__Record-ipad Use computer': '使用电脑操作 ',
  '__Record-ipad record': '就可以哦~',

  // 录屏浏览器支持相关
  '__Record-browser Browser cant use': '哎呀，你的浏览器无法录制',
  '__Record-browser Cant see': '老师同学看不到你的精彩介绍了...o(╥﹏╥)o',
  '__Record-browser Use Chrome': '使用谷歌浏览器 ',
  '__Record-browser To record': '就可以哦~',
  '__Record-browser Download': '下载',

  // 提交作品提示
  '__Submit-homework Submit Homework': '作业提交',
  '__Submit-homework I know': '我知道了',
  '__Submit-homework Teacher mark homework':
    '老师会尽快批改，完成后将通过微信公众号、官网消息中心发送通知。',
  '__Submit-homework Resubmit tip': '*重新提交，将去除原有评语，老师会重新批改',

  // 作业页面相关
  'project type: ': '作品类型：',
  'homework name: ': '作业名称：',
  'homework course: ': '所属课程：',
  'project name: ': '作品名称：',
  'course time: ': '课程开启：',
  'homework status: ': '作业状态：',
  'project status: ': '作品状态：',
  'recent submit: ': '最新提交：',
  'comments list: ': '点评记录：',
  'comment time': '点评时间',
  'comment by teacher': '点评老师',
  'comment result': '点评结果',
  'record check: ': '录屏检测：',
  'record audio evaluate: ': '录音评价：',
  '__Record-evaluate great': '优',
  '__Record-evaluate good': '良',
  '__Record-evaluate bad': '差',
  '__Record-evaluate noise': '环境噪音',
  '__Record-status noSound': '未录音',
  '__Record-status recorded': '已录音',
  '__Record-status unRecord': '未录屏',
  'prize obtained': '发放奖励',
  'medals awarded': '勋章授予',
  'submit time': '作业提交时间',
  'comments content': '点评内容',
  'project code': '作品代码',
  checkout: '跳转IDE',
  'project recording': '作品录屏',
  'play recording': '播放录屏',
  'project quality: ': '作业批改：',
  'award medal: ': '勋章授予：',
  'rewards: ': '发放奖励：',
  'comment content editor: ': '点评内容：',
  pass: '通过',
  'no pass': '不通过',
  'applied: ': '申请的：',
  'not applied: ': '未申请的：',
  award: '授予',
  awarded: '已授予',
  reward: '奖励',
  'confirm and push': '确认并推送通知',
  cancel: '取消',
  comment: '点评',
  apply: '申请',
  medal: '勋章',
  '(click to luster medal)': '（点击勋章点亮）',
  'Please check the content to be pushed to guardians':
    '请确认推送给家长的内容',
  'homework result': '批改',
  sure: '确认',
  'has nothing': '暂无',
  'free project': '自由创作',
  'not commented': '待点评',
  commented: '已点评',
  none: '无',
  'try again': '再努力一次',
  'gui-puzzle2_Try again': '重新尝试',
  'gui-puzzle2_ok': '好的',
  'previous award': '上一个',
  'next award': '下一个',
  'receive gold': '领取金币',
  'receive code': '领取编程力',
  ok: '完成',
  'to be reviewed': '待批改',
  'not passed': '未通过',
  passed: '已通过',
  saving: '保存中',
  save: '保存',
};
