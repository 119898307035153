import {connect} from 'react-redux';
import Diytips from './../components/diy-guide-tips/diy-guide-tips';
import {openL0Guide} from './../reducers/diy/diy';
import {LoadingState} from '../../reducers/project-state';

const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
    loadingFinished:
        state.scratchGui.projectState.loadingState ===
        LoadingState.SHOWING_WITH_ID
});
const mapDispatchToProps = dispatch => ({
    openL0Guide: () => dispatch(openL0Guide())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Diytips);
