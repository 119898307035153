// action type
const TOGGLE_PUZZLE_PLAYING = 'puzzle/control/TOGGLE_PUZZLE_PLAYING';
const TOGGLE_WORKSPACE_EXPEND = 'puzzle/control/TOGGLE_WORKSPACE_EXPEND';
const TOGGLE_AUDIO_STATE = 'puzzle/control/TOGGLE_AUDIO_STATE';
const TOGGLE_HELP_STATE = 'puzzle/control/TOGGLE_HELP_STATE';
const RESET_BLOCK_STATE = 'puzzle/control/RESET_BLOCK_STATE';
const SWITCH_EDITING_TARGET_INDEX =
    'puzzle/control/SWITCH_EDITING_TARGET_INDEX';
const initialState = {
    isWorkspaceExpended: true,
    isAudioOpen: true,
    isPuzzlePlaying: false,
    isHelpOpen: false,
    currentTargetIndex: 0
};

// action creator
/**
 * 切换 puzzle 运行状态
 * @param { string } [isPlaying] should be playing or not
 * @returns { string } action type
 */
const togglePuzzlePlaying = isPlaying => ({
    type: TOGGLE_PUZZLE_PLAYING,
    payload: isPlaying
});

/**
 * 切换代码块区域是否显示
 * @returns {{type: string}} action type
 */
const toggleWorkspaceExpend = () => ({
    type: TOGGLE_WORKSPACE_EXPEND
});

/**
 * 切换音效开关
 * @returns {{type: string}} action type
 */
const toggleAudioState = () => ({
    type: TOGGLE_AUDIO_STATE
});

/**
 * 切换帮助弹框显示状态
 * @returns {{type: string}} action type
 */
const toggleHelpState = () => ({
    type: TOGGLE_HELP_STATE
});

/**
 * 重置代码块状态
 * @returns {{type: string}} action type
 */
const resetBlockState = () => ({
    type: RESET_BLOCK_STATE
});

const switchEditingTargetIndex = payload => ({
    type: SWITCH_EDITING_TARGET_INDEX,
    payload: payload
});

const reducer = function(state, {type, payload}) {
    if (typeof state === 'undefined') state = initialState;

    switch (type) {
        case TOGGLE_PUZZLE_PLAYING:
            return {...state, isPuzzlePlaying: payload};
        case TOGGLE_WORKSPACE_EXPEND:
            return {...state, isWorkspaceExpended: !state.isWorkspaceExpended};
        case TOGGLE_AUDIO_STATE:
            return {...state, isAudioOpen: !state.isAudioOpen};
        case TOGGLE_HELP_STATE:
            return {...state, isHelpOpen: !state.isHelpOpen};
        case RESET_BLOCK_STATE:
            return {
                ...state,
                isPuzzlePlaying: false
            };
        case SWITCH_EDITING_TARGET_INDEX:
            return {
                ...state,
                currentTargetIndex: payload
            };
        default:
            return state;
    }
};

export {
    reducer as default,
    togglePuzzlePlaying,
    toggleWorkspaceExpend,
    toggleAudioState,
    toggleHelpState,
    resetBlockState,
    switchEditingTargetIndex,
    initialState as controlInitialState
};
