import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import style from './clip-wrap.css';
import classNames from 'classnames';

const getEven = num => (num % 2 === 0 ? num : num + 1);

class ClipWrap extends PureComponent {
    constructor(props) {
        super(props);
        this.handlePosition = this.handlePosition.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.state = this.handlePosition();
    }
    componentDidMount() {
        window.addEventListener('resize', this.setPosition);
        document.addEventListener('orientationchange', this.setPosition);
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.targetDom !== this.props.targetDom ||
            newProps.targetIndex !== this.props.targetIndex
        ) {
            this.setPosition(newProps);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setPosition);
        document.removeEventListener('orientationchange', this.setPosition);
    }
    setPosition(props) {
        this.setState({
            ...this.handlePosition(props)
        });
    }
    handlePosition(props) {
        props = props || this.props;
        const {targetDom, offset = 10, targetIndex} = props;
        let ele;
        if (typeof targetDom === 'string') {
            ele = document.getElementsByClassName(targetDom)[targetIndex];
        } else {
            ele = targetDom;
        }
        const {left, top, width, height} = ele.getBoundingClientRect();
        const [domX, domY, domWidth, domHeight] = [
            left,
            top,
            width,
            height
        ].map(e => Math.ceil(e));
        return {
            top: {
                width: window.innerWidth,
                height: getEven(domY - offset)
            },
            right: {
                width: window.innerWidth - domX - width,
                height: getEven(domHeight + (2 * offset)),
                top: getEven(domY - offset),
                left: domX + domWidth + offset
            },
            bottom: {
                width: window.innerWidth,
                height: getEven(document.body.clientHeight - domY - domHeight),
                top: getEven(domY - offset) + getEven(domHeight + (2 * offset))
            },
            left: {
                width: domX - offset,
                height: getEven(domHeight + (2 * offset)),
                top: getEven(domY - offset)
            },
            border: {
                width: domWidth + (2 * offset) + 4,
                height: domHeight + (2 * offset) + 6,
                transform: `translate(${domX - offset - 2}px, ${domY -
                    offset -
                    2}px)`
            }
        };
    }

    render() {
        const {left, right, bottom, top, border} = this.state;
        const {mountDom, hiddenBlockTipShow, children, className} = this.props;
        let mountPoint = document.body;
        if (mountDom) {
            mountPoint = mountDom;
        }
        return ReactDOM.createPortal(
            <React.Fragment>
                <div
                    className={classNames(style.fixed, className, {
                        [style.hidden]: hiddenBlockTipShow
                    })}
                    style={top}
                />
                <div
                    className={classNames(style.fixed, className, {
                        [style.hidden]: hiddenBlockTipShow
                    })}
                    style={right}
                />
                <div
                    className={classNames(style.fixed, className, {
                        [style.hidden]: hiddenBlockTipShow
                    })}
                    style={bottom}
                />
                <div
                    className={classNames(style.fixed, className, {
                        [style.hidden]: hiddenBlockTipShow
                    })}
                    style={left}
                />
                <div
                    className={classNames(style.fixed, style.touch, className, {
                        [style.hidden]: hiddenBlockTipShow
                    })}
                    style={{
                        width: border.width,
                        height: border.height,
                        transform: border.transform
                    }}
                />
                <div
                    className={classNames(style.fixed, className, {
                        [style.hidden]: hiddenBlockTipShow
                    })}
                >
                    {children && children('left-center')}
                </div>
            </React.Fragment>,
            mountPoint
        );
    }
}

ClipWrap.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    hiddenBlockTipShow: PropTypes.bool,
    mountDom: PropTypes.instanceOf(Element),
    // offset: PropTypes.number,
    targetDom: PropTypes.oneOfType([
        PropTypes.instanceOf(Element),
        PropTypes.string
    ]).isRequired
    // targetIndex: PropTypes.number
};

export default ClipWrap;
