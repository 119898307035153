import NodeTypes from './node-type';
import PathSymbol from './path-symbol';

import LevelFSM from '../helpers/level-fsm';
import Config, {FSMState} from '../helpers/path-config';
import log from 'Lib/log';

const DISORDER_TAG = 'DISORDER_TAG';
const tryParseDisorderBlocksToTree = patternTree => {
    const parsedDisorderBlocks = [];
    let hasDisorderBlocks = false;

    // process disorder blocks (with '*')
    // [a, b*, c*] -> [a, [b*,c*]]
    patternTree.chain.forEach(item => {
        if (item[DISORDER_TAG] !== true) {
            parsedDisorderBlocks.push(item);
            return;
        }
        hasDisorderBlocks = true;
        delete item[DISORDER_TAG];

        const latestIndex =
            parsedDisorderBlocks[parsedDisorderBlocks.length - 1];
        if (latestIndex instanceof Array) {
            latestIndex.push(item);
        } else {
            parsedDisorderBlocks.push([parsedDisorderBlocks.pop(), item]);
        }
    });

    if (!hasDisorderBlocks) return patternTree;

    const generalChains = nodes => {
        const collect = [];

        if (nodes.length === 1) {
            collect.push([].concat(nodes));
        } else {
            nodes.forEach((node, index) => {
                const restNodes = [].concat(nodes);
                restNodes.splice(index, 1);

                generalChains(restNodes).forEach(chain => {
                    collect.push([node].concat(chain));
                });
            });
        }

        return collect;
    };

    const tree = {
        type: NodeTypes.PATTERN,
        chain: []
    };

    parsedDisorderBlocks.forEach(item => {
        if (!(item instanceof Array)) {
            tree.chain.push(item);
            return;
        }

        const disorderItem = {
            type: NodeTypes.PATTERNS,
            patterns: []
        };

        Reflect.apply(
            disorderItem.patterns.push,
            disorderItem.patterns,
            generalChains(item).map(chain => ({
                type: NodeTypes.PATTERN,
                chain
            }))
        );

        tree.chain.push(disorderItem);
    });

    return tree;
};

const parseBlockTokenToTree = token => {
    if (token === PathSymbol.ASTERISK) {
        return {
            type: NodeTypes.BLOCKS_OPTIONAL,
            min: 0,
            max: Number.POSITIVE_INFINITY
        };
    } else if (token === PathSymbol.PLUS) {
        return {
            type: NodeTypes.BLOCKS_OPTIONAL,
            min: 1,
            max: Number.POSITIVE_INFINITY
        };
    }

    const rangeMatchResult = token.match(
        new RegExp(
            `^${PathSymbol.RANGE_OPENING}(\\d+)(${
                PathSymbol.RANGE_SEPARATOR
            })?(\\d+)?${PathSymbol.RANGE_CLOSING}$`
        )
    );

    if (rangeMatchResult) {
        const range = [];
        const hasComma = !!rangeMatchResult[2];
        const firstNum = Math.max(parseInt(rangeMatchResult[1], 10), 0);
        let secondNum;

        if (hasComma) {
            secondNum = rangeMatchResult[3]
                ? Math.max(parseInt(rangeMatchResult[3], 10), 0)
                : Number.POSITIVE_INFINITY;
        } else {
            secondNum = firstNum;
        }

        range.push(firstNum, secondNum);
        range.sort((a, b) => a - b);

        return {
            type: NodeTypes.BLOCKS_OPTIONAL,
            min: range[0],
            max: range[1]
        };
    }

    return {
        type: NodeTypes.BLOCK,
        opCode: token,
        params: {}
    };
};

const buildTreeWithFSM = result => {
    const {state, consume} = result;

    switch (state) {
        case FSMState.GROUP:
            return {
                type: NodeTypes.PATTERNS,
                patterns: []
            };
        case FSMState.GROUP_DISORDER:
            return {
                [DISORDER_TAG]: true,
                type: NodeTypes.PATTERNS,
                patterns: []
            };
        case FSMState.PATTERN:
            return {
                type: NodeTypes.PATTERN,
                chain: []
            };
        case FSMState.BLOCK:
            return parseBlockTokenToTree(consume);
        case FSMState.BLOCK_DISORDER: {
            const parsedTree = parseBlockTokenToTree(consume);
            parsedTree[DISORDER_TAG] = true;

            return parsedTree;
        }
        case FSMState.PARAM:
            return {};
        case FSMState.PARAM_KEY:
            return {
                type: NodeTypes.PARAM,
                key: consume
            };
        case FSMState.PARAM_VALUE:
            return {
                value: null
            };
        case FSMState.OLD_PARAM_VALUE_NUM_STRING:
        case FSMState.PARAM_VALUE_NUM_STRING:
            return {
                type: NodeTypes.PARAM_VALUE_NUM_STRING,
                text: consume
            };
        default:
            log.warn('Uncaught state.');
    }
};

const mergeTreeWithFSM = (result, lastState, treeStack) => {
    const {state} = result;
    const lastTree = treeStack.pop();
    const tree = treeStack[treeStack.length - 1];

    switch (lastState) {
        case FSMState.GROUP:
            switch (state) {
                case FSMState.GROUP:
                    tree.patterns.push(lastTree);
                    break;
                case FSMState.PATTERN:
                    tree.chain.push(lastTree);
                    break;
                case FSMState.PARAM_VALUE:
                    tree.value = lastTree;
                    break;
                default:
            }
            break;
        case FSMState.PATTERN:
            {
                const parsedTree = tryParseDisorderBlocksToTree(lastTree);
                switch (state) {
                    case FSMState.GROUP:
                    case FSMState.GROUP_DISORDER:
                        tree.patterns.push(parsedTree);
                        break;
                    case FSMState.PARAM_VALUE:
                        tree.value = parsedTree;
                        break;
                    default:
                }
            }
            break;
        case FSMState.BLOCK:
        case FSMState.BLOCK_DISORDER:
        case FSMState.GROUP_DISORDER:
            if (state === FSMState.PATTERN) {
                tree.chain.push(lastTree);
            }
            break;
        case FSMState.PARAM:
            if (state === FSMState.BLOCK || state === FSMState.BLOCK_DISORDER) {
                Object.assign(tree.params, lastTree);
            }
            break;
        case FSMState.PARAM_KEY:
            if (state === FSMState.PARAM) {
                tree[lastTree.key] = lastTree;
            }
            break;
        case FSMState.PARAM_VALUE:
            if (state === FSMState.PARAM_KEY) {
                tree.value = lastTree.value;
            }
            break;
        case FSMState.OLD_PARAM_VALUE_NUM_STRING:
            if (state === FSMState.PARAM_KEY) {
                tree.value = lastTree;
            }
            break;
        case FSMState.PARAM_VALUE_NUM_STRING:
            if (state === FSMState.PARAM_VALUE) {
                tree.value = lastTree;
            }
            break;
        default:
            log.warn('Uncaught state.');
    }
};

const parsePathToTree = path => {
    const levelFsm = new LevelFSM(Config);
    return path.split(PathSymbol.PATH_SEPARATOR).map(splitPath => {
        levelFsm.read(splitPath);

        const treeStack = [];

        let rootTree;
        let result;
        let lastState;

        while ((result = levelFsm.next())) {
            if (result.level === treeStack.length - 1) continue;
            if (result.level > treeStack.length - 1) {
                treeStack.push(buildTreeWithFSM(result));

                if (!rootTree && treeStack.length > 0) {
                    rootTree = treeStack[0];
                }
            } else {
                mergeTreeWithFSM(result, lastState, treeStack);
            }

            lastState = result.state;
        }

        return rootTree;
    });
};

export default parsePathToTree;
