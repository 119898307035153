import {
  Realtime,
  TypedMessage,
  messageType,
  messageField,
} from 'PublicRepo/node_modules/leancloud-realtime';

const realtimeInstance = new Realtime({
  appId: 'g749IPktwfPinMCEBC1QramQ-gzGzoHsz',
  appKey: 'GaimgDjEk1WSof19R3v4iKzv',
  server: {
    api: 'leancloud.wkcoding.com',
    RTMRouter: 'router-g0-push.avoscloud.com',
  },
});

const ActionMessageOperation = {
  UPDATE_MAP_LEVEL_DATA: 1,
  CHALLENGE_RANK_UPDATED: 2,

  // tob业务教师控制学生视频进度
  // 学生上报进度
  REMOTE_VIDEO_CONTROL_STUDENT_SCHEDULE: 3,
  ASK_FOR_VIDEO_TIME: 4, // 学生询问老师播放进度
  RESPONSE_VIDEO_TIME: 5, // 老师广播播放进度

  PROXY_BELLPLANET: 6,
};

const StateMessageOperation = {
  OPEN_QUIZ: 'OPEN_QUIZ',
};

// 注册动作消息
class ActionMessage extends TypedMessage {}
const ActionMessageType = 1;
messageType(ActionMessageType)(ActionMessage);
messageField('op')(ActionMessage);
messageField('data')(ActionMessage);
realtimeInstance.register(ActionMessage);

// 注册状态更新消息
class StateUpdateMessage extends TypedMessage {}
const StateUpdateMessageType = 2;
messageType(StateUpdateMessageType)(StateUpdateMessage);
messageField('op')(StateUpdateMessage);
realtimeInstance.register(StateUpdateMessage);

// 禁用学生 id 的列表
const globalType = {
  STUDENT_CONTROL_BLACK_LIST: 'STUDENT_CONTROL_BLACK_LIST',
  STUDENT_REMOTE_VIDEO_CONTROL: 'STUDENT_REMOTE_VIDEO_CONTROL',
};

export {
  realtimeInstance,
  ActionMessage,
  StateUpdateMessage,
  ActionMessageOperation,
  ActionMessageType,
  StateUpdateMessageType,
  StateMessageOperation,
  globalType,
};
