import log from '../../../../lib/log';

export default class Handler {
    constructor(handlers, handlerArgs, isHandlersAutoBreak = true) {
        this.handlers = [];
        this.handlerArgs = [].concat(handlerArgs);
        this.isHandlersAutoBreak = isHandlersAutoBreak;

        this.addHandlers(handlers || []);
    }

    createDetectFuncByStr(funcString, argKeys) {
        let func;

        try {
            func = new Function(...argKeys, `return !!(${funcString});`);
        } catch (e) {
            log.warn(`Function string invalid. ${e} "${funcString}"`);
        }

        return func;
    }

    addHandlers(handlers) {
        const argKeys = this.handlerArgs.map(arg => arg.key);
        const argValues = this.handlerArgs.map(arg => arg.value);

        handlers.forEach(handler => {
            let handlerTemp = handler;

            if (typeof handlerTemp === 'string') {
                handlerTemp = this.createDetectFuncByStr(handler, argKeys);
            }

            if (typeof handlerTemp !== 'function') {
                log.warn('Handler invalid.');
                return;
            }

            handlerTemp = handlerTemp.bind(null, ...argValues);
            this.handlers.push(handlerTemp);
        });
    }

    tryChainExecute() {
        if (this.handlers.length === 0) {
            log.warn('No handlers.');
            return {
                status: true,
                data: null
            };
        }

        const results = [];
        let returnVal;

        this.handlers[this.isHandlersAutoBreak ? 'some' : 'forEach'](
            handler => {
                try {
                    returnVal = handler();
                } catch (e) {
                    log.error(`Detector execute error. ${e}`);
                    returnVal = {
                        status: false,
                        data: null
                    };
                }
                if (typeof returnVal === 'boolean') {
                    returnVal = {
                        status: returnVal,
                        data: null
                    };
                }

                if (typeof returnVal !== 'object') {
                    log.warn('Return value of detector illegal.');
                    returnVal = {
                        status: false,
                        data: null
                    };
                }

                results.push(returnVal);

                return !returnVal.status;
            }
        );

        return results;
    }
}
